import { AxiosResponse } from "axios";
import qs from "qs";
import Repository from "@/service/bluealApi/BluealApiCliente";
import {
  PedidoImpressaoModeloListaRequest,
  PedidoImpressaoModeloListaResponse,
} from "./PedidoImpressaoModeloListaData";

const buscarPedidoModeloImpressaoLista = (
  params: PedidoImpressaoModeloListaRequest
): Promise<AxiosResponse<PedidoImpressaoModeloListaResponse>> => {
  return Repository().get<PedidoImpressaoModeloListaResponse>(
    `pedidos_impressao_modelo_lista`,
    {
      params: {
        ...params,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    }
  );
};

export { buscarPedidoModeloImpressaoLista };
