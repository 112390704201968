<template>
  <div>
    <div class="columns mb-1">
      <div class="column is-6">
        <p class="title is-6">Informações do Paciente</p>
      </div>
    </div>
    <hr class="mt-0" />
    <div v-if="envioDadosIniciado === false">
      <div>
        <div
          v-if="!formPacienteVisivel && !dadosPacienteVisivel"
          class="columns is-vcentered"
        >
          <div class="column is-6 has-text-centered">
            <p class="title is-6">
              Deseja adicionar novo paciente ou buscar existente?
            </p>
          </div>
          <div class="column is-2">
            <b-button
              @click="modalBuscarPacientes(1)"
              type="is-primary is-small"
              rounded
              icon-left="user-plus"
              >Adicionar</b-button
            >
          </div>

          <div class="column is-2">
            <b-button
              @click="modalBuscarPacientes(0)"
              type="is-twitter is-small"
              rounded
              icon-left="search"
              >Buscar</b-button
            >
          </div>
        </div>
        <div v-if="formPacienteVisivel" class="columns">
          <div class="column is-10">
            <adicionar-paciente
              @aplicarPaciente="aplicarPaciente"
            ></adicionar-paciente>
          </div>
          <div class="column is-2">
            <b-button
              type="is-warning"
              rounded
              icon-left="user-slash"
              @click="modalBuscarPacientes(0)"
              >Alterar</b-button
            >
          </div>
        </div>
        <div v-if="dadosPacienteVisivel" class="columns">
          <div class="column is-10">
            <dados-paciente
              :nome="dadosPaciente.nome"
              :dataNascimento="
                dadosPaciente.dataNascimento !== null &&
                dadosPaciente.dataNascimento !== ''
                  ? new Date(
                      dadosPaciente.dataNascimento[(0, 3)],
                      dadosPaciente.dataNascimento[(5, 2)],
                      dadosPaciente.dataNascimento[(8, 2)]
                    ).toLocaleDateString('pt-BR')
                  : ''
              "
            />
          </div>
          <div class="column is-2">
            <b-button
              type="is-warning"
              rounded
              icon-left="user-slash"
              @click="modalBuscarPacientes(0)"
              >Alterar</b-button
            >
          </div>
        </div>
      </div>
      <hr class="mt-5" />
      <form ref="formDadosTratamento">
        <div class="columns">
          <div class="column is-6">
            <b-field
              label="O que você pretende fazer com os alinhadores?"
              label-position="on-border"
            >
              <b-input
                type="textarea"
                placeholder="Obs: Plano de Tratamento"
                required
                rounded
                :disabled="dadosPaciente === null"
                minlength="10"
                validation-message="Campo deve possuir no mínimo 10 caracteres"
                v-model="planoTratamento"
              />
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              label="Qual a queixa principal do paciente:"
              label-position="on-border"
            >
              <b-input
                type="textarea"
                placeholder="Descreva"
                required
                rounded
                :disabled="dadosPaciente === null"
                minlength="10"
                validation-message="Campo deve possuir no mínimo 10 caracteres"
                v-model="queixaPrincipal"
              />
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3 pl-6 mt-3">
            <p class="title is-6">Paciente com implante?</p>
            <b-field>
              <b-radio-button
                v-model="possuiImplanteRadio"
                size="is-small"
                native-value="NAO"
                required
                :disabled="dadosPaciente === null"
                type="is-primary"
                @input="implantesPaciente = ''"
              >
                <b-icon size="is-small" icon="times"> </b-icon>
                <span>NÃO</span>
              </b-radio-button>

              <b-radio-button
                v-model="possuiImplanteRadio"
                size="is-small"
                native-value="SIM"
                aria-required="true"
                type="is-primary"
                :disabled="dadosPaciente === null"
              >
                <b-icon size="is-small" icon="check"></b-icon>
                <span>SIM</span>
              </b-radio-button>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              label="Paciente possui algum implante"
              label-position="on-border"
            >
              <b-input
                :disabled="
                  possuiImplanteRadio !== 'SIM' || dadosPaciente === null
                "
                type="textarea"
                placeholder="Descreva"
                rounded
                required
                minlength="10"
                validation-message="Campo deve possuir no mínimo 10 caracteres"
                v-model="implantesPaciente"
              />
            </b-field>
          </div>
        </div>
      </form>
    </div>
    <div v-if="envioDadosIniciado === true">
      <div class="columns is-centered mt-6 pl-6">
        <div class="column is-6">
          <h6 class="title is-6">
            {{
              codigoSolicitacao === "0"
                ? "Gerando Solicitação de Planejamento"
                : "Atualizando Solicitação de Planejamento"
            }}
          </h6>
          <b-progress size="is-large"> </b-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import solicitaProjetoRepoV2 from "../../../repository/solicitacaoProjetoV2Repository";
import DadosPaciente from "../../paciente/DadosPaciente";
import AdicionarPaciente from "../../paciente/AdicionarPaciente";
import PacientesModal from "../../paciente/PacientesModal";

export default {
  name: "DadosPacienteSolicitacao",
  props: {
    codigoSolicitacao: String,
  },
  data() {
    return {
      formPacienteVisivel: false,
      dadosPacienteVisivel: false,
      dadosPaciente: null,
      queixaPrincipal: "",
      implantesPaciente: "",
      planoTratamento: "",
      possuiImplanteRadio: "",
      envioDadosIniciado: false,
    };
  },
  components: {
    AdicionarPaciente,
    DadosPaciente,
  },
  methods: {
    exibirformPaciente() {
      this.formPacienteVisivel = true;
      this.dadosPacienteVisivel = false;
    },
    exibirDadosPaciente() {
      this.dadosPacienteVisivel = true;
      this.formPacienteVisivel = false;
    },
    modalBuscarPacientes(itemTab) {
      this.$buefy.modal.open({
        props: {
          itemTabAtivo: itemTab,
        },
        parent: this,
        component: PacientesModal,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        events: {
          pacienteSelecionado: (value) => {
            this.aplicarPaciente(value);
          },
        },
      });
    },
    aplicarPaciente(dadosPaciente) {
      this.dadosPaciente = dadosPaciente;
      this.exibirDadosPaciente();
      this.$emit("aplicarPaciente", this.dadosPaciente);
    },
    redefinir() {
      this.dadosPaciente = null;
      this.formPacienteVisivel = false;
      this.dadosPacienteVisivel = false;
      this.$emit("aplicarPaciente", this.dadosPaciente);
    },
    async gerarSolicitacaoPlanejamento() {
      let pacienteIRI =
        this.dadosPaciente !== null ? this.dadosPaciente["@id"] : "";

      let body = {
        objetivoAlinhadores: this.planoTratamento,
        queixaPaciente: this.queixaPrincipal,
        paciente: pacienteIRI,
      };

      if (this.possuiImplanteRadio === "SIM") {
        body = {
          ...body,
          implantesDescricao: this.implantesPaciente,
        };
      }

      try {
        this.envioDadosIniciado = true;
        this.$emit("desabilitarProximoPasso");
        const response = await solicitaProjetoRepoV2.gerarSolicitacao(body);
        this.envioDadosIniciado = false;
        this.$emit("proximoPasso", response.data.codigoSolicitacao);
        this.$emit("isentarCobranca", response.data.isentoCobranca);
        this.$emit("habilitarProximoPasso");
      } catch (e) {
        this.$emit("habilitarProximoPasso");
        this.envioDadosIniciado = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async adicionarInformacoesPaciente() {
      let pacienteIRI =
        this.dadosPaciente !== null ? this.dadosPaciente["@id"] : "";

      let body = {
        objetivoAlinhadores: this.planoTratamento,
        queixaPaciente: this.queixaPrincipal,
        paciente: pacienteIRI,
      };

      if (this.possuiImplanteRadio === "SIM") {
        body = {
          ...body,
          implantesDescricao: this.implantesPaciente,
        };
      }

      try {
        this.envioDadosIniciado = true;
        this.$emit("desabilitarProximoPasso");
        const response = await solicitaProjetoRepoV2.alterarSolicitacao(
          this.codigoSolicitacao,
          body
        );
        this.envioDadosIniciado = false;
        this.$emit("proximoPasso", response.data.codigoSolicitacao);
        this.$emit("habilitarProximoPasso");
      } catch (e) {
        this.$emit("habilitarProximoPasso");
        this.envioDadosIniciado = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    contextualizarDadosPaciente(
      paciente,
      queixa,
      objetivoAlinhadores,
      implantesDescricao
    ) {
      this.aplicarPaciente(paciente);
      this.queixaPrincipal = queixa;
      this.planoTratamento = objetivoAlinhadores;
      this.implantesPaciente =
        implantesDescricao === null ? "" : implantesDescricao;
      this.implantesPaciente !== ""
        ? (this.possuiImplanteRadio = "SIM")
        : (this.possuiImplanteRadio = "NAO");
    },
    validarDados() {
      if (this.dadosPaciente === null) {
        this.$buefy.toast.open({
          message: `Paciente não selecionado ou adicionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.planoTratamento.length < 10) {
        this.$buefy.toast.open({
          message: `Objetivo dos alinhadores não informado`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.queixaPrincipal.length < 10) {
        this.$buefy.toast.open({
          message: `Queixa do paciente não informada`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.possuiImplanteRadio === "") {
        this.$buefy.toast.open({
          message: `Informe se paciente possui implantes`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (
        this.possuiImplanteRadio === "SIM" &&
        this.implantesPaciente.length < 10
      ) {
        this.$buefy.toast.open({
          message: `Descreve os implantes do paciente`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else {
        if (this.codigoSolicitacao === "0") {
          this.gerarSolicitacaoPlanejamento();
        } else {
          this.adicionarInformacoesPaciente();
        }
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped></style>
