<template>
  <div class="modal-card" style="width: 100%">
    <header class="modal-card-head">
      <p class="modal-card-title">Pacientes</p>
    </header>
    <section class="modal-card-body">
      <div class="ml-2 mr-3">
        <b-tabs v-model="itemTabAtivoData">
          <b-tab-item label="Lista Pacientes" icon="align-justify">
            <div v-if="isLoading">
              <b-skeleton height="3rem" width="580px"></b-skeleton>
              <b-skeleton height="2.5rem"></b-skeleton>
              <b-skeleton size="is-medium"></b-skeleton>
              <b-skeleton size="is-medium"></b-skeleton>
              <b-skeleton size="is-medium"></b-skeleton>
              <b-skeleton size="is-medium"></b-skeleton>
              <b-skeleton size="is-medium"></b-skeleton>
              <b-skeleton size="is-medium"></b-skeleton>
              <b-skeleton size="is-medium"></b-skeleton>
              <b-skeleton size="is-medium"></b-skeleton>
              <b-skeleton size="is-medium"></b-skeleton>
            </div>
            <div class="tabela" v-if="listaPacientes !== null">
              <b-table
                :sticky-header="true"
                :data="listaPacientes.length > 0 ? listaPacientes : []"
                :selected.sync="pacienteSelecionado"
                @click="selecionarPaciente"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :default-sort-direction="defaultSortDirection"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                :striped="isStriped"
                :narrowed="isNarrowed"
                :hoverable="isHoverable"
                default-sort="codigo"
                aria-next-label="Pag. Proxima"
                aria-previous-label="Pag. Anterior"
                aria-page-label="Pag."
                aria-current-label="Pag. Atual"
              >
                <b-table-column
                  field="codigoPaciente"
                  label="Código"
                  searchable
                  sortable
                  width="15%"
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{
                      props.row.codigoPaciente
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="nome"
                  label="Nome"
                  searchable
                  sortable
                  width="50%"
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{ props.row.nome }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="dataNascimento"
                  label="Data Nascimento"
                  searchable
                  sortable
                  width="35%"
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{
                      props.row.dataNascimento !== null
                        ? new Date(props.row.dataNascimento).toLocaleDateString(
                            "pt-BR",
                            { timeZone: "UTC" }
                          )
                        : ""
                    }}</span>
                  </template>
                </b-table-column>
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <b-message type="is-warning"
                        >Sem pacientes registrados</b-message
                      >
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
          </b-tab-item>
          <b-tab-item label="Adicionar Novo Paciente" icon="user-plus">
            <PacienteAdicionar
              @pacienteSelecionado="selecionarPaciente($event)"
              @fecharModal="fecharModal()"
            />
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        rounded
        :type="pacienteSelecionado === null ? 'is-link is-light' : 'is-primary'"
        @click="fecharModal()"
        >Fechar</b-button
      >
    </footer>
  </div>
</template>

<script>
import PacienteAdicionar from "./PacienteAdicionar";
import pacienteV2Repository from "../../repository/pacienteV2Repository";

export default {
  name: "PacientesModal",
  components: {
    PacienteAdicionar,
  },
  props: {
    itemTabAtivo: Number,
  },
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 6,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      listaPacientes: null,
      pacienteSelecionado: null,
      isLoading: false,
      itemTabAtivoData: 0,
    };
  },
  beforeMount() {
    this.buscarPacientes();
    this.itemTabAtivoData = this.itemTabAtivo;
  },
  methods: {
    async buscarPacientes() {
      this.isLoading = true;
      try {
        const response = await pacienteV2Repository.buscaPacientes();
        this.listaPacientes = response.data["hydra:member"];
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro ao obter pacientes",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    selecionarPaciente(pacienteSelecionado) {
      this.$emit("pacienteSelecionado", pacienteSelecionado);
    },
    fecharModal() {
      this.$parent.close();
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tabela {
  font-size: 0.95rem;
}

.coluna-tabela {
  white-space: nowrap;
}
</style>
