<template>
  <div>
    <div v-if="isZip === null" class="columns">
      <div class="column is-12">
        <div class="columns">
          <div class="column is-12 mt-6">
            <p class="title is-6 has-text-centered">
              Deseja enviar os arquivos de Modelo 3D do paciente comprimidos em
              formato ZIP ou individualmente?
            </p>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-2">
            <b-button
              type="is-primary"
              rounded
              @click="alterarArquivoIndividual()"
            >
              <strong>Individualmente</strong>
            </b-button>
          </div>
          <div class="column is-1">
            <b-button type="is-twitter" rounded @click="alterarArquivoZip()">
              <strong>ZIP</strong>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <form
      v-if="isZip === false && envioNaoIniciado === true"
      ref="formArquivosSTL"
      class="needs-validation"
      novalidate
    >
      <div class="columns is-centered">
        <div class="column is-9">
          <p class="title is-6 has-text-centered">
            <b-message type="is-danger" size="is-small" has-icon>
              Tamanho máximo de 100 MB por arquivo, você pode enviar arquivo
              STL, OBJ e PLY, inclusive no formato ZIP.
            </b-message>
          </p>
        </div>
        <div class="column is-2 mt-1">
          <b-button type="is-link" expanded @click="alterarArquivoZip()">
            <strong>Alterar para ZIP</strong>
          </b-button>
        </div>
      </div>
      <br />
      <div class="columns is-centered is-vcentered">
        <div class="column is-4">
          <b-field>
            <b-upload
              v-model="stlSuperior"
              @input="validarArquivo(stlSuperior, 'stlSuperior')"
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large"
                      :type="iconStlSuperior"
                    >
                    </b-icon>
                  </p>
                  <p>
                    Arraste e solte o arquivo de Modelo 3D do Maxilar aqui ou
                    clique
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags" v-if="stlSuperior !== null">
            <span class="tag is-primary">
              {{ stlSuperior.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteStlSuperior()"
              ></button>
            </span>
          </div>
        </div>
        <div class="colum is-2">
          <button class="button is-info" @click.prevent="inverterArquivosStl">
            <b-icon icon="exchange-alt"></b-icon>
          </button>
        </div>
        <div class="column is-4">
          <b-field>
            <b-upload
              v-model="stlInferior"
              @input="validarArquivo(stlInferior, 'stlInferior')"
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large"
                      :type="iconStlInferior"
                    >
                    </b-icon>
                  </p>
                  <p>
                    Arraste e solte o arquivo de Modelo 3D da Mandíbula aqui ou
                    clique
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="tags" v-if="stlInferior !== null">
            <span class="tag is-primary">
              {{ stlInferior.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteStlInferior()"
              ></button>
            </span>
          </div>
        </div>
      </div>
    </form>
    <form
      v-if="isZip === true && envioNaoIniciado === true"
      ref="formArquivosSTL"
      class="needs-validation"
      novalidate
    >
      <div class="columns is-centered">
        <div class="column is-9">
          <p class="title is-6 has-text-centered">
            <b-message type="is-danger" size="is-small" has-icon>
              Tamanho máximo de 150MB. Certifique-se de que o arquivo ZIP
              contenha os modelos 3D da mandíbula e maxilar.
            </b-message>
          </p>
        </div>
        <div class="column is-2 mt-1">
          <b-button type="is-link" expanded @click="alterarArquivoIndividual()">
            <strong>Alterar para Individual</strong>
          </b-button>
        </div>
      </div>
      <br />
      <div class="columns is-centered is-vcentered">
        <div class="column is-4">
          <b-field>
            <b-upload
              v-model="arquivoZip"
              @input="validarArquivoZip()"
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large"
                      :type="iconArquivoZip"
                    >
                    </b-icon>
                  </p>
                  <p>Arraste e solte o arquivo ZIP aqui ou clique</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags" v-if="arquivoZip !== null">
            <span class="tag is-primary">
              {{ arquivoZip.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteArquivoZip()"
              ></button>
            </span>
          </div>
        </div>
      </div>
    </form>
    <div class="columns is-centered" v-if="obterUrlUploadLoading">
      <div class="column is-6">
        <h6 class="title is-6">Alocando espaço ...</h6>
        <b-progress size="is-large"> </b-progress>
      </div>
    </div>
    <div class="columns is-centered" v-if="uploadArquivoMaxilaLoading">
      <div class="column is-6">
        <h6 class="title is-6">Enviando arquivo maxilar ...</h6>
        <b-progress
          size="is-large"
          :value="uploadPercentualMaxila"
          show-value
          format="percent"
        >
        </b-progress>
      </div>
    </div>
    <div class="columns is-centered" v-if="uploadArquivoMandibulaLoading">
      <div class="column is-6">
        <h6 class="title is-6">Enviando arquivo mandíbula ...</h6>
        <b-progress
          size="is-large"
          :value="uploadPercentualMandibula"
          show-value
          format="percent"
        >
        </b-progress>
      </div>
    </div>
    <div class="columns is-centered" v-if="uploadArquivoZipLoading">
      <div class="column is-6">
        <h6 class="title is-6">Enviando arquivo zip ...</h6>
        <b-progress
          size="is-large"
          :value="uploadPercentualZip"
          show-value
          format="percent"
        >
        </b-progress>
      </div>
    </div>
    <div class="columns is-centered" v-if="envioFinalizado === true">
      <div class="column is-6">
        <h6 class="title is-5">Envio finalizado.</h6>
      </div>
    </div>
  </div>
</template>

<script>
import solicitacaoProjetoArquivosV2Repository from "@/repository/solicitacaoProjetoArquivosV2Repository";
import solicitacaoProjetoV2Repository from "@/repository/solicitacaoProjetoV2Repository";

export default {
  name: "DadosArquivosModelo3D",
  props: {
    codigoSolicitacao: String,
  },
  data() {
    return {
      envioNaoIniciado: true,
      envioFinalizado: false,
      obterUrlUploadLoading: false,
      uploadArquivoMaxilaLoading: false,
      uploadPercentualMaxila: 0,
      uploadArquivoMandibulaLoading: false,
      uploadPercentualMandibula: 0,
      uploadArquivoZipLoading: false,
      uploadPercentualZip: 0,
      isZip: null,
      arquivoZip: null,
      zipExt: null,
      stlInferior: null,
      stlInferiorExt: null,
      stlSuperior: null,
      stlSuperiorExt: null,
      arquivoZipCodigo: null,
      stlInferiorCodigo: null,
      stlSuperiorCodigo: null,
      iconArquivoZip: "",
      iconStlSuperior: "",
      iconStlInferior: "",
      exibirModalProgresso: false,
      uploadPercentage: 0,
      acceptedTypes: [
        "model/stl",
        "application/x-tgif",
        "text/plain",
        "application/zip",
        "application/octet-stream",
        "application/x-zip",
        "application/x-zip-compressed",
        "",
      ],
      acceptedTypesZip: [
        "application/zip",
        "application/octet-stream",
        "application/x-zip",
        "application/x-zip-compressed",
      ],
    };
  },
  methods: {
    validarArquivo(arquivo, model) {
      if (!arquivo) {
        this.$emit("desabilitarEnvioArquivo");
        this.$buefy.toast.open({
          message: `Nenhum Arquivo Selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
        if (model === "stlInferior") {
          this.iconStlInferior = "";
        } else if (model == "stlSuperior") {
          this.iconStlSuperior = "";
        }
      } else if (arquivo.size > 100338608) {
        this[model] = null;
        this.$emit("desabilitarEnvioArquivo");
        this.$buefy.toast.open({
          message: `Arquivo maior que 100MB`,
          position: "is-bottom",
          type: "is-danger",
        });
        if (model === "stlInferior") {
          this.iconStlInferior = "";
        } else if (model == "stlSuperior") {
          this.iconStlSuperior = "";
        }
      } else if (!this.acceptedTypes.includes(arquivo["type"])) {
        this[model] = null;
        this.$emit("desabilitarEnvioArquivo");
        this.$buefy.toast.open({
          message: `Formato do arquivo inválido`,
          position: "is-bottom",
          type: "is-danger",
        });
        if (model === "stlInferior") {
          this.iconStlInferior = "";
        } else if (model == "stlSuperior") {
          this.iconStlSuperior = "";
        }
      } else if (model === "stlInferior") {
        this.iconStlInferior = "is-success";
        if (this.stlSuperior !== null) {
          this.$emit("habilitarEnvioArquivo");
        }
      } else if (model === "stlSuperior") {
        this.iconStlSuperior = "is-success";
        if (this.stlInferior !== null) {
          this.$emit("habilitarEnvioArquivo");
        }
      }
    },
    validarArquivoZip() {
      if (this.arquivoZip === null) {
        this.$emit("desabilitarEnvioArquivo");
        this.iconArquivoZip = "";
        this.$buefy.toast.open({
          message: `Nenhum Arquivo Selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.arquivoZip.size > 160338608) {
        this.arquivoZip = null;
        this.$emit("desabilitarEnvioArquivo");
        this.iconArquivoZip = "";
        this.$buefy.toast.open({
          message: `Arquivo maior que 150MB`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (!this.acceptedTypesZip.includes(this.arquivoZip["type"])) {
        this.arquivoZip = null;
        this.iconArquivoZip = "";
        this.$emit("desabilitarEnvioArquivo");
        this.$buefy.toast.open({
          message: `Formato do arquivo inválido`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else {
        this.iconArquivoZip = "is-success";
        this.$emit("habilitarEnvioArquivo");
      }
    },
    deleteStlSuperior() {
      this.stlSuperior = null;
      this.iconStlSuperior = "";
      this.$emit("desabilitarEnvioArquivo");
    },
    deleteStlInferior() {
      this.stlInferior = null;
      this.iconStlInferior = "";
      this.$emit("desabilitarEnvioArquivo");
    },
    deleteArquivoZip() {
      this.arquivoZip = null;
      this.iconArquivoZip = "";
      this.$emit("desabilitarEnvioArquivo");
    },
    alterarArquivoZip() {
      this.isZip = true;
      this.deleteStlSuperior();
      this.deleteStlInferior();
      this.$emit("exibirUploadModelos");
    },
    alterarArquivoIndividual() {
      this.isZip = false;
      this.deleteArquivoZip();
      this.$emit("exibirUploadModelos");
    },
    inverterArquivosStl() {
      var stlInferiorAux = this.stlInferior;
      var stlInferiorCodigoAux = this.stlInferiorCodigo;
      var stlSuperiorAux = this.stlSuperior;
      var stlSuperiorCodigoAux = this.stlSuperiorCodigo;

      this.stlInferior = stlSuperiorAux;
      this.stlInferiorCodigo = stlSuperiorCodigoAux;
      this.stlSuperior = stlInferiorAux;
      this.stlSuperiorCodigo = stlInferiorCodigoAux;
    },
    async prepararUploadArquivos() {
      if (
        (this.stlInferior !== null && this.stlSuperior !== null) ||
        this.arquivoZip !== null
      ) {
        this.$emit("desabilitarEnvioArquivo");
        this.envioNaoIniciado = false;
        this.obterUrlUploadLoading = true;
        this.stlInferiorExt =
          this.stlInferior !== null
            ? this.stlInferior.name.replace(/^.*\./, "")
            : "";
        this.stlSuperiorExt =
          this.stlSuperior !== null
            ? this.stlSuperior.name.replace(/^.*\./, "")
            : "";
        this.zipExt =
          this.arquivoZip !== null
            ? this.arquivoZip.name.replace(/^.*\./, "")
            : "";
        try {
          let body = {
            operacao: "M",
          };
          if (this.stlSuperior !== null) {
            body = {
              ...body,
              extArquivoStlSuperior: this.stlSuperiorExt,
            };
          }

          if (this.stlInferior !== null) {
            body = {
              ...body,
              extArquivoStInferior: this.stlInferiorExt,
            };
          }

          if (this.arquivoZip !== null) {
            body = {
              ...body,
              extArquivoUnicoModelos: this.zipExt,
            };
          }

          await solicitacaoProjetoV2Repository.alterarSolicitacao(
            this.codigoSolicitacao,
            body
          );
          this.uploadArquivos();
        } catch (e) {
          this.obterUrlUploadLoading = false;
          this.envioNaoIniciado = true;
          this.$buefy.dialog.alert({
            title: "Erro",
            message: e.response.data["hydra:description"],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      } else {
        this.$buefy.toast.open({
          message: `Nenhum arquivo foi selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async uploadArquivos() {
      let solicitacaoProjetoIRI =
        "/solicitacao_projeto/" + this.codigoSolicitacao;
      this.envioNaoIniciado = false;
      this.obterUrlUploadLoading = true;
      try {
        const body = {
          solicitacaoProjeto: solicitacaoProjetoIRI,
          operacao: "U",
        };
        const response =
          await solicitacaoProjetoArquivosV2Repository.gerarLinkArquivos(body);
        this.obterUrlUploadLoading = false;
        this.enviarArquivos(
          response.data.linkUploadMaxilar,
          response.data.linkUploadMandibula,
          response.data.linkUploadArquivoUnicoModelos
        );
      } catch (e) {
        this.obterUrlUploadLoading = false;
        this.envioNaoIniciado = true;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    atualizarUploadMaxila(event) {
      this.uploadPercentualMaxila = Number(
        ((event.loaded * 100) / event.total).toFixed(2)
      );
    },
    atualizarUploadMandibula(event) {
      this.uploadPercentualMandibula = Number(
        ((event.loaded * 100) / event.total).toFixed(2)
      );
    },
    atualizarUploadArquivoUnico(event) {
      this.uploadPercentualZip = Number(
        ((event.loaded * 100) / event.total).toFixed(2)
      );
    },
    async enviarArquivos(linkMaxilar, linkMandibula, linkUploadArquivoUnico) {
      try {
        if (this.stlSuperior !== null && linkMaxilar !== null) {
          this.uploadArquivoMaxilaLoading = true;
          const reqMaxilar = new XMLHttpRequest();
          reqMaxilar.upload.addEventListener(
            "progress",
            this.atualizarUploadMaxila
          );
          reqMaxilar.open("PUT", linkMaxilar, true);
          reqMaxilar.setRequestHeader("Content-Type", this.stlSuperiorExt);
          reqMaxilar.onload = () => {
            if (reqMaxilar.status === 200) {
              this.uploadArquivoMaxilaLoading = false;
              if (this.stlInferior !== null && linkMandibula !== null) {
                this.uploadArquivoMandibulaLoading = true;
                const reqMandibula = new XMLHttpRequest();
                reqMandibula.upload.addEventListener(
                  "progress",
                  this.atualizarUploadMandibula
                );
                reqMandibula.open("PUT", linkMandibula, true);
                reqMandibula.setRequestHeader(
                  "Content-Type",
                  this.stlInferiorExt
                );
                reqMandibula.onload = () => {
                  if (reqMandibula.status === 200) {
                    this.uploadArquivoMandibulaLoading = false;
                    this.envioFinalizado = true;
                    this.$emit("envioModelosFinalizado");
                  }
                };
                reqMandibula.onerror = () => {
                  this.uploadArquivoMandibulaLoading = false;
                };
                reqMandibula.send(this.stlInferior);
              } else {
                this.envioFinalizado = true;
                this.$emit("envioModelosFinalizado");
              }
            }
          };
          reqMaxilar.onerror = () => {
            this.uploadArquivoMaxilaLoading = false;
          };
          reqMaxilar.send(this.stlSuperior);
        } else if (
          this.arquivoZip !== null &&
          linkUploadArquivoUnico !== null
        ) {
          this.uploadArquivoZipLoading = true;
          const reqArquivoUnico = new XMLHttpRequest();
          reqArquivoUnico.upload.addEventListener(
            "progress",
            this.atualizarUploadArquivoUnico
          );
          reqArquivoUnico.open("PUT", linkUploadArquivoUnico, true);
          reqArquivoUnico.setRequestHeader("Content-Type", this.zipExt);
          reqArquivoUnico.onload = () => {
            if (reqArquivoUnico.status === 200) {
              this.uploadArquivoZipLoading = false;
              this.envioFinalizado = true;
              this.$emit("envioModelosFinalizado");
            }
          };
          reqArquivoUnico.onerror = () => {
            this.uploadArquivoZipLoading = false;
          };
          reqArquivoUnico.send(this.arquivoZip);
        }
      } catch (e) {
        this.envioNaoIniciado = true;
        this.uploadArquivoMaxilaLoading = false;
        this.uploadArquivoMandibulaLoading = false;
        this.uploadArquivoZipLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: "Erro ao enviar arquivos",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    finalizarEnvio() {
      this.envioNaoIniciado = false;
      this.envioFinalizado = true;
      this.$emit("envioModelosFinalizado");
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped></style>
