import Vue from "vue";
import Vuex from "vuex";
import SnackBarStore from "./modules/SnackBarStore";

Vue.use(Vuex);

//init store
const store = new Vuex.Store({
  modules: {
    snackBarStore: SnackBarStore,
  },
  state: {
    dadosUsuario: JSON.parse(localStorage.getItem("@dadosUsuario")) || null,
    sessionToken: localStorage.getItem("@sessionToken"),
  },
  getters: {
    doneDadosUsuario: (state) => {
      return state.dadosUsuario;
    },
    doneSessionToken: (state) => {
      if (state.sessionToken === "null") {
        return null;
      }
      return state.sessionToken;
    },
  },
  actions: {
    changedDadosUsuario({ commit }, user) {
      commit("SET_DADOS_USUARIO", user);
    },
  },
  mutations: {
    SET_DADOS_USUARIO(state, user) {
      localStorage.setItem("@dadosUsuario", JSON.stringify(user));
      state.dadosUsuario = user;
    },
    setSessionToken(state, sessionToken) {
      localStorage.setItem("@sessionToken", sessionToken);
      state.sessionToken = sessionToken;
    },
  },
});

export default store;
