<template>
  <div class="modal-card modal-endereco">
    <header class="modal-card-head">
      <p class="modal-card-title">Endereços</p>
    </header>
    <section class="modal-card-body">
      <div>
        <b-tabs v-model="currentTab">
          <b-tab-item
            label="Lista Endereços"
            icon="align-justify"
            :visible="listaOculta !== true"
          >
            <div v-if="isLoading">
              <b-skeleton height="3vh"></b-skeleton>
              <b-skeleton height="5vh"></b-skeleton>
              <b-skeleton height="4vh"></b-skeleton>
              <b-skeleton height="4vh"></b-skeleton>
              <b-skeleton height="4vh"></b-skeleton>
              <b-skeleton height="4vh"></b-skeleton>
              <b-skeleton height="4vh"></b-skeleton>
              <b-skeleton height="4vh"></b-skeleton>
              <b-skeleton height="4vh"></b-skeleton>
              <b-skeleton height="4vh"></b-skeleton>
            </div>
            <div class="tabela-enderecos" v-if="listaEnderecos !== null">
              <b-table
                :sticky-header="true"
                :data="listaEnderecos.length > 0 ? listaEnderecos : []"
                :selected.sync="enderecoSelecionado"
                @cellclick="selecionarEndereco"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :default-sort-direction="defaultSortDirection"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                :striped="isStriped"
                :narrowed="isNarrowed"
                :hoverable="isHoverable"
                :loading="isLoading"
                default-sort="codigo"
                aria-next-label="Pag. Proxima"
                aria-previous-label="Pag. Anterior"
                aria-page-label="Pag."
                aria-current-label="Pag. Atual"
              >
                <b-table-column
                  field="logradouro"
                  label="Logradouro"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar logradouro"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela-enderecos">{{
                      props.row.logradouro
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  :numeric="true"
                  field="numero"
                  label="Número"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar número"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela-enderecos">{{
                      props.row.numero
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="bairro"
                  label="Bairro"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar bairro"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela-enderecos">{{
                      props.row.bairro
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  :numeric="true"
                  field="cep"
                  label="CEP"
                  cell-class="coluna-tabela-enderecos"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar número"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela-enderecos">{{
                      props.row.cep
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="municipio"
                  label="Municipio"
                  cell-class="coluna-tabela-enderecos"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar número"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela-enderecos">{{
                      props.row.municipio
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="uf"
                  label="UF"
                  cell-class="coluna-tabela-enderecos"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar número"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela-enderecos">{{
                      props.row.uf
                    }}</span>
                  </template>
                </b-table-column>
                <!-- <b-table-column
                  field="cep"
                  label="Editar"
                  cell-class="coluna-tabela-enderecos"
                >
                  <template>
                    <b-icon
                      icon="edit"
                      size="is-small"
                    ></b-icon>
                  </template>
                </b-table-column> -->
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <b-message type="is-warning"
                        >Sem endereços registrados</b-message
                      >
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
          </b-tab-item>
          <b-tab-item label="Adicionar Novo Endereço" icon="ambulance">
            <IncluirEndereco
              @enderecoIncluido="enderecoIncluido($event)"
              @atualizarListaEndereco="buscarEnderecos()"
              :is-endereco-principal="isEnderecoPrincipal"
            />
          </b-tab-item>
          <b-tab-item
            v-if="exibirAlterarEndereco === true"
            label="Alterar Endereco"
            icon="ambulance"
          >
            <AlterarEndereco
              :endereco-alteracao="enderecoAlteracao"
              @enderecoSelecionado="selecionarEndereco($event)"
              @atualizarListaEndereco="buscarEnderecos()"
              @tabAlteracaoCarregada="tabAlteracaoCarregada()"
            />
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button type="is-twitter" @click="fecharModal()" rounded
        ><strong>Fechar</strong></b-button
      >
    </footer>
  </div>
</template>

<script>
import IncluirEndereco from "./IncluirEndereco";
import AlterarEndereco from "./AlterarEndereco";
import enderecoRespository from "@/repository/enderecoV2Repository";
import store from "@/store/store";

export default {
  name: "EnderecosModal",
  components: {
    IncluirEndereco,
    AlterarEndereco,
  },
  props: {
    tabIndex: Number || null,
    isEnderecoPrincipal: Boolean || null,
    listaOculta: Boolean || null,
  },
  data() {
    return {
      currentTab: 0,
      enderecoAlteracao: null,
      exibirAlterarEndereco: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      enderecoPrincipal: null,
      isOpen: false,
      isOpenLista: true,
      listaEnderecos: null,
      enderecoSelecionado: null,
      isLoading: false,
    };
  },
  beforeMount() {
    if (this.tabIndex !== null) {
      this.currentTab = this.tabIndex;
    }
    this.buscarEnderecos();
  },
  methods: {
    async buscarEnderecos() {
      this.isLoading = true;
      try {
        const params = {
          estadoEndereco: 10,
          usuario: store.getters.doneUsuarioDados.codigoUsuario,
        };
        const response = await enderecoRespository.buscaEnderecosAtivosUsuario(
          params
        );
        this.listaEnderecos = response.data["hydra:member"];
        this.enderecoPrincipal = this.listaEnderecos.find((obj) => {
          return obj.tipoEndereco.codigoTipoEndereco === 10;
        });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro ao obter endereços do usuário",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    tabAlteracaoCarregada() {
      this.currentTab = 2;
    },
    alterarTab() {
      if (this.currentTab == 2) {
        this.enderecoAlteracao = null;
        this.exibirAlterarEndereco = false;
      }
    },
    enderecoIncluido(endereco) {
      this.$emit("enderecoIncluido", endereco);
      this.fecharModal();
    },
    selecionarEndereco(row, column) {
      if (column.label === "Editar") {
        this.enderecoAlteracao = row;
        this.exibirAlterarEndereco = true;
      } else {
        this.$emit("enderecoSelecionado", row);
        this.fecharModal();
      }
    },
    fecharModal() {
      this.$parent.close();
      this.$emit("fecharModal");
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.modal-endereco {
  width: 70vw;
  height: 97vh;
}
.tabela-enderecos {
  font-size: 0.85rem;
}

.coluna-tabela-enderecos {
  white-space: nowrap;
}

.first-row {
  padding-top: 1.2rem;
}
</style>
