<template>
  <div class="modal-card" style="width: auto; height: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        <b-icon icon="file-upload"></b-icon>
        <strong> Arquivos Modelos</strong>
      </p>
    </header>
    <section class="modal-card-body">
      <div class="columns is-centered is-vcentered" v-if="envioNaoIniciado">
        <div class="column is-4">
          <div class="columns is-centered mb-5">
            <h5 class="title is-5">Maxilar</h5>
          </div>
          <div class="columns is-centered mb-5">
            <b-field>
              <b-upload
                v-model="arquivoMaxilar"
                @input="validarAquivoMaxilar()"
                drag-drop
              >
                <section>
                  <div class="content has-text-centered">
                    <p>
                      <b-icon
                        icon="upload"
                        size="is-large"
                        :type="typeUploadMaxila"
                      ></b-icon>
                    </p>
                    <p>arraste e solte, ou clique</p>
                    <p>Arquivo (formato STL, PLY ou OBJ)</p>
                    <p>tamanho máximo 100mb</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
          </div>
          <div class="columns is-centered mb-5">
            <div class="tags" v-if="arquivoMaxilar !== null">
              <span class="tag is-primary">
                {{
                  arquivoMaxilar.name.length > 25
                    ? arquivoMaxilar.name.substr(0, 25) +
                      "..." +
                      arquivoMaxilar.name.substr(
                        arquivoMaxilar.name.length - 4,
                        arquivoMaxilar.name.length + 1
                      )
                    : arquivoMaxilar.name
                }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteArquivoMaxilar()"
                ></button>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-2 botao-inverter">
          <button class="button is-info" @click="inverterArquivosStl()">
            <b-icon icon="exchange-alt"></b-icon>
          </button>
        </div>
        <div class="column is-4">
          <div class="columns is-centered mb-5">
            <h5 class="title is-5">Mandíbula</h5>
          </div>
          <div class="columns is-centered mb-5">
            <b-field>
              <b-upload
                v-model="arquivoMandibula"
                @input="validarAquivoMandibula()"
                drag-drop
              >
                <section>
                  <div class="content has-text-centered">
                    <p>
                      <b-icon
                        icon="upload"
                        size="is-large"
                        :type="typeUploadMadibula"
                      ></b-icon>
                    </p>
                    <p>arraste e solte, ou clique</p>
                    <p>Arquivo (formato STL, PLY ou OBJ)</p>
                    <p>tamanho máximo 100mb</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
          </div>
          <div class="columns is-centered mb-5">
            <div class="tags" v-if="arquivoMandibula !== null">
              <span class="tag is-primary">
                {{
                  arquivoMandibula.name.length > 25
                    ? arquivoMandibula.name.substr(0, 25) +
                      "..." +
                      arquivoMandibula.name.substr(
                        arquivoMandibula.name.length - 3,
                        arquivoMandibula.name.length + 1
                      )
                    : arquivoMandibula.name
                }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteArquivoMandibula()"
                ></button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-centered" v-if="obterUrlUploadLoading">
        <div class="column is-12">
          <h6 class="title is-6">Alocando espaço ...</h6>
          <b-progress size="is-large"> </b-progress>
        </div>
      </div>
      <div class="columns is-centered" v-if="uploadArquivoMaxilaLoading">
        <div class="column is-12">
          <h6 class="title is-6">Enviando arquivo maxilar ...</h6>
          <b-progress
            size="is-large"
            :value="uploadPercentualMaxila"
            show-value
            format="percent"
          >
          </b-progress>
        </div>
      </div>
      <div class="columns is-centered" v-if="uploadArquivoMandibulaLoading">
        <div class="column is-12">
          <h6 class="title is-6">Enviando arquivo mandíbula ...</h6>
          <b-progress
            size="is-large"
            :value="uploadPercentualMandibula"
            show-value
            format="percent"
          >
          </b-progress>
        </div>
      </div>
      <div class="columns is-centered" v-if="envioFinalizado === true">
        <div class="column is-12">
          <h6 class="title is-5">Envio finalizado.</h6>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-twitter"
        rounded
        icon-left="arrow-left"
        @click="fecharModal()"
        ><strong>Fechar</strong></b-button
      >
      <b-button
        v-if="envioNaoIniciado"
        type="is-primary"
        rounded
        icon-left="cloud-upload-alt"
        @click="alterarCodigoArquivos()"
        ><strong>Enviar</strong></b-button
      >
    </footer>
  </div>
</template>

<script>
import solicitacaoProjetoArquivosV2Repository from "../../../../repository/solicitacaoProjetoArquivosV2Repository";
import solicitacaoProjetoV2Repository from "@/repository/solicitacaoProjetoV2Repository";

export default {
  name: "ModalAlterarArquivosModelo",
  props: {
    nrSolicitacaoPlanejamento: String,
  },
  data() {
    return {
      envioNaoIniciado: true,
      envioFinalizado: false,
      obterUrlUploadLoading: false,
      uploadArquivoMaxilaLoading: false,
      uploadPercentualMaxila: 0,
      uploadArquivoMandibulaLoading: false,
      uploadPercentualMandibula: 0,
      arquivoMaxilar: null,
      maxilarExt: null,
      iconArquivoMaxilar: "",
      arquivoMandibula: null,
      mandibulaExt: "",
      iconArquivoMandibula: "",
      typeUploadMadibula: "is-black",
      typeUploadMaxila: "is-black",
      acceptedTypes: ["model/stl", "application/x-tgif", "text/plain", ""],
    };
  },
  methods: {
    async alterarCodigoArquivos() {
      if (this.arquivoMandibula !== null || this.arquivoMaxilar !== null) {
        this.envioNaoIniciado = false;
        this.obterUrlUploadLoading = true;
        this.mandibulaExt =
          this.arquivoMandibula !== null
            ? this.arquivoMandibula.name.replace(/^.*\./, "")
            : "";
        this.maxilarExt =
          this.arquivoMaxilar !== null
            ? this.arquivoMaxilar.name.replace(/^.*\./, "")
            : "";
        try {
          let body = {
            operacao: "M",
          };

          if (this.arquivoMaxilar !== null) {
            body = {
              ...body,
              extArquivoStlSuperior: this.maxilarExt,
            };
          }

          if (this.arquivoMandibula !== null) {
            body = {
              ...body,
              extArquivoStInferior: this.mandibulaExt,
            };
          }

          await solicitacaoProjetoV2Repository.alterarSolicitacao(
            this.nrSolicitacaoPlanejamento,
            body
          );
          this.uploadArquivos();
        } catch (e) {
          this.obterUrlUploadLoading = false;
          this.$buefy.dialog.alert({
            title: "Erro",
            message: e.response.data["hydra:description"],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            onConfirm: () => {
              this.$parent.close();
            },
          });
        }
      } else {
        this.$buefy.toast.open({
          message: `Nenhum arquivo foi selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async uploadArquivos() {
      let solicitacaoProjetoIRI =
        "/solicitacao_projeto/" + this.nrSolicitacaoPlanejamento;
      this.envioNaoIniciado = false;
      this.obterUrlUploadLoading = true;
      try {
        const body = {
          solicitacaoProjeto: solicitacaoProjetoIRI,
          operacao: "U",
        };
        const response =
          await solicitacaoProjetoArquivosV2Repository.gerarLinkArquivos(body);
        this.obterUrlUploadLoading = false;
        this.enviarArquivos(
          response.data.linkUploadMaxilar,
          response.data.linkUploadMandibula
        );
      } catch (e) {
        this.obterUrlUploadLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          onConfirm: () => {
            this.$parent.close();
          },
        });
      }
    },
    atualizarUploadMaxila(event) {
      this.uploadPercentualMaxila = (
        (event.loaded * 100) /
        event.total
      ).toFixed(2);
    },
    atualizarUploadMandibula(event) {
      this.uploadPercentualMandibula = (
        (event.loaded * 100) /
        event.total
      ).toFixed(2);
    },
    async enviarArquivos(linkMaxilar, linkMandibula) {
      try {
        if (this.arquivoMaxilar !== null && linkMaxilar !== null) {
          this.uploadArquivoMaxilaLoading = true;
          const reqMaxilar = new XMLHttpRequest();
          reqMaxilar.upload.addEventListener(
            "progress",
            this.atualizarUploadMaxila
          );
          reqMaxilar.open("PUT", linkMaxilar, true);
          reqMaxilar.setRequestHeader("Content-Type", this.maxilarExt);
          reqMaxilar.onload = () => {
            if (reqMaxilar.status === 200) {
              this.uploadArquivoMaxilaLoading = false;
              if (this.arquivoMandibula !== null && linkMandibula !== null) {
                this.uploadArquivoMandibulaLoading = true;
                const reqMandibula = new XMLHttpRequest();
                reqMandibula.upload.addEventListener(
                  "progress",
                  this.atualizarUploadMandibula
                );
                reqMandibula.open("PUT", linkMandibula, true);
                reqMandibula.setRequestHeader(
                  "Content-Type",
                  this.mandibulaExt
                );
                reqMandibula.onload = () => {
                  if (reqMandibula.status === 200) {
                    this.uploadArquivoMandibulaLoading = false;
                    this.envioFinalizado = true;
                  }
                };
                reqMandibula.onerror = () => {
                  this.uploadArquivoMandibulaLoading = false;
                };
                reqMandibula.send(this.arquivoMandibula);
              } else {
                this.envioFinalizado = true;
              }
            }
          };
          reqMaxilar.onerror = () => {
            this.uploadArquivoMaxilaLoading = false;
          };
          reqMaxilar.send(this.arquivoMaxilar);
        } else if (this.arquivoMandibula !== null && linkMandibula !== null) {
          this.uploadArquivoMandibulaLoading = true;
          const reqMandibula = new XMLHttpRequest();
          reqMandibula.upload.addEventListener(
            "progress",
            this.atualizarUploadMandibula
          );
          reqMandibula.open("PUT", linkMandibula, true);
          reqMandibula.setRequestHeader("Content-Type", this.mandibulaExt);
          reqMandibula.onload = () => {
            if (reqMandibula.status === 200) {
              this.uploadArquivoMandibulaLoading = false;
              this.envioFinalizado = true;
            }
          };
          reqMandibula.onerror = () => {
            this.uploadArquivoMandibulaLoading = false;
          };
          reqMandibula.send(this.arquivoMandibula);
        }
      } catch (e) {
        this.uploadArquivoMaxilaLoading = false;
        this.uploadArquivoMandibulaLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: "Erro ao enviar arquivos",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    validarAquivoMaxilar() {
      const arquivoValido = this.validarArquivo(this.arquivoMaxilar);
      if (arquivoValido) {
        this.typeUploadMaxila = "is-success";
      } else {
        this.deleteArquivoMaxilar();
      }
    },
    validarAquivoMandibula() {
      const arquivoValido = this.validarArquivo(this.arquivoMandibula);
      if (arquivoValido) {
        this.typeUploadMadibula = "is-success";
      } else {
        this.deleteArquivoMandibula();
      }
    },
    validarArquivo(arquivo) {
      if (!arquivo) {
        this.$buefy.toast.open({
          message: `Nenhum Arquivo Selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
        return false;
      } else if (arquivo.size > 100000608) {
        this.$buefy.toast.open({
          message: `Arquivo maior que 100mb`,
          position: "is-bottom",
          type: "is-danger",
        });
        return false;
      } else if (!this.acceptedTypes.includes(arquivo["type"])) {
        this.$buefy.toast.open({
          message: `Formato do arquivo inválido`,
          position: "is-bottom",
          type: "is-danger",
        });
        return false;
      } else {
        return true;
      }
    },
    deleteArquivoMaxilar() {
      this.arquivoMaxilar = null;
      this.iconArquivoMaxilar = "";
      this.typeUploadMaxila = "is-black";
      this.alterarArquivoMaxilar();
    },
    deleteArquivoMandibula() {
      this.arquivoMandibula = null;
      this.iconArquivoMandibula = "";
      this.typeUploadMadibula = "is-black";
      this.alterarArquivoMandibula();
    },
    inverterArquivosStl() {
      var arquivoMandibulaAux = this.arquivoMandibula;
      var iconArquivoMandibulaAux = this.iconArquivoMandibula;
      var typeUploadMadibulaAux = this.typeUploadMadibula;

      var arquivoMaxilarAux = this.arquivoMaxilar;
      var iconArquivoMaxilarAux = this.iconArquivoMaxilar;
      var typeUploadMaxilaAux = this.typeUploadMaxila;

      this.arquivoMandibula = arquivoMaxilarAux;
      this.iconArquivoMandibula = iconArquivoMaxilarAux;
      this.typeUploadMadibula = typeUploadMaxilaAux;

      this.arquivoMaxilar = arquivoMandibulaAux;
      this.iconArquivoMaxilar = iconArquivoMandibulaAux;
      this.typeUploadMaxila = typeUploadMadibulaAux;
    },
    fecharModal() {
      if (this.envioFinalizado === true) {
        this.$emit("atualizacaoProjeto");
      }
      this.$parent.close();
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.titulo-model {
  text-align: center;
}
.botao-inverter {
  text-align: center;
}
.div-text-file {
  max-width: 100%;
}
.text-file {
  max-width: 100%;
  white-space: nowrap;
}
</style>
