<template>
  <section>
    <article class="panel is-light">
      <p class="panel-heading">Cadastrar Usuário</p>
      <div class="panel-block p-5">
        <form @submit.prevent="modalNovoUsuario.isOpen = true">
          <div class="columns">
            <div class="column is-6">
              <b-field label="Nome" label-position="on-border">
                <b-input
                  placeholder="Nome completo"
                  minlength="10"
                  maxlength="255"
                  required
                  validation-message="Campo deve possuir no mínimo 10 caracteres"
                  v-model="nome"
                  :disabled="isLoading"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="E-mail" label-position="on-border">
                <b-input
                  placeholder="Email"
                  minlength="5"
                  maxlength="150"
                  required
                  validation-message="Email inválido"
                  v-model="email"
                  type="email"
                  :disabled="isLoading"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Fone Móvel" label-position="on-border">
                <b-input
                  placeholder="DDD (sem zero) + Número"
                  minlength="11"
                  maxlength="11"
                  validation-message="Fone inválido"
                  v-model="fone"
                  type="text"
                  :disabled="isLoading"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <p class="title is-6">Perfil</p>
              <b-field>
                <b-radio-button
                  v-model="role"
                  size="is-small"
                  :native-value="'ROLE_USER'"
                  required
                  :disabled="isLoading"
                  type="is-primary"
                >
                  <b-icon size="is-small" icon="user"></b-icon>
                  <span>CLIENTE</span>
                </b-radio-button>

                <b-radio-button
                  v-model="role"
                  size="is-small"
                  :native-value="'ROLE_COLAB'"
                  required
                  :disabled="isLoading"
                  type="is-warning  "
                >
                  <b-icon size="is-small" icon="user"></b-icon>
                  <span>COLABORADOR</span>
                </b-radio-button>
                <b-radio-button
                  v-model="role"
                  size="is-small"
                  :native-value="'ROLE_ADMIN'"
                  required
                  :disabled="isLoading"
                  type="is-danger"
                >
                  <b-icon size="is-small" icon="user"></b-icon>
                  <span>ADMINISTRADOR</span>
                </b-radio-button>
              </b-field>
            </div>
          </div>
          <hr class="mt-0" />
          <div class="columns">
            <div class="column is-9">
              <BluealButton
                type="submit"
                label="Cadastrar"
                :onClick="() => (modalNovoUsuario.isOpen = true)"
                color="is-primary"
                icon="fa fa-user-plus"
                :disabled="isLoading"
                rounded
                :isLoading="isLoading"
              />
            </div>
          </div>
        </form>
      </div>
      <BluealModal
        title="Cadastro Usuário"
        icon="fas fa-user-plus"
        :isOpen="modalNovoUsuario.isOpen"
        @closeModal="modalNovoUsuario.isOpen = false"
      >
        <template v-slot:content>
          <p class="title is-6">
            Deseja criar usuário com perfil de
            {{ getUserRole(role) }}?
          </p>
        </template>
        <template v-slot:footer>
          <BluealButton
            label="Cancelar"
            :onClick="() => (modalNovoUsuario.isOpen = false)"
            color="is-danger"
            icon="fa fa-times"
            rounded
          />
          <BluealButton
            label="Sim"
            :onClick="
              () => (cadastrarUsuario(), (modalNovoUsuario.isOpen = false))
            "
            icon="fa fa-check"
            rounded
          />
        </template>
      </BluealModal>
      <BluealModal
        title="Usuário Cadastrado"
        icon="fas fa-check"
        :isOpen="modalCadastroSucesso.isOpen"
        @closeModal="modalCadastroSucesso.isOpen = false"
      >
        <template v-if="modalCadastroSucesso.isOpen" v-slot:content>
          <p class="subtitle is-6">Usuário cadastrado com sucesso.</p>
          <p class="subtitle is-6 mb-1">
            Código: <strong>{{ modalCadastroSucesso.usuario.codigo }}</strong>
          </p>
          <p class="subtitle is-6 mb-1">
            Email: <strong>{{ modalCadastroSucesso.usuario.email }}</strong>
          </p>
          <p class="subtitle is-6 mb-1">
            Perfil:
            <strong>{{
              getUserRole(modalCadastroSucesso.usuario.roles[0])
            }}</strong>
          </p>
          <p class="subtitle is-6 mb-1">
            Senha provisória:
            <strong>{{ modalCadastroSucesso.usuario.plainPassword }}</strong>
          </p>
        </template>
        <template v-slot:footer>
          <BluealButton
            label="Fechar"
            :onClick="() => (modalCadastroSucesso.isOpen = false)"
            icon="fa fa-times"
            rounded
          />
        </template>
      </BluealModal>
    </article>
  </section>
</template>

<script>
import BluealModal from "@/components/bueal-modal/BluealModal";
import BluealButton from "@/components/blueal-button/BluealButton";
import usuarioV2Repository from "@/repository/usuarioV2Repository";
import axios from "axios";
import store from "@/store/store";

export default {
  name: "CadastrarUsuario",
  components: {
    BluealModal,
    BluealButton,
  },
  data() {
    return {
      nome: "",
      email: "",
      fone: "",
      role: "ROLE_USER",
      isLoading: false,
      modalNovoUsuario: {
        isOpen: false,
      },
      modalCadastroSucesso: {
        isOpen: false,
        usuario: {
          email: "",
          codigo: "",
          roles: [],
          plainPassword: "",
        },
      },
    };
  },
  methods: {
    async cadastrarUsuario() {
      try {
        this.isLoading = true;

        const response = await usuarioV2Repository.cadastrarUsuario({
          nome: this.nome,
          email: this.email,
          fone: this.fone,
          roles: [this.role],
        });
        this.modalCadastroSucesso = {
          isOpen: true,
          usuario: {
            codigo: response.data.codigoUsuario,
            email: response.data.email,
            roles: response.data.roles,
            plainPassword: response.data.plainPassword,
          },
        };
        this.resetDados();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        let message = "Erro desconhecido";
        if (axios.isAxiosError(error) && error.response) {
          message = error.response.data["hydra:description"];
        }
        await store.dispatch("snackBarStore/showSnackBar", {
          title: "Erro cadastro usuário",
          message: message,
          color: "danger",
        });
      }
    },
    getUserRole(role) {
      if (role === "ROLE_USER") {
        return "Cliente";
      } else if (role === "ROLE_COLAB") {
        return "Colaborador";
      } else if (role === "ROLE_ADMIN") {
        return "Administrador";
      }
    },
    resetDados() {
      this.nome = "";
      this.email = "";
      this.fone = "";
      this.role = "ROLE_USER";
    },
  },
};
</script>
