import axios, { AxiosInstance } from "axios";
import store from "@/store/store";
import router from "@/router/index";

const bluealApiclient = (): AxiosInstance => {
  const baseUrl = `${process.env.VUE_APP_BLUE_ALIGNER_API}`;
  const client = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
  });

  client.interceptors.request.use(
    async (config) => {
      const tokenSession = await store.getters["doneSessionToken"];
      if (tokenSession && config.headers) {
        config.headers.Authorization = `Bearer ${tokenSession}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.commit("setSessionToken", null);
        router.push({ name: "Login" });
      }
      return Promise.reject(error);
    }
  );

  return client;
};

export default bluealApiclient;
