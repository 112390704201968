<template>
  <section>
    <article class="panel is-ligth">
      <p class="panel-heading">Etapas do Planejamento</p>
      <div class="panel-block">
        <b-steps
          type="is-primary"
          label-position="bottom"
          size="is-small"
          :has-navigation="false"
        >
          <b-step-item step="1" label="Novo Planejamento" :clickable="true">
            <div class="tile notification is-primary text-content">
              <span>
                A solicitação é iniciada pelo menu superior
                <b>Novo Planejamento</b>. <br />Após preenchimento dos dados e
                finalização da <b>Solicitação</b>, a mesma será disponibilizada
                para elaboraçao do planejamento e poderá ser consultada no menu
                <b>Planejamento > Meus Planejamentos</b>.
              </span>
            </div>
          </b-step-item>
          <b-step-item
            step="2"
            label="Planejamento em Elaboração"
            :clickable="true"
          >
            <div class="tile notification is-primary text-content">
              <span
                >Nessa etapa o planejamento está sendo confeccionando, e pode
                demorar até 7 dias para ser concluído.</span
              >
            </div>
          </b-step-item>
          <b-step-item
            step="3"
            label="Planejamento Concluído"
            :clickable="true"
          >
            <div class="tile notification is-primary text-content">
              <span>
                Nessa etapa o planejamento foi concluído e está disponível para
                consulta acessando a
                <b>Solicitação</b>
                na seção
                <b>Planejamentos</b>. É necessário <b>Aceitar</b> ou
                <b>Recusar</b>, caso o planejamento seja aceito será possível
                efetuar o pedido dos alinhadores (próxima etapa), caso o
                planejamento seja recusado a solicitação retornará para o estado
                <b>"Planejamento em Elaboração"</b>, não é necessário iniciar
                nova solicitação e um novo planejamento será registrado
                vinculado a mesma solicitação. <br />Atenção: solicitação deve
                possuir apenas <b>um Planejamento Aceito</b>.
              </span>
            </div>
          </b-step-item>
          <b-step-item step="4" label="Pedido Alinhadores" :clickable="true">
            <div class="tile notification is-primary text-content">
              <span>
                O pedido dos alinhadores pode ser feito através do menu
                <b>Alinhadores > Novo Pedido</b>, será exibido lista com
                planejamentos disponíveis para efetuar pedido de alinhadores,
                apenas clique no que deseja. É possível também iniciar o pedido
                consultando a solicitação desejada, selecionando o Planejamento
                Aceito e clicando no botão <b>Pedir Alinhadores</b>. <br />O
                pedido de alinhadores pode ser parcial, e caso ainda restem
                alinhadores disponíveis é possível fazer novo pedido, quando não
                restarem mais alinhadores disponíveis o planejamento será
                encerrado e ficará disponível apenas para consulta.
              </span>
            </div>
          </b-step-item>
        </b-steps>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: "FluxoSolicitacao",
  props: {
    open: Boolean,
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.text-content {
  text-align: justify;
  text-justify: inter-word;
}
</style>
