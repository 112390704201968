import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/cobranca";

export default {
  buscarCobrancaCodigo(codigo) {
    return Repository().get(`${resource}/${codigo}`);
  },
  solicitacaoProjetoCobranca(playload) {
    return Repository().post(`${resource}/solicitacao_projeto`, playload);
  },
  recuperarBoletoCobranca(playload) {
    return Repository().post(
      `${resource}/solicitacao_projeto/boleto`,
      playload
    );
  },
};
