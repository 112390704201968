<template>
  <div>
    <div class="column is-12" v-if="!listaPedidosLoading">
      <div
        class="tabela-projetos"
        v-if="listaPedidos !== null && !listaPedidosLoading"
      >
        <b-table
          :data="listaPedidos.length > 0 ? listaPedidos : []"
          :selected.sync="pedidoSelecionado"
          @click="selecionarPedido"
          @page-change="verificarRechamada"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :striped="isStriped"
          :narrowed="isNarrowed"
          :hoverable="isHoverable"
          default-sort="numero"
          aria-next-label="Pag. Proxima"
          aria-previous-label="Pag. Anterior"
          aria-page-label="Pag."
          aria-current-label="Pag. Atual"
        >
          <template v-for="(column, index) in columnsTemplate">
            <b-table-column
              :label="column.title"
              :key="index"
              v-bind="column"
              searchable
              :numeric="column.numeric"
            >
              <template #searchable="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                <span class="blueal-table-row-field">{{
                  column.currency
                    ? props.row[column.field].toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : props.row[column.field]
                }}</span>
              </template>
            </b-table-column>
          </template>
          <template #empty>
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <b-message type="is-warning"
                  >Sem pedido de alinhadores.</b-message
                >
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
    <div class="column is-12" v-if="listaPedidosLoading">
      <b-skeleton height="3rem"></b-skeleton>
      <b-skeleton height="2rem"></b-skeleton>
      <b-skeleton v-for="n in 9" :key="n" size="is-medium"></b-skeleton>
    </div>
  </div>
</template>

<script>
import ModalConsultaPedidoAlinhadores from "./ModalConsultaPedidoAlinhadores";
import pedidoAlinhadoresListaRepositoryV2 from "@/repository/pedidoAlinhadoresListaRepositoryV2";
import store from "@/store/store";

export default {
  name: "ListaPedidosAlinhadores",
  props: {
    estado: Number,
  },
  data() {
    return {
      listaPedidosLoading: false,
      listaPedidos: [],
      pedidoSelecionado: null,
      //configuracoes table
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      qtdPaginas: 0,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      userProfile: "ROLE_USER",
      columnsTemplate: [
        {
          title: "Pedido",
          field: "nrPedido",
          visible: true,
          numeric: false,
          currency: false,
        },
        {
          title: "Planejamento",
          field: "nrProjeto",
          visible: true,
          numeric: false,
          currency: false,
        },
        {
          title: "Usuario",
          field: "nomeUsuario",
          visible: true,
          numeric: false,
          currency: false,
        },
        {
          title: "Paciente",
          field: "nomePaciente",
          visible: true,
          numeric: false,
          currency: false,
        },
        {
          title: "Data",
          field: "dataPedido",
          visible: true,
          numeric: false,
          currency: false,
        },
        {
          title: "Estado",
          field: "descEstadoPedido",
          visible: true,
          numeric: false,
          currency: false,
        },
        {
          title: "Qtd. Inf.",
          field: "qtdAlinhadoresInferiores",
          visible: true,
          numeric: true,
          currency: false,
        },
        {
          title: "Qtd. Sup.",
          field: "qtdAlinhadoresSuperiores",
          visible: true,
          numeric: true,
          currency: false,
        },
        {
          title: "Valor",
          field: "vlrTotalPedido",
          visible: true,
          numeric: true,
          currency: true,
        },
      ],
    };
  },
  beforeMount() {
    this.listarPedidos(1);
    const userData = store.getters["doneDadosUsuario"];
    if (userData.roles.indexOf("ROLE_ADMIN") !== -1) {
      this.userProfile = userData.roles[userData.roles.indexOf("ROLE_ADMIN")];
    } else if (userData.roles.indexOf("ROLE_COLAB") !== -1) {
      this.userProfile = userData.roles[userData.roles.indexOf("ROLE_COLAB")];
    }
    this.checkUserProfileAccess(this.userProfile);
  },
  methods: {
    async listarPedidos(page) {
      this.listaPedidosLoading = true;
      try {
        const response =
          await pedidoAlinhadoresListaRepositoryV2.listarPedidoAlinhadores(
            page,
            this.estado
          );
        page === 1 ? (this.listaPedidos = []) : null;
        this.qtdPaginas = page;
        this.listaPedidos = this.listaPedidos.concat(
          response.data["hydra:member"]
        );
        this.listaPedidosLoading = false;
      } catch (error) {
        this.listaPedidosLoading = false;
        this.$buefy.dialog.alert({
          title: error.response.status + " " + error.response.statusText,
          message: error.response.data.mensagem,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    checkUserProfileAccess(profile) {
      this.columnsTemplate = this.columnsTemplate.map((column) => {
        if (column.field === "vlrTotalPedido") {
          column.visible = profile !== "ROLE_COLAB" ? true : false;
        }
        return column;
      });
    },
    selecionarPedido(pedidoSelecionado) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          dadosPedidoSelecionado: pedidoSelecionado,
        },
        hasModalCard: true,
        fullScreen: true,
        component: ModalConsultaPedidoAlinhadores,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        events: {
          atualizarPedidos: () => {
            this.listarPedidos(1);
          },
        },
      });
    },
    verificarRechamada(page) {
      const pageNumber = Number(page / 10) + 1;
      const rechamar = Number.isInteger(pageNumber);
      if (rechamar && this.qtdPaginas < pageNumber) {
        this.currentPage = page;
        this.listarPedidos(pageNumber);
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tabela-projetos {
  font-size: 0.95rem;
  max-width: 100%;
}
.blueal-table-row-field {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 50px;
}
.coluna-valor {
  text-align: right;
}
.painel-pedidos-alinhadores {
  padding: 1rem;
  padding-top: 0.6rem;
}
</style>
