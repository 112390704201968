<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-12">
        <b-field label="Observações gerais" label-position="on-border">
          <b-input
            maxlength="1500"
            v-model="observacaoProjeto"
            type="textarea"
            @input="informarObservacaoGeral(observacaoProjeto)"
            :disabled="!edicaoHabilitada"
          ></b-input>
        </b-field>
      </div>
    </div>
    <b-tabs @change="deleteArquivo()">
      <template v-for="(item, index) in listaImagensObs">
        <b-tab-item
          :key="index"
          :label="'Obs ' + item.nrSeqImagem"
          icon="file-image"
        >
          <div class="columns is-vcentered tab-item-obs">
            <div v-show="edicaoHabilitada" class="column is-3">
              <b-field>
                <b-upload
                  v-model="arquivo"
                  @input="validarArquivo(arquivo)"
                  drag-drop
                  :disabled="!edicaoHabilitada"
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon
                          icon="upload"
                          size="is-large"
                          :type="iconArquivo"
                        ></b-icon>
                      </p>
                      <p>Novo Arquivo (imagem ou zip/rar)</p>
                      <p>arraste e solte ou clique</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>
              <div class="tags" v-if="arquivo !== null">
                <span class="tag is-primary">
                  {{ arquivo.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteArquivo()"
                  ></button>
                </span>
              </div>
            </div>
            <div class="column is-9">
              <div class="columns is-centered">
                <div class="column is-8">
                  <b-field
                    :label="'Observações ' + item.nrSeqImagem"
                    label-position="on-border"
                  >
                    <b-input
                      maxlength="1500"
                      type="textarea"
                      v-model="item.observacao"
                      :value="item.observacao"
                      :disabled="!edicaoHabilitada"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-4">
                  <div class="columns is-centered">
                    <div v-if="edicaoHabilitada" class="column is-12">
                      <b-button
                        type="is-twitter"
                        icon-left="save"
                        rounded
                        @click="
                          item.edicao = false;
                          verificarArquivo(item);
                        "
                        :disabled="!edicaoHabilitada"
                        ><strong>Salvar</strong></b-button
                      >
                    </div>
                  </div>
                  <div class="columns is-centered">
                    <div class="column is-12">
                      <b-button
                        icon-left="file-image"
                        type="is-link"
                        rounded
                        @click="abrirUrlArquivo(item.nrSeqImagem)"
                        ><strong>{{ "Abrir Arquivo " }}</strong></b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
      </template>
      <b-tab-item label="Nova" icon="plus">
        <div class="columns is-centered">
          <div class="column is-3">
            <b-field>
              <b-upload
                v-model="arquivo"
                @input="validarArquivo(arquivo)"
                drag-drop
                :disabled="!edicaoHabilitada"
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon
                        icon="upload"
                        size="is-large"
                        :type="iconArquivo"
                      ></b-icon>
                    </p>
                    <p>Novo Arquivo (imagem ou zip/rar)</p>
                    <p>arraste e solte ou clique</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags" v-if="arquivo !== null">
              <span class="tag is-primary">
                {{ arquivo.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteArquivo()"
                ></button>
              </span>
            </div>
          </div>
          <div class="column is-9">
            <div class="columns">
              <div class="column is-8">
                <b-field label="Observações Imagem" label-position="on-border">
                  <b-input
                    maxlength="1500"
                    type="textarea"
                    v-model="observacaoProjetoImagem"
                    :disabled="!edicaoHabilitada"
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-button
                  icon-left="save"
                  rounded
                  type="is-primary"
                  @click="verificarArquivo(null)"
                  :disabled="!edicaoHabilitada"
                  ><strong>Enviar</strong></b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
    <b-modal
      :active.sync="exibirModalProgresso"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 500px">
        <header class="modal-card-head">
          <p class="modal-card-title">Enviando arquivo ...</p>
        </header>
        <section class="modal-card-body">
          <b-progress
            :value="uploadPercentage"
            show-value
            format="percent"
            size="is-medium"
          ></b-progress>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import projetoRepositoryV2 from "@/repository/projetoRepositoryV2";
import projetoArquivosRepositoryV2 from "@/repository/projetoArquivosRepositoryV2";

export default {
  name: "ObservacaoProjeto",
  props: {
    dadosProjeto: Object,
    edicaoHabilitada: Boolean,
  },
  data() {
    return {
      arquivo: null,
      listaImagensObs: [],
      observacaoProjeto: "",
      observacaoProjetoImagem: "",
      exibirModalProgresso: false,
      uploadPercentage: 0,
      iconArquivo: "",
      acceptedImageTypes: [
        "image/gif",
        "image/jpeg",
        "image/png",
        "application/zip",
        "application/octet-stream",
        "application/x-zip",
        "application/x-zip-compressed",
        "application/x-rar-compressed",
        "multipart/x-zip",
        "application/x-rar",
        "application/vnd.rar",
        "",
      ],
    };
  },
  beforeMount() {
    this.atribuirListaObs(this.dadosProjeto.listaImagens);
    this.observacaoProjeto = this.dadosProjeto.observacaoProjeto;
  },
  methods: {
    async enviarImagemObs() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        let body = {
          imagemObs: this.observacaoProjetoImagem,
        };

        if (this.arquivo !== null) {
          body = {
            ...body,
            extImagemObs: this.arquivo["type"].substring(
              this.arquivo["type"].indexOf("/") + 1
            ),
          };
        }

        const response = await projetoRepositoryV2.alterarProjeto(
          this.dadosProjeto.nrProjeto,
          body
        );

        const item =
          response.data.listaImagens[response.data.listaImagens.length - 1];

        loadingComponent.close();
        this.atribuirListaObs(response.data.listaImagens);
        if (this.arquivo !== null) {
          this.gerarLinkUploadImagem(item.nrSeqImagem);
        }
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao atualizar projeto",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async salvarObservacao(nrSeqImagem, observacaoTexto) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        let body = {
          nrSeqImgObs: nrSeqImagem,
          imagemObs: observacaoTexto,
        };

        if (this.arquivo !== null) {
          body = {
            ...body,
            extImagemObs: this.arquivo["type"].substring(
              this.arquivo["type"].indexOf("/") + 1
            ),
          };
        }

        const response = await projetoRepositoryV2.alterarProjeto(
          this.dadosProjeto.nrProjeto,
          body
        );
        loadingComponent.close();
        this.atribuirListaObs(response.data.listaImagens);
        if (this.arquivo !== null) {
          this.gerarLinkUploadImagem(nrSeqImagem);
        }
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao atualizar projeto",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async gerarLinkUploadImagem(nrSqlImage) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        let body = {
          projeto: `/projeto/${this.dadosProjeto.nrProjeto}`,
          operacao: "U",
        };

        if (nrSqlImage !== null) {
          body = {
            ...body,
            nrSeqImgObs: nrSqlImage,
          };
        }

        const response = await projetoArquivosRepositoryV2.gerarLinkArquivos(
          body
        );
        let itemUpload = response.data.linkUploadImagensObs.filter((item) => {
          if (item.nrSql === nrSqlImage) {
            return item;
          }
        });
        this.uploadImagemObs(itemUpload[0].linkUpload);
        loadingComponent.close();
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao gerar links de upload",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    verificarArquivo(item) {
      if (this.arquivo === null) {
        this.$buefy.dialog.confirm({
          title: "Sem novo arquivo",
          message:
            "Você deseja salvar sem alterar o arquivo de imagem existente?",
          confirmText: "Sim",
          cancelText: "Não",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () =>
            item === null
              ? this.enviarImagemObs()
              : this.salvarObservacao(item.nrSeqImagem, item.observacao),
        });
      } else {
        item === null
          ? this.enviarImagemObs()
          : this.salvarObservacao(item.nrSeqImagem, item.observacao);
      }
    },
    uploadImagemObs(link) {
      this.uploadPercentage = 0;
      this.exibirModalProgresso = true;
      const reqArquivoObs = new XMLHttpRequest();
      reqArquivoObs.upload.addEventListener("progress", (event) => {
        this.uploadPercentage = Number(
          ((event.loaded * 100) / event.total).toFixed(2)
        );
      });
      reqArquivoObs.open("PUT", link, true);
      reqArquivoObs.setRequestHeader(
        "Content-Type",
        this.arquivo.name.replace(/^.*\./, "")
      );
      reqArquivoObs.onload = () => {
        if (reqArquivoObs.status === 200) {
          this.exibirModalProgresso = false;
          this.deleteArquivo();
        }
      };
      reqArquivoObs.onerror = (error) => {
        this.reiniciarUpload();
        this.$buefy.dialog.alert({
          title: "Erro ao enviar arquivo",
          message: error,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      };
      reqArquivoObs.send(this.arquivo);
    },
    async abrirUrlArquivo(nrSqlImage) {
      const loadingComponent = this.$buefy.loading.open();

      let body = {
        projeto: `/projeto/${this.dadosProjeto.nrProjeto}`,
        operacao: "D",
      };

      if (nrSqlImage !== null) {
        body = {
          ...body,
          nrSeqImgObs: nrSqlImage,
        };
      }

      try {
        const response = await projetoArquivosRepositoryV2.gerarLinkArquivos(
          body
        );

        let itemDownload = response.data.linkDownloadImagensObs.filter(
          (item) => {
            if (item.nrSql === nrSqlImage) {
              return item;
            }
          }
        );
        loadingComponent.close();
        window.open(itemDownload[0].linkDownload, "_blank");
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao obter imagens",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    atribuirListaObs(lista) {
      this.listaImagensObs = [];
      if (lista !== null) {
        Object.keys(lista).map((i) => {
          lista[i].edicao = true;
          this.listaImagensObs.push(lista[i]);
        });
      }
      this.observacaoProjetoImagem = "";
    },
    validarArquivo(arquivo) {
      if (!arquivo) {
        this.deleteArquivo();
        this.$buefy.toast.open({
          message: `Nenhum Arquivo Selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (arquivo.size > 100000608) {
        this.deleteArquivo();
        this.$buefy.toast.open({
          message: `Arquivo maior que 100MB`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (!this.acceptedImageTypes.includes(arquivo["type"])) {
        this.deleteArquivo();
        this.$buefy.toast.open({
          message: `Arquivo não é do tipo aceito`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else {
        this.iconArquivo = "is-success";
      }
    },
    informarObservacaoGeral(observacao) {
      this.$emit("informarObservacao", observacao);
    },
    deleteArquivo() {
      this.arquivo = null;
      this.iconArquivo = "";
    },
    habilitarEdicao() {
      //this.edicaoHabilitada = true;
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tab-item-obs {
  padding-top: 0.5rem;
}
</style>
