import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/servico";

export default {
  buscarServicoAvaliacaoVigente() {
    return Repository().get(`${resource}/servico_avaliacao_vigente`);
  },
  buscarServicoConfeccaoAlinhadoresVigente() {
    return Repository().get(
      `${resource}/servico_confeccao_alinhadores_vigente`
    );
  },
};
