import { AxiosResponse } from "axios";
import Repository from "@/service/bluealApi/BluealApiCliente";
import { PedidoImpressaoModeloListaResponse } from "./PedidoImpressaoModeloListaData";

const buscarPedidoModeloImpressao = (
  codigo: number
): Promise<AxiosResponse<PedidoImpressaoModeloListaResponse>> => {
  return Repository().get<PedidoImpressaoModeloListaResponse>(
    `pedido_impressao_modelo/${codigo}`
  );
};

export { buscarPedidoModeloImpressao };
