<template>
  <div>
    <form ref="formPaciente" class="needs-validation" novalidate>
      <div class="columns" id="dados-paciente">
        <div class="column is-5">
          <b-field label="Nome" label-position="on-border">
            <b-input
              placeholder="Nome completo"
              minlength="10"
              maxlength="255"
              required
              validation-message="Campo deve possuir no mínimo 10 caracteres"
              v-model="nome"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-5">
          <b-field label="Data Nascimento" label-position="on-border">
            <b-input
              placeholder
              type="date"
              minlength="10"
              maxlength="10"
              validation-message="Data inválida"
              v-model="dataNascimento"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-button
            rounded
            type="is-primary"
            icon-left="user-plus"
            v-on:click="adicionarPaciente()"
            >Adicionar</b-button
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import pacienteV2Repository from "../../repository/pacienteV2Repository";

export default {
  name: "PacienteAdicionar",
  data() {
    return {
      nome: "",
      dataNascimento: "",
    };
  },
  methods: {
    selecionarPaciente(value) {
      this.$emit("pacienteSelecionado", value);
      this.$emit("fecharModal");
    },
    async adicionarPaciente() {
      if (this.$refs.formPaciente.checkValidity()) {
        const loadingComponent = this.$buefy.loading.open();
        try {
          const body = {
            nome: this.nome,
            dataNascimento: this.dataNascimento,
          };
          const response = await pacienteV2Repository.incluirPaciente(body);
          this.selecionarPaciente(response.data);
          loadingComponent.close();
        } catch (e) {
          loadingComponent.close();
          this.$buefy.dialog.alert({
            title: "Erro ao Incluir Endereço",
            message: e.response.data["hydra:description"],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      } else {
        this.$buefy.toast.open({
          message: `Verificar os dados informados do paciente`,
          position: "is-top",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.coluna-tabela {
  white-space: nowrap;
}

.modal {
  display: block;
  background: rgb(0, 0, 0, 0.5);
}

.modal-reponse .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-reponse .icon-box i {
  color: #dfc800;
  font-size: 60px;
  display: inline-block;
  margin-top: 13px;
}

.modal-response .icon-box-success i {
  color: #028a09;
  font-size: 60px;
  display: inline-block;
  margin-top: 13px;
}

.modal-response .icon-box-error i {
  color: #ca0707;
  font-size: 60px;
  display: inline-block;
  margin-top: 13px;
}

/* .zoom-enter-active,
    .zoom-leave-active {
        animation-duration: 0.2s;
        animation-fill-mode: both;
        animation-name: zoom;
    }

    .zoom-leave-active {
        animation-direction: reverse;
    }

    @keyframes zoom {
        from {
            opacity: 1;
            transform: scale3d(0.3, 0.3, 0.3);
        }

        100% {
            opacity: 1;
        }
    } */
</style>
