<template>
  <section>
    <article class="panel is-ligth">
      <p class="panel-heading">Pedidos de Impressão de Modelo 3D</p>
      <div class="panel-block painel-lista-pedidos-impressao columns">
        <div class="column is-12">
          <div class="columns">
            <div class="column is-12">
              <b-tabs type="is-toggle" :expanded="true">
                <b-tab-item>
                  <template slot="header">
                    <span class="is-size-7 has-text-weight-semibold">
                      <b-icon icon="list-ul"></b-icon>
                      Todas
                      <b-tag rounded>
                        <span v-if="qtdPedidosImpressaoProducao !== null">
                          {{ qtdPedidosImpressaoTodas }}
                        </span>
                        <b-skeleton
                          v-if="qtdPedidosImpressaoProducao === null"
                          width="1rem"
                        />
                      </b-tag>
                    </span>
                  </template>
                  <ListaPedidosImpressao
                    @setQtdPedidosImpressao="setQtdPedidosImpressaoTodas"
                  />
                </b-tab-item>
                <b-tab-item>
                  <template slot="header">
                    <span class="is-size-7 has-text-weight-semibold">
                      <b-icon icon="cogs"></b-icon>
                      Em Produção
                      <b-tag rounded>
                        <span v-if="qtdPedidosImpressaoProducao !== null">
                          {{ qtdPedidosImpressaoProducao }}
                        </span>
                        <b-skeleton
                          v-if="qtdPedidosImpressaoProducao === null"
                          width="1rem"
                        />
                      </b-tag>
                    </span>
                  </template>
                  <ListaPedidosImpressao
                    :pedido-codigo-estado="10"
                    @setQtdPedidosImpressao="setQtdPedidosImpressaoProducao"
                  />
                </b-tab-item>
                <b-tab-item>
                  <template slot="header">
                    <span class="is-size-7 has-text-weight-semibold">
                      <b-icon icon="check-circle"></b-icon>
                      Produção Finalizada
                      <b-tag rounded>
                        <span v-if="qtdPedidosImpressaoProducao !== null">
                          {{ qtdPedidosImpressaoFinalizado }}
                        </span>
                        <b-skeleton
                          v-if="qtdPedidosImpressaoProducao === null"
                          width="1rem"
                        />
                      </b-tag>
                    </span>
                  </template>
                  <ListaPedidosImpressao
                    :pedido-codigo-estado="20"
                    @setQtdPedidosImpressao="setQtdPedidosImpressaoFinalizado"
                  />
                </b-tab-item>
                <b-tab-item>
                  <template slot="header">
                    <span class="is-size-7 has-text-weight-semibold">
                      <b-icon icon="shipping-fast"></b-icon>
                      Enviados
                      <b-tag rounded>
                        <span v-if="qtdPedidosImpressaoProducao !== null">
                          {{ qtdPedidosImpressaoEnviado }}
                        </span>
                        <b-skeleton
                          v-if="qtdPedidosImpressaoProducao === null"
                          width="1rem"
                        />
                      </b-tag>
                    </span>
                  </template>
                  <ListaPedidosImpressao
                    :pedido-codigo-estado="30"
                    @setQtdPedidosImpressao="setQtdPedidosImpressaoEnviado"
                  />
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import ListaPedidosImpressao from "@/views/solicitacao-impressao-modelo/components/ListaPedidosImpressao.vue";

export default {
  name: "GerenciarPedidosImpressao",
  components: {
    ListaPedidosImpressao,
  },
  data() {
    return {
      qtdPedidosImpressaoTodas: null,
      qtdPedidosImpressaoProducao: null,
      qtdPedidosImpressaoFinalizado: null,
      qtdPedidosImpressaoEnviado: null,
    };
  },
  methods: {
    setQtdPedidosImpressaoTodas(qtd) {
      this.qtdPedidosImpressaoTodas = qtd;
    },
    setQtdPedidosImpressaoProducao(qtd) {
      this.qtdPedidosImpressaoProducao = qtd;
    },
    setQtdPedidosImpressaoFinalizado(qtd) {
      this.qtdPedidosImpressaoFinalizado = qtd;
    },
    setQtdPedidosImpressaoEnviado(qtd) {
      this.qtdPedidosImpressaoEnviado = qtd;
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.painel-lista-pedidos-impressao {
  padding: 0.5rem;
}
</style>
