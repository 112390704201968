import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/endereco";

export default {
  buscarEnderecoPrincipal() {
    return Repository().get(`${resource}/buscar_endereco_principal`);
  },
  buscaListaEnderecosAtivosUsuario() {
    return Repository().get(`${resource}/buscar_lista_enderecos`);
  },
  incluirEndereco(payload) {
    return Repository().post(`${resource}/incluir`, payload);
  },
  buscarEndereco(payload) {
    return Repository().post(`${resource}/buscar_endereco`, payload);
  },
};
