<template>
  <div>
    <div class="columns mb-1">
      <div class="column is-12">
        <p v-if="isUnicoArquivo === null" class="title is-5">Arquivos</p>
        <p v-if="isUnicoArquivo === true" class="title is-5">
          Arquivo Único (Imagens do Paciente e Modelos 3D)
        </p>
        <p
          v-if="isUnicoArquivo === false && imagensPacientePendente === true"
          class="title is-5"
        >
          Imagens do Paciente
        </p>
        <p
          v-if="isUnicoArquivo === false && imagensPacientePendente === false"
          class="title is-5"
        >
          Arquivos Modelo 3D
        </p>
      </div>
    </div>
    <hr class="mt-0" />
    <div
      v-if="
        isUnicoArquivo === null &&
        (enviarNovamente === true || possuiArquivos === false)
      "
      class="columns"
    >
      <div class="column is-12">
        <div class="columns">
          <div class="column is-12 mt-6">
            <p class="title is-6 has-text-centered">
              Deseja enviar todos arquivos, modelo 3D (stl) e imagens pacientes,
              comprimidos em formato ZIP ou individualmente?
            </p>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-2">
            <b-button type="is-primary" rounded @click="alterarArquivoUnico()">
              <strong>Arquivo ZIP</strong>
            </b-button>
          </div>
          <div class="column is-2">
            <b-button
              type="is-twitter"
              rounded
              @click="alterarArquivosIndividual()"
            >
              <strong>Individualmente</strong>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        isUnicoArquivo === null &&
        possuiArquivos === true &&
        enviarNovamente === false
      "
      class="columns"
    >
      <div class="column is-12">
        <div class="columns">
          <div class="column is-12 mt-6">
            <p class="title is-6 has-text-centered">
              Esta solicitação já possui arquivos, deseja enviar enviá-los
              novamente?
            </p>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-2">
            <b-button type="is-primary" rounded @click="enviarNovamente = true">
              <strong>SIM</strong>
            </b-button>
          </div>
          <div class="column is-2">
            <b-button type="is-twitter" rounded @click="proximoPasso()">
              <strong>Não</strong>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isUnicoArquivo === true">
      <DadosArquivoUnico
        :codigo-solicitacao="codigoSolicitacao"
        ref="refDadosArquivoUnico"
        @envioArquivoUnicoFinalizado="envioArquivoUnicoFinalizado"
        @reiniciarSelecaoArquivo="reiniciarSelecaoArquivo"
        @habilitarEnvioArquivo="habilitarEnvioArquivo"
        @desabilitarEnvioArquivo="desabilitarEnvioArquivo"
        @proximoPasso="proximoPasso"
      />
    </div>
    <div v-if="isUnicoArquivo === false && imagensPacientePendente === true">
      <DadosArquivosImagens
        :codigo-solicitacao="codigoSolicitacao"
        ref="refArquivoImagens"
        @naoEnviarImagemPaciente="envioImagensFinalizado"
        @exibirUploadImagens="exibirUploadImagens"
        @envioImagensFinalizado="envioImagensFinalizado"
        @habilitarEnvioArquivo="habilitarEnvioArquivo"
        @desabilitarEnvioArquivo="desabilitarEnvioArquivo"
        @uploadModelos="envioImagensFinalizado"
        @uploadImagens="envioImagensFinalizado"
      />
    </div>
    <div v-if="isUnicoArquivo === false && imagensPacientePendente === false">
      <DadosArquivosModelo3D
        :codigo-solicitacao="codigoSolicitacao"
        @exibirUploadModelos="exibirUploadModelos"
        ref="refArquivoModelo"
        @envioModelosFinalizado="envioModelosFinalizado"
        @habilitarEnvioArquivo="habilitarEnvioArquivo"
        @desabilitarEnvioArquivo="desabilitarEnvioArquivo"
      />
    </div>
  </div>
</template>

<script>
import DadosArquivoUnico from "./DadosArquivoUnico";
import DadosArquivosModelo3D from "./DadosArquivosModelo3D";
import DadosArquivosImagens from "./DadosArquivosImagens";

export default {
  name: "DadosArquivosV2",
  props: {
    codigoSolicitacao: String,
    possuiArquivos: Boolean,
    possuiImagens: Boolean,
  },
  components: {
    DadosArquivoUnico,
    DadosArquivosModelo3D,
    DadosArquivosImagens,
  },
  data() {
    return {
      isUnicoArquivo: null,
      imagensPacientePendente: true,
      modelosPendente: true,
      enviarNovamente: false,
    };
  },
  methods: {
    reiniciarEnvioArquivo() {
      this.enviarNovamente = false;
      this.isUnicoArquivo = null;
    },
    alterarArquivoUnico() {
      this.isUnicoArquivo = true;
      this.$emit("exibirUploadArquivoUnico", true);
      this.$emit("desabilitarEnvioArquivo");
    },
    alterarArquivosIndividual() {
      this.isUnicoArquivo = false;
      this.$emit("desabilitarEnvioArquivo");
    },
    validarDados() {
      if (this.isUnicoArquivo === true) {
        this.imagensPacientePendente = false;
      } else if (
        this.isUnicoArquivo === false &&
        this.imagensPacientePendente === true
      ) {
        this.imagensPacientePendente = true;
      } else if (
        this.isUnicoArquivo === false &&
        this.imagensPacientePendente === false
      ) {
        this.imagensPacientePendente = false;
      }
    },
    reiniciarSelecaoArquivo() {
      this.isUnicoArquivo = null;
      this.imagensPacientePendente = true;
      this.$emit("exibirUploadArquivoUnico", false);
    },
    exibirUploadImagens() {
      this.$emit("exibirUploadImagens");
    },
    exibirUploadModelos() {
      this.$emit("exibirUploadModelos");
    },
    exibirUploadModelo() {
      this.imagensPacientePendente = false;
      this.isUnicoArquivo = false;
    },
    enviarArquivoUnico() {
      //iniciar validacao e exibirUploadArquivo
      this.$refs.refDadosArquivoUnico.prepararUploadArquivos();
    },
    enviarImagens() {
      this.$refs.refArquivoImagens.iniciarEnvio();
    },
    enviarModelos() {
      this.$refs.refArquivoModelo.prepararUploadArquivos();
    },
    envioArquivoUnicoFinalizado() {
      this.$emit("envioArquivoUnicoFinalizado");
    },
    envioImagensFinalizado() {
      this.imagensPacientePendente = false;
      this.$emit("envioImagensFinalizado");
    },
    envioModelosFinalizado() {
      this.$emit("envioModelosFinalizado");
    },
    habilitarEnvioArquivo() {
      this.$emit("habilitarEnvioArquivo");
    },
    desabilitarEnvioArquivo() {
      this.$emit("desabilitarEnvioArquivo");
    },
    proximoPasso() {
      this.$emit("proximoPasso", this.codigoSolicitacao);
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped></style>
