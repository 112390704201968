<template>
  <div>
    <div class="column is-12">
      <div class="columns mb-1">
        <div class="column is-12">
          <p class="title is-5">Dados do tratamento</p>
        </div>
      </div>
      <hr class="mt-0" />
      <div v-if="envioDadosIniciado === false">
        <div class="columns">
          <div class="column is-4">
            <section>
              <p class="title is-7">Quais Arcadas deseja movimentar?</p>
              <b-field>
                <b-radio-button
                  v-model="arcadasMovimentacao"
                  size="is-small"
                  native-value="Superior"
                  required
                  type="is-primary"
                >
                  <b-icon icon="sort-up"> </b-icon>
                  <span>Superior</span>
                </b-radio-button>

                <b-radio-button
                  v-model="arcadasMovimentacao"
                  size="is-small"
                  native-value="Inferior"
                  required
                  type="is-primary"
                >
                  <b-icon icon="sort-down"></b-icon>
                  <span>Inferior</span>
                </b-radio-button>

                <b-radio-button
                  v-model="arcadasMovimentacao"
                  size="is-small"
                  required
                  native-value="Ambas"
                  type="is-primary"
                >
                  <b-icon icon="sort"></b-icon>
                  <span>Ambas</span>
                </b-radio-button>
              </b-field>
            </section>
          </div>
          <div class="column is-3">
            <section>
              <p class="title is-7">Paciente aceita desgaste interproximal?</p>
              <b-field>
                <b-radio-button
                  v-model="desgasteInterproximal"
                  size="is-small"
                  native-value="SIM"
                  type="is-primary"
                >
                  <b-icon size="is-small" icon="check"> </b-icon>
                  <span>SIM</span>
                </b-radio-button>

                <b-radio-button
                  v-model="desgasteInterproximal"
                  size="is-small"
                  native-value="NAO"
                  type="is-primary"
                >
                  <b-icon size="is-small" icon="times"></b-icon>
                  <span>NÃO</span>
                </b-radio-button>
              </b-field>
            </section>
          </div>
          <div class="column is-3">
            <section>
              <p class="title is-7">Relação Molar</p>
              <b-field>
                <b-radio-button
                  v-model="relacaoMolar"
                  size="is-small"
                  native-value="Manter"
                  type="is-primary"
                >
                  <b-icon size="is-small" icon="check"> </b-icon>
                  <span>Manter</span>
                </b-radio-button>

                <b-radio-button
                  v-model="relacaoMolar"
                  size="is-small"
                  native-value="Melhorar"
                  type="is-primary"
                >
                  <b-icon size="is-small" icon="check"> </b-icon>
                  <span>Melhorar</span>
                </b-radio-button>

                <b-radio-button
                  v-model="relacaoMolar"
                  size="is-small"
                  native-value="Corrigir"
                  type="is-primary"
                >
                  <b-icon size="is-small" icon="times"></b-icon>
                  <span>Corrigir</span>
                </b-radio-button>
              </b-field>
            </section>
          </div>
        </div>
        <br /><br />
        <div class="columns">
          <div class="column is-12">
            <section>
              <div class="columns">
                <div class="column is-2">
                  <div class="field">
                    <p class="title is-7">Linha Média Superior</p>
                    <b-switch
                      v-model="linhaMediaSuperior"
                      true-value="Corrigir"
                      false-value="Manter"
                      @input="
                        linhaMediaSuperiorDesc = '';
                        linhaMediaSuperiorDirecao = '';
                      "
                    >
                      {{ linhaMediaSuperior }}
                    </b-switch>
                  </div>
                </div>
                <div class="column is-2">
                  <p class="title is-7">Linha Média Superior Direção</p>
                  <b-field>
                    <b-radio-button
                      v-model="linhaMediaSuperiorDirecao"
                      :disabled="linhaMediaSuperior !== 'Corrigir'"
                      size="is-small"
                      native-value="Direita"
                      type="is-primary"
                    >
                      <b-icon icon="caret-left"> </b-icon>
                      <span>Direita</span>
                    </b-radio-button>

                    <b-radio-button
                      v-model="linhaMediaSuperiorDirecao"
                      :disabled="linhaMediaSuperior !== 'Corrigir'"
                      size="is-small"
                      native-value="Esquerda"
                      type="is-primary"
                    >
                      <b-icon icon="caret-right"></b-icon>
                      <span>Esquerda</span>
                    </b-radio-button>
                  </b-field>
                </div>
                <div class="column is-3">
                  <p class="title is-7">Linha Média Superior Distância</p>
                  <b-field>
                    <b-input
                      type="text"
                      placeholder="informar valores em mm"
                      v-model="linhaMediaSuperiorDesc"
                      :disabled="linhaMediaSuperior !== 'Corrigir'"
                    />
                  </b-field>
                </div>
              </div>
            </section>
          </div>
        </div>
        <br /><br />
        <div class="columns">
          <div class="column is-12">
            <section>
              <div class="columns">
                <div class="column is-2">
                  <div class="field">
                    <p class="title is-7">Linha Média Inferior</p>
                    <b-switch
                      v-model="linhaMediaInferior"
                      true-value="Corrigir"
                      false-value="Manter"
                      @input="
                        linhaMediaInferiorDesc = '';
                        linhaMediaInferiorDirecao = '';
                      "
                    >
                      {{ linhaMediaInferior }}
                    </b-switch>
                  </div>
                </div>
                <div class="column is-2">
                  <p class="title is-7">Linha Média Inferior Direção</p>
                  <b-field>
                    <b-radio-button
                      v-model="linhaMediaInferiorDirecao"
                      size="is-small"
                      native-value="Direita"
                      type="is-primary"
                      :disabled="linhaMediaInferior !== 'Corrigir'"
                    >
                      <b-icon icon="caret-left"> </b-icon>
                      <span>Direita</span>
                    </b-radio-button>

                    <b-radio-button
                      v-model="linhaMediaInferiorDirecao"
                      size="is-small"
                      native-value="Esquerda"
                      type="is-primary"
                      :disabled="linhaMediaInferior !== 'Corrigir'"
                    >
                      <b-icon icon="caret-right"></b-icon>
                      <span>Esquerda</span>
                    </b-radio-button>
                  </b-field>
                </div>
                <div class="column is-3">
                  <p class="title is-7">Linha Média Inferior Distância</p>
                  <b-field>
                    <b-input
                      type="text"
                      placeholder="informar valores em mm"
                      v-model="linhaMediaInferiorDesc"
                      :disabled="linhaMediaInferior !== 'Corrigir'"
                    />
                  </b-field>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div v-if="envioDadosIniciado === true">
        <div class="columns is-centered mt-6 pl-6">
          <div class="column is-6">
            <h6 class="title is-6">Atualizando Solicitação de Planejamento</h6>
            <b-progress size="is-large"> </b-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import solicitaProjetoRepoV2 from "../../../repository/solicitacaoProjetoV2Repository";

export default {
  name: "DadosTratamentoV2",
  props: {
    codigoSolicitacao: String,
  },
  data() {
    return {
      arcadasMovimentacao: "",
      desgasteInterproximal: "",
      linhaMediaSuperior: "Manter",
      linhaMediaSuperiorDirecao: "",
      linhaMediaSuperiorDesc: "",
      linhaMediaInferior: "Manter",
      linhaMediaInferiorDirecao: "",
      linhaMediaInferiorDesc: "",
      relacaoMolar: "",
      envioDadosIniciado: false,
    };
  },
  methods: {
    async adicionarDadosTratamento() {
      let body = {
        arcadaMovimentacao: this.arcadasMovimentacao,
        desgasteInterProximal: this.desgasteInterproximal,
        relacaoMolar: this.relacaoMolar,
        linhaSuperiorMedia: this.linhaMediaSuperior,
        linhaInferiorMedia: this.linhaMediaInferior,
      };

      if (this.linhaMediaSuperior === "Corrigir") {
        body = {
          ...body,
          linhaSuperiorDirecao: this.linhaMediaSuperiorDirecao,
          linhaSuperiorMediaMovimento: this.linhaMediaSuperiorDesc,
        };
      }

      if (this.linhaMediaInferior === "Corrigir") {
        body = {
          ...body,
          linhaInferiorDirecao: this.linhaMediaInferiorDirecao,
          linhaInferiorMovimento: this.linhaMediaInferiorDesc,
        };
      }

      try {
        this.envioDadosIniciado = true;
        this.$emit("desabilitarProximoPasso");
        const response = await solicitaProjetoRepoV2.alterarSolicitacao(
          this.codigoSolicitacao,
          body
        );
        this.envioDadosIniciado = false;
        this.$emit("proximoPasso", response.data.codigoSolicitacao);
        this.$emit("habilitarProximoPasso");
      } catch (e) {
        this.envioDadosIniciado = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    validarDados() {
      if (this.arcadasMovimentacao === "") {
        this.$buefy.toast.open({
          message: `Informe a arcada que deseja movimentar`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.desgasteInterproximal === "") {
        this.$buefy.toast.open({
          message: `Informe se aceita desgaste interproximal`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.relacaoMolar === "") {
        this.$buefy.toast.open({
          message: `Informe a relação molar`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (
        this.linhaMediaSuperior === "Corrigir" &&
        this.linhaMediaSuperiorDirecao == ""
      ) {
        this.$buefy.toast.open({
          message: `Informe direção da linha média superior`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (
        this.linhaMediaSuperior === "Corrigir" &&
        this.linhaMediaSuperiorDesc == ""
      ) {
        this.$buefy.toast.open({
          message: `Informe a distância da linha média superior`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (
        this.linhaMediaInferior === "Corrigir" &&
        this.linhaMediaInferiorDirecao == ""
      ) {
        this.$buefy.toast.open({
          message: `Informe a direção da linha média inferior`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (
        this.linhaMediaInferior === "Corrigir" &&
        this.linhaMediaInferiorDesc == ""
      ) {
        this.$buefy.toast.open({
          message: `Informe a distância da linha média inferior`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else {
        this.adicionarDadosTratamento();
      }
    },
    contextualizarDadosTratamento(
      arcadaMovimentacao,
      desgasteInterProximal,
      relacaoMolar,
      linhaSuperiorMedia,
      linhaSuperiorDirecao,
      linhaSuperiorMediaMovimento,
      linhaInferiorMedia,
      linhaInferiorDirecao,
      linhaInferiorMovimento
    ) {
      this.arcadasMovimentacao =
        arcadaMovimentacao !== null ? arcadaMovimentacao : "";
      this.desgasteInterproximal =
        desgasteInterProximal !== null ? desgasteInterProximal : "";
      this.linhaMediaSuperior =
        linhaSuperiorMedia !== null ? linhaSuperiorMedia : "Manter";
      this.linhaMediaSuperiorDirecao =
        linhaSuperiorDirecao !== null ? linhaSuperiorDirecao : "";
      this.linhaMediaSuperiorDesc =
        linhaSuperiorMediaMovimento !== null ? linhaSuperiorMediaMovimento : "";
      this.linhaMediaInferior =
        linhaInferiorMedia !== null ? linhaInferiorMedia : "Manter";
      this.linhaMediaInferiorDirecao =
        linhaInferiorDirecao !== null ? linhaInferiorDirecao : "";
      this.linhaMediaInferiorDesc =
        linhaInferiorMovimento !== null ? linhaInferiorMovimento : "";
      this.relacaoMolar = relacaoMolar !== null ? relacaoMolar : "";
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped></style>
