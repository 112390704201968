import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/solicitacao_projeto";

export default {
  obterDadosSolicitacaoProjeto(payload) {
    return Repository().post(`${resource}`, payload);
  },
  listarTodoSolicitacoes() {
    return Repository().get(`${resource}/lista_solicitacoes`);
  },
  listarTodoSolicitacoesUsuario() {
    return Repository().get(`${resource}/lista_solicitacoes_usuario`);
  },
  gerarUrlArquivo(payload) {
    return Repository().post(`${resource}/url_arquivo`, payload);
  },
  aceitarSolicitacao(payload) {
    return Repository().put(`${resource}/inicio_projeto`, payload);
  },
  listarSolicitacoesUsuarioIniciadas() {
    return Repository().get(`${resource}/lista_solicitacoes_iniciadas_usuario`);
  },
  finalizarProjetoSemCobranca(payload) {
    return Repository().put(`${resource}/finalizar_projeto`, payload);
  },
  verificarPacienteComProjetoAnterior(payload) {
    return Repository().put(`${resource}/paciente_com_projeto`, payload);
  },
};
