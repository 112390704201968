<template>
  <div>
    <section>
      <article class="panel is-ligth">
        <p class="panel-heading">Lista de Usuários</p>
        <div class="panel-block painel columns">
          <div class="column is-12" v-if="!listaUsuariosLoading">
            <div class="tabela">
              <b-table
                v-if="listaUsuarios !== null && listaUsuarios.length > 0"
                :data="listaUsuarios"
                :selected.sync="usuarioSelecionado"
                @click="selecionarUsuario"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :default-sort-direction="defaultSortDirection"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                :striped="isStriped"
                :narrowed="isNarrowed"
                :hoverable="isHoverable"
                @page-change="verificarRechamada"
                default-sort="numero"
                aria-next-label="Pag. Proxima"
                aria-previous-label="Pag. Anterior"
                aria-page-label="Pag."
                aria-current-label="Pag. Atual"
              >
                <b-table-column
                  field="codigoUsuario"
                  width="50"
                  label="Codigo"
                  class="coluna-tabela"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{
                      props.row.codigoUsuario
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="nome"
                  width="150"
                  label="Nome"
                  class="coluna-tabela"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{ props.row.nome }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="email"
                  width="100"
                  label="Email"
                  class="coluna-tabela"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{ props.row.email }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="roles"
                  width="100"
                  label="Tipo"
                  class="coluna-tabela-projetos"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{
                      props.row.roles.includes("ROLE_ADMIN") ? "ADMIN" : "COMUM"
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="numeroCRO"
                  width="50"
                  label="CRO"
                  class="coluna-tabela-projetos"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{
                      props.row.numeroCro !== null ? props.row.numeroCRO : "-"
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="ufCRO"
                  width="50"
                  label="UF"
                  class="coluna-tabela-projetos"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{
                      props.row.ufCro !== null ? props.row.ufCRO : "-"
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="usuarioEstado.descricao"
                  width="50"
                  label="Estado"
                  class="coluna-tabela-projetos"
                  searchable
                  sortable
                >
                  <template slot="searchable" slot-scope="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Filtrar"
                      size="is-small"
                    />
                  </template>
                  <template v-slot="props">
                    <span class="coluna-tabela">{{
                      props.row.usuarioEstado.descricao
                    }}</span>
                  </template>
                </b-table-column>
              </b-table>
            </div>
          </div>
          <div class="column is-12" v-if="listaUsuariosLoading">
            <b-skeleton height="3rem"></b-skeleton>
            <b-skeleton height="2rem"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
            <b-skeleton size="is-medium"></b-skeleton>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import ModalConsultaDadosUsuario from "./ModalConsultaDadosUsuario";
import usuarioV2Repository from "@/repository/usuarioV2Repository";

export default {
  name: "ListaTodosUsuarios",
  data() {
    return {
      listaUsuariosLoading: false,
      listaUsuarios: null,
      usuarioSelecionado: null,
      //configuracoes table
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      nextPage: 0,
      lastPage: 0,
    };
  },
  beforeMount() {
    this.listarUsuarios(1);
  },
  methods: {
    async listarUsuarios(page) {
      this.listaUsuariosLoading = true;
      try {
        const params = {
          page: page,
          itemsPerPage: 100,
        };
        const response = await usuarioV2Repository.listarUsuarios(params);
        if (page === 1) {
          this.listaUsuarios = response.data["hydra:member"];
        } else {
          this.listaUsuarios = this.listaUsuarios.concat(
            response.data["hydra:member"]
          );
        }
        const nextPageString = response.data["hydra:view"]["hydra:next"];
        this.nextPage = nextPageString
          ? Number(
              nextPageString.substring(nextPageString.indexOf("&page=") + 6)
            )
          : 0;

        const lastPageString = response.data["hydra:view"]["hydra:last"];
        this.lastPage = lastPageString
          ? Number(
              lastPageString.substring(lastPageString.indexOf("&page=") + 6)
            )
          : 0;

        this.listaUsuariosLoading = false;
      } catch (error) {
        this.listaUsuariosLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro ao obter lista de usuários",
          message: error.response
            ? error.response.data["hydra:description"]
            : error.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async verificarRechamada(page) {
      const pageNumber = Number(page / 10) + 1;
      if (
        this.nextPage <= pageNumber &&
        this.nextPage <= this.lastPage &&
        this.nextPage !== 0
      ) {
        await this.listarUsuarios(this.nextPage);
        this.currentPage = page;
      }
    },
    selecionarUsuario(usuarioSelecionado) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          dadosUsuario: usuarioSelecionado,
        },
        hasModalCard: true,
        fullScreen: true,
        component: ModalConsultaDadosUsuario,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        events: {
          atualizarUsuarios: () => {
            this.listarUsuarios(1);
          },
        },
      });
    },
    formatarFoneCelular(nrCelular) {
      if (nrCelular) {
        return nrCelular.replace(
          /(\d{2})(\d{5})(\d{4})/,
          function (regex, arg1, arg2, arg3) {
            return arg1 + " " + arg2 + "-" + arg3;
          }
        );
      } else {
        return nrCelular;
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tabela {
  font-size: 0.95rem;
  max-width: 100%;
}
.coluna-tabela {
  white-space: nowrap;
}
.painel {
  padding: 1rem;
}
</style>
