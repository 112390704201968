<template>
  <div class="columns">
    <div class="column is-12" v-if="!listaPedidosImpressaoLoading">
      <b-table
        @page-change="verificarRechamada"
        :sticky-header="false"
        :data="listaPedidosImpressao"
        :selected.sync="pedidoSelecionado"
        @click="selecionarPedido"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :total="totalItens"
        backend-pagination
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        :striped="isStriped"
        :narrowed="isNarrowed"
        :hoverable="isHoverable"
        default-sort="codigoPaciente"
        aria-next-label="Pag. Proxima"
        aria-previous-label="Pag. Anterior"
        aria-page-label="Pag."
        aria-current-label="Pag. Atual"
      >
        <template v-for="(column, index) in columnsTemplate">
          <b-table-column
            :key="index"
            v-bind="column"
            :label="column.title"
            :visible="column.visible"
            searchable
          >
            <template #searchable="props">
              <b-input
                style="width: 70%"
                v-model="props.filters[props.column.field]"
                placeholder="Pesquisar..."
                size="is-small"
                expanded
              />
            </template>
            <template v-slot="props">
              {{
                Date.parse(props.row[column.field])
                  ? new Date(
                      Date.parse(props.row[column.field])
                    ).toLocaleDateString()
                  : props.row[column.field.substring(0, 25)]
              }}
            </template>
          </b-table-column>
        </template>
        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <b-message type="is-warning">Sem pedido de modelos.</b-message>
            </div>
          </section>
        </template>
      </b-table>
    </div>
    <div class="column is-12" v-if="listaPedidosImpressaoLoading">
      <b-skeleton height="3rem"></b-skeleton>
      <b-skeleton height="2.5rem"></b-skeleton>
      <b-skeleton height="2.5rem"></b-skeleton>
      <b-skeleton v-for="n in 9" :key="n" size="is-medium" />
    </div>
  </div>
</template>

<script>
import ModalConsultaPedidoImpressaoModelo from "./ModalConsultaPedidoImpressaoModelo";
import { buscarPedidoModeloImpressaoLista } from "@/repository/PedidoImpressaoModelo/PedidoImpressaoModeloListaRepository";
import store from "@/store/store";

export default {
  name: "ListaPedidosImpressao",
  props: {
    pedidoCodigoEstado: { type: Number, require: false },
  },
  data() {
    return {
      listaPedidosImpressaoLoading: true,
      listaPedidosImpressao: [],
      pedidoSelecionado: null,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      totalItens: 0,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      columnsTemplate: [
        { title: "Pedido", field: "pedidoNumero", visible: true },
        { title: "Data", field: "pedidoData", visible: true },
        { title: "Estado", field: "pedidoEstadoDescricao", visible: true },
        {
          title: "Estado Cobrança",
          field: "cobrancaEstadoDescricao",
          visible: true,
        },
        { title: "Usuario", field: "usuarioNome", visible: true },
        { title: "Paciente", field: "pacienteNome", visible: true },
        { title: "Valor", field: "pedidoValor", visible: true },
      ],
    };
  },
  async beforeMount() {
    await this.buscarPedidosImpressao(
      this.pedidoCodigoEstado,
      this.currentPage
    );
    const userData = await store.getters["doneDadosUsuario"];
    if (userData.roles.indexOf("ROLE_ADMIN") !== -1) {
      this.userProfile = userData.roles[userData.roles.indexOf("ROLE_ADMIN")];
    } else if (userData.roles.indexOf("ROLE_COLAB") !== -1) {
      this.userProfile = userData.roles[userData.roles.indexOf("ROLE_COLAB")];
    }
    this.checkUserProfileAccess(this.userProfile);
  },
  methods: {
    async buscarPedidosImpressao(codigoEstado, page) {
      this.listaPedidosImpressaoLoading = true;
      let request = {
        page: page,
      };

      if (codigoEstado) {
        request = {
          ...request,
          pedidoEstadoCodigo: codigoEstado,
        };
      }
      this.$emit("setQtdPedidosImpressao", null);
      try {
        const response = await buscarPedidoModeloImpressaoLista(request);
        this.listaPedidosImpressao = this.listaPedidosImpressao.concat(
          response.data["hydra:member"]
        );
        this.totalItens = response.data["hydra:totalItems"];
        this.listaPedidosImpressaoLoading = false;
        this.$emit("setQtdPedidosImpressao", this.totalItens);
      } catch (e) {
        this.listaPedidosImpressaoLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro buscar pedidos de impressão",
          message: e.response
            ? e.response.data["hydra:description"]
            : e.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    verificarRechamada(page) {
      const itensPageLoaded = Math.ceil(
        this.listaPedidosImpressao.length / this.perPage
      );
      const itensPageTotal = Math.ceil(this.totalItens / this.perPage);
      if (page > itensPageLoaded && page <= itensPageTotal) {
        this.buscarPedidosImpressao(
          this.pedidoCodigoEstado,
          Math.floor(page / this.perPage) + 1
        );
      }
    },
    selecionarPedido(value) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          pedidoImpressaoModeloCodigo: value.pedidoCodigo,
        },
        component: ModalConsultaPedidoImpressaoModelo,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        fullScreen: true,
        events: {
          atualizacaoPedido: () => {
            this.$emit("atualizacaoPedido");
          },
        },
      });
    },
    checkUserProfileAccess(profile) {
      this.columnsTemplate = this.columnsTemplate.map((column) => {
        if (column.field === "pedidoValor") {
          column.visible = profile !== "ROLE_COLAB" ? true : false;
        }
        return column;
      });
    },
  },
};
</script>
