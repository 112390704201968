import BluealApiClient from "@/service/bluealApi/BluealApiCliente";
import {
  LostPasswordRequest,
  LostPasswordResponse,
  UserData,
} from "@/service/bluealApi/data/user/UserData";
import { AxiosResponse } from "axios";
import QueryString from "qs";

const resourceSingle = "/usuario";
const resource = "/usuarios";

const getUserLogged = (): Promise<AxiosResponse<UserData>> => {
  const response = BluealApiClient().get<UserData>(`/usuario_logado`);
  return response;
};

const lostPassword = (
  request: LostPasswordRequest
): Promise<AxiosResponse<LostPasswordResponse>> => {
  return BluealApiClient().post<LostPasswordResponse>(
    `/lost_password`,
    request
  );
};

const obterLinkUploadAvatar = (codigo: number, body: { extAvatar: string }) => {
  return BluealApiClient().put(
    `${resourceSingle}/link_upload_avatar/${codigo}`,
    body
  );
};

const atualizarUsuario = (body: any, codigo: number) => {
  return BluealApiClient().put(`${resource}/${codigo}`, body);
};

const cadastrarUsuario = (body: any) => {
  return BluealApiClient().post(`${resource}`, body);
};

const listarUsuarios = (params: any) => {
  return BluealApiClient().get(`${resource}`, {
    params: {
      ...params,
    },
    paramsSerializer: (params: any) =>
      QueryString.stringify(params, { arrayFormat: "repeat" }),
  });
};

export {
  lostPassword,
  getUserLogged,
  obterLinkUploadAvatar,
  atualizarUsuario,
  cadastrarUsuario,
  listarUsuarios,
};
