<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Cadastro Desativado</p>
    </header>
    <section class="modal-card-body">
      <div class="columns is-centered is-vcentered">
        <div class="column is-2">
          <b-icon
            icon="check-circle"
            size="is-large"
            custom-size="fa-3x"
            type="is-success"
          >
          </b-icon>
        </div>
        <div class="column is-8">
          <div class="columns is-centered is-vcentered">
            <div class="column is-12">
              <p class="title is-5">Email: {{ email }}</p>
            </div>
          </div>
          <div class="columns is-centered is-vcentered">
            <div class="column is-12">
              <p class="title is-7">
                Cadastro desativado, para reativar redefina a senha do usuário
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button type="is-twitter" rounded icon-left="" @click="fechar">
        Fechar
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalCadastroDesativado",
  props: {
    email: String,
  },
  methods: {
    fechar() {
      this.$parent.close();
      this.$emit("atualizarUsuario");
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped></style>
