var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns mb-1"},[_c('div',{staticClass:"column is-12"},[(_vm.isUnicoArquivo === null)?_c('p',{staticClass:"title is-5"},[_vm._v("Arquivos")]):_vm._e(),(_vm.isUnicoArquivo === true)?_c('p',{staticClass:"title is-5"},[_vm._v(" Arquivo Único (Imagens do Paciente e Modelos 3D) ")]):_vm._e(),(_vm.isUnicoArquivo === false && _vm.imagensPacientePendente === true)?_c('p',{staticClass:"title is-5"},[_vm._v(" Imagens do Paciente ")]):_vm._e(),(_vm.isUnicoArquivo === false && _vm.imagensPacientePendente === false)?_c('p',{staticClass:"title is-5"},[_vm._v(" Arquivos Modelo 3D ")]):_vm._e()])]),_c('hr',{staticClass:"mt-0"}),(
      _vm.isUnicoArquivo === null &&
      (_vm.enviarNovamente === true || _vm.possuiArquivos === false)
    )?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_vm._m(0),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-2"},[_c('b-button',{attrs:{"type":"is-primary","rounded":""},on:{"click":function($event){return _vm.alterarArquivoUnico()}}},[_c('strong',[_vm._v("Arquivo ZIP")])])],1),_c('div',{staticClass:"column is-2"},[_c('b-button',{attrs:{"type":"is-twitter","rounded":""},on:{"click":function($event){return _vm.alterarArquivosIndividual()}}},[_c('strong',[_vm._v("Individualmente")])])],1)])])]):_vm._e(),(
      _vm.isUnicoArquivo === null &&
      _vm.possuiArquivos === true &&
      _vm.enviarNovamente === false
    )?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_vm._m(1),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-2"},[_c('b-button',{attrs:{"type":"is-primary","rounded":""},on:{"click":function($event){_vm.enviarNovamente = true}}},[_c('strong',[_vm._v("SIM")])])],1),_c('div',{staticClass:"column is-2"},[_c('b-button',{attrs:{"type":"is-twitter","rounded":""},on:{"click":function($event){return _vm.proximoPasso()}}},[_c('strong',[_vm._v("Não")])])],1)])])]):_vm._e(),(_vm.isUnicoArquivo === true)?_c('div',[_c('DadosArquivoUnico',{ref:"refDadosArquivoUnico",attrs:{"codigo-solicitacao":_vm.codigoSolicitacao},on:{"envioArquivoUnicoFinalizado":_vm.envioArquivoUnicoFinalizado,"reiniciarSelecaoArquivo":_vm.reiniciarSelecaoArquivo,"habilitarEnvioArquivo":_vm.habilitarEnvioArquivo,"desabilitarEnvioArquivo":_vm.desabilitarEnvioArquivo,"proximoPasso":_vm.proximoPasso}})],1):_vm._e(),(_vm.isUnicoArquivo === false && _vm.imagensPacientePendente === true)?_c('div',[_c('DadosArquivosImagens',{ref:"refArquivoImagens",attrs:{"codigo-solicitacao":_vm.codigoSolicitacao},on:{"naoEnviarImagemPaciente":_vm.envioImagensFinalizado,"exibirUploadImagens":_vm.exibirUploadImagens,"envioImagensFinalizado":_vm.envioImagensFinalizado,"habilitarEnvioArquivo":_vm.habilitarEnvioArquivo,"desabilitarEnvioArquivo":_vm.desabilitarEnvioArquivo,"uploadModelos":_vm.envioImagensFinalizado,"uploadImagens":_vm.envioImagensFinalizado}})],1):_vm._e(),(_vm.isUnicoArquivo === false && _vm.imagensPacientePendente === false)?_c('div',[_c('DadosArquivosModelo3D',{ref:"refArquivoModelo",attrs:{"codigo-solicitacao":_vm.codigoSolicitacao},on:{"exibirUploadModelos":_vm.exibirUploadModelos,"envioModelosFinalizado":_vm.envioModelosFinalizado,"habilitarEnvioArquivo":_vm.habilitarEnvioArquivo,"desabilitarEnvioArquivo":_vm.desabilitarEnvioArquivo}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12 mt-6"},[_c('p',{staticClass:"title is-6 has-text-centered"},[_vm._v(" Deseja enviar todos arquivos, modelo 3D (stl) e imagens pacientes, comprimidos em formato ZIP ou individualmente? ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12 mt-6"},[_c('p',{staticClass:"title is-6 has-text-centered"},[_vm._v(" Esta solicitação já possui arquivos, deseja enviar enviá-los novamente? ")])])])}]

export { render, staticRenderFns }