export interface SnackBarStoreState {
  title: string;
  message: string;
  timeout: number;
  color: "danger" | "warning" | "success" | "info";
}

/**
 * initial state
 */
const state: SnackBarStoreState = {
  title: "",
  message: "",
  timeout: 5000,
  color: "danger",
};

/**
 * getter
 */
const getters = {
  doneSnackBarState(state: SnackBarStoreState): SnackBarStoreState {
    return state;
  },
};

/**
 * actions
 */
const actions = {
  showSnackBar({ commit }: unknown, payload: SnackBarStoreState): void {
    commit("SET_SNACKBAR", payload);
  },
};

/**
 * mutations
 */
const mutations = {
  SET_SNACKBAR(state: SnackBarStoreState, payload: SnackBarStoreState): void {
    if (!payload.timeout) {
      payload.timeout = 5000;
    }
    state = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
