import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/pedidos_impressao_modelo";
const resourceSingle = "/pedido_impressao_modelo";

export default {
  listarPedidos() {
    return Repository().get(`${resource}`);
  },
  atualizarPedido(body, codigo) {
    return Repository().put(`${resourceSingle}/${codigo}`, body);
  },
  incluirPedido(body) {
    return Repository().post(`${resourceSingle}`, body);
  },
};
