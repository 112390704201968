<template>
  <div class="body-menu">
    <b-navbar class="is-primary p-3">
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ name: 'Home' }">
          <img
            :src="blLogoVertical"
            style="border-radius: 5px"
            height="15rem"
            width="auto"
            alt="Blue Aligner Logo"
          />
        </b-navbar-item>
      </template>
      <template #start>
        <div class="bl-header">
          <span class="is-size-5 is-text-semibold">
            Blue Aligner Web Admin
            <span class="is-size-7">v{{ appVersion }}</span>
          </span>
        </div>
      </template>

      <template #end v-if="userFirstName">
        <div class="action">
          <div class="profile" @click="userMenuActive = !userMenuActive">
            <img :src="userAvatar" @error="userAvatarFallback()" />
          </div>
          <div
            class="menu"
            v-bind:class="{ active: userMenuActive }"
            @mouseleave="userMenuActive = false"
          >
            <h3>{{ userFirstName }}</h3>
            <ul @click="userMenuActive = !userMenuActive">
              <li @click="navigate('AtualizarCadastroUsuario')">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-user"></i>
                  </span>
                  <span>Meus Dados</span>
                </span>
              </li>
              <li @click="abrirModalAlterarSenha">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-lock"></i>
                  </span>
                  <span>Alterar Senha</span>
                </span>
              </li>
              <li @click="logout">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-sign-out-alt"></i>
                  </span>
                  <span>Sair</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import userAnonymous from "@/assets/anonymous-user.png";
import blLogoVertical from "@/assets/bl-logo-vertical.webp";
import store from "@/store/store";
import FormAlterarSenha from "./usuario/FormAlterarSenha";
import { version } from "../../package";
import router from "@/router/index";

export default {
  name: "MenuSuperior",
  props: {
    userFirstName: { required: false, type: String },
    userImgLink: { required: false, type: String, default: userAnonymous },
  },
  data() {
    return {
      blLogoVertical: blLogoVertical,
      appVersion: version,
      userMenuActive: false,
      userAvatar: this.userImgLink,
    };
  },
  methods: {
    navigate(route) {
      router.push({ name: route });
    },
    async logout() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        store.commit("SET_DADOS_USUARIO", null);
        await store.dispatch("changedDadosUsuario", null);
        localStorage.removeItem("usuarioDados");
        localStorage.removeItem("@sessionToken");
        await this.$router.replace({ name: "Login" });
        loadingComponent.close();
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Logout",
          message: "Erro ao efetuar logout.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    exibirModalResponse(payload) {
      this.$refs.modal.loadModal(payload);
    },
    abrirModalAlterarSenha() {
      this.$buefy.modal.open({
        parent: this,
        component: FormAlterarSenha,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        width: 600,
      });
    },
    userAvatarFallback() {
      this.userAvatar = userAnonymous;
    },
  },
};
</script>

<style scoped>
.body-menu {
  height: 5rem;
  background-color: #2c7ad4;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  transition: all ease 0.3s;
}

.bl-header {
  height: 100%;
  display: flex;
  align-items: center;
}

.action {
  position: fixed;
  top: 20px;
  right: 30px;
}

.action .profile {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  border: #2c7ad4 solid 1px;
}

.action .profile img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.action .menu {
  position: absolute;
  top: 120px;
  right: -10px;
  padding: 10px 20px;
  background: #2c7ad4;
  width: 200px;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
}

.action .menu.active {
  top: 80px;
  visibility: visible;
  opacity: 1;
}

.action .menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 28px;
  width: 20px;
  height: 20px;
  background: #2c7ad4;
  transform: rotate(45deg);
}

.action .menu h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.5em;
}

.action .menu h3 span {
  font-size: 14px;
  color: #cecece;
  font-weight: 300;
}

.action .menu ul li {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

.action .menu ul li:hover {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  background: #2670c4;
  cursor: pointer;
}
</style>
