














































































import Vue from "vue";
import store from "@/store/store";
import router from "@/router";
import { login } from "@/service/bluealApi/repository/login/LoginRepository";
import axios from "axios";
import { getUserLogged } from "@/service/bluealApi/repository/user/UserRepository";

const Login = Vue.extend({
  name: "LoginSys",
  data() {
    return {
      open: true,
      email: "" as string,
      password: "" as string,
      errorMessage: "" as string,
      isLoading: false as boolean,
    };
  },
  methods: {
    setLoading(state: boolean) {
      this.isLoading = state;
    },
    navigate(route: string) {
      router.push({ name: route });
    },
    async loginApp() {
      try {
        this.setLoading(true);
        const response = await login({
          email: this.email,
          password: this.password,
        });
        store.commit("setSessionToken", response.data.token);
        await this.buscarDadosUsuario();
      } catch (error) {
        this.setLoading(false);
        let message = "Erro desconhecido";
        if (axios.isAxiosError(error) && error.response) {
          message = error.response.data.message;
        }
        await store.dispatch("snackBarStore/showSnackBar", {
          title: "Não foi possível efetuar login",
          message: message,
          color: "warning",
        });
      }
    },
    async buscarDadosUsuario() {
      try {
        const response = await getUserLogged();
        store.commit("SET_DADOS_USUARIO", response.data);
        await store.dispatch("changedDadosUsuario", response.data);
        if (response.data.usuarioEstado.codigoEstadoUsuario === 100) {
          await this.$router.push({ name: "AtualizarCadastroUsuario" });
        }
        this.setLoading(false);
        if (
          response.data.roles.indexOf("ROLE_ADMIN") === -1 &&
          response.data.roles.indexOf("ROLE_COLAB") === -1
        ) {
          window.location.href = "https://sistema.bluealigner.com.br";
        } else {
          await router.push({ name: "Home" });
        }
      } catch (error) {
        store.commit("SET_DADOS_USUARIO", null);
        await store.dispatch("changedDadosUsuario", null);
        let message = "Erro desconhecido";
        if (axios.isAxiosError(error) && error.response) {
          message = error.response.data.message;
        } else if (error instanceof Error) {
          message = error.message;
        }
        await store.dispatch("snackBarStore/showSnackBar", {
          title: "Erro ao obter dados da usuário",
          message: message,
        });
        this.setLoading(false);
      }
    },
  },
});

export default Login;
