import UsuarioRepository from "./usuarioRepository";
import CobrancaRespository from "./cobrancaRepository";
import SolicitacaoProjeto from "./solicitacaoProjetoRepository";
import PacienteRespository from "./pacienteRepository";
import ProjetoRepository from "./projetoRepository";
import PedidoAlinhadoresRepository from "./pedidoAlinhadoresRepository";
import ServicoRepository from "./servicoRepository";
import EnderecoRepository from "./enderecoRepository";

const repositories = {
  usuario: UsuarioRepository,
  cobranca: CobrancaRespository,
  solicitacaoProjeto: SolicitacaoProjeto,
  paciente: PacienteRespository,
  projeto: ProjetoRepository,
  pedidoAlinhadores: PedidoAlinhadoresRepository,
  servico: ServicoRepository,
  endereco: EnderecoRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
