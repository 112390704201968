<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        <b-icon size="is-small" icon="user" class="mr-3" />
        <span>Usuário</span>
      </p>
    </header>
    <section class="modal-card-body corpo-modal">
      <form action="">
        <div class="columns nova-secao">
          <div class="column is-10">
            <h5 class="title is-6">Dados</h5>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <b-field label="Usuario" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="codUsuario + ' ' + nome"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Email" label-position="on-border">
              <b-input custom-class="input-show-only" :value="email" disabled>
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field
              label="CRO"
              v-if="nrCro !== null"
              label-position="on-border"
            >
              <b-input
                custom-class="input-show-only"
                :value="nrCro + ' ' + ufCro"
                disabled
              >
              </b-input>
            </b-field>
            <b-field
              label="CRO"
              v-if="nrCro === null"
              label-position="on-border"
            >
              <b-input custom-class="input-show-only" value=" - " disabled>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-1">
            <b-field label="Tipo" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="
                  roles
                    ? roles.includes('ROLE_ADMIN')
                      ? 'ADMIN'
                      : 'COMUM'
                    : ''
                "
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Data Cadastro" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="
                  new Date(Date.parse(dataCadastro)).toLocaleDateString(
                    'pt-BR',
                    { timeZone: 'UTC' }
                  )
                "
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field
              label="CPF/CPNJ"
              v-if="cpf === null"
              label-position="on-border"
            >
              <b-input custom-class="input-show-only" value=" - " disabled>
              </b-input>
            </b-field>
            <b-field
              label="CPF"
              v-if="cpf !== null && cpf.length <= 14"
              label-position="on-border"
            >
              <b-input custom-class="input-show-only" :value="cpf" disabled>
              </b-input>
            </b-field>
            <b-field
              label="CNPJ"
              v-if="cpf !== null && cpf.length > 14"
              label-position="on-border"
            >
              <b-input custom-class="input-show-only" :value="cpf" disabled>
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Cod. Estado" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="codEstadoUsuario"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Desc. Estado" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="descEstadoUsuario"
                disabled
              >
              </b-input>
            </b-field>
          </div>
        </div>
        <hr />
        <div class="columns nova-secao">
          <div class="column is-10">
            <h5 class="title is-6">Endereço</h5>
          </div>
        </div>
        <div class="columns" v-if="isEnderecoLoading === true">
          <div class="column is-3">
            <b-skeleton height="5.6vh" />
          </div>
          <div class="column is-1">
            <b-skeleton height="5.6vh" />
          </div>
          <div class="column is-2">
            <b-skeleton height="5.6vh" />
          </div>
          <div class="column is-2">
            <b-skeleton height="5.6vh" />
          </div>
          <div class="column is-2">
            <b-skeleton height="5.6vh" />
          </div>
        </div>
        <div class="columns" v-if="isEnderecoLoading === true">
          <div class="column is-1">
            <b-skeleton height="5.6vh" />
          </div>
          <div class="column is-2">
            <b-skeleton height="5.6vh" />
          </div>
        </div>
        <div class="columns" v-if="isEnderecoLoading === false">
          <div class="column is-3">
            <b-field label="Logradouro" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="logradouro"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="Número" label-position="on-border">
              <b-input custom-class="input-show-only" :value="numero" disabled>
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Complemento" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="complemento"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Bairro" label-position="on-border">
              <b-input custom-class="input-show-only" :value="bairro" disabled>
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Município" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="municipio"
                disabled
              >
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns" v-if="isEnderecoLoading === false">
          <div class="column is-1">
            <b-field label="UF" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="ufEndereco"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="CEP" label-position="on-border">
              <b-input custom-class="input-show-only" :value="cep" disabled>
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns nova-secao">
          <div class="column is-10">
            <h5 class="title is-6">Fones</h5>
          </div>
        </div>
        <div class="columns" v-if="isFonesLoading === true">
          <div class="column is-2">
            <b-skeleton height="5.6vh" />
          </div>
          <div class="column is-2">
            <b-skeleton height="5.6vh" />
          </div>
        </div>
        <div class="columns" v-if="isFonesLoading === false">
          <div class="column is-2">
            <b-field label="Fone Móvel" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="formatarFoneCelular(foneMovel)"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Fone Fixo" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="formatarFoneFixo(foneFixo)"
                disabled
              >
              </b-input>
            </b-field>
          </div>
        </div>
      </form>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-primary is-rounded"
        type="button"
        @click="$parent.close()"
      >
        <strong>Fechar</strong>
      </button>
      <button
        class="button is-twitter is-rounded"
        @click="abrirDialogDesativarCadastro"
        :disabled="codEstadoUsuario === 400"
      >
        <strong>Desativar Cadastro</strong>
      </button>
      <button
        class="button is-danger is-rounded"
        @click="abrirDialogRedefinirSenha"
      >
        <strong>{{
            codEstadoUsuario === 400
              ? "Ativar Cadastro / Redefinir Senha"
              : "Redefinir Senha"
          }}</strong>
      </button>
    </footer>
  </div>
</template>

<script>
import ModalSenhaRedefinida from "./ModalSenhaRedefinida";
import ModalCadastroDesativado from "./ModalCadastroDesativado";
import usuarioV2Repository from "@/repository/usuarioV2Repository";
import enderecoRespository from "@/repository/enderecoV2Repository";
import { buscaTelefones } from "@/repository/telefoneV2Repository";

export default {
  name: "ModalConsultaDadosUsuario",
  props: {
    dadosUsuario: Object
  },
  data() {
    return {
      isEnderecoLoading: true,
      isFonesLoading: true,
      codUsuario: null,
      nome: null,
      email: null,
      nrCro: null,
      ufCro: null,
      cpf: null,
      roles: null,
      dataCadastro: null,
      codEstadoUsuario: null,
      descEstadoUsuario: null,
      codEndereco: null,
      logradouro: null,
      numero: null,
      bairro: null,
      complemento: null,
      municipio: null,
      ufEndereco: null,
      cep: null,
      foneMovel: null,
      foneFixo: null
    };
  },
  beforeMount() {
    this.buscarEnderecoPrincipal();
    this.buscarTelefones();
  },
  mounted() {
    this.codUsuario = this.dadosUsuario.codigoUsuario;
    this.nome = this.dadosUsuario.nome;
    this.email = this.dadosUsuario.email;
    this.cpf = this.dadosUsuario.cpfCnpj;
    this.nrCro = this.dadosUsuario.numeroCRO;
    this.ufCro = this.dadosUsuario.ufCRO;
    this.roles = this.dadosUsuario.roles;
    this.dataCadastro = this.dadosUsuario.dataCadastro;
    this.codEstadoUsuario = this.dadosUsuario.usuarioEstado.codigoEstadoUsuario;
    this.descEstadoUsuario = this.dadosUsuario.usuarioEstado.descricao;
  },
  methods: {
    async atualizarCadastro(operacao) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        let body = {
          operacao: operacao
        };

        const response = await usuarioV2Repository.atualizarUsuario(
          body,
          this.dadosUsuario.codigoUsuario
        );
        loadingComponent.close();
        if (operacao === "D") {
          this.desativarCadastro();
        } else if (operacao === "R") {
          this.redefinirSenha(response.data.plainPassword);
        }
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao atualizar cadastro",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true
        });
      }
    },
    async buscarEnderecoPrincipal() {
      try {
        this.isLoadingEndereco = true;
        const params = {
          estadoEndereco: 10,
          tipoEndereco: 10,
          usuario: this.dadosUsuario.codigoUsuario
        };
        const response = await enderecoRespository.buscaEnderecosAtivosUsuario(
          params
        );
        response.data["hydra:member"].length > 0
          ? (this.enderecoPrincipal = response.data["hydra:member"][0])
          : (this.enderecoPrincipal = null);
        this.enderecoPrincipal !== null
          ? this.atribuirDadosEndereco(this.enderecoPrincipal)
          : null;
        this.isEnderecoLoading = false;
      } catch (e) {
        this.isEnderecoLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro ao obter endereços do usuário",
          message: e.response
            ? e.response.data["hydra:description"]
            : e.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true
        });
      }
    },
    async buscarTelefones() {
      try {
        this.isFonesLoading = true;
        const params = {
          estado: 10,
          usuario: this.dadosUsuario.codigoUsuario
        };
        const response = await buscaTelefones(params);

        response.data["hydra:member"].length > 0
          ? this.atribuirDadosTelefones(response.data["hydra:member"])
          : null;
        this.isFonesLoading = false;
      } catch (e) {
        this.isLoadingEndereco = false;
        this.$buefy.dialog.alert({
          title: "Erro ao obter endereços do usuário",
          message: e.response
            ? e.response.data["hydra:description"]
            : e.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true
        });
      }
    },
    atribuirDadosEndereco(endereco) {
      this.codEndereco = endereco.codigo;
      this.logradouro = endereco.logradouro;
      this.numero = endereco.numero;
      this.complemento = endereco.complemento;
      this.bairro = endereco.bairro;
      this.municipio = endereco.municipio;
      this.ufEndereco = endereco.uf;
      this.cep = endereco.cep;
    },
    atribuirDadosTelefones(fones) {
      fones.forEach((element) => {
        if (element.tipo === 1) {
          this.foneMovel = element.numero;
        } else if (element.tipo === 2) {
          this.foneFixo = element.numero;
        }
      });
    },
    async desativarCadastro() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          email: this.email
        },
        hasModalCard: true,
        fullScreen: false,
        component: ModalCadastroDesativado,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        events: {
          atualizarUsuario: () => {
            this.$emit("atualizarUsuarios");
            this.$parent.close();
          }
        }
      });
    },
    async redefinirSenha(senha) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          senhaProvisoria: senha,
          email: this.email
        },
        hasModalCard: true,
        fullScreen: false,
        component: ModalSenhaRedefinida,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        events: {
          atualizarUsuario: () => {
            this.$emit("atualizarUsuarios");
            this.$parent.close();
          }
        }
      });
    },
    abrirDialogRedefinirSenha() {
      this.$buefy.dialog.confirm({
        title: "Redefinir Senha",
        message: "Confirma redefinição de senha do usuário?",
        type: "is-danger",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        onConfirm: () => this.atualizarCadastro("R")
      });
    },
    abrirDialogDesativarCadastro() {
      this.$buefy.dialog.confirm({
        title: "Desativar Cadastro",
        message: "Confirma desativação do cadastro do usuário?",
        type: "is-danger",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        onConfirm: () => this.atualizarCadastro("D")
      });
    },
    formatarFoneCelular(nrCelular) {
      if (nrCelular !== null) {
        return nrCelular
          .toString()
          .replace(/(\d{2})(\d{5})(\d{4})/, function(regex, arg1, arg2, arg3) {
            return arg1 + " " + arg2 + "-" + arg3;
          });
      } else {
        return nrCelular;
      }
    },
    formatarFoneFixo(nrFixo) {
      if (nrFixo !== null) {
        return nrFixo
          .toString()
          .replace(/(\d{2})(\d{4})(\d{4})/, function(regex, arg1, arg2, arg3) {
            return arg1 + " " + arg2 + "-" + arg3;
          });
      } else {
        return nrFixo;
      }
    }
  }
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tabela-projetos {
  font-size: 0.9rem;
  max-width: 100%;
}

.coluna-tabela-projetos {
  white-space: nowrap;
}

.item-list {
  float: left;
  margin-left: 1rem;
  margin-top: 0.5rem;
  min-width: 3.7rem;
}

.corpo-modal {
  padding-left: 4rem;
}

.nova-secao {
  margin-bottom: 1rem !important;
}
</style>
