import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import store from "@/store/store";
import Dashboard from "../views/dashboard/Dashboard";
import SolicitarProjetoV2 from "../views/solicitacao-projeto-v2/SolicitarProjetoV2";
import GerenciarSolicitacoes from "../views/gerenciar-solicitacoes/GerenciarSolicitacoes";
import ListaPedidosAlinhadores from "../views/pedidos-alinhadores-consulta/ListaPedidosAlinhadores";
import CadastrarUsuario from "@/views/usuario/CadastrarUsuario.vue";
import ListaTodosUsuarios from "../views/usuario-consulta/ListaTodosUsuarios";
import AtualizarCadastroUsuario from "../views/usuario/AtualizarCadastroUsuario";
import GerenciarPedidosImpressao from "../views/solicitacao-impressao-modelo/GerenciarPedidosImpressao";
import FinanceiroPlanejamentos from "@/views/financeiro/FinanceiroPlanejamentos";
import FinanceiroPedidosAlinhadores from "@/views/financeiro/FinanceiroPedidosAlinhadores";
import FinanceiroPedidoModelo from "@/views/financeiro/FinanceiroPedidoModelo";
import Login from "@/views/login/Login.vue";
import RecuperarSenha from "@/views/login/RecuperarSenha";

Vue.use(VueRouter);
Vue.use(Vuex);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/recuperar-senha",
    name: "RecuperarSenha",
    component: RecuperarSenha,
    meta: { requiresAuth: false },
  },
  {
    path: "",
    name: "Home",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/solicitar-projeto",
    name: "SolicitarProjeto",
    component: SolicitarProjetoV2,
    meta: {
      requiresAuth: true,
      title: "Planejamento | Solicitar Planejamento",
    },
  },
  {
    path: "/gerenciar-solicitacoes-projeto",
    name: "GerenciarSolicitacoes",
    component: GerenciarSolicitacoes,
    meta: { requiresAuth: true },
  },
  {
    path: "/consultar-pedidos-alinhados",
    name: "ListaPedidosAlinhadores",
    component: ListaPedidosAlinhadores,
    meta: { requiresAuth: true },
  },
  {
    path: "/cadastrar-usuario",
    name: "CadastrarUsuario",
    component: CadastrarUsuario,
    meta: { requiresAuth: true },
  },
  {
    path: "/consultar-usuarios",
    name: "ListaTodosUsuarios",
    component: ListaTodosUsuarios,
    meta: { requiresAuth: true },
  },
  {
    path: "/atualizar-cadastro",
    name: "AtualizarCadastroUsuario",
    component: AtualizarCadastroUsuario,
    meta: { requiresAuth: true },
  },
  {
    path: "/lista-pedidos-impressao-modelo",
    name: "GerenciarPedidosImpressao",
    component: GerenciarPedidosImpressao,
    meta: { requiresAuth: true },
  },
  {
    path: "/financeiro-planejamento",
    name: "FinanceiroPlanejamentos",
    component: FinanceiroPlanejamentos,
    meta: { requiresAuth: true },
  },
  {
    path: "/financeiro-pedido-alinhadores",
    name: "FinanceiroPedidosAlinhadores",
    component: FinanceiroPedidosAlinhadores,
    meta: { requiresAuth: true },
  },
  {
    path: "/financeiro-pedido-modelo",
    name: "FinanceiroPedidoModelo",
    component: FinanceiroPedidoModelo,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const sessionToken = store.getters["doneSessionToken"];

  if (to.meta.requiresAuth && !sessionToken) {
    next({ name: "Login" });
  } else next();
});

export default router;
