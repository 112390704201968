



































































































































import Vue from "vue";
import { buscarCobrancasPedidoAlinhadoresLista } from "@/repository/Cobranca/CobrancaListaRepository";
import {
  CobrancaListaParamsRequest,
  CobrancasPedidoAlinhadoresItem,
  CobrancasPedidoAlinhadoresListaResponse,
} from "@/repository/Cobranca/CobrancaListaData";

import FinanceiroPedidoAlinhadoresTabela from "@/views/financeiro/components/FinanceiroPedidoAlinhadoresTabela.vue";
import axios from "axios";

const financeiroPedidoAlinhadores = Vue.extend({
  name: "FinanceiroPlanejamentos",
  components: { FinanceiroPedidoAlinhadoresTabela },
  data() {
    return {
      totalItensPedidoAlinhadoresEmitidos: 0,
      isLoadingListaPedidoAlinhadoresEmitidos: false as boolean,
      listaPedidoAlinhadoresEmitidos: [] as CobrancasPedidoAlinhadoresItem[],
      totalItensPedidoAlinhadoresPagos: 0,
      isLoadingListaPedidoAlinhadoresPagos: false as boolean,
      listaPedidoAlinhadoresPagos: [] as CobrancasPedidoAlinhadoresItem[],
      totalItensPedidoAlinhadoresTodos: 0,
      isLoadingListaPedidoAlinhadoresTodos: false as boolean,
      listaPedidoAlinhadoresTodos: [] as CobrancasPedidoAlinhadoresItem[],
      totalItensPedidoAlinhadoresVencidos: 0,
      isLoadingListaPedidoAlinhadoresVencidos: false as boolean,
      listaPedidoAlinhadoresVencidos: [] as CobrancasPedidoAlinhadoresItem[],
    };
  },
  beforeMount() {
    this.listarPedidoAlinhadoresEmitidos(1);
    this.listarPedidoAlinhadoresPagos(1);
    this.listarPedidoAlinhadoresTodos(1);
    this.listarPedidoAlinhadoresVencidos(1);
  },
  methods: {
    async listarPedidoAlinhadores(
      page: number,
      estado?: number
    ): Promise<CobrancasPedidoAlinhadoresListaResponse | null> {
      try {
        let requestParam: CobrancaListaParamsRequest = {
          page: page,
        };

        if (estado) {
          requestParam = {
            ...requestParam,
            codigoEstadoCobranca: estado,
          };
        }

        const response = await buscarCobrancasPedidoAlinhadoresLista(
          requestParam
        );
        return response.data;
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Erro ao buscar lista de cobranças",
          message:
            axios.isAxiosError(error) && error.response
              ? error.response.data.mensagem
              : "Error desconhecido.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        return null;
      }
    },
    async listarPedidoAlinhadoresEmitidos(page: number) {
      this.isLoadingListaPedidoAlinhadoresEmitidos = true;
      const response = await this.listarPedidoAlinhadores(page, 100);
      if (response) {
        this.listaPedidoAlinhadoresEmitidos =
          this.listaPedidoAlinhadoresEmitidos.concat(response["hydra:member"]);
        this.totalItensPedidoAlinhadoresEmitidos = response["hydra:totalItems"];
      } else {
        this.listaPedidoAlinhadoresEmitidos = [];
        this.totalItensPedidoAlinhadoresEmitidos = 0;
      }
      this.isLoadingListaPedidoAlinhadoresEmitidos = false;
    },
    async listarPedidoAlinhadoresVencidos(page: number) {
      this.isLoadingListaPedidoAlinhadoresVencidos = true;
      const response = await this.listarPedidoAlinhadores(page, 101);
      if (response) {
        this.listaPedidoAlinhadoresVencidos =
          this.listaPedidoAlinhadoresVencidos.concat(response["hydra:member"]);
        this.totalItensPedidoAlinhadoresVencidos = response["hydra:totalItems"];
      } else {
        this.listaPedidoAlinhadoresVencidos = [];
        this.totalItensPedidoAlinhadoresVencidos = 0;
      }
      this.isLoadingListaPedidoAlinhadoresVencidos = false;
    },
    async listarPedidoAlinhadoresPagos(page: number) {
      this.isLoadingListaPedidoAlinhadoresPagos = true;
      const response = await this.listarPedidoAlinhadores(page, 102);
      if (response) {
        this.listaPedidoAlinhadoresPagos =
          this.listaPedidoAlinhadoresPagos.concat(response["hydra:member"]);
        this.totalItensPedidoAlinhadoresPagos = response["hydra:totalItems"];
      } else {
        this.listaPedidoAlinhadoresPagos = [];
        this.totalItensPedidoAlinhadoresPagos = 0;
      }
      this.isLoadingListaPedidoAlinhadoresPagos = false;
    },
    async listarPedidoAlinhadoresTodos(page: number) {
      this.isLoadingListaPedidoAlinhadoresTodos = true;
      const response = await this.listarPedidoAlinhadores(page);
      if (response) {
        this.listaPedidoAlinhadoresTodos =
          this.listaPedidoAlinhadoresTodos.concat(response["hydra:member"]);
        this.totalItensPedidoAlinhadoresTodos = response["hydra:totalItems"];
      } else {
        this.listaPedidoAlinhadoresTodos = [];
        this.totalItensPedidoAlinhadoresTodos = 0;
      }
      this.isLoadingListaPedidoAlinhadoresTodos = false;
    },
  },
});

export default financeiroPedidoAlinhadores;
