<template>
  <form @submit.prevent="atualizarCadastro()">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Alterar Senha</p>
      </header>
      <section class="modal-card-body">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Senha Atual" label-position="on-border">
                <b-input
                  type="password"
                  placeholder
                  minlength="6"
                  maxlength="12"
                  required
                  v-model="senhaAtual"
                  @input="senhaAtual.length >= 6 ? senhaNovaValida() : null"
                />
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Nova Senha"
                label-position="on-border"
                :message="senhaNovaValidationMessage"
                :type="senhaNovaValidationMessage.length > 0 ? 'is-danger' : ''"
              >
                <b-input
                  type="password"
                  placeholder
                  minlength="6"
                  maxlength="12"
                  required
                  v-model="senhaNova"
                  @input="senhaNova.length >= 6 ? senhaNovaValida() : null"
                />
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Confirmação"
                label-position="on-border"
                :message="senhaConfirmacaoValidationMessage"
                :type="
                  senhaConfirmacaoValidationMessage.length > 0
                    ? 'is-danger'
                    : ''
                "
              >
                <b-input
                  type="password"
                  placeholder
                  minlength="6"
                  maxlength="12"
                  required
                  v-model="senhaConfirmacao"
                  @input="
                    senhaConfirmacao.length >= 6 ? senhaNovaValida() : null
                  "
                />
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          type="is-twitter"
          rounded
          icon-left="arrow-left"
          @click="$parent.close()"
        >
          Voltar
        </b-button>
        <b-button
          native-type="submit"
          type="is-primary"
          icon-left="user-plus"
          rounded
        >
          <strong>Alterar</strong>
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import store from "@/store/store";
import usuarioV2Repository from "@/repository/usuarioV2Repository";

export default {
  name: "FormAlterarSenha",
  data() {
    return {
      codigoUsuario: null,
      senhaAtual: "",
      senhaNova: "",
      senhaConfirmacao: "",
      isAlterarDisabled: true,
      senhaNovaValidationMessage: "",
      senhaConfirmacaoValidationMessage: "",
    };
  },
  beforeMount() {
    this.codigoUsuario = store.getters.doneUsuarioDados.codigoUsuario;
  },
  methods: {
    async atualizarCadastro() {
      var dadosValidos = this.senhaNovaValida();

      if (dadosValidos === true) {
        const loadingComponent = this.$buefy.loading.open();
        try {
          let body = {
            nome: this.nome,
            newPassword: this.senhaNova,
            oldPassword: this.senhaAtual,
          };

          await usuarioV2Repository.atualizarUsuario(body, this.codigoUsuario);

          loadingComponent.close();

          this.$buefy.dialog.alert({
            message: "Senha alterada com sucesso.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        } catch (error) {
          loadingComponent.close();
          this.$buefy.dialog.alert({
            title: "Erro ao alterar senha",
            message: error.response.data["hydra:description"],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      }
    },
    senhaNovaValida() {
      let senhasValidas = true;

      if (this.senhaAtual === this.senhaNova) {
        this.senhaNovaValidationMessage = "Senha nova igual a atual";
        senhasValidas = false;
      } else {
        this.senhaNovaValidationMessage = "";
      }

      if (this.senhaNova !== this.senhaConfirmacao) {
        this.senhaConfirmacaoValidationMessage = "Senha não confere";
        senhasValidas = false;
      } else {
        this.senhaConfirmacaoValidationMessage = "";
      }

      return senhasValidas;
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped></style>
