<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        <b-icon icon="file-invoice" />
        {{ " " }}Solicitação de Planejamento
      </p>
    </header>
    <section class="modal-card-body">
      <div class="columns is-centered">
        <div class="column is-12">
          <b-tabs position="atLeft" type="is-boxed" vertical :expanded="true">
            <b-tab-item label="Dados Iniciais" icon="address-card">
              <div class="body-tab">
                <dados-basicos-solicitacao
                  v-if="dadosSolicitacao !== null"
                  :dados-solicitacao-projeto="dadosSolicitacao"
                />
              </div>
            </b-tab-item>

            <b-tab-item label="Dados Tratamento" icon="clipboard-list">
              <div class="body-tab">
                <DadosTratamentoSolicitacao
                  v-if="dadosSolicitacao !== null"
                  :dados-solicitacao-projeto="dadosSolicitacao"
                />
              </div>
            </b-tab-item>

            <b-tab-item label="Arquivos" icon="file">
              <div class="body-tab">
                <ArquivosSolicitacao
                  v-if="dadosSolicitacao !== null"
                  :dados-solicitacao-projeto="dadosSolicitacao"
                />
              </div>
            </b-tab-item>
            <b-tab-item label="Planejamentos" icon="file-invoice">
              <div class="body-tab">
                <PlanejamentosSolicitacao
                  v-if="dadosSolicitacao !== null"
                  :nr-slct-projeto="codigoSolicitacao"
                  @atualizacaoProjeto="reiniciar"
                />
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-danger"
        rounded
        icon-left="arrow-left"
        @click="$parent.close()"
      >
        <strong>Fechar</strong>
      </b-button>
      <b-button
        v-if="
          dadosSolicitacao !== null &&
          dadosSolicitacao.cobranca !== null &&
          dadosSolicitacao.estado.codigoEstadoSolicitacao >= 20
        "
        type="is-primary"
        rounded
        icon-left="barcode"
        @click="recuperarBoleto"
      >
        <strong>Visualizar boleto</strong>
      </b-button>
      <b-button
        type="is-primary"
        rounded
        v-if="
          dadosSolicitacao !== null &&
          dadosSolicitacao.estado.codigoEstadoSolicitacao === 20
        "
        icon-left="check-circle"
        @click="aceitarSolicitacao(codigoSolicitacao)"
      >
        <strong>Aceitar Solicitação</strong>
      </b-button>
      <b-button
        type="is-primary"
        rounded
        v-if="
          dadosSolicitacao !== null &&
          dadosSolicitacao.estado.codigoEstadoSolicitacao === 50
        "
        icon-left="check-circle"
        @click="inciarProjeto(codigoSolicitacao)"
      >
        <strong>Iniciar Novo Planejamento</strong>
      </b-button>
      <b-button
        type="is-twitter"
        rounded
        v-if="
          dadosSolicitacao !== null &&
          dadosSolicitacao.estado.codigoEstadoSolicitacao === 30
        "
        icon-left="cube"
        @click="abrirModalAlterarArquivosModelo()"
      >
        <strong>Alterar Arquivos Modelo</strong>
      </b-button>
      <b-button
        v-if="
          dadosSolicitacao !== null &&
          dadosSolicitacao.estado.codigoEstadoSolicitacao !== 60 &&
          dadosSolicitacao.estado.codigoEstadoSolicitacao !== 70 &&
          dadosSolicitacao.estado.codigoEstadoSolicitacao !== 90
        "
        type="is-warning"
        rounded
        icon-left="trash"
        @click="promptCancelar"
      >
        <strong>Cancelar</strong>
      </b-button>
    </footer>
  </div>
</template>

<script>
import DadosBasicosSolicitacao from "./dados-projetos-solicitacao/DadosBasicosSolicitacao";
import DadosTratamentoSolicitacao from "./dados-projetos-solicitacao/DadosTratamentoSolicitacao";
import ArquivosSolicitacao from "./dados-projetos-solicitacao/ArquivosSolicitacao";
import PlanejamentosSolicitacao from "./dados-projetos-solicitacao/PlanejamentosSolicitacao";
import ModalAlterarArquivosModelo from "./dados-projetos-solicitacao/Modal/ModalAlterarArquivosModelo";
import solicitacaoProjetoV2Repository from "@/repository/solicitacaoProjetoV2Repository";
import cobrancaRepository from "@/repository/cobrancaRepository";

export default {
  name: "ModalConsultarSolicitacaoProjeto",
  props: {
    codigoSolicitacao: String,
  },
  data() {
    return {
      dadosSolicitacao: null,
    };
  },
  components: {
    DadosBasicosSolicitacao,
    DadosTratamentoSolicitacao,
    ArquivosSolicitacao,
    PlanejamentosSolicitacao,
  },
  beforeMount() {
    this.buscarSolicitacao(this.codigoSolicitacao);
  },
  methods: {
    reiniciar() {
      this.buscarSolicitacao(this.codigoSolicitacao);
      this.$emit("atualizacaoProjeto");
    },
    async buscarSolicitacao(nrSlct) {
      this.dadosSolicitacao = null;
      const loadingComponent = this.$buefy.loading.open();
      try {
        const { data } = await solicitacaoProjetoV2Repository.buscarSolicitacao(
          nrSlct
        );
        loadingComponent.close();
        this.dadosSolicitacao = data;
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao buscar dados da Solicitação",
          message: error.response
            ? error.response.data["hydra:description"]
            : error.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async inciarProjeto(nrSlct) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        let body = {
          operacao: "P",
        };
        await solicitacaoProjetoV2Repository.alterarSolicitacao(nrSlct, body);
        loadingComponent.close();
        this.reiniciar();
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao iniciar novo projeto",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async recuperarBoleto() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const response = await cobrancaRepository.buscarCobrancaCodigo(
          this.dadosSolicitacao.cobranca.codigo
        );
        loadingComponent.close();
        if (response.data.linkBoleto !== null) {
          window.open(response.data.linkBoleto, "_blank");
        } else {
          this.$buefy.dialog.alert({
            title: "Erro ao recuperar boleto",
            message: "Este boleto não está mais disponível.",
            type: "is-warning",
            hasIcon: true,
            icon: "exclamation-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao gerar boleto",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    promptCancelar() {
      this.$buefy.dialog.alert({
        title: "Atenção",
        message: "Deseja cancelar essa solicitação?",
        type: "is-warning",
        hasIcon: true,
        icon: "exclamation-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
        canCancel: true,
        cancelText: "Não",
        confirmText: "Sim",
        onConfirm: () => this.cancelarSolicitacao(),
      });
    },
    async cancelarSolicitacao() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        let body = {
          operacao: "C",
        };

        await solicitacaoProjetoV2Repository.alterarSolicitacao(
          this.dadosSolicitacao.codigoSolicitacao,
          body
        );
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Sucesso",
          message: "Cancelamento efetuado.",
          type: "is-primary",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          canCancel: false,
          confirmText: "Fechar",
          onConfirm: () => {
            window.location.href = "/gerenciar-solicitacoes-projeto";
          },
        });
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao cancelar",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    abrirModalAlterarArquivosModelo() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          nrSolicitacaoPlanejamento: this.codigoSolicitacao,
        },
        component: ModalAlterarArquivosModelo,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        fullScreen: false,
        events: {
          atualizacaoProjeto: () => {
            this.$emit("atualizacaoProjeto");
            this.$parent.close();
          },
        },
      });
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.body-tab {
  height: 70vh;
  max-width: 80vw;
}
</style>
