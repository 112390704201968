<template>
  <section>
    <article class="panel is-twitter">
      <p class="panel-heading">Solicitações de Planejamento</p>
      <div class="panel-block">
        <div class="columns is-centered">
          <div
            id="chartSolicitacoes"
            ref="chartSolicitacoes"
            class="column is-12"
          ></div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import ApexCharts from "apexcharts";
import solicitacaoProjetoResumoEstadoRespositoryV2 from "@/repository/solicitacaoProjetoResumoEstadoRespositoryV2";
export default {
  name: "SolicitacaoDashboard",
  props: {
    open: Boolean,
  },
  mounted() {
    this.buscarResumoSolicitacoes();
  },
  methods: {
    async buscarResumoSolicitacoes() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const response =
          await solicitacaoProjetoResumoEstadoRespositoryV2.listarResumo();
        this.processarListaSolicitacoes(response.data["hydra:member"]);
        loadingComponent.close();
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao buscar resumo de solicitações",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    processarListaSolicitacoes(lista) {
      const data = [];
      const categories = [];
      lista.forEach((item) => {
        data.push(item.quantidade);
        categories.push(item.descricao.replace(/Planejamento /g, ""));
      });
      this.renderChartSolicitacaoes(data, categories);
    },
    renderChartSolicitacaoes(data, categories) {
      var options = {
        chart: {
          type: "bar",
          height: "215rem",
          width: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        series: [
          {
            name: "Solicitações",
            data: data,
          },
        ],
        xaxis: {
          categories: categories,
        },
      };
      var chart = new ApexCharts(this.$refs.chartSolicitacoes, options);

      chart.render();
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.text-content {
  text-align: justify;
  text-justify: inter-word;
}
</style>
