<template>
  <div>
    <div v-if="envioNaoIniciado === true">
      <div class="columns is-centered">
        <div class="column is-9">
          <p class="title is-6 has-text-centered">
            <b-message type="is-danger" size="is-small" has-icon>
              Certifique-se de que o arquivo contenha os modelos 3D da mandíbula
              e maxilar, eles são obrigatórios. As imagens do paciente são
              opcionais.
            </b-message>
          </p>
        </div>
        <div class="column is-2 mt-1">
          <b-button
            type="is-twitter"
            @click="alterarArquivoIndividual()"
            expanded
          >
            <strong>Alterar Tipo Envio</strong>
          </b-button>
        </div>
      </div>
      <br />
      <div class="columns is-centered is-vcentered">
        <div class="column is-4">
          <b-field>
            <b-upload
              v-model="arquivoZip"
              @input="validarArquivoZip()"
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large"
                      :type="iconArquivoZip"
                    >
                    </b-icon>
                  </p>
                  <p>Arraste e solte o arquivo no formato ZIP aqui ou clique</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags" v-if="arquivoZip !== null">
            <span class="tag is-primary">
              {{ arquivoZip.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteArquivoZip()"
              ></button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered mt-6 pt-6" v-if="obterUrlUploadLoading">
      <div class="column is-6">
        <h6 class="title is-6">Alocando espaço ...</h6>
        <b-progress size="is-large"> </b-progress>
      </div>
    </div>
    <div class="columns is-centered mt-6 pt-6" v-if="uploadArquivoZipLoading">
      <div class="column is-6">
        <h6 class="title is-6">Enviando arquivo ...</h6>
        <b-progress
          size="is-large"
          :value="uploadPercentualZip"
          show-value
          format="percent"
        >
        </b-progress>
      </div>
    </div>
    <div class="columns is-centered mt-6 pt-6" v-if="envioFinalizado === true">
      <div class="column is-6">
        <h6 class="title is-5">Envio finalizado.</h6>
      </div>
    </div>
  </div>
</template>

<script>
import solicitacaoProjetoArquivosV2Repository from "../../../repository/solicitacaoProjetoArquivosV2Repository";
import solicitacaoProjetoV2Repository from "../../../repository/solicitacaoProjetoV2Repository";

export default {
  name: "DadosArquivoUnico",
  props: {
    codigoSolicitacao: String,
  },
  data() {
    return {
      envioNaoIniciado: true,
      envioFinalizado: false,
      obterUrlUploadLoading: false,
      uploadArquivoMaxilaLoading: false,
      uploadPercentualMaxila: 0,
      uploadArquivoMandibulaLoading: false,
      uploadPercentualMandibula: 0,
      uploadArquivoZipLoading: false,
      uploadPercentualZip: 0,
      isZip: null,
      arquivoZip: null,
      zipExt: null,
      stlInferior: null,
      stlInferiorExt: null,
      stlSuperior: null,
      stlSuperiorExt: null,
      arquivoZipCodigo: null,
      stlInferiorCodigo: null,
      stlSuperiorCodigo: null,
      iconArquivoZip: "",
      iconStlSuperior: "",
      iconStlInferior: "",
      exibirModalProgresso: false,
      uploadPercentage: 0,
      acceptedTypes: [
        "model/stl",
        "application/x-tgif",
        "text/plain",
        "application/zip",
        "application/octet-stream",
        "application/x-zip",
        "application/x-zip-compressed",
      ],
      acceptedTypesZip: [
        "application/zip",
        "application/octet-stream",
        "application/x-zip",
        "application/x-zip-compressed",
      ],
    };
  },
  methods: {
    validarArquivoZip() {
      if (this.arquivoZip === null) {
        this.$emit("desabilitarEnvioArquivo");
        this.$buefy.toast.open({
          message: `Nenhum Arquivo Selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.arquivoZip.size > 150338608) {
        this.arquivoZip = null;
        this.$emit("desabilitarEnvioArquivo");
        this.$buefy.toast.open({
          message: `Arquivo maior que 150MB`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (!this.acceptedTypesZip.includes(this.arquivoZip["type"])) {
        this.arquivoZip = null;
        this.$emit("desabilitarEnvioArquivo");
        this.$buefy.toast.open({
          message: `Formato do arquivo inválido`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else {
        this.iconArquivoZip = "is-success";
        this.$emit("habilitarEnvioArquivo");
      }
    },
    deleteArquivoZip() {
      this.arquivoZip = null;
      this.iconArquivoZip = "";
      this.$emit("desabilitarEnvioArquivo");
    },
    alterarArquivoIndividual() {
      this, this.deleteArquivoZip();
      this.$emit("reiniciarSelecaoArquivo");
      this.$emit("desabilitarEnvioArquivo");
    },
    async prepararUploadArquivos() {
      if (this.arquivoZip !== null) {
        this.envioNaoIniciado = false;
        this.obterUrlUploadLoading = true;
        this.zipExt =
          this.arquivoZip !== null
            ? this.arquivoZip.name.replace(/^.*\./, "")
            : "";

        let body = {
          extArquivoUnicoImagemModelos: this.zipExt,
          operacao: "A",
        };

        try {
          await solicitacaoProjetoV2Repository.alterarSolicitacao(
            this.codigoSolicitacao,
            body
          );
          this.uploadArquivos();
        } catch (e) {
          this.obterUrlUploadLoading = false;
          this.envioNaoIniciado = true;
          this.$buefy.dialog.alert({
            title: "Erro",
            message: e.response.data["hydra:description"],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      } else {
        this.$buefy.toast.open({
          message: `Nenhum arquivo foi selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async uploadArquivos() {
      let solicitacaoProjetoIRI =
        "/solicitacao_projeto/" + this.codigoSolicitacao;
      this.envioNaoIniciado = false;
      this.obterUrlUploadLoading = true;
      try {
        const body = {
          solicitacaoProjeto: solicitacaoProjetoIRI,
          operacao: "U",
        };
        const response =
          await solicitacaoProjetoArquivosV2Repository.gerarLinkArquivos(body);
        this.obterUrlUploadLoading = false;
        this.enviarArquivos(response.data.linkUploadArquivoUnicoImagemModelos);
      } catch (e) {
        this.obterUrlUploadLoading = false;
        this.envioNaoIniciado = true;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    atualizarUploadArquivoUnico(event) {
      this.uploadPercentualZip = Number(
        ((event.loaded * 100) / event.total).toFixed(2)
      );
    },
    async enviarArquivos(linkUploadArquivoUnicoImagemModelos) {
      try {
        if (
          this.arquivoZip !== null &&
          linkUploadArquivoUnicoImagemModelos !== null
        ) {
          this.uploadArquivoZipLoading = true;
          const reqArquivoUnico = new XMLHttpRequest();
          reqArquivoUnico.upload.addEventListener(
            "progress",
            this.atualizarUploadArquivoUnico
          );
          reqArquivoUnico.open(
            "PUT",
            linkUploadArquivoUnicoImagemModelos,
            true
          );
          reqArquivoUnico.setRequestHeader("Content-Type", this.zipExt);
          reqArquivoUnico.onload = () => {
            if (reqArquivoUnico.status === 200) {
              this.uploadArquivoZipLoading = false;
              this.envioFinalizado = true;
              this.$emit("proximoPasso");
            }
          };
          reqArquivoUnico.onerror = () => {
            this.uploadArquivoZipLoading = false;
          };
          reqArquivoUnico.send(this.arquivoZip);
        }
      } catch (e) {
        this.envioNaoIniciado = true;
        this.uploadArquivoMaxilaLoading = false;
        this.uploadArquivoMandibulaLoading = false;
        this.uploadArquivoZipLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: "Erro ao enviar arquivos",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped></style>
