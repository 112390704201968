<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title"><b-icon icon="file-alt" /> Planejamento</p>
    </header>
    <section class="modal-card-body">
      <div class="columns is-centered">
        <div class="column is-12">
          <b-tabs position="atLeft" type="is-boxed" :expanded="false">
            <b-tab-item label="Dados/Modelos" icon="file-alt">
              <div class="tab-body">
                <div class="columns is-centered is-variable is-12">
                  <div class="column is-3">
                    <b-field label="Número" label-position="on-border">
                      <b-input
                        custom-class="input-show-only"
                        disabled
                        :value="numero"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field label="Data" label-position="on-border">
                      <b-input
                        custom-class="input-show-only"
                        disabled
                        :value="data"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field
                      label="Estado Planejamento"
                      label-position="on-border"
                    >
                      <b-input
                        custom-class="input-show-only"
                        disabled
                        :value="cdEstadoProjeto + ' ' + descEstProjeto"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field label="Solicitação" label-position="on-border">
                      <b-input
                        custom-class="input-show-only"
                        disabled
                        :value="solicitacao"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
                <form
                  ref="formDadosProjeto"
                  class="needs-validation"
                  novalidate
                >
                  <div class="columns">
                    <div class="column is-3">
                      <b-field
                        label="Qtd. Total Alinhadores Superior*"
                        label-position="on-border"
                      >
                        <b-input
                          custom-class="input-show-only"
                          :disabled="!edicaoHabilitada"
                          required
                          v-model="qtdTotalAlinhadoresSuperior"
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column is-3">
                      <b-field
                        label="Qtd. Total Alinhadores Inferior*"
                        label-position="on-border"
                      >
                        <b-input
                          custom-class="input-show-only"
                          required
                          :disabled="!edicaoHabilitada"
                          v-model="qtdTotalAlinhadoresInferior"
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column is-2">
                      <b-field label="Link Video" label-position="on-border">
                        <b-input
                          custom-class="input-show-only"
                          :disabled="!edicaoHabilitada"
                          v-model="linkHttpVideo"
                        ></b-input>
                      </b-field>
                    </div>
                    <div class="column is-2">
                      <h6 class="title is-7 pb-0 mb-1">
                        Guia Attach. Superior
                      </h6>
                      <b-field>
                        <b-radio
                          v-model="alAttachementSup"
                          size="is-small"
                          :native-value="true"
                          required
                          type="is-primary"
                          :disabled="!edicaoHabilitada"
                          class="mr-5"
                        >
                          <span>SIM</span>
                        </b-radio>
                        <b-radio
                          v-model="alAttachementSup"
                          size="is-small"
                          :native-value="false"
                          required
                          type="is-danger"
                          :disabled="!edicaoHabilitada"
                        >
                          <span>NÃO</span>
                        </b-radio>
                      </b-field>
                    </div>
                    <div class="column is-2">
                      <h6 class="title is-7 pb-0 mb-1">
                        Guia Attach. Inferior
                      </h6>
                      <b-field>
                        <b-radio
                          v-model="alAttachementInf"
                          size="is-small"
                          :native-value="true"
                          required
                          type="is-primary"
                          :disabled="!edicaoHabilitada"
                          class="mr-5"
                        >
                          <span>SIM</span>
                        </b-radio>
                        <b-radio
                          v-model="alAttachementInf"
                          size="is-small"
                          :native-value="false"
                          required
                          type="is-danger"
                          :disabled="!edicaoHabilitada"
                        >
                          <span>NÃO</span>
                        </b-radio>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-12">
                      <h6 class="title is-7">
                        *Caso tenha informado anteriormente, esse valor
                        contempla adição do alinhador de contenção
                      </h6>
                    </div>
                  </div>
                </form>
                <hr />
                <div
                  v-if="
                    dadosProjeto.listaAlinhadoresSuperior &&
                    dadosProjeto.listaAlinhadoresSuperior.length > 0
                  "
                  class="mb-3"
                >
                  <div class="columns pl-4">
                    <span class="has-text-weight-semibold is-size-7">
                      Alinhadores Superiores ({{ qtdAlSuperiorNormal }} +
                      {{ qtdAlSuperiorExtra }})
                    </span>
                  </div>
                  <div class="columns pl-4">
                    <div class="column is-12">
                      <b-taglist>
                        <b-tag
                          size="is-medium"
                          v-for="al in dadosProjeto.listaAlinhadoresSuperior"
                          :type="
                            al.pedido === true ? 'is-info is-light' : 'is-info'
                          "
                          :key="al.alinhador"
                          >{{ al.alinhador }}</b-tag
                        >
                      </b-taglist>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    dadosProjeto.listaAlinhadoresInferior &&
                    dadosProjeto.listaAlinhadoresInferior.length > 0
                  "
                  class="mb-3"
                >
                  <div class="columns pl-4">
                    <span class="has-text-weight-semibold is-size-7">
                      Alinhadores Inferiores ({{ qtdAlInferiorNormal }} +
                      {{ qtdAlInferiorExtra }})
                    </span>
                  </div>
                  <div class="columns pl-4">
                    <div class="column is-12">
                      <b-taglist>
                        <b-tag
                          size="is-medium"
                          v-for="al in dadosProjeto.listaAlinhadoresInferior"
                          :type="
                            al.pedido === true ? 'is-info is-light' : 'is-info'
                          "
                          :key="al.alinhador"
                          >{{ al.alinhador }}</b-tag
                        >
                      </b-taglist>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    dadosProjeto.listaAlinhadoresInferior.length > 0 &&
                    dadosProjeto.listaAlinhadoresSuperior.length > 0
                  "
                >
                  <div class="columns pl-4">
                    <span class="has-text-weight-semibold is-size-7">
                      Legenda
                    </span>
                  </div>
                  <div class="columns is-gapless pl-4">
                    <div class="column is-2">
                      <b-tag size="is-medium" type="is-info is-light">
                        Solicitado
                      </b-tag>
                    </div>
                    <div class="column is-2">
                      <b-tag size="is-medium" type="is-info">Disponível</b-tag>
                    </div>
                  </div>
                </div>
                <hr />
                <div
                  v-if="edicaoHabilitada === true"
                  class="columns is-centered"
                >
                  <div class="column is-2 mr-5">
                    <div class="columns is-centered">
                      <b-field>
                        <b-upload
                          v-model="arquivoProjeto"
                          @input="
                            validarArquivo(arquivoProjeto, 'arquivoProjeto')
                          "
                          drag-drop
                        >
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon
                                  icon="upload"
                                  size="is-medium"
                                  :type="iconArquivoProjeto"
                                ></b-icon>
                              </p>
                              <p class="subtitle is-6">ARQUIVO PRINCIPAL PDF</p>
                              <p>arraste e solte ou clique</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                    </div>
                    <div class="columns is-centered mt-3">
                      <div class="tags" v-if="arquivoProjeto !== null">
                        <span class="tag is-primary">
                          {{
                            arquivoProjeto.name.length > 25
                              ? arquivoProjeto.name.substr(0, 22) +
                                ".." +
                                arquivoProjeto.name.substr(
                                  arquivoProjeto.name.length - 4,
                                  arquivoProjeto.name.length + 1
                                )
                              : arquivoProjeto.name
                          }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteArquivoProjeto('arquivoProjeto')"
                          ></button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-2 mr-5">
                    <div class="columns is-centered">
                      <b-field>
                        <b-upload
                          v-model="arquivoSupInicial"
                          @input="
                            validarArquivo(
                              arquivoSupInicial,
                              'arquivoSupInicial'
                            )
                          "
                          drag-drop
                        >
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon
                                  icon="upload"
                                  size="is-medium"
                                  :type="iconArquivoSupInicial"
                                ></b-icon>
                              </p>
                              <p class="subtitle is-6">
                                ARQUIVO SUPERIOR INICIAL
                              </p>
                              <p>arraste e solte ou clique</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                    </div>
                    <div class="columns is-centered mt-3">
                      <div class="tags" v-if="arquivoSupInicial !== null">
                        <span class="tag is-primary">
                          {{
                            arquivoSupInicial.name.length > 25
                              ? arquivoSupInicial.name.substr(0, 22) +
                                ".." +
                                arquivoSupInicial.name.substr(
                                  arquivoSupInicial.name.length - 4,
                                  arquivoSupInicial.name.length + 1
                                )
                              : arquivoSupInicial.name
                          }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteArquivoProjeto('arquivoSupInicial')"
                          ></button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-2 mr-5">
                    <div class="columns is-centered">
                      <b-field>
                        <b-upload
                          v-model="arquivoSupFinal"
                          @input="
                            validarArquivo(arquivoSupFinal, 'arquivoSupFinal')
                          "
                          drag-drop
                        >
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon
                                  icon="upload"
                                  size="is-medium"
                                  :type="iconArquivoSupFinal"
                                ></b-icon>
                              </p>
                              <p class="subtitle is-6">
                                ARQUIVO SUPERIOR FINAL
                              </p>
                              <p>arraste e solte ou clique</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                    </div>
                    <div class="columns is-centered mt-3">
                      <div class="tags" v-if="arquivoSupFinal !== null">
                        <span class="tag is-primary">
                          {{
                            arquivoSupFinal.name.length > 25
                              ? arquivoSupFinal.name.substr(0, 22) +
                                ".." +
                                arquivoSupFinal.name.substr(
                                  arquivoSupFinal.name.length - 4,
                                  arquivoSupFinal.name.length + 1
                                )
                              : arquivoSupFinal.name
                          }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteArquivoProjeto('arquivoSupFinal')"
                          ></button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-2 mr-5">
                    <div class="columns is-centered">
                      <b-field>
                        <b-upload
                          v-model="arquivoInfInicial"
                          @input="
                            validarArquivo(
                              arquivoInfInicial,
                              'arquivoInfInicial'
                            )
                          "
                          drag-drop
                        >
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon
                                  icon="upload"
                                  size="is-medium"
                                  :type="iconArquivoInfInicial"
                                ></b-icon>
                              </p>
                              <p class="subtitle is-6">
                                ARQUIVO INFERIOR INICIAL
                              </p>
                              <p>arraste e solte ou clique</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                    </div>
                    <div class="columns is-centered mt-3">
                      <div class="tags" v-if="arquivoInfInicial !== null">
                        <span class="tag is-primary">
                          {{
                            arquivoInfInicial.name.length > 25
                              ? arquivoInfInicial.name.substr(0, 22) +
                                ".." +
                                arquivoInfInicial.name.substr(
                                  arquivoInfInicial.name.length - 4,
                                  arquivoInfInicial.name.length + 1
                                )
                              : arquivoInfInicial.name
                          }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteArquivoProjeto('arquivoInfInicial')"
                          ></button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-2 mr-5">
                    <div class="columns is-centered">
                      <b-field>
                        <b-upload
                          v-model="arquivoInfFinal"
                          @input="
                            validarArquivo(arquivoInfFinal, 'arquivoInfFinal')
                          "
                          drag-drop
                        >
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon
                                  icon="upload"
                                  size="is-medium"
                                  :type="iconArquivoInfFinal"
                                ></b-icon>
                              </p>
                              <p class="subtitle is-6">
                                ARQUIVO INFERIOR FINAL
                              </p>
                              <p>arraste e solte ou clique</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>
                    </div>
                    <div class="columns is-centered mt-3">
                      <div class="tags" v-if="arquivoInfFinal !== null">
                        <span class="tag is-primary">
                          {{
                            arquivoInfFinal.name.length > 25
                              ? arquivoInfFinal.name.substr(0, 22) +
                                ".." +
                                arquivoInfFinal.name.substr(
                                  arquivoInfFinal.name.length - 4,
                                  arquivoInfFinal.name.length + 1
                                )
                              : arquivoInfFinal.name
                          }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteArquivoProjeto('arquivoInfFinal')"
                          ></button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="edicaoHabilitada === false"
                  class="columns is-variable is-7"
                >
                  <div class="column is-2">
                    <button
                      class="button is-primary is-rounded"
                      :disabled="
                        cdArquivoProjeto === '' || cdArquivoProjeto === null
                      "
                      @click="
                        abrirArquivoProjeto(
                          cdArquivoProjeto,
                          linkArquivoProjeto,
                          'Planejamento'
                        )
                      "
                    >
                      <b-icon icon="file-invoice"></b-icon>
                      <strong>Arquivo Planejamento</strong>
                    </button>
                  </div>
                  <div class="column is-2">
                    <button
                      class="button is-primary is-rounded"
                      :disabled="
                        cdArquivoSupInicial === '' ||
                        cdArquivoSupInicial === null
                      "
                      rounded
                      @click="
                        abrirArquivoProjeto(
                          cdArquivoSupInicial,
                          linkArquivoSupInicial,
                          'Superior Inicial'
                        )
                      "
                    >
                      <b-icon icon="file-invoice"></b-icon>
                      <strong>Superior Inicial</strong>
                    </button>
                  </div>
                  <div class="column is-2">
                    <button
                      class="button is-primary is-rounded"
                      :disabled="
                        cdArquivoSupFinal === '' || cdArquivoSupFinal === null
                      "
                      rounded
                      @click="
                        abrirArquivoProjeto(
                          cdArquivoSupFinal,
                          linkArquivoSupFinal,
                          'Superior Final'
                        )
                      "
                    >
                      <b-icon icon="file-invoice"></b-icon>
                      <strong>Superior Final</strong>
                    </button>
                  </div>
                  <div class="column is-2">
                    <button
                      class="button is-primary is-rounded"
                      :disabled="
                        cdArquivoInfInicial === '' ||
                        cdArquivoInfInicial === null
                      "
                      rounded
                      @click="
                        abrirArquivoProjeto(
                          cdArquivoInfInicial,
                          linkArquivoInfInicial,
                          'Inferior Inicial'
                        )
                      "
                    >
                      <b-icon icon="file-invoice"></b-icon>
                      <strong>Inferior Incial</strong>
                    </button>
                  </div>
                  <div class="column is-2">
                    <button
                      class="button is-primary is-rounded"
                      :disabled="
                        cdArquivoInfFinal === '' || cdArquivoInfFinal === null
                      "
                      rounded
                      @click="
                        abrirArquivoProjeto(
                          cdArquivoInfFinal,
                          linkArquivoInfFinal,
                          'Inferior Final'
                        )
                      "
                    >
                      <b-icon icon="file-invoice"></b-icon>
                      <strong>Inferior Final</strong>
                    </button>
                  </div>
                </div>
                <div
                  v-if="cdEstadoProjeto !== 10 && edicaoHabilitada === false"
                  class="columns is-variable is-7"
                >
                  <div class="column is-3">
                    <button
                      class="button is-danger is-rounded"
                      :disabled="linkHttpVideo === '' || linkHttpVideo === null"
                      rounded
                      @click="abrirModalVideo(codVideo)"
                    >
                      <b-icon icon="video"></b-icon>
                      <strong>Vídeo Explicativo</strong>
                    </button>
                  </div>
                </div>
                <hr v-if="cdEstadoProjeto === 40" />
                <div
                  v-if="cdEstadoProjeto === 40"
                  class="columns is-variable is-7"
                >
                  <div class="column is-6">
                    <b-field label="Motivo Recusa">
                      <b-input
                        custom-class="input-show-only"
                        type="textarea"
                        min-length="5"
                        v-model="textoRecusa"
                        rounded
                        disabled
                        placeholder="Preencher somente em caso de recusa"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="Observações" icon="eye">
              <div class="tab-body">
                <ObservacaoProjeto
                  :edicao-habilitada="edicaoHabilitada"
                  :dados-projeto="dadosProjeto"
                  @informarObservacao="setObservacao"
                />
              </div>
            </b-tab-item>
          </b-tabs>
          <hr />
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-danger"
        rounded
        icon-left="arrow-left"
        @click="$parent.close()"
        ><strong>Fechar</strong></b-button
      >
      <b-button
        v-if="edicaoHabilitada === true"
        type="is-twitter"
        rounded
        icon-left="save"
        @click="validarFinalizacao('S')"
        ><strong>Salvar</strong></b-button
      >
      <b-button
        v-if="
          (cdEstadoProjeto !== null && cdEstadoProjeto === 10) ||
          edicaoHabilitada === true
        "
        type="is-primary"
        rounded
        icon-left="share"
        @click="validarFinalizacao('F')"
        ><strong>Finalizar</strong></b-button
      >
      <b-button
        v-if="
          cdEstadoProjeto !== null &&
          cdEstadoProjeto <= 20 &&
          edicaoHabilitada === false
        "
        type="is-warning"
        rounded
        icon-left="save"
        @click="habilitarEdicao"
        ><strong>Editar</strong></b-button
      >
    </footer>
    <b-modal
      :active.sync="exibirModalProgresso"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 500px">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <b-icon icon="hourglass-end" /> Finalizando projeto
          </p>
        </header>
        <section class="modal-card-body pt-4">
          <div v-if="isUpdatingProject">
            <div class="columns is-centered mb-2">
              <div class="column is-10">
                <p class="subtitle is-6">Atualizando projeto ...</p>
              </div>
            </div>
            <div class="columns is-centered mb-4">
              <div class="column is-10">
                <b-progress size="is-large" />
              </div>
            </div>
          </div>
          <div v-if="isLinkGenerating">
            <div class="columns is-centered mb-2">
              <div class="column is-10">
                <p class="subtitle is-6">
                  Gerando Links de Upload dos Aquivos ...
                </p>
              </div>
            </div>
            <div class="columns is-centered mb-4">
              <div class="column is-8">
                <b-progress size="is-large" />
              </div>
            </div>
          </div>
          <div v-if="isUploadArquivoProjeto">
            <div class="columns is-centered mb-2">
              <div class="column is-10">
                <p class="subtitle is-6">Enviando arquivo principal ...</p>
              </div>
            </div>
            <div class="columns is-centered mb-4">
              <div class="column is-10">
                <b-progress
                  size="is-large"
                  :value="uploadPercentualArquivoProjeto"
                  show-value
                  format="percent"
                >
                </b-progress>
              </div>
            </div>
          </div>
          <div v-if="isUploadArquivoSupInicial">
            <div class="columns is-centered mb-2">
              <div class="column is-10">
                <p class="subtitle is-6">Enviando arquivo maxila inicial ...</p>
              </div>
            </div>
            <div class="columns is-centered mb-4">
              <div class="column is-10">
                <b-progress
                  size="is-large"
                  :value="uploadPercentualArquivoSupInicial"
                  show-value
                  format="percent"
                >
                </b-progress>
              </div>
            </div>
          </div>
          <div v-if="isUploadArquivoSupFinal">
            <div class="columns is-centered mb-2">
              <div class="column is-10">
                <p class="subtitle is-6">Enviando arquivo maxila final ...</p>
              </div>
            </div>
            <div class="columns is-centered mb-4">
              <div class="column is-10">
                <b-progress
                  size="is-large"
                  :value="uploadPercentualArquivoSupFinal"
                  show-value
                  format="percent"
                >
                </b-progress>
              </div>
            </div>
          </div>
          <div v-if="isUploadArquivoInfInicial">
            <div class="columns is-centered mb-2">
              <div class="column is-10">
                <p class="subtitle is-6">
                  Enviando arquivo mandíbula inicial ...
                </p>
              </div>
            </div>
            <div class="columns is-centered mb-4">
              <div class="column is-10">
                <b-progress
                  size="is-large"
                  :value="uploadPercentualArquivoInfInicial"
                  show-value
                  format="percent"
                >
                </b-progress>
              </div>
            </div>
          </div>
          <div v-if="isUploadArquivoInfFinal">
            <div class="columns is-centered mb-2">
              <div class="column is-10">
                <p class="subtitle is-6">
                  Enviando arquivo mandíbula final ...
                </p>
              </div>
            </div>
            <div class="columns is-centered mb-4">
              <div class="column is-10">
                <b-progress
                  size="is-large"
                  :value="uploadPercentualArquivoInfFinal"
                  show-value
                  format="percent"
                >
                </b-progress>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ModalModeloSTL from "../gerenciar-solicitacoes/dados-projetos-solicitacao/Modal/ModalModeloSTL";
import ObservacaoProjeto from "./ObservacaoProjeto";
import ModalVideoExplicativo from "./ModalVideoExplicativo";
import projetoRepositoryV2 from "@/repository/projetoRepositoryV2";
import projetoArquivosRepositoryV2 from "@/repository/projetoArquivosRepositoryV2";

export default {
  name: "ModalProjeto",
  props: {
    dadosProjeto: Object,
  },
  components: {
    ObservacaoProjeto,
  },
  data() {
    return {
      numero: null,
      data: null,
      solicitacao: null,
      cdArquivoProjeto: null,
      arquivoProjeto: null,
      linkArquivoProjeto: null,
      cdArquivoSupInicial: null,
      arquivoSupInicial: null,
      linkArquivoSupInicial: null,
      cdArquivoSupFinal: null,
      arquivoSupFinal: null,
      linkArquivoSupFinal: null,
      cdArquivoInfInicial: null,
      arquivoInfInicial: null,
      linkArquivoInfInicial: null,
      cdArquivoInfFinal: null,
      arquivoInfFinal: null,
      linkArquivoInfFinal: null,
      linkHttpVideo: null,
      qtdTotalAlinhadoresSuperior: null,
      qtdTotalAlinhadoresInferior: null,
      qtdAlinhadoresSuperiorSolicitados: null,
      qtdAlinhadoresInferiorSolicitados: null,
      cdEstadoProjeto: null,
      descEstProjeto: null,
      iconArquivoProjeto: "",
      iconArquivoSupInicial: "",
      iconArquivoSupFinal: "",
      iconArquivoInfInicial: "",
      iconArquivoInfFinal: "",
      textoRecusa: null,
      exibirModalProgresso: false,
      isUpdatingProject: false,
      isLinkGenerating: false,
      isUploadArquivoProjeto: false,
      isUploadArquivoSupInicial: false,
      isUploadArquivoSupFinal: false,
      isUploadArquivoInfInicial: false,
      isUploadArquivoInfFinal: false,
      uploadPercentualArquivoProjeto: 0,
      uploadPercentualArquivoSupInicial: 0,
      uploadPercentualArquivoSupFinal: 0,
      uploadPercentualArquivoInfInicial: 0,
      uploadPercentualArquivoInfFinal: 0,
      edicaoHabilitada: false,
      codVideo: null,
      alAttachementSup: null,
      alAttachementInf: null,
      observacao: "",
      qtdAlSuperiorNormal: 0,
      qtdAlSuperiorExtra: 0,
      qtdAlInferiorNormal: 0,
      qtdAlInferiorExtra: 0,
    };
  },
  beforeMount() {
    this.buscarDadosProjeto();
  },
  methods: {
    async buscarDadosProjeto() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const response = await projetoRepositoryV2.buscaProjeto(
          this.dadosProjeto.nrProjeto
        );
        this.isLinkGenerating = false;
        this.atribuirDados(response.data);
        loadingComponent.close();
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro obter dados do planejamento",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    validarFinalizacao(operacao) {
      if (this.$refs.formDadosProjeto.checkValidity()) {
        if (this.alAttachementSup === null || this.alAttachementInf === null) {
          this.$buefy.dialog.alert({
            title: "Erro",
            message:
              "Informe se planejamento possui guia de attachment inferior ou superior.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        } else if (this.observacao.length > 2) {
          this.finalizarProjeto(operacao);
        } else {
          this.$buefy.dialog.confirm({
            title: "Sem observações gerais",
            message: `Você deseja ${
              operacao === "S" ? "Salvar" : "Finalizar"
            } o projeto sem observações gerais?`,
            confirmText: "Sim",
            cancelText: "Não",
            type: "is-danger",
            hasIcon: true,
            onConfirm: () => this.finalizarProjeto(operacao),
          });
        }
      } else {
        this.$buefy.dialog.alert({
          title: "Erro",
          message: "preenchar os campos obrigatórios",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async finalizarProjeto(operacao) {
      this.exibirModalProgresso = true;
      this.isUpdatingProject = true;
      let qtdArquivoUpload = 0;

      try {
        let body = {
          operacao: operacao,
          linkHttpVideo: this.linkHttpVideo,
          observacaoProjeto: this.observacao,
        };

        if (
          this.qtdTotalAlinhadoresSuperior !==
          this.dadosProjeto.qtdTotalAlinhadoresSuperior
        ) {
          const listaAlinhadoresSuperior = this.gerarListaAlinhadores(
            this.qtdTotalAlinhadoresSuperior,
            "U"
          );

          body = {
            ...body,
            qtdTotalAlinhadoresSuperior: listaAlinhadoresSuperior.length,
            listaAlinhadoresSuperior: listaAlinhadoresSuperior,
          };
        }

        if (
          this.qtdTotalAlinhadoresInferior !==
          this.dadosProjeto.qtdTotalAlinhadoresInferior
        ) {
          const listaAlinhadoresInferior = this.gerarListaAlinhadores(
            this.qtdTotalAlinhadoresInferior,
            "L"
          );

          body = {
            ...body,
            qtdTotalAlinhadoresInferior: listaAlinhadoresInferior.length,
            listaAlinhadoresInferior: listaAlinhadoresInferior,
          };
        }

        if (this.arquivoProjeto !== null) {
          body = {
            ...body,
            extArquivoProjeto: this.arquivoProjeto.name.replace(/^.*\./, ""),
          };
          qtdArquivoUpload += 1;
        }

        if (this.arquivoSupInicial !== null) {
          body = {
            ...body,
            extArquivoSupInicial: this.arquivoSupInicial.name.replace(
              /^.*\./,
              ""
            ),
          };
          qtdArquivoUpload += 1;
        }

        if (this.arquivoSupFinal !== null) {
          body = {
            ...body,
            extArquivoSupFinal: this.arquivoSupFinal.name.replace(/^.*\./, ""),
          };
          qtdArquivoUpload += 1;
        }

        if (this.arquivoInfInicial !== null) {
          body = {
            ...body,
            extArquivoInfInicial: this.arquivoInfInicial.name.replace(
              /^.*\./,
              ""
            ),
          };
          qtdArquivoUpload += 1;
        }

        if (this.arquivoInfFinal !== null) {
          body = {
            ...body,
            extArquivoInfFinal: this.arquivoInfFinal.name.replace(/^.*\./, ""),
          };
          qtdArquivoUpload += 1;
        }

        body = {
          ...body,
          guiaAttach: {
            attachmentSuperior: {
              disponivel: this.alAttachementSup ? true : false,
              pedido: false,
            },
            attachmentInferior: {
              disponivel: this.alAttachementInf ? true : false,
              pedido: false,
            },
          },
        };
        await projetoRepositoryV2.alterarProjeto(this.numero, body);
        this.isUpdatingProject = false;
        if (qtdArquivoUpload > 0) {
          this.gerLinkUploadArquivos();
        } else {
          this.exibirModalProgresso = false;
          this.$emit("atualizarProjetos");
          this.$parent.close();
        }
      } catch (e) {
        this.isUpdatingProject = false;
        this.exibirModalProgresso = false;
        this.$buefy.dialog.alert({
          title: "Erro ao Finalizar Projeto",
          message: e.reponse ? e.response.data["hydra:description"] : e.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async gerLinkUploadArquivos() {
      try {
        this.isLinkGenerating = true;
        const body = {
          projeto: `/projeto/${this.numero}`,
          operacao: "U",
        };

        const response = await projetoArquivosRepositoryV2.gerarLinkArquivos(
          body
        );
        this.isLinkGenerating = false;
        this.processarEnvioArquivos(response.data);
      } catch (e) {
        this.exibirModalProgresso = false;
        this.isLinkGenerating = false;
        this.$buefy.dialog.alert({
          title: "Erro ao gerar links de upload",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    processarEnvioArquivos(links) {
      links.linkUploadArquivoProjeto && this.arquivoProjeto
        ? this.enviarArquivoProjeto(links.linkUploadArquivoProjeto)
        : null;
      links.linkUploadArquivoSupInicial && this.arquivoSupInicial
        ? this.enviarArquivoSupInicial(links.linkUploadArquivoSupInicial)
        : null;
      links.linkUploadArquivoSupFinal && this.arquivoSupFinal
        ? this.enviarArquivoSupFinal(links.linkUploadArquivoSupFinal)
        : null;
      links.linkUploadArquivoInfInicial && this.arquivoInfInicial
        ? this.enviarArquivoInfInicial(links.linkUploadArquivoInfInicial)
        : null;
      links.linkUploadArquivoInfFinal && this.arquivoInfFinal
        ? this.enviarArquivoInfFinal(links.linkUploadArquivoInfFinal)
        : null;
    },
    enviarArquivoProjeto(link) {
      this.isUploadArquivoProjeto = true;
      const reqArquivoProjeto = new XMLHttpRequest();
      reqArquivoProjeto.upload.addEventListener("progress", (event) => {
        this.uploadPercentualArquivoProjeto = Number(
          ((event.loaded * 100) / event.total).toFixed(2)
        );
      });
      reqArquivoProjeto.open("PUT", link, true);
      reqArquivoProjeto.setRequestHeader(
        "ContentType",
        encodeURIComponent(this.arquivoProjeto.type)
      );
      reqArquivoProjeto.onload = () => {
        if (reqArquivoProjeto.status === 200) {
          this.isUploadArquivoProjeto = false;
          this.uploadPercentualArquivoProjeto = 0;
          this.verificarUploadCompletos();
        } else if (reqArquivoProjeto.status === 403) {
          this.reiniciarUpload();
          this.$buefy.dialog.alert({
            title: "Erro ao enviar arquivo",
            message: "Existe inconsistência no arquivo selecionado.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      };
      reqArquivoProjeto.onerror = (error) => {
        this.reiniciarUpload();
        this.$buefy.dialog.alert({
          title: "Erro ao enviar arquivo",
          message: error,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      };
      reqArquivoProjeto.send(this.arquivoProjeto);
    },
    enviarArquivoSupInicial(link) {
      this.isUploadArquivoSupInicial = true;
      const reqArquivoSupInicial = new XMLHttpRequest();
      reqArquivoSupInicial.upload.addEventListener("progress", (event) => {
        this.uploadPercentualArquivoSupInicial = Number(
          ((event.loaded * 100) / event.total).toFixed(2)
        );
      });
      reqArquivoSupInicial.open("PUT", link, true);
      reqArquivoSupInicial.setRequestHeader(
        "Content-Type",
        this.arquivoSupInicial.name.replace(/^.*\./, "")
      );
      reqArquivoSupInicial.onload = () => {
        if (reqArquivoSupInicial.status === 200) {
          this.isUploadArquivoSupInicial = false;
          this.uploadPercentualArquivoSupInicial = 0;
          this.verificarUploadCompletos();
        }
      };
      reqArquivoSupInicial.onerror = (error) => {
        this.reiniciarUpload();
        this.$buefy.dialog.alert({
          title: "Erro ao enviar arquivo",
          message: error,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      };
      reqArquivoSupInicial.send(this.arquivoSupInicial);
    },
    enviarArquivoSupFinal(link) {
      this.isUploadArquivoSupFinal = true;
      const reqArquivoSupFinal = new XMLHttpRequest();
      reqArquivoSupFinal.upload.addEventListener("progress", (event) => {
        this.uploadPercentualArquivoSupFinal = Number(
          ((event.loaded * 100) / event.total).toFixed(2)
        );
      });
      reqArquivoSupFinal.open("PUT", link, true);
      reqArquivoSupFinal.setRequestHeader(
        "Content-Type",
        this.arquivoSupFinal.name.replace(/^.*\./, "")
      );
      reqArquivoSupFinal.onload = () => {
        if (reqArquivoSupFinal.status === 200) {
          this.isUploadArquivoSupFinal = false;
          this.uploadPercentualArquivoSupFinal = 0;
          this.verificarUploadCompletos();
        }
      };
      reqArquivoSupFinal.onerror = (error) => {
        this.reiniciarUpload();
        this.$buefy.dialog.alert({
          title: "Erro ao enviar arquivo",
          message: error,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      };
      reqArquivoSupFinal.send(this.arquivoSupFinal);
    },
    enviarArquivoInfInicial(link) {
      this.isUploadArquivoInfInicial = true;
      const reqArquivoInfInicial = new XMLHttpRequest();
      reqArquivoInfInicial.upload.addEventListener("progress", (event) => {
        this.uploadPercentualArquivoInfInicial = Number(
          ((event.loaded * 100) / event.total).toFixed(2)
        );
      });
      reqArquivoInfInicial.open("PUT", link, true);
      reqArquivoInfInicial.setRequestHeader(
        "Content-Type",
        this.arquivoInfInicial.name.replace(/^.*\./, "")
      );
      reqArquivoInfInicial.onload = () => {
        if (reqArquivoInfInicial.status === 200) {
          this.isUploadArquivoInfInicial = false;
          this.uploadPercentualArquivoInfInicial = 0;
          this.verificarUploadCompletos();
        }
      };
      reqArquivoInfInicial.onerror = (error) => {
        this.reiniciarUpload();
        this.$buefy.dialog.alert({
          title: "Erro ao enviar arquivo",
          message: error,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      };
      reqArquivoInfInicial.send(this.arquivoInfInicial);
    },
    enviarArquivoInfFinal(link) {
      this.isUploadArquivoInfFinal = true;
      const reqArquivoInfFinal = new XMLHttpRequest();
      reqArquivoInfFinal.upload.addEventListener("progress", (event) => {
        this.uploadPercentualArquivoInfFinal = Number(
          ((event.loaded * 100) / event.total).toFixed(2)
        );
      });
      reqArquivoInfFinal.open("PUT", link, true);
      reqArquivoInfFinal.setRequestHeader(
        "Content-Type",
        this.arquivoInfFinal.name.replace(/^.*\./, "")
      );
      reqArquivoInfFinal.onload = () => {
        if (reqArquivoInfFinal.status === 200) {
          this.isUploadArquivoInfFinal = false;
          this.uploadPercentualArquivoInfFinal = 0;
          this.verificarUploadCompletos();
        }
      };
      reqArquivoInfFinal.onerror = (error) => {
        this.reiniciarUpload();
        this.$buefy.dialog.alert({
          title: "Erro ao enviar arquivo",
          message: error,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      };
      reqArquivoInfFinal.send(this.arquivoInfFinal);
    },
    verificarUploadCompletos() {
      if (
        this.isUploadArquivoProjeto === false &&
        this.isUploadArquivoSupInicial === false &&
        this.isUploadArquivoSupFinal == false &&
        this.isUploadArquivoInfInicial === false &&
        this.isUploadArquivoInfFinal === false
      ) {
        this.exibirModalProgresso = false;
        this.$buefy.dialog.alert({
          title: "Sucesso",
          message: "Projeto finalizado com sucesso!",
          type: "is-success",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          onConfirm: () => {
            this.$emit("atualizarProjetos");
            this.$parent.close();
          },
        });
      }
    },
    abrirArquivoProjeto(codigoArquivo, link, titulo) {
      let formato = codigoArquivo.substr(codigoArquivo.indexOf("."));
      if (formato === ".stl") {
        this.$buefy.modal.open({
          parent: this,
          props: {
            caminhoModelo: link,
            tituloModelo: titulo,
          },
          component: ModalModeloSTL,
          hasModalCard: true,
          customClass: "custom-class custom-class-2",
          trapFocus: true,
          fullScreen: true,
        });
      } else {
        window.open(link, "_blank");
      }
    },
    async gerarUrlLinks() {
      const loadingComponent = this.$buefy.loading.open();

      let body = {
        projeto: `/projeto/${this.numero}`,
        operacao: "D",
      };

      try {
        const response = await projetoArquivosRepositoryV2.gerarLinkArquivos(
          body
        );
        this.atribuirLinks(response.data);
        loadingComponent.close();
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao obter links download",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    atribuirLinks(listaLinks) {
      if (this.cdArquivoProjeto !== null) {
        this.linkArquivoProjeto = listaLinks.linkDownloadArquivoProjeto;
      }

      if (this.cdArquivoSupInicial !== null) {
        this.linkArquivoSupInicial = listaLinks.linkDownloadArquivoSupInicial;
      }

      if (this.cdArquivoSupFinal !== null) {
        this.linkArquivoSupFinal = listaLinks.linkDownloadArquivoSupFinal;
      }

      if (this.cdArquivoInfInicial !== null) {
        this.linkArquivoInfInicial = listaLinks.linkDownloadArquivoInfInicial;
      }

      if (this.cdArquivoInfFinal !== null) {
        this.linkArquivoInfFinal = listaLinks.linkDownloadArquivoInfFinal;
      }
    },
    validarArquivo(arquivo, model) {
      if (!arquivo) {
        this.$buefy.toast.open({
          message: `Nenhum Arquivo Selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (arquivo.size > 81000608) {
        this[model] = null;
        this.$buefy.toast.open({
          message: `Arquivo maior que 80MB`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else {
        switch (model) {
          case "arquivoProjeto":
            this.iconArquivoProjeto = "is-success";
            break;
          case "arquivoSupInicial":
            this.iconArquivoSupInicial = "is-success";
            break;
          case "arquivoSupFinal":
            this.iconArquivoSupFinal = "is-success";
            break;
          case "arquivoInfInicial":
            this.iconArquivoInfInicial = "is-success";
            break;
          case "arquivoInfFinal":
            this.iconArquivoInfFinal = "is-success";
            break;
        }
      }
    },
    deleteArquivoProjeto(model) {
      switch (model) {
        case "arquivoProjeto":
          this.arquivoProjeto = null;
          this.iconArquivoProjeto = "";
          break;
        case "arquivoSupInicial":
          this.arquivoSupInicial = null;
          this.iconArquivoSupInicial = "";
          break;
        case "arquivoSupFinal":
          this.arquivoSupFinal = null;
          this.iconArquivoSupFinal = "";
          break;
        case "arquivoInfInicial":
          this.arquivoInfInicial = null;
          this.iconArquivoInfInicial = "";
          break;
        case "arquivoInfFinal":
          this.arquivoInfFinal = null;
          this.iconArquivoInfFinal = "";
          break;
      }
    },
    gerarListaAlinhadores(qtdAlinhadores, tipo) {
      var lista = [];
      for (var i = 1; i <= qtdAlinhadores; i++) {
        var item = {
          alinhador: "",
          pedido: false,
        };

        item.alinhador = tipo + i;

        lista.push(item);
      }

      if (qtdAlinhadores > 0) {
        var itemContencao = {
          alinhador: "",
          pedido: false,
        };
        itemContencao.alinhador = tipo + qtdAlinhadores + " 1.0";
        lista.push(itemContencao);

        var itemUltimoContencao = {
          alinhador: "",
          pedido: false,
        };
        itemUltimoContencao.alinhador = tipo + qtdAlinhadores + " Contenção";
        lista.push(itemUltimoContencao);
      }

      return lista;
    },
    habilitarEdicao() {
      this.edicaoHabilitada = true;
    },
    abrirModalVideo(codVideo) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          codVideo: codVideo,
        },
        component: ModalVideoExplicativo,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        fullScreen: false,
      });
    },
    setObservacao(obs) {
      this.observacao = obs;
    },
    reiniciarUpload() {
      this.isUploadArquivoProjeto = false;
      this.uploadPercentualArquivoProjeto = 0;
      this.isUploadArquivoSupInicial = false;
      this.uploadPercentualArquivoSupInicial = 0;
      this.isUploadArquivoSupFinal = false;
      this.uploadPercentualArquivoSupFinal = 0;
      this.isUploadArquivoInfInicial = false;
      this.uploadPercentualArquivoInfInicial = 0;
      this.isUploadArquivoInfFinal = false;
      this.uploadPercentualArquivoInfFinal = 0;
      this.exibirModalProgresso = false;
    },
    atribuirDados(dadosProjeto) {
      this.numero = dadosProjeto.nrProjeto;

      this.data = dadosProjeto.dataHoraProjeto;

      this.solicitacao = dadosProjeto.solicitacaoProjeto.codigoSolicitacao;

      this.cdArquivoProjeto = dadosProjeto.cdArquivoProjeto;

      this.cdArquivoSupInicial = dadosProjeto.cdArquivoSupInicial;

      this.cdArquivoSupFinal = dadosProjeto.cdArquivoSupFinal;

      this.cdArquivoInfInicial = dadosProjeto.cdArquivoInfInicial;

      this.cdArquivoInfFinal = dadosProjeto.cdArquivoInfFinal;

      if (
        this.dadosProjeto.linkHttpVideo !== null &&
        this.dadosProjeto.linkHttpVideo !== ""
      ) {
        this.linkHttpVideo = dadosProjeto.linkHttpVideo;
        this.codVideo = this.linkHttpVideo.substring(
          this.linkHttpVideo.indexOf("v=") + 2
        );
      } else {
        this.linkHttpVideo = "";
        this.codVideo = "";
      }

      this.qtdTotalAlinhadoresSuperior =
        dadosProjeto.qtdTotalAlinhadoresSuperior;
      this.qtdTotalAlinhadoresInferior =
        dadosProjeto.qtdTotalAlinhadoresInferior;
      this.qtdAlinhadoresSuperiorSolicitados =
        dadosProjeto.qtdAlinhadoresSuperiorSolicitados;
      this.qtdAlinhadoresInferiorSolicitados =
        dadosProjeto.qtdAlinhadoresInferiorSolicitados;
      this.cdEstadoProjeto = dadosProjeto.estadoProjeto.cdEstProjeto;
      this.descEstProjeto = dadosProjeto.estadoProjeto.descricao;
      this.textoRecusa = dadosProjeto.textoRecusa;
      this.observacao =
        dadosProjeto.observacaoProjeto !== null
          ? dadosProjeto.observacaoProjeto
          : "";

      if (dadosProjeto.guiaAttach) {
        this.alAttachementSup =
          dadosProjeto.guiaAttach.attachmentSuperior.disponivel;
        this.alAttachementInf =
          dadosProjeto.guiaAttach.attachmentInferior.disponivel;
      }
      this.gerarUrlLinks();
      this.calcularQtdAlinhadores(dadosProjeto);
    },
    calcularQtdAlinhadores(dadosProjeto) {
      this.qtdAlSuperiorExtra = dadosProjeto.listaAlinhadoresSuperior.filter(
        (value) => {
          if (
            value.alinhador.includes("1.0") ||
            value.alinhador.includes("Contenção")
          )
            return value;
        }
      ).length;
      this.qtdAlSuperiorNormal =
        dadosProjeto.listaAlinhadoresSuperior.length - this.qtdAlSuperiorExtra;

      this.qtdAlInferiorExtra = dadosProjeto.listaAlinhadoresInferior.filter(
        (value) => {
          if (
            value.alinhador.includes("1.0") ||
            value.alinhador.includes("Contenção")
          )
            return value;
        }
      ).length;
      this.qtdAlInferiorNormal =
        dadosProjeto.listaAlinhadoresInferior.length - this.qtdAlSuperiorExtra;
    },
  },
};
</script>

<style scoped>
.tab-body {
  min-height: 50vh;
  width: 95vw;
  padding: 1vw;
}
</style>
