
























































































































import Vue from "vue";
import { buscarCobrancaPedidoModeloLista } from "@/repository/Cobranca/CobrancaListaRepository";
import {
  CobrancaListaParamsRequest,
  CobrancasPedidoModeloItem,
  CobrancasPedidoModeloListaResponse,
} from "@/repository/Cobranca/CobrancaListaData";

import FinanceiroPedidoModeloTabela from "@/views/financeiro/components/FinanceiroPedidoModeloTabela.vue";
import axios from "axios";

const financeiroPedidoModelo = Vue.extend({
  name: "FinanceiroPlanejamentos",
  components: { FinanceiroPedidoModeloTabela },
  data() {
    return {
      totalItensPedidoModeloEmitidos: 0,
      isLoadingListaPedidoModeloEmitidos: false as boolean,
      listaPedidoModeloEmitidos: [] as CobrancasPedidoModeloItem[],
      totalItensPedidoModeloPagos: 0,
      isLoadingListaPedidoModeloPagos: false as boolean,
      listaPedidoModeloPagos: [] as CobrancasPedidoModeloItem[],
      totalItensPedidoModeloTodos: 0,
      isLoadingListaPedidoModeloTodos: false as boolean,
      listaPedidoModeloTodos: [] as CobrancasPedidoModeloItem[],
      totalItensPedidoModeloVencidos: 0,
      isLoadingListaPedidoModeloVencidos: false as boolean,
      listaPedidoModeloVencidos: [] as CobrancasPedidoModeloItem[],
    };
  },
  beforeMount() {
    this.listarPedidoModeloEmitidos(1);
    this.listarPedidoModeloPagos(1);
    this.listarPedidoModeloTodos(1);
    this.listarPedidoModeloVencidos(1);
  },
  methods: {
    async listarPedidoModelo(
      page: number,
      estado?: number
    ): Promise<CobrancasPedidoModeloListaResponse | null> {
      try {
        let requestParam: CobrancaListaParamsRequest = {
          page: page,
        };

        if (estado) {
          requestParam = {
            ...requestParam,
            codigoEstadoCobranca: estado,
          };
        }

        const response = await buscarCobrancaPedidoModeloLista(requestParam);
        return response.data;
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Erro ao buscar lista de cobranças",
          message:
            axios.isAxiosError(error) && error.response
              ? error.response.data.mensagem
              : "Error desconhecido.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        return null;
      }
    },
    async listarPedidoModeloEmitidos(page: number) {
      this.isLoadingListaPedidoModeloEmitidos = true;
      const response = await this.listarPedidoModelo(page, 100);
      if (response) {
        this.listaPedidoModeloEmitidos = this.listaPedidoModeloEmitidos.concat(
          response["hydra:member"]
        );
        this.totalItensPedidoModeloEmitidos = response["hydra:totalItems"];
      } else {
        this.listaPedidoModeloEmitidos = [];
        this.totalItensPedidoModeloEmitidos = 0;
      }
      this.isLoadingListaPedidoModeloEmitidos = false;
    },
    async listarPedidoModeloVencidos(page: number) {
      this.isLoadingListaPedidoModeloVencidos = true;
      const response = await this.listarPedidoModelo(page, 101);
      if (response) {
        this.listaPedidoModeloVencidos = this.listaPedidoModeloVencidos.concat(
          response["hydra:member"]
        );
        this.totalItensPedidoModeloVencidos = response["hydra:totalItems"];
      } else {
        this.listaPedidoModeloVencidos = [];
        this.totalItensPedidoModeloVencidos = 0;
      }
      this.isLoadingListaPedidoModeloVencidos = false;
    },
    async listarPedidoModeloPagos(page: number) {
      this.isLoadingListaPedidoModeloPagos = true;
      const response = await this.listarPedidoModelo(page, 102);
      if (response) {
        this.listaPedidoModeloPagos = this.listaPedidoModeloPagos.concat(
          response["hydra:member"]
        );
        this.totalItensPedidoModeloPagos = response["hydra:totalItems"];
      } else {
        this.listaPedidoModeloPagos = [];
        this.totalItensPedidoModeloPagos = 0;
      }
      this.isLoadingListaPedidoModeloPagos = false;
    },
    async listarPedidoModeloTodos(page: number) {
      this.isLoadingListaPedidoModeloTodos = true;
      const response = await this.listarPedidoModelo(page);
      if (response) {
        this.listaPedidoModeloTodos = this.listaPedidoModeloTodos.concat(
          response["hydra:member"]
        );
        this.totalItensPedidoModeloTodos = response["hydra:totalItems"];
      } else {
        this.listaPedidoModeloTodos = [];
        this.totalItensPedidoModeloTodos = 0;
      }
      this.isLoadingListaPedidoModeloTodos = false;
    },
  },
});

export default financeiroPedidoModelo;
