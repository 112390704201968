import { AxiosResponse } from "axios";
import qs from "qs";
import Repository from "@/service/bluealApi/BluealApiCliente";
import {
  CobrancaListaParamsRequest,
  CobrancasPedidoAlinhadoresListaResponse,
  CobrancasPedidoModeloListaResponse,
  CobrancasSolicitacaoProjetoListaResponse,
} from "./CobrancaListaData";

const buscarCobrancasPedidoAlinhadoresLista = (
  params: CobrancaListaParamsRequest
): Promise<AxiosResponse<CobrancasPedidoAlinhadoresListaResponse>> => {
  return Repository().get<CobrancasPedidoAlinhadoresListaResponse>(
    `cobrancas_pedido_alinhadores_lista`,
    {
      params: {
        ...params,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    }
  );
};

const buscarCobrancaPedidoModeloLista = (
  params: CobrancaListaParamsRequest
): Promise<AxiosResponse<CobrancasPedidoModeloListaResponse>> => {
  return Repository().get<CobrancasPedidoModeloListaResponse>(
    `cobrancas_pedido_modelo_lista`,
    {
      params: {
        ...params,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    }
  );
};

const buscarCobrancasSolicitacaoProjetoLista = (
  params: CobrancaListaParamsRequest
): Promise<AxiosResponse<CobrancasSolicitacaoProjetoListaResponse>> => {
  return Repository().get<CobrancasSolicitacaoProjetoListaResponse>(
    `cobrancas_solicitacao_projeto_lista`,
    {
      params: {
        ...params,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    }
  );
};

export {
  buscarCobrancasPedidoAlinhadoresLista,
  buscarCobrancaPedidoModeloLista,
  buscarCobrancasSolicitacaoProjetoLista,
};
