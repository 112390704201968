import {
  LoginRequest,
  LoginResponse,
} from "@/service/bluealApi/data/login/LoginData";
import { AxiosResponse } from "axios";
import bluealApiclientLogin from "../../BluealApiClienteLogin";

const login = (
  request: LoginRequest
): Promise<AxiosResponse<LoginResponse>> => {
  return bluealApiclientLogin().post<LoginResponse>(
    `/authentication_token`,
    request
  );
};

export { login };
