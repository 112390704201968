<template>
  <div class="modal-card" style="width: 600; height: 600">
    <header class="modal-card-head">
      <p class="modal-card-title">Vídeo Explicativo</p>
    </header>
    <section class="modal-card-body">
      <iframe
        class="video"
        width="550"
        height="380"
        :src="'https://www.youtube.com/embed/' + codVideo"
      >
      </iframe>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">
        Fechar
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalVideo",
  props: {
    codVideo: String,
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.video {
  width: 550px !important;
  height: 380px !important;
}
</style>
