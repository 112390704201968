
















import Vue from "vue";

const BluealButton = Vue.extend({
  name: "BluealButton",
  props: {
    label: { type: String, required: true },
    icon: { type: String, required: false },
    color: { type: String, default: "is-primary", required: false },
    type: { type: String, default: "button", required: false },
    onClick: { type: Function, required: false },
    bold: { type: Boolean, default: true, required: false },
    disabled: { type: Boolean, default: false, required: false },
    rounded: { type: Boolean, default: false, required: false },
    isLoading: { type: Boolean, default: false, required: false },
  },
});

export default BluealButton;
