<template>
  <div class="column is-12">
    <div class="columns mb-1">
      <div
        v-if="isentoCobranca === 'N' && cobrancaFinalizada === false"
        class="column is-12"
      >
        <p class="title is-5">Cobrança</p>
      </div>
      <div
        v-if="isentoCobranca === 'S' || cobrancaFinalizada === true"
        class="column is-12"
      >
        <p class="title is-5">Solicitação Finalizada</p>
      </div>
    </div>
    <hr class="mt-0" />
    <div
      v-if="
        cobrancaFinalizada === false &&
        valorServicoAnalise !== null &&
        isentoCobranca === 'N' &&
        envioDadosIniciado === false
      "
      class="columns is-centered mt-6"
    >
      <div class="column is-1 mt-2">
        <b-icon
          icon="exclamation-circle"
          size="is-large"
          custom-size="fa-4x"
          type="is-warning"
        >
        </b-icon>
      </div>
      <div class="column is-9">
        <div class="columns is-centered is-vcentered">
          <div class="column is-12">
            <p class="title is-6">
              Será gerado cobrança referente a confecção do planejamento no
              valor de
              {{
                valorServicoAnalise.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}.
            </p>
          </div>
        </div>
        <div class="columns is-vcentered">
          <div class="column is-4">
            <p class="title is-6">
              Escolha a data de vencimento{{
                maxDate
                  ? " (prazo máximo " +
                    maxDate.toLocaleDateString("pt-BR", {
                      timeZone: "UTC",
                    }) +
                    "):"
                  : ":"
              }}
            </p>
          </div>
          <div class="column is-2 mt-1">
            <b-field>
              <b-datepicker
                @input="alteraData"
                placeholder="Clique aqui"
                :min-date="minDate"
                :max-date="maxDate"
                size="is-small"
                :nearby-selectable-month-days="false"
                :nearby-month-days="false"
                :append-to-body="true"
                :unselectable-dates="unselectableDates"
                :unselectable-days-of-week="[0, 6]"
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div v-if="envioDadosIniciado === true && isentoCobranca === 'N'">
      <div class="columns is-centered mt-6 pl-6">
        <div class="column is-6">
          <h6 class="title is-6">Gerando Cobrança</h6>
          <b-progress size="is-large"> </b-progress>
        </div>
      </div>
    </div>
    <div v-if="envioDadosIniciado === true && isentoCobranca === 'S'">
      <div class="columns is-centered mt-6 pl-6">
        <div class="column is-6">
          <h6 class="title is-6">Finalizando Solicitação</h6>
          <b-progress size="is-large"> </b-progress>
        </div>
      </div>
    </div>
    <div v-if="cobrancaFinalizada === true" class="columns is-centered">
      <div class="column is-1 mt-6">
        <b-icon
          icon="check-circle"
          size="is-large"
          custom-size="fa-4x"
          type="is-success"
        >
        </b-icon>
      </div>
      <div class="column is-7 mt-5">
        <div class="columns">
          <div class="column is-12 mt-3">
            <h6 class="title is-5">
              Nr. solicitacao: {{ codigoSolicitacao }},
              <a href="/sys/gerenciar-solicitacoes-projeto">
                clique aqui para acompanhá-la</a
              >.
            </h6>
            <h6 v-if="isentoCobranca === 'N'" class="title is-6">
              Foi gerado boleto para pagamento,<a
                :href="linkBoleto"
                target="_blank"
              >
                clique aqui para abrir</a
              >.
            </h6>
            <h6 v-if="isentoCobranca === 'S'" class="title is-6">
              Não foi gerado boleto para pagamento
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import servicoRepositoryV2 from "@/repository/servicoV2Repository";
import solicitacaoProjetoV2Repository from "../../../repository/solicitacaoProjetoV2Repository";
import { unselectableDates, maxDate } from "@/service/paymentDueDate";
export default {
  name: "DadosCobrancaV2",
  props: {
    codigoSolicitacao: String,
    isentoCobranca: String,
  },
  data() {
    return {
      envioDadosIniciado: false,
      cobrancaFinalizada: false,
      valorServicoAnalise: null,
      diasVencimento: 0,
      minDate: null,
      maxDate: null,
      linkBoleto: "",
      unselectableDates: unselectableDates,
    };
  },
  beforeMount() {
    if (this.isentoCobranca === "N") {
      var today = new Date();
      this.buscarValorServicoAnalise();
      this.minDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 0
      );
      this.maxDate = new Date();
    } else {
      this.finalizarSemCobranca();
      this.$emit("desabilitarFinalizar");
    }
  },
  methods: {
    async buscarValorServicoAnalise() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const params = {
          codigoTipoServico: 100,
          indicadorValidade: 1,
        };
        const response = await servicoRepositoryV2.buscaServicos(params);
        loadingComponent.close();
        this.maxDate = maxDate(
          response.data["hydra:member"][0].prazoPagamentoQtdDias
        );
        this.valorServicoAnalise =
          response.data["hydra:member"][0].valorServico;
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro",
          message: "Erro ao obter dados do serviço",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async gerarCobranca() {
      let body = {
        operacao: "F",
        cobranca: {
          diasVencimento: this.diasVencimento,
        },
      };
      try {
        this.envioDadosIniciado = true;
        this.$emit("desabilitarFinalizar");
        const response =
          await solicitacaoProjetoV2Repository.alterarSolicitacao(
            this.codigoSolicitacao,
            body
          );
        this.cobrancaFinalizada = true;
        this.envioDadosIniciado = false;
        this.linkBoleto = response.data.cobranca.linkBoleto;
      } catch (e) {
        this.$emit("habilitarFinalizar");
        this.cobrancaFinalizada = false;
        this.envioDadosIniciado = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async finalizarSemCobranca() {
      let body = {
        operacao: "F",
      };
      try {
        this.envioDadosIniciado = true;
        this.$emit("desabilitarFinalizar");
        await solicitacaoProjetoV2Repository.alterarSolicitacao(
          this.codigoSolicitacao,
          body
        );
        this.envioDadosIniciado = false;
        this.cobrancaFinalizada = true;
      } catch (e) {
        this.envioDadosIniciado = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    alteraData(data) {
      this.diasVencimento = Math.ceil(
        Math.abs(data - new Date()) / (1000 * 60 * 60 * 24)
      );
      this.$emit("habilitarFinalizar");
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped></style>
