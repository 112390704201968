






























































import Vue from "vue";
import store from "@/store/store";
import router from "@/router";
import axios from "axios";
import usuarioV2Repository from "@/repository/usuarioV2Repository";

const RecuperarSenha = Vue.extend({
  name: "RecuperarSenha",
  data() {
    return {
      open: true,
      email: "" as string,
      password: "" as string,
      errorMessage: "" as string,
      isLoading: false as boolean,
    };
  },
  methods: {
    setOpen(state: boolean) {
      this.open = state;
    },
    setLoading(state: boolean) {
      this.isLoading = state;
    },
    navigate(route: string) {
      router.push(route);
    },
    async recuperarSenha() {
      try {
        this.setLoading(true);
        const payload = {
          email: this.email,
        };

        await usuarioV2Repository.esqueciSenha(payload);
        this.setLoading(false);
        this.navigate("Login");
        await store.dispatch("snackBarStore/showSnackBar", {
          title: "Senha do usuário alterada",
          message: "Um email foi enviado para o usuário com a nova senha",
          color: "success",
        });
      } catch (error) {
        this.setLoading(false);
        let message = "Erro desconhecido";
        if (axios.isAxiosError(error) && error.response) {
          message = error.response.data["hydra:description"];
        }
        await store.dispatch("snackBarStore/showSnackBar", {
          title: "Não foi possível recuperar senha",
          message: message,
          color: "danger",
        });
      }
    },
  },
});

export default RecuperarSenha;
