import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/usuario";

export default {
  obterDadosUsuario() {
    return Repository().get(`${resource}`);
  },
  obterUrlImagemPerfilUsuario() {
    return Repository().get(`${resource}/buscar_imagem_perfil`);
  },
  listarTodosUsuarios() {
    return Repository().get(`${resource}/buscar_todos`);
  },
  redefinirSenha(playload) {
    return Repository().post(`${resource}/redefinir_senha`, playload);
  },
  desativarCadastro(playload) {
    return Repository().post(`${resource}/desativar_cadastro`, playload);
  },
  alterarSenhaUsuario(playload) {
    return Repository().put(`${resource}/senha`, playload);
  },
  esqueciSenha(playload) {
    return Repository().post(`${resource}/esqueci_senha`, playload);
  },
};
