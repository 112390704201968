<template>
  <div class="corpo-dados-basicos">
    <div class="columns is-variable is-12">
      <div class="column is-4">
        <b-field label="Número" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="numero"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-5">
        <b-field label="Estado Solicitacao" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="codEstSolicitacao + ' ' + descEstSolicitacao"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Data Solicitação" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="dataSolicitacao"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-variable is-12">
      <div class="column is-5">
        <b-field label="Paciente" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="cdPaciente + ' ' + nomePaciente"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Data Nascimento" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="
              dataNascimentoPaciente !== null
                ? new Date(dataNascimentoPaciente).toLocaleDateString('pt-BR', {
                    timeZone: 'UTC',
                  })
                : ''
            "
          >
          </b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-variable is-12">
      <div class="column is-5">
        <b-field label="Usuário" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="codUsuario + ' ' + nomeUsuario"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Código Cobrança" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="codCobranca"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-4">
        <b-field label="Estado Cobrança" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="descCobranca !== null ? descCobranca : 'Sem Cobrança'"
          ></b-input>
        </b-field>
      </div>
    </div>
    <hr />
    <div class="columns is-variable is-12">
      <div class="column is-6">
        <b-field label="Plano Tratamento" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            type="textarea"
            disabled
            :value="objetivoAlinhadores"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Queixa Paciente" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            type="textarea"
            disabled
            :value="queixaPaciente"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-variable is-12">
      <div class="column is-6">
        <b-field label="Possui Implantes" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="implantesDescricao === '' ? 'NÃO' : 'SIM'"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-6" v-if="implantesDescricao !== ''">
        <b-field label="Implantes Descrição" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            type="textarea"
            disabled
            :value="implantesDescricao"
          ></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DadosBasicosSolicitacao",
  props: {
    dadosSolicitacaoProjeto: Object,
  },
  data() {
    return {
      numero: null,
      cdPaciente: null,
      nomePaciente: null,
      codCobranca: null,
      descCobranca: null,
      codUsuario: null,
      nomeUsuario: null,
      codEstSolicitacao: null,
      descEstSolicitacao: null,
      dataSolicitacao: null,
      queixaPaciente: null,
      objetivoAlinhadores: null,
      implantesDescricao: null,
      dataNascimentoPaciente: null,
    };
  },
  beforeMount: function () {
    this.inicializarData(this.dadosSolicitacaoProjeto);
  },
  methods: {
    inicializarData(dadosSolicitacaoProjeto) {
      this.numero = dadosSolicitacaoProjeto.codigoSolicitacao;
      this.cdPaciente = dadosSolicitacaoProjeto.paciente.codigoPaciente;
      this.nomePaciente = dadosSolicitacaoProjeto.paciente.nome;
      this.codCobranca =
        dadosSolicitacaoProjeto.cobranca !== null
          ? dadosSolicitacaoProjeto.cobranca.codigo
          : "";
      this.descCobranca =
        dadosSolicitacaoProjeto.cobranca !== null
          ? dadosSolicitacaoProjeto.cobranca.estadoCobrancaAsaas
          : "Sem Cobrança";
      this.codUsuario = dadosSolicitacaoProjeto.usuario.codigoUsuario;
      this.nomeUsuario = dadosSolicitacaoProjeto.usuario.nome;
      this.dataSolicitacao = dadosSolicitacaoProjeto.dataHoraSolicitacao;
      this.codEstSolicitacao =
        dadosSolicitacaoProjeto.estado.codigoEstadoSolicitacao;
      this.descEstSolicitacao = dadosSolicitacaoProjeto.estado.descricao;
      this.queixaPaciente =
        dadosSolicitacaoProjeto.queixaPaciente !== null
          ? dadosSolicitacaoProjeto.queixaPaciente
          : "";
      this.objetivoAlinhadores =
        dadosSolicitacaoProjeto.objetivoAlinhadores !== null
          ? dadosSolicitacaoProjeto.objetivoAlinhadores
          : "";
      this.implantesDescricao =
        dadosSolicitacaoProjeto.implantesDescricao !== null
          ? dadosSolicitacaoProjeto.implantesDescricao
          : "";
      this.dataNascimentoPaciente =
        dadosSolicitacaoProjeto.paciente.dataNascimento !== null
          ? dadosSolicitacaoProjeto.paciente.dataNascimento
          : "";
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.corpo-dados-basicos {
  padding-left: 2rem;
}
</style>
