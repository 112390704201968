import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/projeto";

export default {
  iniciarProjeto(payload) {
    return Repository().post(`${resource}`, payload);
  },
  buscarProjetosSolicitacoes(payload) {
    return Repository().post(`${resource}/projetos_solicitacao`, payload);
  },
  finalizarProjeto(payload) {
    return Repository().post(`${resource}/finalizar`, payload);
  },
  gerarUrlArquivoProjeto(payload) {
    return Repository().post(`${resource}/url_arquivo`, payload);
  },
  recusarProjeto(payload) {
    return Repository().post(`${resource}/recusar`, payload);
  },
  aprovarProjeto(payload) {
    return Repository().post(`${resource}/aprovar`, payload);
  },
  incluirImagemObs(payload) {
    return Repository().post(`${resource}/incluir_imagem_obs`, payload);
  },
};
