<template>
  <div class="corpo-dados-basicos">
    <div
      v-if="
        !dadosSolicitacaoProjeto.listaArquivos ||
        dadosSolicitacaoProjeto.listaArquivos.length === 0
      "
    >
      <div class="columns is-variable is-12">
        <div class="column is-3" v-if="codigoArquivoUnico !== null">
          <b-button
            :type="codigoArquivoUnico ? 'is-primary' : 'is-light'"
            :disabled="codigoArquivoUnico === null"
            rounded
            @click="downloadArquivo(linkArquivoUnico)"
          >
            <strong>Arquivo Único</strong>
          </b-button>
        </div>
        <div class="column is-3" v-if="codigoArquivoUnicoImagem !== null">
          <b-button
            :type="codigoArquivoUnicoImagem ? 'is-primary' : 'is-light'"
            :disabled="codigoArquivoUnicoImagem === null"
            rounded
            @click="downloadArquivo(linkArquivoUnicoImagem)"
          >
            <strong>Arquivo Zip Imagens</strong>
          </b-button>
        </div>
        <div class="column is-3" v-if="codigoArquivoUnicoModelos !== null">
          <b-button
            :type="codigoArquivoUnicoModelos ? 'is-primary' : 'is-light'"
            :disabled="codigoArquivoUnicoModelos === null"
            rounded
            @click="downloadArquivo(linkArquivoUnicoModelos)"
          >
            <strong>Arquivo Zip Modelos</strong>
          </b-button>
        </div>
        <div class="column is-3" v-if="codigoStlSuperior !== null">
          <b-button
            :type="codigoStlSuperior ? 'is-primary' : 'is-light'"
            :disabled="codigoStlSuperior === null"
            rounded
            @click="
              visualizarModeloSt(
                codigoStlSuperior,
                'Modelo Maxilar',
                linkStlSuperior
              )
            "
            ><strong>Modelo Superior</strong></b-button
          >
        </div>
        <div class="column is-3" v-if="codigoStlInferior !== null">
          <b-button
            :type="codigoStlInferior ? 'is-primary' : 'is-light'"
            :disabled="codigoStlInferior === null"
            rounded
            @click="
              visualizarModeloSt(
                codigoStlInferior,
                'Modelo Mandíbula',
                linkStlInferior
              )
            "
            ><strong>Modelo Inferior</strong></b-button
          >
        </div>
      </div>
      <hr />
      <div class="columns is-variable is-12">
        <div class="column is-3">
          <b-button
            :type="codigoFotoTeleRadiografia ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoTeleRadiografia === null"
            rounded
            @click="downloadArquivo(linkFotoTeleRadiografia)"
            ><strong>Teleradiografia</strong></b-button
          >
        </div>
        <div class="column is-3">
          <b-button
            :type="codigoFotoOclusaoSuperior ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoOclusaoSuperior === null"
            rounded
            @click="downloadArquivo(linkFotoOclusaoSuperior)"
            ><strong>Oclusal Superior</strong></b-button
          >
        </div>
        <div class="column is-3">
          <b-button
            :type="codigoFotoOclusaoInferior ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoOclusaoInferior === null"
            rounded
            @click="downloadArquivo(linkFotoOclusaoInferior)"
            ><strong>Oclusal Inferior</strong></b-button
          >
        </div>
        <div class="column is-3">
          <b-button
            :type="codigoFotoRxPan ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoRxPan === null"
            rounded
            @click="downloadArquivo(linkFotoRxPan)"
            ><strong>Rx Pan</strong></b-button
          >
        </div>
      </div>
      <hr />
      <div class="columns is-variable is-2">
        <div class="column is-3">
          <b-button
            :type="codigoFotoSorrindo ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoSorrindo === null"
            rounded
            @click="downloadArquivo(linkFotoSorrindo)"
            ><strong>Foto Frontal Sorrindo</strong></b-button
          >
        </div>
        <div class="column is-3">
          <b-button
            :type="codigoFotoLateral ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoLateral === null"
            rounded
            @click="downloadArquivo(linkFotoLateral)"
            ><strong>Foto Lateral</strong></b-button
          >
        </div>
        <div class="column is-3">
          <b-button
            :type="codigoFotoFrontal ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoFrontal === null"
            rounded
            @click="downloadArquivo(linkFotoFrontal)"
            ><strong>Foto Frontal</strong></b-button
          >
        </div>
      </div>
      <hr />
      <div class="columns is-variable is-2">
        <div class="column is-3">
          <b-button
            :type="codigoFotoIntrabucalFrontal ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoIntrabucalFrontal === null"
            rounded
            @click="downloadArquivo(linkFotoIntrabucalFrontal)"
            ><strong>Foto Intrabucal Frontal</strong></b-button
          >
        </div>
        <div class="column is-3">
          <b-button
            :type="codigoFotoIntrabucalDireita ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoIntrabucalDireita === null"
            rounded
            @click="downloadArquivo(linkDownloadFotoIntrabucalDireita)"
            ><strong>Foto Intrabucal Direita</strong></b-button
          >
        </div>
        <div class="column is-3">
          <b-button
            :type="codigoFotoIntrabucalEsquerda ? 'is-primary' : 'is-light'"
            :disabled="codigoFotoIntrabucalEsquerda === null"
            rounded
            @click="downloadArquivo(linkDownloadFotoIntrabucalEsquerda)"
            ><strong>Foto Intrabucal Esquerda</strong></b-button
          >
        </div>
      </div>
    </div>
    <div v-if="dadosSolicitacaoProjeto.listaArquivos">
      <div class="columns">
        <div class="column">
          <h5 class="is-title is-6">Arquivos</h5>
        </div>
      </div>
      <div class="columns is-multiline">
        <div
          v-for="item in dadosSolicitacaoProjeto.listaArquivos"
          class="column"
          :key="item.codigoArquivo"
        >
          <button
            @click="downloadArquivo(item.linkDownload)"
            class="button is-link"
            :disabled="!item.linkDownload"
          >
            <span class="icon">
              <i class="fas fa-check-circle"></i>
            </span>
            <span class="has-text-weight-medium">{{
              item.codigoArquivo.replace(/[\s\S]*\//, "")
            }}</span>
          </button>
        </div>
      </div>
    </div>
    <hr v-if="dadosAcessoLogin" />
    <div v-if="dadosAcessoLogin" class="columns is-variable is-2">
      <div class="column is-3">
        <h5 class="is-title is-6">Dados Acesso Radiologia</h5>
      </div>
    </div>
    <div v-if="dadosAcessoLogin" class="columns is-variable is-2">
      <div class="column is-3">
        <b-field label="Usuário" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="dadosAcessoLogin.usuario"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Senha" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="dadosAcessoLogin.senha"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="URL" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="dadosAcessoLogin.url"
          ></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import ModalModeloSTL from "./Modal/ModalModeloSTL";
import solicitacaoProjetoArquivosV2Repository from "@/repository/solicitacaoProjetoArquivosV2Repository";

export default {
  name: "ArquivosSolicitacao",
  props: {
    dadosSolicitacaoProjeto: Object,
  },
  data() {
    return {
      codigoStlSuperior: null,
      codigoStlInferior: null,
      codigoFotoFrontal: null,
      codigoFotoSorrindo: null,
      codigoFotoIntrabucalFrontal: null,
      codigoFotoIntrabucalDireita: null,
      codigoFotoIntrabucalEsquerda: null,
      codigoFotoOclusaoSuperior: null,
      codigoFotoRxPan: null,
      codigoFotoTeleRadiografia: null,
      codigoFotoLateral: null,
      codigoFotoOclusaoInferior: null,
      codigoArquivoUnicoModelos: null,
      codigoArquivoUnicoImagem: null,
      codigoArquivoUnico: null,
      linkStlSuperior: null,
      linkStlInferior: null,
      linkFotoFrontal: null,
      linkFotoSorrindo: null,
      linkFotoIntrabucalFrontal: null,
      linkFotoIntrabucalDireita: null,
      linkFotoIntrabucalEsquerda: null,
      linkFotoOclusaoSuperior: null,
      linkFotoRxPan: null,
      linkFotoTeleRadiografia: null,
      linkFotoLateral: null,
      linkFotoOclusaoInferior: null,
      linkArquivoUnicoModelos: null,
      linkArquivoUnicoImagem: null,
      linkArquivoUnico: null,
      dadosAcessoLogin: null,
    };
  },
  beforeMount: function () {
    this.inicializarData(this.dadosSolicitacaoProjeto);
  },
  methods: {
    async gerarLinksArquivos() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const body = {
          solicitacaoProjeto: this.dadosSolicitacaoProjeto["@id"],
          operacao: "D",
        };
        const response =
          await solicitacaoProjetoArquivosV2Repository.gerarLinkArquivos(body);
        this.atribuirLinks(response.data);
        loadingComponent.close();
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao obter link arquivos",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    atribuirLinks(links) {
      this.linkStlSuperior = links.linkDownloadMaxilar
        ? links.linkDownloadMaxilar
        : null;
      this.linkStlInferior = links.linkDownloadMandibula
        ? links.linkDownloadMandibula
        : null;
      this.linkFotoFrontal = links.linkDownloadFotoFrontal
        ? links.linkDownloadFotoFrontal
        : null;
      this.linkFotoSorrindo = links.linkDownloadFotoSorrindo
        ? links.linkDownloadFotoSorrindo
        : null;
      this.linkFotoIntrabucalFrontal = links.linkDownloadFotoIntrabucalFrontal
        ? links.linkDownloadFotoIntrabucalFrontal
        : null;
      this.linkFotoIntrabucalDireita = links.linkDownloadFotoIntrabucalDireita
        ? links.linkDownloadFotoIntrabucalDireita
        : null;
      this.linkFotoIntrabucalEsquerda = links.linkDownloadFotoIntrabucalEsquerda
        ? links.linkDownloadFotoIntrabucalEsquerda
        : null;
      this.linkFotoOclusaoSuperior = links.linkDownloadFotoOclusaoSuperior
        ? links.linkDownloadFotoOclusaoSuperior
        : null;
      this.linkFotoRxPan = links.linkDownloadFotoRxPan
        ? links.linkDownloadFotoRxPan
        : null;
      this.linkFotoTeleRadiografia = links.linkDownloadFotoTeleRadiografia
        ? links.linkDownloadFotoTeleRadiografia
        : null;
      this.linkFotoLateral = links.linkDownloadFotoLateral
        ? links.linkDownloadFotoLateral
        : null;
      this.linkFotoOclusaoInferior = links.linkUploadArquivoUnicoModelos
        ? links.linkUploadArquivoUnicoModelos
        : null;
      this.linkArquivoUnicoModelos = links.linkDownloadArquivoUnicoModelos
        ? links.linkDownloadArquivoUnicoModelos
        : null;
      this.linkArquivoUnicoImagem = links.linkDownloadArquivoUnicoImagem
        ? links.linkDownloadArquivoUnicoImagem
        : null;
      this.linkArquivoUnico = links.linkDownloadArquivoUnicoImagemModelos
        ? links.linkDownloadArquivoUnicoImagemModelos
        : null;
    },
    async downloadArquivo(link) {
      window.open(link, "_blank");
    },
    async visualizarModeloSt(codigoArquivo, titulo, link) {
      let formato = codigoArquivo.substr(codigoArquivo.indexOf("."));
      if (formato === ".stl") {
        this.$buefy.modal.open({
          parent: this,
          props: {
            caminhoModelo: link,
            tituloModelo: titulo,
          },
          component: ModalModeloSTL,
          hasModalCard: true,
          customClass: "custom-class custom-class-2",
          trapFocus: true,
          fullScreen: true,
        });
      } else {
        this.downloadArquivo(link);
      }
    },
    inicializarData(dadosSolicitacaoProjeto) {
      this.codigoStlSuperior = dadosSolicitacaoProjeto.codigoStlSuperior;
      this.codigoStlInferior = dadosSolicitacaoProjeto.codigoStlInferior;
      this.codigoFotoFrontal = dadosSolicitacaoProjeto.codigoFotoFrontal;
      this.codigoFotoSorrindo = dadosSolicitacaoProjeto.codigoFotoSorrindo;
      this.codigoFotoIntrabucalFrontal =
        dadosSolicitacaoProjeto.codigoFotoIntrabucalFrontal;
      this.codigoFotoIntrabucalDireita =
        dadosSolicitacaoProjeto.codigoFotoIntrabucalDireita;
      this.codigoFotoIntrabucalEsquerda =
        dadosSolicitacaoProjeto.codigoFotoIntrabucalEsquerda;
      this.codigoFotoOclusaoSuperior =
        dadosSolicitacaoProjeto.codigoFotoOclusaoSuperior;
      this.codigoFotoRxPan = dadosSolicitacaoProjeto.codigoFotoRxPan;
      this.codigoFotoTeleRadiografia =
        dadosSolicitacaoProjeto.codigoFotoTeleRadiografia;
      this.codigoFotoLateral = dadosSolicitacaoProjeto.codigoFotoLateral;
      this.codigoFotoOclusaoInferior =
        dadosSolicitacaoProjeto.codigoFotoOclusaoInferior;
      this.codigoArquivoUnicoModelos =
        dadosSolicitacaoProjeto.codigoArquivoUnicoModelos;
      this.codigoArquivoUnico =
        dadosSolicitacaoProjeto.codigoArquivoUnicoImagemModelos;
      this.codigoArquivoUnicoImagem =
        dadosSolicitacaoProjeto.codigoArquivoUnicoImagem;
      this.dadosAcessoLogin = dadosSolicitacaoProjeto.dadosAcessoRadiologia;
      this.gerarLinksArquivos();
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.corpo-dados-basicos {
  padding-left: 2rem;
}
</style>
