<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <b-icon icon="cube" />
      <p class="modal-card-title">{{ " " }}Visualizar Modelo</p>
    </header>
    <section class="modal-card-body">
      <div class="columns is-centered">
        <div class="column is-12 titulo-model">
          <h3 class="title is-3">{{ tituloModelo }}</h3>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-10">
          <iframe
            id="vs_iframe"
            src="https://www.viewstl.com/?embedded"
            style="border: 0; margin: 0; width: 100%; height: 400px"
          ></iframe>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-twitter"
        rounded
        icon-left="arrow-left"
        @click="$parent.close()"
        ><strong>Fechar</strong></b-button
      >
      <b-button
        type="is-primary"
        rounded
        icon-left="download"
        @click="dowloadArquivo()"
        ><strong>Download</strong></b-button
      >
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalModeloSTL",
  props: {
    caminhoModelo: String,
    tituloModelo: String,
  },
  mounted() {
    var iframe = document.getElementById("vs_iframe");
    iframe.urlStl = this.caminhoModelo;
    document.getElementById("vs_iframe").onload = function () {
      document
        .getElementById("vs_iframe")
        .contentWindow.postMessage(
          { msg_type: "load", url: encodeURIComponent(this.urlStl) },
          "*"
        );
    };
  },
  methods: {
    dowloadArquivo() {
      window.open(this.caminhoModelo, "_blank");
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.titulo-model {
  text-align: center;
}
</style>
