
























































































































import Vue from "vue";
import { buscarCobrancasSolicitacaoProjetoLista } from "@/repository/Cobranca/CobrancaListaRepository";
import {
  CobrancaListaParamsRequest,
  CobrancasSolicitacaoProjetoItem,
  CobrancasSolicitacaoProjetoListaResponse,
} from "@/repository/Cobranca/CobrancaListaData";

import FinanceiroPlanejamentosTabela from "@/views/financeiro/components/FinanceiroPlanejamentosTabela.vue";
import axios from "axios";

const financeiroPlanejamentos = Vue.extend({
  name: "FinanceiroPlanejamentos",
  components: { FinanceiroPlanejamentosTabela },
  data() {
    return {
      totalItensListaPanejamentoEmitidos: 0,
      isLoadingListaPlanejamentoEmitidos: false as boolean,
      listaPlanejamentoEmitidos: [] as CobrancasSolicitacaoProjetoItem[],
      totalItensListaPanejamentoPagos: 0,
      isLoadingListaPlanejamentoPagos: false as boolean,
      listaPlanejamentoPagos: [] as CobrancasSolicitacaoProjetoItem[],
      totalItensListaPanejamentoTodos: 0,
      isLoadingListaPlanejamentoTodos: false as boolean,
      listaPlanejamentoTodos: [] as CobrancasSolicitacaoProjetoItem[],
      totalItensListaPanejamentoVencidos: 0,
      isLoadingListaPlanejamentoVencidos: false as boolean,
      listaPlanejamentoVencidos: [] as CobrancasSolicitacaoProjetoItem[],
    };
  },
  beforeMount() {
    this.listarPlanejamentoEmitidos(1);
    this.listarPlanejamentoPagos(1);
    this.listarPlanejamentoTodos(1);
    this.listarPlanejamentoVencidos(1);
  },
  methods: {
    async listarPlanejamento(
      page: number,
      estado?: number
    ): Promise<CobrancasSolicitacaoProjetoListaResponse | null> {
      try {
        let requestParam: CobrancaListaParamsRequest = {
          page: page,
        };

        if (estado) {
          requestParam = {
            ...requestParam,
            codigoEstadoCobranca: estado,
          };
        }

        const response = await buscarCobrancasSolicitacaoProjetoLista(
          requestParam
        );
        return response.data;
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Erro ao buscar lista de cobranças",
          message:
            axios.isAxiosError(error) && error.response
              ? error.response.data.mensagem
              : "Error desconhecido.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        return null;
      }
    },
    async listarPlanejamentoEmitidos(page: number) {
      this.isLoadingListaPlanejamentoEmitidos = true;
      const response = await this.listarPlanejamento(page, 100);
      if (response) {
        this.listaPlanejamentoEmitidos = this.listaPlanejamentoEmitidos.concat(
          response["hydra:member"]
        );
        this.totalItensListaPanejamentoEmitidos = response["hydra:totalItems"];
      } else {
        this.listaPlanejamentoEmitidos = [];
        this.totalItensListaPanejamentoEmitidos = 0;
      }
      this.isLoadingListaPlanejamentoEmitidos = false;
    },
    async listarPlanejamentoVencidos(page: number) {
      this.isLoadingListaPlanejamentoVencidos = true;
      const response = await this.listarPlanejamento(page, 101);
      if (response) {
        this.listaPlanejamentoVencidos = this.listaPlanejamentoVencidos.concat(
          response["hydra:member"]
        );
        this.totalItensListaPanejamentoVencidos = response["hydra:totalItems"];
      } else {
        this.listaPlanejamentoVencidos = [];
        this.totalItensListaPanejamentoVencidos = 0;
      }
      this.isLoadingListaPlanejamentoVencidos = false;
    },
    async listarPlanejamentoPagos(page: number) {
      this.isLoadingListaPlanejamentoPagos = true;
      const response = await this.listarPlanejamento(page, 102);
      if (response) {
        this.listaPlanejamentoPagos = this.listaPlanejamentoPagos.concat(
          response["hydra:member"]
        );
        this.totalItensListaPanejamentoPagos = response["hydra:totalItems"];
      } else {
        this.listaPlanejamentoPagos = [];
        this.totalItensListaPanejamentoPagos = 0;
      }
      this.isLoadingListaPlanejamentoPagos = false;
    },
    async listarPlanejamentoTodos(page: number) {
      this.isLoadingListaPlanejamentoTodos = true;
      const response = await this.listarPlanejamento(page);
      if (response) {
        this.listaPlanejamentoTodos = this.listaPlanejamentoTodos.concat(
          response["hydra:member"]
        );
        this.totalItensListaPanejamentoTodos = response["hydra:totalItems"];
      } else {
        this.listaPlanejamentoTodos = [];
        this.totalItensListaPanejamentoTodos = 0;
      }
      this.isLoadingListaPlanejamentoTodos = false;
    },
  },
});

export default financeiroPlanejamentos;
