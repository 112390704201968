import { render, staticRenderFns } from "./DadosArquivoUnico.vue?vue&type=template&id=c355aafc&scoped=true&"
import script from "./DadosArquivoUnico.vue?vue&type=script&lang=js&"
export * from "./DadosArquivoUnico.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c355aafc",
  null
  
)

export default component.exports