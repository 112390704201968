<template>
  <div>
    <div v-if="isZip === null" class="columns">
      <div class="column is-12">
        <div class="columns">
          <div class="column is-12 mt-6">
            <p class="title is-6 has-text-centered">
              Deseja enviar todas as imagens do paciente em arquivo ZIP ou
              individualmente?
            </p>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-2">
            <b-button type="is-primary" rounded @click="alterarArquivoZip()">
              <strong>Imagens em ZIP</strong>
            </b-button>
          </div>
          <div class="column is-2">
            <b-button
              type="is-twitter"
              rounded
              @click="alterarArquivoIndividual()"
            >
              <strong>Imagens Individuais</strong>
            </b-button>
          </div>
          <div class="column is-2">
            <b-button
              type="is-danger"
              rounded
              @click="naoEnviarImagemPaciente()"
            >
              <strong>Não Enviar Imagens</strong>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isZip === false && envioIniciado === false" class="columns">
      <div class="column is-8">
        <p class="title is-6 has-text-centered">
          <b-message type="is-danger" size="is-small" has-icon>
            Arquivos não podem ultrapassar 3 MB e devem estar no formato JPEG,
            JPG, PNG e GIF, é possível arrastá-los.
          </b-message>
        </p>
      </div>
      <div class="column is-2 mt-1">
        <b-button type="is-twitter" @click="alterarArquivoZip()" expanded>
          <strong>Alterar Para ZIP</strong>
        </b-button>
      </div>
      <div class="column is-2 mt-1">
        <b-button type="is-danger" @click="naoEnviarImagemPaciente()" expanded>
          <strong>Não Enviar</strong>
        </b-button>
      </div>
    </div>
    <div v-if="isZip === false && envioIniciado === false">
      <div class="columns is-centered mt-3">
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7">
                <strong>Imagem Face Frontal</strong>
              </h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoFrontal"
                  @input="validarArquivo(fotoFrontal, 'fotoFrontal')"
                  drag-drop
                >
                  <div>
                    <a
                      class="button is-small"
                      v-bind:class="[
                        fotoFrontal || fotoFrontalCodigo
                          ? 'is-info'
                          : 'is-light',
                      ]"
                    >
                      <span class="span-nome-arquivo">{{
                        fotoFrontal !== null
                          ? fotoFrontal.name.slice(0, 25)
                          : "Arraste ou clique aqui..."
                      }}</span>
                    </a>
                  </div>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoFrontal = null"
                :disabled="fotoFrontal === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7">
                <strong>Imagem Face Frontal Sorrindo</strong>
              </h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoFrontalSorrindo"
                  @input="
                    validarArquivo(fotoFrontalSorrindo, 'fotoFrontalSorrindo')
                  "
                  drag-drop
                >
                  <a
                    class="button is-small a-expanded"
                    v-bind:class="[
                      fotoFrontalSorrindo || fotoFrontalSorrindoCodigo
                        ? 'is-info'
                        : 'is-light',
                    ]"
                  >
                    <span class="span-nome-arquivo">{{
                      fotoFrontalSorrindo !== null
                        ? fotoFrontalSorrindo.name.slice(0, 25)
                        : "Arraste ou clique aqui..."
                    }}</span>
                  </a>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoFrontalSorrindo = null"
                :disabled="fotoFrontalSorrindo === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7"><strong>Imagem Face Perfil</strong></h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoLateral"
                  @input="validarArquivo(fotoLateral, 'fotoLateral')"
                  drag-drop
                >
                  <a
                    class="button is-small a-expanded"
                    v-bind:class="[
                      fotoLateral || fotoLateralCodigo ? 'is-info' : 'is-light',
                    ]"
                  >
                    <span class="span-nome-arquivo">{{
                      fotoLateral !== null
                        ? fotoLateral.name.slice(0, 25)
                        : "Arraste ou clique aqui..."
                    }}</span>
                  </a>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoLateral = null"
                :disabled="fotoLateral === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7">
                <strong>Imagem Intrabucal Frontal</strong>
              </h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoIntrabucalFrontal"
                  @input="
                    validarArquivo(
                      fotoIntrabucalFrontal,
                      'fotoIntrabucalFrontal'
                    )
                  "
                  drag-drop
                  expanded
                >
                  <a
                    class="button is-small a-expanded"
                    v-bind:class="[
                      fotoIntrabucalFrontal || fotoIntrabucalFrontalCodigo
                        ? 'is-info'
                        : 'is-light',
                    ]"
                  >
                    <span class="span-nome-arquivo">{{
                      fotoIntrabucalFrontal !== null
                        ? fotoIntrabucalFrontal.name.slice(0, 25)
                        : "Arraste ou clique aqui..."
                    }}</span>
                  </a>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoIntrabucalFrontal = null"
                :disabled="fotoIntrabucalFrontal === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7">
                <strong>Imagem Intrabucal Direita</strong>
              </h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoIntrabucalDireita"
                  @input="
                    validarArquivo(
                      fotoIntrabucalDireita,
                      'fotoIntrabucalDireita'
                    )
                  "
                  drag-drop
                  expanded
                >
                  <a
                    class="button is-small a-expanded"
                    v-bind:class="[
                      fotoIntrabucalDireita || fotoIntrabucalDireitaCodigo
                        ? 'is-info'
                        : 'is-light',
                    ]"
                  >
                    <span class="span-nome-arquivo">{{
                      fotoIntrabucalDireita !== null
                        ? fotoIntrabucalDireita.name.slice(0, 25)
                        : "Arraste ou clique aqui..."
                    }}</span>
                  </a>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoIntrabucalDireita = null"
                :disabled="fotoIntrabucalDireita === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7">
                <strong>Imagem Intrabucal Esquerda</strong>
              </h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoIntrabucalEsquerda"
                  @input="
                    validarArquivo(
                      fotoIntrabucalEsquerda,
                      'fotoIntrabucalEsquerda'
                    )
                  "
                  drag-drop
                  expanded
                >
                  <a
                    class="button is-small a-expanded"
                    v-bind:class="[
                      fotoIntrabucalEsquerda || fotoIntrabucalEsquerdaCodigo
                        ? 'is-info'
                        : 'is-light',
                    ]"
                  >
                    <span class="span-nome-arquivo">{{
                      fotoIntrabucalEsquerda !== null
                        ? fotoIntrabucalEsquerda.name.slice(0, 25)
                        : "Arraste ou clique aqui..."
                    }}</span>
                  </a>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoIntrabucalEsquerda = null"
                :disabled="fotoIntrabucalEsquerda === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7">
                <strong>Oclusal Superior</strong>
              </h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoOclusalSuperior"
                  @input="
                    validarArquivo(fotoOclusalSuperior, 'fotoOclusalSuperior')
                  "
                  drag-drop
                  expanded
                >
                  <a
                    class="button is-small a-expanded"
                    v-bind:class="[
                      fotoOclusalSuperior || fotoOclusalSuperiorCodigo
                        ? 'is-info'
                        : 'is-light',
                    ]"
                  >
                    <span class="span-nome-arquivo">{{
                      fotoOclusalSuperior !== null
                        ? fotoOclusalSuperior.name.slice(0, 25)
                        : "Arraste ou clique aqui..."
                    }}</span>
                  </a>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoIntrabucalEsquerda = null"
                :disabled="fotoIntrabucalEsquerda === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7">
                <strong>Oclusal Inferior</strong>
              </h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoOclusalInferior"
                  @input="
                    validarArquivo(fotoOclusalInferior, 'fotoOclusalInferior')
                  "
                  drag-drop
                  expanded
                >
                  <a
                    class="button is-small a-expanded"
                    v-bind:class="[
                      fotoOclusalInferior || fotoOclusalSuperiorCodigo
                        ? 'is-info'
                        : 'is-light',
                    ]"
                  >
                    <span class="span-nome-arquivo">{{
                      fotoOclusalInferior !== null
                        ? fotoOclusalInferior.name.slice(0, 25)
                        : "Arraste ou clique aqui..."
                    }}</span>
                  </a>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoOclusalInferior = null"
                :disabled="fotoOclusalInferior === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="columns mb-2">
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7">
                <strong>RX PAN</strong>
              </h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoRxPan"
                  @input="validarArquivo(fotoRxPan, 'fotoRxPan')"
                  drag-drop
                  expanded
                >
                  <a
                    class="button is-small a-expanded"
                    v-bind:class="[
                      fotoRxPan || fotoRxPanCodigo ? 'is-info' : 'is-light',
                    ]"
                  >
                    <span class="span-nome-arquivo">{{
                      fotoRxPan !== null
                        ? fotoRxPan.name.slice(0, 25)
                        : "Arraste ou clique aqui..."
                    }}</span>
                  </a>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoRxPan = null"
                :disabled="fotoRxPan === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="columns is-vcentered is-0 mb-1">
            <div class="column is-12 pb-0 mb-0">
              <h6 class="subtitle is-7">
                <strong>Teleradiografia</strong>
              </h6>
            </div>
          </div>
          <div class="columns is-vcentered mb-0">
            <div class="column is-9 mr-0">
              <b-field class="file is-small">
                <b-upload
                  v-model="fotoTeleRadiografia"
                  @input="
                    validarArquivo(fotoTeleRadiografia, 'fotoTeleRadiografia')
                  "
                  drag-drop
                  expanded
                >
                  <a
                    class="button is-small a-expanded"
                    v-bind:class="[
                      fotoTeleRadiografia || fotoTeleRadiografiaCodigo
                        ? 'is-info'
                        : 'is-light',
                    ]"
                  >
                    <span class="span-nome-arquivo">{{
                      fotoTeleRadiografia !== null
                        ? fotoTeleRadiografia.name.slice(0, 25)
                        : "Arraste ou clique aqui..."
                    }}</span>
                  </a>
                </b-upload>
                <p class="control"></p>
              </b-field>
            </div>
            <div class="column is-2 ml-0 pl-0">
              <b-button
                type="is-danger"
                size="is-small"
                @click="fotoTeleRadiografia = null"
                :disabled="fotoTeleRadiografia === null"
              >
                <b-icon icon="trash-alt" size="is-small"> </b-icon>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isZip === true && envioIniciado === false" class="columns">
      <div class="column is-8">
        <p class="title is-6 has-text-centered">
          <b-message type="is-danger" size="is-small" has-icon>
            Arquivo deve estar no formato ZIP e não pode ultrapassar 45 MB.
          </b-message>
        </p>
      </div>
      <div class="column is-2 mt-1">
        <b-button type="is-twitter" @click="alterarArquivoIndividual()">
          <strong>Alterar Para Individuais</strong>
        </b-button>
      </div>
      <div class="column is-2 mt-1">
        <b-button type="is-danger" @click="naoEnviarImagemPaciente()" expanded>
          <strong>Não Enviar</strong>
        </b-button>
      </div>
    </div>
    <div v-if="isZip === true && envioIniciado === false">
      <div class="columns is-centered">
        <div class="column is-6 has-text-centered">
          <b-field>
            <b-upload
              v-model="arquivoZip"
              @input="validarArquivoZip()"
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large"
                      :type="iconArquivoZip"
                    >
                    </b-icon>
                  </p>
                  <p>Arraste e solte o arquivo ZIP aqui ou clique</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags has-text-centered" v-if="arquivoZip !== null">
            <span class="tag is-primary has-text-centered">
              {{ arquivoZip.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteArquivoZip()"
              ></button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="envioIniciado === true">
      <div class="columns is-centered mt-6 pt-6" v-if="obterUrlUploadLoading">
        <div class="column is-6">
          <h6 class="title is-6">Alocando espaço ...</h6>
          <b-progress size="is-large"> </b-progress>
        </div>
      </div>
      <div class="columns is-centered mt-6 pt-6" v-if="uploadArquivoZipLoading">
        <div class="column is-6">
          <h6 class="title is-6">Enviando arquivo ...</h6>
          <b-progress
            size="is-large"
            :value="uploadPercentualZip"
            show-value
            format="percent"
          >
          </b-progress>
        </div>
      </div>
      <div class="columns is-centered mt-2" v-if="uploadArquivoImagensLoading">
        <div class="column is-12">
          <div class="columns is-centered mb-5">
            <div class="column is-12 has-text-centered">
              <h5 class="title is-5">Enviando Imagens ...</h5>
            </div>
          </div>
          <div class="columns ml-5 pl-5 mb-5">
            <div class="column is-2 mr-3" v-if="upFotoFrontalIniciado">
              <p class="is-size-7 has-text-weight-semibold">Frontal</p>
              <b-progress :value="upPerFotoFrontal" show-value format="percent">
              </b-progress>
            </div>
            <div class="column is-2 mr-3" v-if="upFotoFrontalSorrindoIniciado">
              <p class="is-size-7 has-text-weight-semibold">Sorrindo</p>
              <b-progress
                :value="upPerFotoFrontalSorrindo"
                show-value
                format="percent"
              >
              </b-progress>
            </div>
            <div
              class="column is-2 mr-3"
              v-if="upFotoIntrabucalDireitaIniciado"
            >
              <p class="is-size-7 has-text-weight-semibold">
                Intrabucal Direita
              </p>
              <b-progress
                :value="upPerFotoIntrabucalDireita"
                show-value
                format="percent"
              >
              </b-progress>
            </div>
            <div
              class="column is-2 mr-3"
              v-if="upFotoIntrabucalEsquerdaIniciado"
            >
              <p class="is-size-7 has-text-weight-semibold">
                Intrabucal Esquerda
              </p>
              <b-progress
                :value="upPerFotoIntrabucalEsquerda"
                show-value
                format="percent"
              >
              </b-progress>
            </div>
          </div>
          <div class="columns ml-5 pl-5 mb-5">
            <div
              class="column is-2 mr-3"
              v-if="upFotoIntrabucalFrontalIniciado"
            >
              <p class="is-size-7 has-text-weight-semibold">
                Intrabucal Frontal
              </p>
              <b-progress
                :value="upPerFotoIntrabucalFrontal"
                show-value
                format="percent"
              >
              </b-progress>
            </div>
            <div class="column is-2 mr-3" v-if="upFotoLateralIniciado">
              <p class="is-size-7 has-text-weight-semibold">Perfil</p>
              <b-progress :value="upPerFotoLateral" show-value format="percent">
              </b-progress>
            </div>
            <div class="column is-2 mr-3" v-if="upFotoOclusalSuperiorIniciado">
              <p class="is-size-7 has-text-weight-semibold">Oclusal Superior</p>
              <b-progress
                :value="upPerFotoOclusalSuperior"
                show-value
                format="percent"
              >
              </b-progress>
            </div>
            <div class="column is-2 mr-3" v-if="upFotoOclusalInferiorIniciado">
              <p class="is-size-7 has-text-weight-semibold">Oclusal Inferior</p>
              <b-progress
                :value="upPerFotoOclusalInferior"
                show-value
                format="percent"
              >
              </b-progress>
            </div>
          </div>
          <div class="columns ml-5 pl-5">
            <div class="column is-2 mr-3" v-if="upFotoRxPanIniciado">
              <p class="is-size-7 has-text-weight-semibold">RX PAN</p>
              <b-progress :value="upPerFotoRxPan" show-value format="percent">
              </b-progress>
            </div>
            <div class="column is-2 mr-3" v-if="upFotoTeleRadiografiaIniciado">
              <p class="is-size-7 has-text-weight-semibold">Teleradiografia</p>
              <b-progress
                :value="upPerFotoTeleRadiografia"
                show-value
                format="percent"
              >
              </b-progress>
            </div>
          </div>
        </div>
      </div>
      <div
        class="columns is-centered mt-6 pt-6"
        v-if="envioFinalizado === true"
      >
        <div class="column is-6">
          <h6 class="title is-5">Envio finalizado.</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import solicitacaoProjetoArquivosV2Repository from "@/repository/solicitacaoProjetoArquivosV2Repository";
import solicitacaoProjetoV2Repository from "@/repository/solicitacaoProjetoV2Repository";

export default {
  name: "DadosArquivosImagens",
  props: {
    codigoSolicitacao: String,
  },
  data() {
    return {
      labelPosition: "on-border",
      dadosPaciente: {
        codigoPaciente: "",
        nome: "",
        dataNascimento: "",
      },
      qtdFotos: 0,
      qtdFotosUpload: 0,
      arquivofotoAtual: 0,
      fotoFrontal: null,
      fotoFrontalCodigo: null,
      fotoFrontalSorrindo: null,
      fotoFrontalSorrindoCodigo: null,
      fotoLateral: null,
      fotoLateralCodigo: null,
      fotoIntrabucalFrontal: null,
      fotoIntrabucalFrontalCodigo: null,
      fotoIntrabucalDireita: null,
      fotoIntrabucalDireitaCodigo: null,
      fotoIntrabucalEsquerda: null,
      fotoIntrabucalEsquerdaCodigo: null,
      fotoOclusalSuperior: null,
      fotoOclusalSuperiorCodigo: null,
      fotoOclusalInferior: null,
      fotoOclusalInferiorCodigo: null,
      fotoRxPan: null,
      fotoRxPanCodigo: null,
      fotoTeleRadiografia: null,
      fotoTeleRadiografiaCodigo: null,
      fotoFrontalExt: "",
      fotoFrontalSorrindoExt: "",
      fotoLateralExt: "",
      fotoIntrabucalFrontalExt: "",
      fotoIntrabucalDireitaExt: "",
      fotoIntrabucalEsquerdaExt: "",
      fotoOclusalSuperiorExt: "",
      fotoOclusalInferiorExt: "",
      fotoRxPanExt: "",
      fotoTeleRadiografiaExt: "",
      fotosValidas: false,
      fotosQtdTotal: 0,
      acceptedImageTypes: ["image/gif", "image/jpeg", "image/png", ""],
      acceptedTypesZip: [
        "application/zip",
        "application/octet-stream",
        "application/x-zip",
        "application/x-zip-compressed",
        "",
      ],
      obterUrlUploadLoading: false,
      uploadArquivoZipLoading: false,
      uploadArquivoImagensLoading: false,
      uploadPercentage: 0,
      uploadPercentualZip: 0,
      isZip: null,
      arquivoZip: null,
      iconArquivoZip: "",
      envioFinalizado: false,
      envioIniciado: false,
      upPerFotoFrontal: 0,
      upPerFotoFrontalSorrindo: 0,
      upPerFotoLateral: 0,
      upPerFotoIntrabucalFrontal: 0,
      upPerFotoIntrabucalDireita: 0,
      upPerFotoIntrabucalEsquerda: 0,
      upPerFotoOclusalSuperior: 0,
      upPerFotoOclusalInferior: 0,
      upPerFotoRxPan: 0,
      upPerFotoTeleRadiografia: 0,
      upFotoFrontalIniciado: false,
      upFotoFrontalSorrindoIniciado: false,
      upFotoLateralIniciado: false,
      upFotoIntrabucalFrontalIniciado: false,
      upFotoIntrabucalDireitaIniciado: false,
      upFotoIntrabucalEsquerdaIniciado: false,
      upFotoOclusalSuperiorIniciado: false,
      upFotoOclusalInferiorIniciado: false,
      upFotoRxPanIniciado: false,
      upFotoTeleRadiografiaIniciado: false,
    };
  },
  methods: {
    validarArquivoZip() {
      if (!this.arquivoZip) {
        this.$buefy.toast.open({
          message: `Nenhum Arquivo Selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
        this.$emit("desabilitarEnvioArquivo");
      } else if (this.arquivoZip.size > 45338608) {
        this.arquivoZip = null;
        this.$buefy.toast.open({
          message: `Arquivo maior que 45MB`,
          position: "is-bottom",
          type: "is-danger",
        });
        this.$emit("desabilitarEnvioArquivo");
      } else if (!this.acceptedTypesZip.includes(this.arquivoZip["type"])) {
        this.arquivoZip = null;
        this.$buefy.toast.open({
          message: `Formato do arquivo inválido`,
          position: "is-bottom",
          type: "is-danger",
        });
        this.$emit("desabilitarEnvioArquivo");
      } else {
        this.iconArquivoZip = "is-success";
        this.$emit("habilitarEnvioArquivo");
      }
    },
    validarArquivo(arquivo, model) {
      if (!arquivo) {
        this.$buefy.toast.open({
          message: `Nenhum Arquivo Selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (arquivo.size > 3106619) {
        this[model] = null;
        this.$buefy.toast.open({
          message: `Arquivo maior que 3MB`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (!this.acceptedImageTypes.includes(arquivo["type"])) {
        this[model] = null;
        this.$buefy.toast.open({
          message: `Arquivo não é do tipo imagem`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else {
        this.contarFotos();
        this.$emit("habilitarEnvioArquivo");
      }
    },
    contarFotos() {
      var qtd = 0;
      this.fotoFrontal !== null || this.fotoFrontalCodigo !== null
        ? (qtd += 1)
        : null;
      this.fotoFrontalSorrindo !== null ||
      this.fotoFrontalSorrindoCodigo !== null
        ? (qtd += 1)
        : null;
      this.fotoLateral !== null || this.fotoLateralCodigo !== null
        ? (qtd += 1)
        : null;
      this.fotoIntrabucalFrontal !== null ||
      this.fotoIntrabucalFrontalCodigo !== null
        ? (qtd += 1)
        : null;
      this.fotoIntrabucalDireita !== null ||
      this.fotoIntrabucalDireitaCodigo !== null
        ? (qtd += 1)
        : null;
      this.fotoIntrabucalEsquerda !== null ||
      this.fotoIntrabucalEsquerdaCodigo !== null
        ? (qtd += 1)
        : null;
      this.fotoOclusalSuperior !== null ||
      this.fotoOclusalSuperiorCodigo !== null
        ? (qtd += 1)
        : null;
      this.fotoOclusalInferior !== null ||
      this.fotoOclusalInferiorCodigo !== null
        ? (qtd += 1)
        : null;
      this.fotoRxPan !== null || this.fotoRxPanCodigo !== null
        ? (qtd += 1)
        : null;
      this.fotoTeleRadiografia !== null ||
      this.fotoTeleRadiografiaCodigo !== null
        ? (qtd += 1)
        : null;

      this.qtdFotos = qtd;
    },
    atribuirCodigoFotos(dados) {
      dados.codigoFotoFrontal !== null
        ? (this.fotoFrontalCodigo = dados.codigoFotoFrontal)
        : (this.fotoFrontalCodigo = null);
      dados.codigoFotoSorrindo !== null
        ? (this.fotoFrontalSorrindoCodigo = dados.codigoFotoSorrindo)
        : (this.fotoFrontalSorrindoCodigo = null);
      dados.codigoFotoLateral !== null
        ? (this.fotoLateralCodigo = dados.codigoFotoLateral)
        : (this.fotoLateralCodigo = null);
      dados.codigoFotoIntrabucalFrontal !== null
        ? (this.fotoIntrabucalFrontalCodigo = dados.codigoFotoIntrabucalFrontal)
        : (this.fotoIntrabucalFrontalCodigo = null);
      dados.codigoFotoIntrabucalDireita !== null
        ? (this.fotoIntrabucalDireitaCodigo = dados.codigoFotoIntrabucalDireita)
        : (this.fotoIntrabucalDireitaCodigo = null);
      dados.codigoFotoIntrabucalEsquerda !== null
        ? (this.fotoIntrabucalEsquerdaCodigo =
            dados.codigoFotoIntrabucalEsquerda)
        : (this.fotoIntrabucalEsquerdaCodigo = null);
      dados.codigoFotoOclusaoSuperior !== null
        ? (this.fotoOclusalSuperiorCodigo = dados.codigoFotoOclusaoSuperior)
        : (this.fotoOclusalSuperiorCodigo = null);
      dados.codigoFotoOclusaoInferior !== null
        ? (this.fotoOclusalInferiorCodigo = dados.codigoFotoOclusaoSuperior)
        : (this.fotoOclusalInferiorCodigo = null);
      dados.codigoFotoRxPan !== null
        ? (this.fotoRxPanCodigo = dados.codigoFotoRxPan)
        : (this.fotoRxPanCodigo = null);
      dados.codigoFotoTeleRadiografia !== null
        ? (this.fotoTeleRadiografiaCodigo = dados.codigoFotoTeleRadiografia)
        : (this.fotoTeleRadiografiaCodigo = null);
      this.contarFotos();
    },
    deleteArquivoZip() {
      this.arquivoZip = null;
      this.iconArquivoZip = "";
      this.$emit("desabilitarEnvioArquivo");
    },
    deleteArquivosIndividuais() {
      this.$emit("desabilitarEnvioArquivo");
      this.fotoFrontal = null;
      this.fotoFrontalCodigo = null;
      this.fotoFrontalSorrindo = null;
      this.fotoFrontalSorrindoCodigo = null;
      this.fotoLateral = null;
      this.fotoLateralCodigo = null;
      this.fotoIntrabucalFrontal = null;
      this.fotoIntrabucalFrontalCodigo = null;
      this.fotoIntrabucalDireita = null;
      this.fotoIntrabucalDireitaCodigo = null;
      this.fotoIntrabucalEsquerda = null;
      this.fotoIntrabucalEsquerdaCodigo = null;
      this.fotoOclusalSuperior = null;
      this.fotoOclusalSuperiorCodigo = null;
      this.fotoOclusalInferior = null;
      this.fotoOclusalInferiorCodigo = null;
      this.fotoRxPan = null;
      this.fotoRxPanCodigo = null;
      this.fotoTeleRadiografia = null;
      this.fotoTeleRadiografiaCodigo = null;
    },
    alterarArquivoZip() {
      this.deleteArquivosIndividuais();
      this.isZip = true;
      this.$emit("exibirUploadImagens");
    },
    alterarArquivoIndividual() {
      this.deleteArquivoZip();
      this.isZip = false;
      this.$emit("exibirUploadImagens");
      this.deleteArquivoZip();
    },
    naoEnviarImagemPaciente() {
      this.$emit("naoEnviarImagemPaciente");
    },
    finalizarEnvio() {
      this.envioIniciado = true;
      this.envioFinalizado = true;
      setTimeout(() => this.$emit("envioImagensFinalizado"), 3000);
    },
    iniciarEnvio() {
      this.$emit("desabilitarEnvioArquivo");
      if (this.isZip === true) {
        this.prepararUploadArquivoZip();
      } else {
        this.prepararUploadImagens();
      }
    },
    //Upload Arquivo ZIp
    async prepararUploadArquivoZip() {
      if (this.arquivoZip !== null) {
        this.envioIniciado = true;
        this.obterUrlUploadLoading = true;
        this.zipExt =
          this.arquivoZip !== null
            ? this.arquivoZip.name.replace(/^.*\./, "")
            : "";

        let body = {
          extArquivoUnicoImagem: this.zipExt,
          operacao: "I",
        };

        try {
          await solicitacaoProjetoV2Repository.alterarSolicitacao(
            this.codigoSolicitacao,
            body
          );
          this.uploadArquivoZip();
        } catch (e) {
          this.envioIniciado = false;
          this.obterUrlUploadLoading = false;
          this.$buefy.dialog.alert({
            title: "Erro",
            message: e.response.data["hydra:description"],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      } else {
        this.$buefy.toast.open({
          message: `Nenhum arquivo foi selecionado`,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async uploadArquivoZip() {
      let solicitacaoProjetoIRI =
        "/api/solicitacao_projeto/" + this.codigoSolicitacao;
      this.envioIniciado = true;
      this.obterUrlUploadLoading = true;
      try {
        const body = {
          solicitacaoProjeto: solicitacaoProjetoIRI,
          operacao: "U",
        };
        const response =
          await solicitacaoProjetoArquivosV2Repository.gerarLinkArquivos(body);
        this.obterUrlUploadLoading = false;
        this.enviarArquivoZip(response.data.linkUploadArquivoUnicoImagem);
      } catch (e) {
        this.obterUrlUploadLoading = false;
        this.envioIniciado = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async enviarArquivoZip(linkUploadArquivoUnicoImagem) {
      try {
        if (this.arquivoZip !== null && linkUploadArquivoUnicoImagem !== null) {
          this.uploadArquivoZipLoading = true;
          const reqArquivoUnico = new XMLHttpRequest();
          reqArquivoUnico.upload.addEventListener(
            "progress",
            this.atualizarUploadArquivoUnico
          );
          reqArquivoUnico.open("PUT", linkUploadArquivoUnicoImagem, true);
          reqArquivoUnico.setRequestHeader("Content-Type", this.zipExt);
          reqArquivoUnico.onload = () => {
            if (reqArquivoUnico.status === 200) {
              this.uploadArquivoZipLoading = false;
              this.envioFinalizado = true;
              this.$emit("uploadModelos");
            }
          };
          reqArquivoUnico.onerror = () => {
            this.uploadArquivoZipLoading = false;
          };
          reqArquivoUnico.send(this.arquivoZip);
        }
      } catch (e) {
        this.envioIniciado = false;
        this.uploadArquivoMaxilaLoading = false;
        this.uploadArquivoMandibulaLoading = false;
        this.uploadArquivoZipLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: "Erro ao enviar arquivo",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    atualizarUploadArquivoUnico(event) {
      this.uploadPercentualZip = ((event.loaded * 100) / event.total).toFixed(
        2
      );
    },
    //Upload Imagens Individuais
    async prepararUploadImagens() {
      this.envioIniciado = true;
      this.obterUrlUploadLoading = true;

      let body = {
        operacao: "I",
      };

      this.fotoFrontalExt =
        this.fotoFrontal !== null
          ? this.fotoFrontal.name.replace(/^.*\./, "")
          : "";

      if (this.fotoFrontalExt !== "") {
        body = {
          ...body,
          extArquivoFotoFrontal: this.fotoFrontalExt,
        };
      }

      this.fotoFrontalSorrindoExt =
        this.fotoFrontalSorrindo !== null
          ? this.fotoFrontalSorrindo.name.replace(/^.*\./, "")
          : "";

      if (this.fotoFrontalSorrindoExt !== "") {
        body = {
          ...body,
          extArquivoFotoSorrindo: this.fotoFrontalSorrindoExt,
        };
      }

      this.fotoLateralExt =
        this.fotoLateral !== null
          ? this.fotoLateral.name.replace(/^.*\./, "")
          : "";

      if (this.fotoLateralExt !== "") {
        body = {
          ...body,
          extArquivoFotoLateral: this.fotoLateralExt,
        };
      }

      this.fotoIntrabucalFrontalExt =
        this.fotoIntrabucalFrontal !== null
          ? this.fotoIntrabucalFrontal.name.replace(/^.*\./, "")
          : "";

      if (this.fotoIntrabucalFrontalExt !== "") {
        body = {
          ...body,
          extArquivoFotoIntrabucalFrontal: this.fotoIntrabucalFrontalExt,
        };
      }

      this.fotoIntrabucalDireitaExt =
        this.fotoIntrabucalDireita !== null
          ? this.fotoIntrabucalDireita.name.replace(/^.*\./, "")
          : "";

      if (this.fotoIntrabucalDireitaExt !== "") {
        body = {
          ...body,
          extArquivoFotoIntrabucalDireita: this.fotoIntrabucalDireitaExt,
        };
      }

      this.fotoIntrabucalEsquerdaExt =
        this.fotoIntrabucalEsquerda !== null
          ? this.fotoIntrabucalEsquerda.name.replace(/^.*\./, "")
          : "";

      if (this.fotoIntrabucalEsquerdaExt !== "") {
        body = {
          ...body,
          extArquivoFotoIntrabucalEsquerda: this.fotoIntrabucalEsquerdaExt,
        };
      }

      this.fotoOclusalSuperiorExt =
        this.fotoOclusalSuperior !== null
          ? this.fotoOclusalSuperior.name.replace(/^.*\./, "")
          : "";

      if (this.fotoOclusalSuperiorExt !== "") {
        body = {
          ...body,
          extArquivoFotoOclusaoSuperior: this.fotoOclusalSuperiorExt,
        };
      }

      this.fotoOclusalInferiorExt =
        this.fotoOclusalInferior !== null
          ? this.fotoOclusalInferior.name.replace(/^.*\./, "")
          : "";

      if (this.fotoOclusalInferiorExt !== "") {
        body = {
          ...body,
          extArquivoFotoOclusaoInferior: this.fotoOclusalInferiorExt,
        };
      }

      this.fotoRxPanExt =
        this.fotoRxPan !== null ? this.fotoRxPan.name.replace(/^.*\./, "") : "";

      if (this.fotoRxPanExt !== "") {
        body = {
          ...body,
          extArquivoFotoRxPan: this.fotoRxPanExt,
        };
      }

      this.fotoTeleRadiografiaExt =
        this.fotoTeleRadiografia !== null
          ? this.fotoTeleRadiografia.name.replace(/^.*\./, "")
          : "";

      if (this.fotoTeleRadiografiaExt !== "") {
        body = {
          ...body,
          extArquivoFotoTeleRadiografia: this.fotoTeleRadiografiaExt,
        };
      }

      try {
        await solicitacaoProjetoV2Repository.alterarSolicitacao(
          this.codigoSolicitacao,
          body
        );
        this.uploadArquivosImagens();
      } catch (e) {
        this.envioIniciado = false;
        this.obterUrlUploadLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async uploadArquivosImagens() {
      let solicitacaoProjetoIRI =
        "/api/solicitacao_projeto/" + this.codigoSolicitacao;
      this.envioIniciado = true;
      this.obterUrlUploadLoading = true;
      try {
        const body = {
          solicitacaoProjeto: solicitacaoProjetoIRI,
          operacao: "U",
        };
        const response =
          await solicitacaoProjetoArquivosV2Repository.gerarLinkArquivos(body);
        this.obterUrlUploadLoading = false;
        this.enviarArquivoImagens(response.data);
      } catch (e) {
        this.obterUrlUploadLoading = false;
        this.envioIniciado = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    enviarArquivoImagens(links) {
      this.contarFotos();
      this.qtdFotosUpload = 0;
      this.uploadArquivoImagensLoading = true;
      links.linkUploadFotoFrontal !== null && links.linkUploadFotoFrontal !== ""
        ? this.uploadImagem(
            "fotoFrontal",
            "fotoFrontalExt",
            "upPerFotoFrontal",
            "upFotoFrontalIniciado",
            links.linkUploadFotoFrontal
          )
        : null;
      links.linkUploadFotoIntrabucalDireita !== null &&
      links.linkUploadFotoIntrabucalDireita !== ""
        ? this.uploadImagem(
            "fotoIntrabucalDireita",
            "fotoIntrabucalDireitaExt",
            "upPerFotoIntrabucalDireita",
            "upFotoIntrabucalDireitaIniciado",
            links.linkUploadFotoIntrabucalDireita
          )
        : null;
      links.linkUploadFotoIntrabucalEsquerda !== null &&
      links.linkUploadFotoIntrabucalEsquerda !== ""
        ? this.uploadImagem(
            "fotoIntrabucalEsquerda",
            "fotoIntrabucalEsquerdaExt",
            "upPerFotoIntrabucalEsquerda",
            "upFotoIntrabucalEsquerdaIniciado",
            links.linkUploadFotoIntrabucalEsquerda
          )
        : null;
      links.linkUploadFotoIntrabucalFrontal !== null &&
      links.linkUploadFotoIntrabucalFrontal !== ""
        ? this.uploadImagem(
            "fotoIntrabucalFrontal",
            "fotoIntrabucalFrontalExt",
            "upPerFotoIntrabucalFrontal",
            "upFotoIntrabucalFrontalIniciado",
            links.linkUploadFotoIntrabucalFrontal
          )
        : null;
      links.linkUploadFotoOclusaoInferior !== null &&
      links.linkUploadFotoOclusaoInferior !== ""
        ? this.uploadImagem(
            "fotoOclusalInferior",
            "fotoOclusalInferiorExt",
            "upPerFotoOclusalInferior",
            "upFotoOclusalInferiorIniciado",
            links.linkUploadFotoOclusaoInferior
          )
        : null;
      links.linkUploadFotoOclusaoSuperior !== null &&
      links.linkUploadFotoOclusaoSuperior !== ""
        ? this.uploadImagem(
            "fotoOclusalSuperior",
            "fotoOclusalSuperiorExt",
            "upPerFotoOclusalSuperior",
            "upFotoOclusalSuperiorIniciado",
            links.linkUploadFotoOclusaoSuperior
          )
        : null;
      links.linkUploadFotoRxPan !== null && links.linkUploadFotoRxPan !== ""
        ? this.uploadImagem(
            "fotoRxPan",
            "fotoRxPanExt",
            "upPerFotoRxPan",
            "upFotoRxPanIniciado",
            links.linkUploadFotoRxPan
          )
        : null;
      links.linkUploadFotoSorrindo !== null &&
      links.linkUploadFotoSorrindo !== ""
        ? this.uploadImagem(
            "fotoFrontalSorrindo",
            "fotoFrontalSorrindoExt",
            "upPerFotoFrontalSorrindo",
            "upFotoFrontalSorrindoIniciado",
            links.linkUploadFotoSorrindo
          )
        : null;
      links.linkUploadFotoTeleRadiografia !== null &&
      links.linkUploadFotoTeleRadiografia !== ""
        ? this.uploadImagem(
            "fotoTeleRadiografia",
            "fotoTeleRadiografiaExt",
            "upPerFotoTeleRadiografia",
            "upFotoTeleRadiografiaIniciado",
            links.linkUploadFotoTeleRadiografia
          )
        : null;
      links.linkUploadFotoLateral !== null && links.linkUploadFotoLateral !== ""
        ? this.uploadImagem(
            "fotoLateral",
            "fotoLateralExt",
            "upPerFotoLateral",
            "upFotoLateralIniciado",
            links.linkUploadFotoLateral
          )
        : null;
    },
    uploadImagem(imagem, extensao, uploadPer, uploadIniciado, linkUpload) {
      try {
        this.qtdFotosUpload += 1;
        this[uploadPer] = 0;
        this[uploadIniciado] = true;
        const reqArquivoImagem = new XMLHttpRequest();
        reqArquivoImagem.upload.addEventListener("progress", () =>
          this.atualizarUploadImagens(event, uploadPer)
        );

        reqArquivoImagem.open("PUT", linkUpload, true);
        reqArquivoImagem.setRequestHeader("Content-Type", this[extensao]);
        reqArquivoImagem.onload = () => {
          if (reqArquivoImagem.status === 200) {
            this.verificarUploadImagensConcluido();
          }
        };
        reqArquivoImagem.onerror = () => {
          this.envioIniciado = false;
          this.uploadArquivoImagensLoading = false;
        };
        reqArquivoImagem.send(this[imagem]);
      } catch (e) {
        this.envioIniciado = false;
        this.uploadArquivoImagensLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: "Erro ao enviar arquivos",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    atualizarUploadImagens(event, uploadPer) {
      this[uploadPer] = Number(((event.loaded * 100) / event.total).toFixed(2));
    },
    verificarUploadImagensConcluido() {
      if (this.qtdFotosUpload === this.qtdFotos) {
        this.envioFinalizado = true;
        this.$emit("uploadImagens");
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.a-expanded {
  width: 100%;
}

.ulpoad-imagem {
  max-width: 78%;
}

.span-nome-arquivo {
  font-family: monospace;
  white-space: nowrap;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.nome-arquivo {
  display: block;
  width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 0.2rem;
}

.overlay-progress {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  cursor: pointer;
}

.overlay-progress-content {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
