<template>
  <div class="tabela-projetos">
    <b-table
      v-if="listaProjetos !== null && listaProjetos.length > 0"
      :data="listaProjetos"
      :selected.sync="projetoSelecionada"
      @click="selecionarProjeto"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      default-sort="numero"
      aria-next-label="Pag. Proxima"
      aria-previous-label="Pag. Anterior"
      aria-page-label="Pag."
      aria-current-label="Pag. Atual"
    >
      <b-table-column
        field="nrProjeto"
        width="20"
        label="Número"
        class="coluna-tabela-solicitoes"
        v-slot="props"
        sortable
        >{{ props.row.nrProjeto }}</b-table-column
      >
      <b-table-column
        field="dataHoraProjeto"
        width="20"
        label="Data"
        class="coluna-tabela-solicitoes"
        sortable
        v-slot="props"
        >{{
          props.row.dataHoraProjeto !== null
            ? new Date(props.row.dataHoraProjeto).toLocaleDateString("pt-BR")
            : ""
        }}</b-table-column
      >
      <b-table-column
        field="cdEstProjeto"
        width="100"
        label="Cd. Estado"
        class="coluna-tabela-solicitoes"
        sortable
        v-slot="props"
        >{{ props.row.estadoProjeto.cdEstProjeto }}</b-table-column
      >
      <b-table-column
        field="descricao"
        width="20"
        label="Desc. Estado"
        class="coluna-tabela-solicitoes"
        sortable
        v-slot="props"
        >{{ props.row.estadoProjeto.descricao }}</b-table-column
      >
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <b-message type="is-warning"
              >Sem planejamentos localizados.</b-message
            >
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import ModalProjeto from "./ModalProjeto";

export default {
  name: "TabelaProjetos",
  props: {
    listaProjetos: Array,
  },
  data() {
    return {
      projetoSelecionada: null,
      //configuracoes table
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
    };
  },
  methods: {
    selecionarProjeto(value) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          dadosProjeto: value,
        },
        component: ModalProjeto,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        fullScreen: true,
        events: {
          atualizarProjetos: () => {
            this.atualizarListaProjetos();
          },
        },
      });
    },
    atualizarListaProjetos() {
      this.$emit("atualizarListaProjetos");
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tabela-projetos {
  font-size: 0.97rem;
}
.coluna-tabela-projeto {
  white-space: nowrap;
}
</style>
