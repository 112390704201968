import bluealApiClient from "@/service/bluealApi/BluealApiCliente";
import qs from "qs";
import Repository from "@/service/bluealApi/BluealApiCliente";

const resourceSingle = "/usuario";
const resource = "/usuarios";

export default {
  obterUsuarioLogado() {
    return bluealApiClient().get(`${resourceSingle}_logado`);
  },
  obterLinkUploadAvatar(codigo, body) {
    return bluealApiClient().put(
      `${resourceSingle}/link_upload_avatar/${codigo}`,
      body
    );
  },
  atualizarUsuario(body, codigo) {
    return bluealApiClient().put(`${resource}/${codigo}`, body);
  },
  cadastrarUsuario(body) {
    return bluealApiClient().post(`${resource}`, body);
  },
  listarUsuarios(params) {
    return bluealApiClient().get(`${resource}`, {
      params: {
        ...params,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });
  },
  esqueciSenha(playload) {
    return Repository().post(`/lost_password`, playload);
  },
};
