<template>
  <div
    v-if="listaSolicitacoes.length > 0"
    class="modal-card"
    style="width: auto"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">Solicitações de Planejamento Iniciadas</p>
    </header>
    <section class="modal-card-body">
      <div class="column is-12 tabela-solicitacoes">
        <b-table
          :data="listaSolicitacoes"
          :selected.sync="selected"
          @select="solicitacaoSelecionada"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :striped="isStriped"
          :narrowed="isNarrowed"
          :hoverable="isHoverable"
          default-sort="numero"
          aria-next-label="Pag. Proxima"
          aria-previous-label="Pag. Anterior"
          aria-page-label="Pag."
          aria-current-label="Pag. Atual"
          pagination-size="is-small"
        >
          <b-table-column
            field="codigoSolicitacao"
            width="20"
            label="Número"
            sortable
          >
            <template slot="searchable" slot-scope="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Filtrar"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              <span class="coluna-tabela">{{
                props.row.codigoSolicitacao
              }}</span>
            </template>
          </b-table-column>
          <b-table-column
            field="dataHoraSolicitacao"
            width="20"
            label="Data"
            sortable
          >
            <template slot="searchable" slot-scope="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Filtrar"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              <span class="coluna-tabela">{{
                props.row.data !== null
                  ? new Date(props.row.dataHoraSolicitacao).toLocaleDateString(
                      "pt-BR"
                    )
                  : ""
              }}</span>
            </template>
          </b-table-column>
          <b-table-column field="cdPaciente" width="30" label="Cód." sortable>
            <template slot="searchable" slot-scope="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Filtrar"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              <span class="coluna-tabela">{{
                props.row.paciente.codigoPaciente
              }}</span>
            </template>
          </b-table-column>
          <b-table-column
            field="nomePaciente"
            width="70"
            label="Paciente"
            sortable
          >
            <template slot="searchable" slot-scope="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Filtrar"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              <span class="coluna-tabela">{{ props.row.paciente.nome }}</span>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-twitter"
        rounded
        icon-left="arrow-left"
        @click="solicitacaoSelecionada(null)"
        >Voltar</b-button
      >
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalSolicitacoesIniciadas",
  props: {
    listaSolicitacoes: Array,
  },
  data() {
    return {
      selected: null,
      loadModal: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 5,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
    };
  },
  methods: {
    solicitacaoSelecionada(value) {
      this.$emit("solicitacaoSelecionada", value);
      this.$parent.close();
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tabela-solicitacoes {
  font-size: 0.95rem;
}
.coluna-tabela {
  white-space: nowrap;
}
</style>
