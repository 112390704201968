<template>
  <div class="section p-0">
    <div class="columns">
      <div class="column p-0 is-12">
        <MenuSuperior
          :key="userFirstName"
          :user-first-name="userFirstName"
          :user-avatar="userAvatar"
        />
      </div>
    </div>
    <div class="columns p-0">
      <div class="column p-0">
        <menu-side
          :key="userProfile"
          :user-profile="userProfile"
          :user-avatar="userAvatar"
        />
      </div>
      <div class="column is-12 pl-3 pr-3">
        <div class="blueal-app-content">
          <router-view v-slot="{ Component }">
            <transition name="fade">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
        <div name="snack-bars">
          <SnackBar
            v-if="snackBar"
            :title="snackBar.title"
            :message="snackBar.message"
            :timeout="snackBar.timeout"
            :color="snackBar.color"
            @snackBarClosed="snackBar = null"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuSuperior from "./views/MenuSuperior";
import store from "@/store/store";
import SnackBar from "./components/snackBar/SnackBar.vue";
import axios from "axios";
import { getUserLogged } from "./service/bluealApi/repository/user/UserRepository";
import MenuSide from "./views/menu/MenuSide.vue";
import router from "./router/index";
import blLogoVertical from "@/assets/bl-logo-vertical.webp";

export default {
  name: "AppSysAdmin",
  components: {
    MenuSuperior,
    SnackBar,
    MenuSide,
  },
  data() {
    return {
      sessaoExpiraEm: null,
      timeTick: null,
      isLoadingInitial: true,
      snackBar: null,
      userProfile: "",
      userFirstName: "",
      userAvatar: "",
      blLogoVertical: blLogoVertical,
    };
  },
  beforeMount() {
    store.subscribeAction((action) => {
      if (action.type === "snackBarStore/showSnackBar") {
        this.snackBar = action.payload;
      } else if (action.type === "changedDadosUsuario") {
        const userData = action.payload;
        this.userFirstName = userData.nome
          .trim()
          .slice(0, userData.nome.indexOf(" "));
        this.userAvatar = userData.linkImagem;
        if (userData.roles.indexOf("ROLE_ADMIN") !== -1) {
          this.userProfile =
            userData.roles[userData.roles.indexOf("ROLE_ADMIN")];
        } else if (userData.roles.indexOf("ROLE_COLAB") !== -1) {
          this.userProfile =
            userData.roles[userData.roles.indexOf("ROLE_COLAB")];
        }
      }
    });
    if (store.getters["doneSessionToken"]) {
      this.buscarDadosUsuario();
    } else {
      router.push({ name: "Login" });
    }
  },
  methods: {
    async buscarDadosUsuario() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const response = await getUserLogged();
        loadingComponent.close();
        store.commit("SET_DADOS_USUARIO", response.data);
        await store.dispatch("changedDadosUsuario", response.data);
        if (response.data.usuarioEstado.codigoEstadoUsuario === 100) {
          await this.$router.push({ name: "AtualizarCadastroUsuario" });
        }
        this.isLoadingInitial = false;
      } catch (error) {
        store.commit("SET_DADOS_USUARIO", null);
        let message = "Erro desconhecido";
        if (axios.isAxiosError(error) && error.response) {
          message = error.response.data.message;
        } else {
          message = error.message;
        }
        await store.dispatch("snackBarStore/showSnackBar", {
          title: "Erro ao obter dados da usuário",
          message: message,
        });
        loadingComponent.close();
      }
    },
  },
};
</script>

<style>
.blueal-app-content {
  padding-top: 0.8rem;
  padding-left: 4.5vw;
}
</style>
