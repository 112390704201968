<template>
  <div class="tabela-solicitacoes">
    <b-table
      :sticky-header="false"
      :data="listaSolicitacoes.length > 0 ? listaSolicitacoes : []"
      :selected.sync="solicitacaoSelecionada"
      @click="selecionarSolicitacao"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      @page-change="verificarRechamada"
      default-sort="codigoPaciente"
      aria-next-label="Pag. Proxima"
      aria-previous-label="Pag. Anterior"
      aria-page-label="Pag."
      aria-current-label="Pag. Atual"
    >
      <b-table-column
        field="codigoSolicitacao"
        label="Número"
        header-class="header-tabela"
        searchable
        sortable
        width="15%"
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Filtrar número"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span class="coluna-tabela-solicitacoes">{{
            props.row.codigoSolicitacao
          }}</span>
        </template>
      </b-table-column>
      <b-table-column
        field="dataHoraSolicitacao"
        label="Data"
        searchable
        sortable
        width="10%"
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Filtrar data"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span class="coluna-tabela-solicitacoes">{{
            props.row.dataHoraSolicitacao !== null
              ? new Date(props.row.dataHoraSolicitacao).toLocaleDateString(
                  "pt-BR",
                  { timeZone: "UTC" }
                )
              : ""
          }}</span>
        </template>
      </b-table-column>
      <b-table-column
        :numeric="true"
        field="codigoUsuario"
        label="Cód."
        searchable
        sortable
        width="8%"
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Filtrar código"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span class="coluna-tabela-solicitacoes">{{
            props.row.codigoUsuario
          }}</span>
        </template>
      </b-table-column>
      <b-table-column
        field="nomeUsuario"
        label="Usuário"
        searchable
        sortable
        width="10%"
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Filtrar nome"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span class="coluna-tabela-solicitacoes">
            {{
              props.row.nomeUsuario ? props.row.nomeUsuario.slice(0, 30) : ""
            }}
          </span>
        </template>
      </b-table-column>
      <b-table-column
        :numeric="true"
        field="codigoPaciente"
        label="Cód."
        searchable
        sortable
        width="8%"
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Filtrar código"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span class="coluna-tabela-solicitacoes">{{
            props.row.codigoPaciente
          }}</span>
        </template>
      </b-table-column>
      <b-table-column
        field="nomePaciente"
        label="Paciente"
        searchable
        sortable
        width="18%"
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Filtrar nome"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span class="coluna-tabela-solicitacoes">{{
            props.row.nomePaciente ? props.row.nomePaciente.slice(0, 30) : ""
          }}</span>
        </template>
      </b-table-column>
      <b-table-column
        field="estadoCobrancaAssas"
        label="Cobrança"
        searchable
        sortable
        width="8%"
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Filtrar descrição"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span class="coluna-tabela-solicitacoes">{{
            props.row.estadoCobrancaAssas !== null
              ? props.row.estadoCobrancaAssas
              : "Sem cobrança"
          }}</span>
        </template>
      </b-table-column>
      <b-table-column
        :numeric="true"
        field="codigoEstadoSolicitacao"
        custom-key="cod-estado"
        label="Cód."
        searchable
        sortable
        width="12%"
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Filtrar código"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span class="coluna-tabela-solicitacoes">{{
            props.row.codigoEstadoSolicitacao
          }}</span>
        </template>
      </b-table-column>
      <b-table-column
        field="descricaoEstadoSolicitacao"
        label="Estado"
        searchable
        sortable
        width="8%"
      >
        <template slot="searchable" slot-scope="props">
          <b-input
            v-model="props.filters[props.column.field]"
            placeholder="Filtrar estado"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span class="coluna-tabela-solicitacoes">{{
            props.row.descricaoEstadoSolicitacao
          }}</span>
        </template>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <b-message type="is-warning"
              >Sem solicitações de Planejamento neste estado.</b-message
            >
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import ModalConsultarSolicitacaoProjeto from "./ModalConsultarSolicitacaoProjeto";

export default {
  name: "TabelaSolicitacoes",
  props: {
    listaSolicitacoes: Array,
    tipoTabela: String,
    qtdPaginas: Number,
  },
  data() {
    return {
      solicitacaoSelecionada: null,
      //configuracoes table
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
    };
  },
  beforeMount() {
    this.currentPage =
      this.qtdPaginas === 1 ? this.qtdPaginas : this.qtdPaginas * 10 - 10;
  },
  methods: {
    verificarRechamada(page) {
      const pageNumber = Number(page / 10) + 1;
      const rechamar = Number.isInteger(pageNumber);
      if (rechamar && this.qtdPaginas < pageNumber) {
        this.currentPage = page;
        this.$emit("efetuarRechamada", {
          tipoTabela: this.tipoTabela,
          pagina: pageNumber,
        });
      }
    },
    selecionarSolicitacao(value) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          codigoSolicitacao: value.codigoSolicitacao,
        },
        component: ModalConsultarSolicitacaoProjeto,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        fullScreen: true,
        events: {
          atualizacaoProjeto: () => {
            this.$emit("atualizacaoProjeto");
          },
        },
      });
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tabela-solicitacoes {
  font-size: 0.88rem;
  max-width: 100%;
}
.coluna-tabela-solicitacoes {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 1rem;
}
</style>
