
import Vue from "vue";
import MenuItem from "./MenuItem.vue";

export interface SubMenu {
  label: string;
  route: string;
  icon: string;
  profiles: string[];
}

const subMenuPlanejamento: SubMenu[] = [
  {
    label: "Consulta Planejamentos",
    route: "GerenciarSolicitacoes",
    icon: "fas fa-angle-right",
    profiles: ["ROLE_ADMIN"],
  },
  {
    label: "Novo Planejamento",
    route: "SolicitarProjeto",
    icon: "fas fa-angle-right",
    profiles: ["ROLE_ADMIN"],
  },
];

const subMenuAlinhadores: SubMenu[] = [
  {
    label: "Gerenciar Pedidos",
    route: "ListaPedidosAlinhadores",
    icon: "fas fa-angle-right",
    profiles: ["ROLE_ADMIN", "ROLE_COLAB"],
  },
];

const subMenuUsuarios: SubMenu[] = [
  {
    label: "Cadastrar",
    route: "CadastrarUsuario",
    icon: "fas fa-angle-right",
    profiles: ["ROLE_ADMIN"],
  },
  {
    label: "Consultar",
    route: "ListaTodosUsuarios",
    icon: "fas fa-angle-right",
    profiles: ["ROLE_ADMIN"],
  },
];

const subMenuImpressao3D: SubMenu[] = [
  {
    label: "Gerenciar Pedidos",
    route: "GerenciarPedidosImpressao",
    icon: "fas fa-angle-right",
    profiles: ["ROLE_ADMIN", "ROLE_COLAB"],
  },
];

const subMenuFinanceiro: SubMenu[] = [
  {
    label: "Planejamentos",
    route: "FinanceiroPlanejamentos",
    icon: "fas fa-angle-right",
    profiles: ["ROLE_ADMIN"],
  },
  {
    label: "Pedido Alinhadores",
    route: "FinanceiroPedidosAlinhadores",
    icon: "fas fa-angle-right",
    profiles: ["ROLE_ADMIN"],
  },
  {
    label: "Impressao 3D",
    route: "FinanceiroPedidoModelo",
    icon: "fas fa-angle-right",
    profiles: ["ROLE_ADMIN"],
  },
];

export interface Menu extends SubMenu {
  subMenuList: SubMenu[];
}

const menusList: Menu[] = [
  {
    label: "Planejamentos",
    route: "Home",
    icon: "fas fa-file-invoice",
    profiles: ["ROLE_ADMIN"],
    subMenuList: subMenuPlanejamento,
  },
  {
    label: "Alinhadores",
    route: "Home",
    icon: "fas fa-indent",
    profiles: ["ROLE_ADMIN", "ROLE_COLAB"],
    subMenuList: subMenuAlinhadores,
  },
  {
    label: "Usuários",
    route: "Home",
    icon: "fas fa-users",
    profiles: ["ROLE_ADMIN"],
    subMenuList: subMenuUsuarios,
  },
  {
    label: "Impressão 3D",
    route: "Home",
    icon: "fas fa-cube",
    profiles: ["ROLE_ADMIN", "ROLE_COLAB"],
    subMenuList: subMenuImpressao3D,
  },
  {
    label: "Financeiro",
    route: "Home",
    icon: "fas fa-file-invoice-dollar",
    profiles: ["ROLE_ADMIN"],
    subMenuList: subMenuFinanceiro,
  },
];

export default Vue.extend({
  components: { MenuItem },
  name: "MenuSide",
  props: {
    userProfile: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      collapsed: true,
      menus: menusList as Menu[],
    };
  },
  beforeUpdate() {
    this.menus = this.menus.filter((menu) => {
      return menu.profiles.includes(this.userProfile);
    });
  },
});
