const unselectableAfterDate = 25;

const unselectableDates = (day: Date) => {
  return unselectableAfterDate < day.getDate();
};

const maxDate = (daysDeadline: number) => {
  const today = new Date();

  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + daysDeadline
  );

  if (maxDate.getDate() > unselectableAfterDate) {
    return new Date(today.getFullYear(), today.getMonth() + 1, 1);
  } else {
    return maxDate;
  }
};

export { unselectableAfterDate, unselectableDates, maxDate };
