


























import Vue from "vue";

const BluealModal = Vue.extend({
  name: "BluealModal",
  props: {
    title: { type: String, required: true },
    isOpen: { type: Boolean, default: false },
    color: { type: String, default: "light", required: false },
    icon: { type: String, required: false },
  },
});

export default BluealModal;
