import axios from "axios";

const baseURL = `https://viacep.com.br/ws`;

const client = axios.create({
  baseURL,
});

export default {
  buscaCep(cep) {
    return client.get(`${baseURL}/${cep}/json`);
  },
};
