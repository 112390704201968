<template>
  <div class="p-2">
    <div class="columns">
      <div class="mr-5">
        <SolicitacaoDashboard />
      </div>
      <div class="mr-5">
        <PedidosDashboard />
      </div>
      <div>
        <ProjetosDashboard />
      </div>
    </div>
    <div class="columns">
      <div class="column is-6">
        <fluxo-solicitacao></fluxo-solicitacao>
      </div>
    </div>
  </div>
</template>

<script>
import FluxoSolicitacao from "./FluxoSolicitacao";
import SolicitacaoDashboard from "@/views/dashboard/SolicitacaoDashboard";
import PedidosDashboard from "@/views/dashboard/PedidosDashboard";
import ProjetosDashboard from "@/views/dashboard/ProjetosDashboard";

export default {
  name: "DashBoard",
  components: {
    FluxoSolicitacao,
    SolicitacaoDashboard,
    PedidosDashboard,
    ProjetosDashboard,
  },
  mounted() {
    this.verificarMSIE();
  },
  methods: {
    verificarMSIE() {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");

      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
        // If Internet Explorer, return version number
        this.$buefy.dialog.alert({
          title: "Incompatibilidade do Navegador",
          message:
            "Você está utilizando browser incompatível com o sistema, pedimos Google Chrome, Firefox, Safari ou Microsoft Edge",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
  },
};
</script>
