<template>
  <div class="incluir-endereco">
    <form ref="formEndereco">
      <div class="columns">
        <div class="column is-3">
          <b-field
            label="CEP"
            label-position="on-border"
            :type="cepMessage.length > 0 ? 'is-danger' : ''"
            :message="cepMessage"
          >
            <b-input
              @input="validarCep"
              placeholder="Somente números"
              v-model="cep"
              v-cleave="masks.cep"
              maxlength="10"
              minlength="10"
              required
            ></b-input>
          </b-field>
        </div>
        <div class="column is-7">
          <b-field label="Logradouro" label-position="on-border">
            <b-input
              v-model="logradouro"
              required
              maxlength="250"
              minlength="4"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Nr." label-position="on-border">
            <b-input
              v-model="numero"
              required
              maxlength="15"
              minlength="1"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <b-field label="Complemento" label-position="on-border">
            <b-input
              v-model="complemento"
              placeholder="Ex. BLOCO B SALA 45"
              required
              maxlength="30"
              minlength="1"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Bairro" label-position="on-border">
            <b-input
              v-model="bairro"
              required
              maxlength="250"
              minlength="3"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-5">
          <b-field label="Município" label-position="on-border">
            <b-input
              v-model="municipio"
              required
              maxlength="250"
              minlength="3"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <b-field label="UF" label-position="on-border">
            <b-select placeholder="UF" required v-model="uf">
              <option v-for="option in ufs" :value="option.uf" :key="option.uf">
                {{ option.uf }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4 coluna-end-princ">
          <p class="title is-7 titulo-end-princ">
            Deseja tornar endereço principal?
          </p>
          <b-field>
            <b-radio-button
              v-model="enderecoPrincipal"
              size="is-small"
              native-value="/api/endereco_tipo/10"
              required
              type="is-primary"
            >
              <b-icon size="is-small" icon="check"></b-icon>
              <span>Sim</span>
            </b-radio-button>

            <b-radio-button
              v-model="enderecoPrincipal"
              size="is-small"
              native-value="/api/endereco_tipo/20"
              required
              type="is-danger"
            >
              <b-icon size="is-small" icon="times"></b-icon>
              <span>Não</span>
            </b-radio-button>
          </b-field>
        </div>
        <div class="column is-4">
          <button
            class="button is-primary"
            type="button"
            @click="incluirEndereco()"
          >
            Adicionar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import { RepositoryFactory } from "./../../repository/RepositoryFactory";
import cepRepository from "./../../repository/terceiros/cepRepository";
const enderecoRespository = RepositoryFactory.get("enderecoV2");

const listaUf = "@/data/uf/ufs.json";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AlterarEndereco",
  props: {
    enderecoAlteracao: Object,
  },
  data() {
    return {
      cep: "",
      cepMessage: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      municipio: "",
      ufs: listaUf.ufs,
      uf: "",
      enderecoPrincipal: "/api/endereco_tipo/20",
      masks: {
        cep: {
          delimiters: [".", "-"],
          blocks: [2, 3, 3],
          numericOnly: true,
        },
      },
    };
  },
  directives: {
    cleave,
  },
  beforeMount() {
    this.cep = this.enderecoAlteracao.cep.match(/[0-9]+/g).join("");
    this.buscaCep(this.cep);
  },
  mounted() {
    this.$emit("tabAlteracaoCarregada");
  },
  methods: {
    async incluirEndereco() {
      if (this.$refs.formEndereco.checkValidity()) {
        const loadingComponent = this.$buefy.loading.open();
        try {
          const body = {
            logradouro: this.logradouro,
            numero: this.numero,
            complemento: this.complemento,
            bairro: this.bairro,
            municipio: this.municipio,
            uf: this.uf,
            cep: this.cep,
            tipoEndereco: this.enderecoPrincipal,
          };
          const response = await enderecoRespository.incluirEndereco(body);
          this.selecionarEndereco(response.data);
          this.atualizarListaEndereco();
          loadingComponent.close();
        } catch (e) {
          loadingComponent.close();
          this.$buefy.dialog.alert({
            title: "Erro ao Incluir Endereço",
            message: e.response.data["hydra:description"],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      } else {
        this.$buefy.toast.open({
          message: `Verifique se os dados do endereço foram preenchidos`,
          position: "is-top",
          type: "is-danger",
        });
      }
    },
    async buscaCep(cep) {
      this.isLoading = true;
      try {
        const response = await cepRepository.buscaCep(cep);
        if (
          typeof response.data.erro === "undefined" ||
          response.data.erro === false
        ) {
          this.logradouro = response.data.logradouro;
          this.bairro = response.data.bairro;
          this.municipio = response.data.localidade;
          this.uf = response.data.uf;
          this.cepMessage = "";
        } else {
          this.cepMessage = "CEP não existe";
          this.logradouro = "";
          this.bairro = "";
          this.municipio = "";
          this.uf = "";
        }
        this.isLoading = false;
      } catch (e) {
        this.cepMessage = "CEP não existe";
        this.isLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: "Erro ao buscar dados do CEP",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    validarCep() {
      if (this.cep.length >= 10) {
        this.buscaCep(this.cep.replace(/\D/g, ""));
      }
    },
    selecionarEndereco(endereco) {
      this.$emit("enderecoSelecionado", endereco);
    },
    atualizarListaEndereco() {
      this.$emit("atualizarListaEndereco");
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.incluir-endereco {
  margin-top: 1.5rem;
  margin-left: 1rem;
}
.coluna-end-princ {
  padding-top: 0px !important;
}
.titulo-end-princ {
  left: 1em;
  font-size: calc(0.9rem * 3 / 4);
  background-color: transparent;
  margin-bottom: 0.35rem !important;
}
</style>
