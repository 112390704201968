<template>
  <div>
    <section>
      <article class="panel is-ligth">
        <p class="panel-heading">Pedidos Alinhadores</p>
        <div class="panel-block painel-pedidos-alinhadores columns">
          <div class="column is-12">
            <b-tabs v-model="tabIndex" type="is-toggle" :expanded="true">
              <b-tab-item>
                <template slot="header">
                  <b-icon icon="file-signature"></b-icon>
                  <span class="is-size-7 has-text-weight-semibold">
                    Em Produção
                  </span>
                </template>
                <TabelaPedidosAlinhadores v-if="tabIndex === 0" :estado="10" />
              </b-tab-item>
              <b-tab-item>
                <template slot="header">
                  <b-icon icon="file-signature"></b-icon>
                  <span class="is-size-7 has-text-weight-semibold">
                    Impressos
                  </span>
                </template>
                <TabelaPedidosAlinhadores v-if="tabIndex === 1" :estado="20" />
              </b-tab-item>
              <b-tab-item>
                <template slot="header">
                  <b-icon icon="truck"></b-icon>
                  <span class="is-size-7 has-text-weight-semibold">
                    Enviados
                  </span>
                </template>
                <TabelaPedidosAlinhadores v-if="tabIndex === 2" :estado="40" />
              </b-tab-item>
              <b-tab-item>
                <template slot="header">
                  <b-icon icon="check-circle"></b-icon>
                  <span class="is-size-7 has-text-weight-semibold">
                    Finalizados
                  </span>
                </template>
                <TabelaPedidosAlinhadores v-if="tabIndex === 3" :estado="30" />
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import TabelaPedidosAlinhadores from "@/views/pedidos-alinhadores-consulta/TabelaPedidosAlinhadores";

export default {
  name: "ListaPedidosAlinhadores",
  components: {
    TabelaPedidosAlinhadores,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.painel-pedidos-alinhadores {
  padding: 1rem;
  padding-top: 0.6rem;
}
</style>
