<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title"><b-icon icon="id-badge" /> Imagem Perfil</p>
    </header>
    <section class="modal-card-body">
      <div class="columns is-vcentered is-centered div-container">
        <div class="column is-6">
          <div class="img-container">
            <img
              id="img"
              class="image-crop"
              ref="img"
              width="auto"
              height="80%"
              :src="objectUrl"
            />
          </div>
        </div>
      </div>
      <div class="columns is-centered is-vcentered">
        <div class="column is-6">
          <b-field
            class="file is-twitter"
            :class="{ 'has-name': !!selectedFile }"
          >
            <b-upload
              rounded
              accept="image/png, image/jpeg"
              v-model="selectedFile"
              class="file-label"
              @input="setupCropper(selectedFile)"
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label"> Escolha a imagem </span>
              </span>
              <span class="file-name" v-if="selectedFile">
                {{ selectedFile.name }}
              </span>
            </b-upload>
          </b-field>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-danger"
        rounded
        icon-left="arrow-left"
        @click="$parent.close()"
      >
        <strong>Fechar</strong>
      </b-button>
      <b-button
        type="is-primary"
        rounded
        icon-left="upload"
        @click="linkUploadAvatar"
      >
        <strong>Enviar</strong>
      </b-button>
    </footer>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import store from "@/store/store";
import { obterLinkUploadAvatar } from "@/service/bluealApi/repository/user/UserRepository";

export default {
  name: "ImageProfile",
  data() {
    return {
      objectUrl: null,
      cropper: null,
      previewCropped: null,
      selectedFile: null,
    };
  },
  methods: {
    getRoundedCanvas(sourceCanvas) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const width = sourceCanvas.width;
      const height = sourceCanvas.height;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = "destination-in";
      context.beginPath();
      context.arc(
        width / 2,
        height / 2,
        Math.min(width, height) / 2,
        0,
        2 * Math.PI,
        true
      );
      context.fill();
      return canvas;
    },
    setupCropper(selectedFile) {
      if (this.cropper) {
        this.cropper.destroy();
      }

      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl);
      }

      if (!selectedFile) {
        this.cropper = null;
        this.objectUrl = null;
        this.previewCropped = null;
        return;
      }

      this.objectUrl = window.URL.createObjectURL(selectedFile);
      this.$nextTick(this.setupCropperInstance);
    },
    setupCropperInstance() {
      this.cropper = new Cropper(this.$refs.img, {
        aspectRatio: 1,
        viewMode: 1,
      });
    },
    async linkUploadAvatar() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const codigoUsuario = store.getters.doneDadosUsuario.codigoUsuario;
        const body = {
          extAvatar: this.selectedFile.name.replace(/^.*\./, ""),
        };
        const response = await obterLinkUploadAvatar(codigoUsuario, body);
        loadingComponent.close();
        this.enviarArquivos(response.data.imagemLinkUpload);
      } catch (error) {
        console.error(error);
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao buscar link upload",
          message: error.response
            ? error.response.data.mensagem
            : "Sem comunicação com serviços.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async enviarArquivos(linkUpload) {
      const croppedCanvas = this.cropper.getCroppedCanvas();
      const roundedCanvas = this.getRoundedCanvas(croppedCanvas);
      const loadingComponent = this.$buefy.loading.open();
      try {
        if (this.linkUpload !== null) {
          const uploadImagem = new XMLHttpRequest();
          uploadImagem.open("PUT", linkUpload, true);
          uploadImagem.setRequestHeader(
            "Content-Type",
            this.selectedFile.name.replace(/^.*\./, "")
          );
          uploadImagem.onload = () => {
            loadingComponent.close();
            this.$parent.close();
          };
          uploadImagem.onerror = () => {
            loadingComponent.close();
            this.errorUpload();
          };
          roundedCanvas.toBlob((blob) => {
            uploadImagem.send(blob);
          });
        } else {
          loadingComponent.close();
        }
      } catch (e) {
        this.errorUpload();
      }
    },
    errorUpload() {
      const loadingComponent = this.$buefy.loading.open();
      loadingComponent.close();
      this.$buefy.dialog.alert({
        title: "Erro",
        message: "Erro ao enviar arquivo",
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
  },
};
</script>

<style scoped>
.div-container {
  height: 90%;
  width: 90%;
}

.image-container {
  display: inline-block;
  height: 90%;
  width: 90%;
}

.image-crop {
  display: block;
  max-width: 100%;
}
</style>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style>
@import "~cropperjs/dist/cropper.min.css";

.cropper-view-box {
  border-radius: 50% !important;
}

.cropper-face {
  border-radius: 50% !important;
}
</style>
