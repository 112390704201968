
import Vue from "vue";
import SubMenuItem from "./SubMenuItem.vue";
import { Menu } from "@/views/menu/MenuSide.vue";

export default Vue.extend({
  components: { SubMenuItem },
  name: "MenuItem",
  props: {
    collapsed: { type: Boolean, required: true },
    isExpandable: { type: Boolean, required: false, default: false },
    menu: { type: Object as () => Menu, required: true },
    profile: { type: String, required: true },
  },
  data() {
    return {
      expanded: false,
    };
  },
});
