import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/pacientes";
const resourceSingle = "/paciente";

export default {
  buscaPacientes() {
    return Repository().get(`${resource}`);
  },
  incluirPaciente(body) {
    return Repository().post(`${resourceSingle}`, body);
  },
};
