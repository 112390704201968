<template>
  <article class="panel is-light m-0">
    <p class="panel-heading">Solicitações de Planejamentos</p>
    <div class="panel-block painel-meus-planejamentos columns">
      <div class="column is-12">
        <div class="columns">
          <div class="column is-12">
            <b-tabs type="is-toggle" :expanded="true">
              <b-tab-item>
                <template slot="header">
                  <b-icon icon="file-signature"></b-icon>
                  <span class="is-size-7 has-text-weight-semibold">
                    Em Elaboração
                    <b-tag
                      v-if="
                        listaSolicitacoesProjetosEmAndamentoLoading === true
                      "
                      rounded
                    >
                      <b-skeleton circle width="8px"></b-skeleton>
                    </b-tag>
                    <b-tag
                      v-if="
                        listaSolicitacoesProjetosEmAndamentoLoading === false
                      "
                      rounded
                      >{{ qtdListaSolicitacoesEmAndamento }}</b-tag
                    >
                  </span>
                </template>
                <tabela-solicitacoes
                  v-if="listaSolicitacoesProjetosEmAndamentoLoading === false"
                  :lista-solicitacoes="listaSolicitacoesProjetosEmAndamento"
                  :tipo-tabela="emAndamento"
                  :qtd-paginas="qtdPaginasEmAndamento"
                  @efetuarRechamada="efetuarRechamada($event)"
                  @atualizacaoProjeto="atualizarLista"
                />
                <div
                  class="column is-12"
                  v-if="listaSolicitacoesProjetosEmAndamentoLoading === true"
                >
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                </div>
              </b-tab-item>
              <b-tab-item>
                <template slot="header">
                  <b-icon icon="question-circle"></b-icon>
                  <span class="is-size-7 has-text-weight-semibold">
                    Pendentes Aprovação
                    <b-tag
                      v-if="
                        listaSolicitacoesProjetosFinzalizadosLoading === true
                      "
                      rounded
                    >
                      <b-skeleton circle width="8px"></b-skeleton>
                    </b-tag>
                    <b-tag
                      v-if="
                        listaSolicitacoesProjetosFinzalizadosLoading === false
                      "
                      rounded
                      >{{ listaSolicitacoesProjetoFinalizados.length }}</b-tag
                    >
                  </span>
                </template>
                <tabela-solicitacoes
                  v-if="listaSolicitacoesProjetosFinzalizadosLoading === false"
                  :lista-solicitacoes="listaSolicitacoesProjetoFinalizados"
                  :tipo-tabela="projetosFinalizados"
                  :qtd-paginas="qtdPaginasProjetosFinalizados"
                  @efetuarRechamada="efetuarRechamada($event)"
                  @atualizacaoProjeto="atualizarLista"
                />
                <div
                  class="column is-12"
                  v-if="listaSolicitacoesProjetosFinzalizadosLoading === true"
                >
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                </div>
              </b-tab-item>
              <b-tab-item>
                <template slot="header">
                  <b-icon icon="exclamation-circle"></b-icon>
                  <span class="is-size-7 has-text-weight-semibold">
                    Recusados
                    <b-tag
                      v-if="listaSolicitacoesProjetosRecusadosLoading === true"
                      rounded
                    >
                      <b-skeleton circle width="8px"></b-skeleton>
                    </b-tag>
                    <b-tag
                      v-if="listaSolicitacoesProjetosRecusadosLoading === false"
                      rounded
                      >{{ listaSolicitacoesProjetosRecusados.length }}</b-tag
                    >
                  </span>
                </template>
                <tabela-solicitacoes
                  v-if="listaSolicitacoesProjetosRecusadosLoading === false"
                  :lista-solicitacoes="listaSolicitacoesProjetosRecusados"
                  :tipo-tabela="projetosRecusados"
                  :qtd-paginas="qtdPaginasProjetosRecusados"
                  @efetuarRechamada="efetuarRechamada($event)"
                  @atualizacaoProjeto="atualizarLista"
                />
                <div
                  class="column is-12"
                  v-if="listaSolicitacoesProjetosRecusadosLoading === true"
                >
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                </div>
              </b-tab-item>
              <b-tab-item>
                <template slot="header">
                  <b-icon icon="check-circle"></b-icon>
                  <span class="is-size-7 has-text-weight-semibold">
                    Aprovados
                    <b-tag
                      v-if="listaSolicitacoesProjetosAceitosLoading === true"
                      rounded
                    >
                      <b-skeleton circle width="8px"></b-skeleton>
                    </b-tag>
                    <b-tag
                      v-if="listaSolicitacoesProjetosAceitosLoading === false"
                      rounded
                      >{{ listaSolicitacoesProjetosAceitos.length }}</b-tag
                    >
                  </span>
                </template>
                <tabela-solicitacoes
                  v-if="listaSolicitacoesProjetosAceitosLoading === false"
                  :lista-solicitacoes="listaSolicitacoesProjetosAceitos"
                  :tipo-tabela="projetosRecusados"
                  :qtd-paginas="qtdPaginasProjetosRecusados"
                  @efetuarRechamada="efetuarRechamada($event)"
                  @atualizacaoProjeto="atualizarLista"
                />
                <div
                  class="column is-12"
                  v-if="listaSolicitacoesProjetosAceitosLoading === true"
                >
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                </div>
              </b-tab-item>
              <b-tab-item>
                <template slot="header">
                  <div
                    @click="
                      !listaSolicitacoesTodasLoading
                        ? iniciarListaTodasSolicitacoes()
                        : null
                    "
                  >
                    <b-icon icon="list-ul"></b-icon>
                    <span class="is-size-7 has-text-weight-semibold">
                      Todas
                      <b-tag rounded>{{
                        qtdListaSolicitacoes > 0
                          ? qtdListaSolicitacoes
                          : "Listar"
                      }}</b-tag>
                    </span>
                  </div>
                </template>
                <tabela-solicitacoes
                  v-if="listaSolicitacoesTodasLoading === false"
                  :lista-solicitacoes="listaSolicitacoes"
                  :tipo-tabela="todasSolicitacoes"
                  :qtd-paginas="qtdPaginasTodas"
                  @efetuarRechamada="efetuarRechamada($event)"
                  @atualizacaoProjeto="atualizarLista"
                />
                <div
                  class="column is-12"
                  v-if="listaSolicitacoesTodasLoading === true"
                >
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton height="2.5rem"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                  <b-skeleton size="is-medium"></b-skeleton>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import TabelaSolicitacoes from "./TabelaSolicitacoes";
import solicitacaoProjetoListaV2Repository from "@/repository/solicitacaoProjetoListaV2Repository";

export default {
  name: "GerenciarSolicitacoes",
  components: {
    TabelaSolicitacoes,
  },
  data() {
    return {
      todasSolicitacoes: "todasSolicitacoes",
      emAndamento: "emAndamento",
      projetosFinalizados: "projetosFinalizados",
      projetosRecusados: "projetosRecusados",
      projetosAceitos: "projetosAceitos",
      qtdPaginasTodas: 1,
      qtdPaginasEmAndamento: 1,
      qtdPaginasProjetosFinalizados: 1,
      qtdPaginasProjetosRecusados: 1,
      qtdPaginasProjetosAceitos: 1,
      listaSolicitacoesTodasLoading: false,
      listaSolicitacoes: [],
      qtdListaSolicitacoes: 0,
      listaSolicitacoesProjetosEmAndamento: [],
      listaSolicitacoesProjetosEmAndamentoLoading: false,
      qtdListaSolicitacoesEmAndamento: 0,
      listaSolicitacoesProjetoFinalizados: [],
      listaSolicitacoesProjetosFinzalizadosLoading: false,
      qtdListaSolicitacoesProjetoFinalizados: 0,
      listaSolicitacoesProjetosRecusados: [],
      listaSolicitacoesProjetosRecusadosLoading: false,
      qtdListaSolicitacoesProjetosRecusados: 0,
      listaSolicitacoesProjetosAceitos: [],
      listaSolicitacoesProjetosAceitosLoading: false,
      qtdListaSolicitacoesProjetosAceitos: 0,
      solicitacaoSelecionada: null,
      //configuracoes table
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
    };
  },
  beforeMount() {
    this.listarSolicitacoesEmAndamento(1);
    this.listarSolicitacoesProjetosFinalizados(1);
    this.listarSolicitacoesProjetosRecusados(1);
    this.listarSolicitacoesProjetosAceitos(1);
  },
  methods: {
    efetuarRechamada(data) {
      switch (data.tipoTabela) {
        case this.todasSolicitacoes:
          this.listarSolicitacoes(data.pagina);
          break;
        case this.emAndamento:
          this.listarSolicitacoesEmAndamento(data.pagina);
          break;
        case this.projetosFinalizados:
          this.listarSolicitacoesProjetosFinalizados(data.pagina);
          break;
        case this.projetosRecusados:
          this.listarSolicitacoesProjetosRecusados(data.pagina);
          break;
        case this.projetosAceitos:
          this.listarSolicitacoesProjetosAceitos(data.pagina);
          break;
        default:
          break;
      }
    },
    atualizarLista() {
      this.qtdPaginasEmAndamento = 1;
      this.qtdPaginasProjetosFinalizados = 1;
      this.qtdPaginasProjetosRecusados = 1;
      this.qtdPaginasProjetosAceitos = 1;

      this.listaSolicitacoesProjetosEmAndamento = [];
      this.listaSolicitacoesPendentesAceite = [];
      this.listaSolicitacoesProjetoFinalizados = [];
      this.listaSolicitacoesProjetosRecusados = [];

      this.qtdListaSolicitacoesEmAndamento = 0;
      this.qtdListaSolicitacoesPendenteAceite = 0;
      this.qtdListaSolicitacoesProjetoFinalizados = 0;
      this.qtdListaSolicitacoesProjetosRecusados = 0;

      this.listarSolicitacoesEmAndamento(1);
      this.listarSolicitacoesProjetosFinalizados(1);
      this.listarSolicitacoesProjetosRecusados(1);
      this.listarSolicitacoesProjetosAceitos(1);
      this.iniciarListaTodasSolicitacoes(1);
    },
    iniciarListaTodasSolicitacoes() {
      this.listaSolicitacoes = [];
      this.qtdListaSolicitacoes = 0;
      this.qtdPaginasTodas = 1;
      this.listarSolicitacoes(1);
    },
    async listarSolicitacoes(page) {
      this.listaSolicitacoesTodasLoading = true;
      try {
        const response =
          await solicitacaoProjetoListaV2Repository.listarSolicitacoes(page);
        this.listaSolicitacoes = this.listaSolicitacoes.concat(
          response.data["hydra:member"]
        );
        this.qtdListaSolicitacoes = response.data["hydra:totalItems"];
        this.qtdPaginasTodas = page;
        this.listaSolicitacoesTodasLoading = false;
      } catch (e) {
        this.listaSolicitacoesTodasLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async listarSolicitacoesEmAndamento(page) {
      this.listaSolicitacoesProjetosEmAndamentoLoading = true;
      try {
        const response =
          await solicitacaoProjetoListaV2Repository.listarSolicitacoesFiltro(
            30,
            page
          );
        this.listaSolicitacoesProjetosEmAndamento =
          response.data["hydra:member"];
        this.qtdListaSolicitacoesEmAndamento =
          response.data["hydra:totalItems"];
        this.listaSolicitacoesProjetosEmAndamentoLoading = false;
        this.qtdPaginasEmAndamento = page;
      } catch (e) {
        this.listaSolicitacoesProjetosEmAndamentoLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async listarSolicitacoesProjetosFinalizados(page) {
      this.listaSolicitacoesProjetosFinzalizadosLoading = true;
      try {
        const response =
          await solicitacaoProjetoListaV2Repository.listarSolicitacoesFiltro(
            40,
            page
          );
        this.listaSolicitacoesProjetoFinalizados =
          response.data["hydra:member"];
        this.qtdListaSolicitacoesProjetoFinalizados =
          response.data["hydra:totalItems"];
        this.listaSolicitacoesProjetosFinzalizadosLoading = false;
        this.qtdPaginasProjetosFinalizados = page;
      } catch (e) {
        this.listaSolicitacoesProjetosFinzalizadosLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async listarSolicitacoesProjetosRecusados(page) {
      this.listaSolicitacoesProjetosRecusadosLoading = true;
      try {
        const response =
          await solicitacaoProjetoListaV2Repository.listarSolicitacoesFiltro(
            50,
            page
          );
        this.listaSolicitacoesProjetosRecusados = response.data["hydra:member"];
        this.qtdListaSolicitacoesProjetosRecusados =
          response.data["hydra:totalItems"];
        this.listaSolicitacoesProjetosRecusadosLoading = false;
        this.qtdPaginasProjetosRecusados = page;
      } catch (e) {
        this.listaSolicitacoesProjetosRecusadosLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async listarSolicitacoesProjetosAceitos(page) {
      this.listaSolicitacoesProjetosAceitosLoading = true;
      try {
        const response =
          await solicitacaoProjetoListaV2Repository.listarSolicitacoesFiltro(
            60,
            page
          );
        this.listaSolicitacoesProjetosAceitos = response.data["hydra:member"];
        this.qtdListaSolicitacoesProjetosAceitos =
          response.data["hydra:totalItems"];
        this.listaSolicitacoesProjetosAceitosLoading = false;
        this.qtdPaginasProjetosAceitos = page;
      } catch (e) {
        this.listaSolicitacoesProjetosAceitosLoading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.painel-meus-planejamentos {
  padding: 0.5rem;
}
</style>
