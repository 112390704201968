<template>
  <form ref="formPaciente" class="needs-validation" novalidate>
    <div class="columns" id="dados-paciente">
      <div class="column is-5">
        <b-field label="Nome" :label-position="labelPosition">
          <b-input
            placeholder="Nome completo"
            minlength="10"
            maxlength="255"
            required
            rounded
            validation-message="Campo deve possuir no mínimo 10 caracteres"
            v-model="nome"
          >
          </b-input>
        </b-field>
      </div>
      <div class="column is-5">
        <b-field label="Data Nascimento" :label-position="labelPosition">
          <b-input
            placeholder=""
            type="date"
            minlength="10"
            maxlength="10"
            rounded
            validation-message="Data inválida"
            v-model="dataNascimento"
          >
          </b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-button
          type="is-primary"
          rounded
          icon-left="user-plus"
          v-on:click="adicionarPaciente()"
        >
          Adicionar
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "AdicionarPaciente",
  data() {
    return {
      codigoPaciente: "",
      nome: "",
      dataNascimento: "",
      labelPosition: "on-border",
    };
  },
  methods: {
    adicionarPaciente: function () {
      if (this.$refs.formPaciente.checkValidity()) {
        const loadingComponent = this.$buefy.loading.open();

        let formDadosIncluirPaciente = new FormData();

        formDadosIncluirPaciente.append("nome", this.nome);
        formDadosIncluirPaciente.append("dataNascimento", this.dataNascimento);

        this.$http
          .post("/api/paciente/incluir_paciente", formDadosIncluirPaciente)
          .then((response) => {
            loadingComponent.close();

            var dadosPaciente = {};

            dadosPaciente.nome = response.data.nomePaciente;
            dadosPaciente.codigoPaciente = response.data.codigoPaciente;
            dadosPaciente.dataNascimento = response.data.dataNacimento;

            this.$emit("aplicarPaciente", dadosPaciente);

            this.$buefy.dialog.alert({
              title: "Sucesso",
              message: "Paciente incluído com sucesso",
              type: "is-success",
              hasIcon: true,
              icon: "check-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
          })
          .catch((error) => {
            loadingComponent.close();
            this.$buefy.dialog.alert({
              title: "Erro",
              message:
                "Erro no cadastro do paciente, tente novamente.\n" + error.data,
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
          });
      } else {
        this.$buefy.toast.open({
          message: `Verificar os dados informados do paciente`,
          position: "is-top",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
