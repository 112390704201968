<template>
  <section>
    <article class="panel is-twitter">
      <p class="panel-heading">Projetos X Pedido Alinhadores</p>
      <div class="panel-block">
        <div class="columns is-centered">
          <div
            id="chartProjetos"
            ref="chartProjetos"
            class="column is-12"
          ></div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import ApexCharts from "apexcharts";
import projetoTotalAlinhdaoresRespositoryV2 from "@/repository/projetoTotalAlinhdaoresRespositoryV2";

export default {
  name: "ProjetosDashboard",
  props: {
    open: Boolean,
  },
  mounted() {
    this.buscarResumoProjetos();
  },
  methods: {
    async buscarResumoProjetos() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const response =
          await projetoTotalAlinhdaoresRespositoryV2.listarResumo();
        this.processarListaProjetos(response.data["hydra:member"]);
        loadingComponent.close();
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao buscar resumo de solicitações",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    processarListaProjetos(lista) {
      const data = [lista.length, 0, 0];
      const categories = ["Total Aptos", "Sem Pedido", "Com 'Estoque'"];
      lista.forEach((item) => {
        if (item.totalAlinhadoresSolicitados === 0) {
          data[1] = data[1] + 1;
        } else if (item.totalAlinhadoresSolicitados < item.totalAlinhadores) {
          data[2] = data[2] + 1;
        }
      });
      this.renderChartProjetos(data, categories);
    },
    renderChartProjetos(data, categories) {
      var options = {
        chart: {
          type: "bar",
          height: "215rem",
          width: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        series: [
          {
            name: "Projetos",
            data: data,
          },
        ],
        xaxis: {
          categories: categories,
        },
      };
      var chart = new ApexCharts(this.$refs.chartProjetos, options);

      chart.render();
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.text-content {
  text-align: justify;
  text-justify: inter-word;
}
</style>
