import axios, { AxiosInstance } from "axios";

const bluealApiclientLogin = (): AxiosInstance => {
  const baseUrl = `${process.env.VUE_APP_BLUE_ALIGNER_API}`;
  return axios.create({
    baseURL: baseUrl,
    withCredentials: true,
  });
};

export default bluealApiclientLogin;
