<template>
  <section>
    <article class="panel is-twitter">
      <p class="panel-heading">Pedido Alinhadores</p>
      <div class="panel-block">
        <div class="columns is-centered">
          <div id="chartPedidos" ref="chartPedidos" class="column is-12"></div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import ApexCharts from "apexcharts";
import pedidoAlinhadoresResumoEstadoRespositoryV2 from "@/repository/pedidoAlinhadoresResumoEstadoRespositoryV2";

export default {
  name: "PedidosDashboard",
  props: {
    open: Boolean,
  },
  mounted() {
    this.buscarResumoPedidoAlinhadores();
  },
  methods: {
    async buscarResumoPedidoAlinhadores() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const response =
          await pedidoAlinhadoresResumoEstadoRespositoryV2.listarResumo();
        this.processarListaPedidos(response.data["hydra:member"]);
        loadingComponent.close();
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao buscar resumo de solicitações",
          message: error.response
            ? error.response.data["hydra:description"]
            : error.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    processarListaPedidos(lista) {
      const data = [];
      const categories = [];
      lista.forEach((item) => {
        data.push(item.quantidade);
        categories.push(item.descricao);
      });
      this.renderChartPedidos(data, categories);
    },
    renderChartPedidos(data, categories) {
      var options = {
        chart: {
          type: "bar",
          height: "215rem",
          width: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        series: [
          {
            name: "Pedidos",
            data: data,
          },
        ],
        xaxis: {
          categories: categories,
        },
      };
      var chart = new ApexCharts(this.$refs.chartPedidos, options);

      chart.render();
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.text-content {
  text-align: justify;
  text-justify: inter-word;
}
</style>
