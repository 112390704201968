<template>
  <section class="section p-0">
    <article class="panel is-light m-0">
      <p class="panel-heading">Solicitar Planejamento</p>
      <div
        class="panel-block columns painel-solicitar-planejamento"
        v-if="estadoUsuario !== '100'"
      >
        <b-steps v-model="activeStep">
          <b-step-item icon="user-cog">
            <div class="body-step">
              <DadosPacienteSolicitacaoV2
                ref="refDadosPaciente"
                @desabilitarProximoPasso="desabilitarProximoPasso"
                @habilitarProximoPasso="habilitarProximoPasso"
                @proximoPasso="proximoPasso($event)"
                @isentarCobranca="isentarCobranca($event)"
                :codigo-solicitacao="codigoSolicitacao"
              />
            </div>
          </b-step-item>
          <b-step-item icon="tooth">
            <div class="body-step">
              <DadosTratamentoV2
                ref="refDadosTratamento"
                :codigo-solicitacao="codigoSolicitacao"
                @desabilitarProximoPasso="desabilitarProximoPasso"
                @habilitarProximoPasso="habilitarProximoPasso"
                @proximoPasso="proximoPasso($event)"
              />
            </div>
          </b-step-item>
          <b-step-item icon="plus-square">
            <div class="body-step">
              <DadosInfoAdicionaisV2
                ref="refDadosInfoAdicionais"
                :codigo-solicitacao="codigoSolicitacao"
                @desabilitarProximoPasso="desabilitarProximoPasso"
                @habilitarProximoPasso="habilitarProximoPasso"
                @proximoPasso="proximoPasso($event)"
              />
            </div>
          </b-step-item>
          <b-step-item icon="file-upload">
            <div class="body-step">
              <DadosArquivosV2
                ref="refDadosArquivos"
                :codigo-solicitacao="codigoSolicitacao"
                :possui-arquivos="possuiArquivos"
                :possui-imagens="possuiImagens"
                @desabilitarProximoPasso="desabilitarProximoPasso"
                @habilitarProximoPasso="habilitarProximoPasso"
                @proximoPasso="proximoPasso($event)"
                @envioArquivoUnicoFinalizado="envioArquivoUnicoFinalizado"
                @envioImagensFinalizado="envioImagensFinalizado"
                @envioModelosFinalizado="envioModelosFinalizado"
                @exibirUploadArquivoUnico="exibirUploadArquivoUnico"
                @exibirUploadImagens="exibirUploadImagens"
                @exibirUploadModelos="exibirUploadModelos($event)"
                @habilitarEnvioArquivo="habilitarEnvioArquivo"
                @desabilitarEnvioArquivo="desabilitarEnvioArquivo"
              />
            </div>
          </b-step-item>
          <b-step-item icon="file-invoice-dollar">
            <div class="body-step">
              <DadosCobrancaV2
                v-if="activeStep === 4"
                ref="refDadosCobranca"
                :codigo-solicitacao="codigoSolicitacao"
                :isento-cobranca="isentoCobranca"
                @desabilitarFinalizar="desabilitarFinalizar"
                @habilitarFinalizar="habilitarFinalizar"
              />
            </div>
          </b-step-item>
          <template slot="navigation" slot-scope="{ previous }">
            <div class="div-botao-avancar">
              <b-button
                type="is-link is-light"
                icon-pack="fas"
                icon-left="arrow-circle-left"
                rounded
                :disabled="previous.disabled"
                @click.prevent="previous.action"
                ><strong>Anterior</strong></b-button
              >
              <b-button
                v-if="activeStep < 3"
                type="is-primary"
                rounded
                icon-pack="fas"
                icon-right="arrow-circle-right"
                :disabled="proximoDisable"
                @click.prevent="processarPassoAtual"
                ><strong>Próximo</strong></b-button
              >
              <b-button
                v-if="activeStep == 3 && uploadArquivoUnico"
                type="is-warning"
                icon-pack="fas"
                rounded
                icon-left="file-upload"
                :disabled="uploadArquivoDisable"
                @click.prevent="enviarArquivoUnico"
                ><strong>Enviar Arquivo</strong></b-button
              >
              <b-button
                v-if="activeStep == 3 && uploadImagens"
                type="is-warning"
                icon-pack="fas"
                rounded
                icon-left="file-upload"
                :disabled="uploadArquivoDisable"
                @click.prevent="enviarImagens"
                ><strong>Enviar Imagens</strong></b-button
              >
              <b-button
                v-if="activeStep == 3 && uploadModelos"
                type="is-warning"
                icon-pack="fas"
                rounded
                icon-left="file-upload"
                :disabled="uploadArquivoDisable"
                @click.prevent="enviarModelos"
                ><strong>Enviar Modelos</strong></b-button
              >
              <b-button
                v-if="
                  activeStep === 3 &&
                  uploadImagens === false &&
                  uploadModelos === false &&
                  uploadArquivoUnico === false
                "
                type="is-primary"
                icon-pack="fas"
                rounded
                :disabled="true"
                icon-left="check"
                @click.prevent="processarPassoAtual"
                ><strong>Próximo</strong></b-button
              >
              <b-button
                v-if="activeStep === 4"
                type="is-primary"
                icon-pack="fas"
                rounded
                :disabled="finalizarDisable"
                icon-left="check"
                @click.prevent="processarPassoAtual"
                ><strong>Finalizar</strong></b-button
              >
            </div>
          </template>
        </b-steps>
      </div>
      <div class="panel-block columns" v-if="estadoUsuario === '100'">
        <b-message type="is-warning" has-icon
          >Para utilizar o sistema é necessário concluir seu cadastro. Acesse o
          menu superior, canto direito, Nome do Usuário -> Meus
          Dados.</b-message
        >
      </div>
    </article>
  </section>
</template>

<script>
import solicitaProjetoRepoV2 from "../../repository/solicitacaoProjetoV2Repository";
import DadosPacienteSolicitacaoV2 from "./dados-paciente-v2/DadosPacienteSolicitacaoV2";
import DadosTratamentoV2 from "./dados-tratamento-v2/DadosTratamentoV2";
import DadosInfoAdicionaisV2 from "./dados-info-adicionais-v2/DadosInfoAdicionaisV2";
import DadosArquivosV2 from "./dados-arquivos-v2/DadosArquivosV2";
import DadosCobrancaV2 from "./dados-cobranca-v2/DadosCobrancaV2";
import ModalSolicitacoesIniciadas from "./ModalSolicitacoesIniciadasV2";

export default {
  name: "SolicitarProjetoV2",
  props: {
    estadoUsuario: String,
  },
  components: {
    DadosPacienteSolicitacaoV2,
    DadosTratamentoV2,
    DadosInfoAdicionaisV2,
    DadosArquivosV2,
    DadosCobrancaV2,
  },
  data() {
    return {
      solicitacao: null,
      codigoSolicitacao: "0",
      isentoCobranca: "N",
      possuiArquivos: false,
      possuiImagens: false,
      activeStep: 0,
      finalizarDisable: true,
      proximoDisable: false,
      uploadImagens: false,
      uploadModelos: false,
      uploadArquivoUnico: false,
      uploadArquivoDisable: true,
    };
  },
  beforeMount() {
    if (
      this.$store.getters.doneDadosUsuario.usuarioEstado.codigoEstadoUsuario ===
      100
    ) {
      this.$router.push({ name: "AtualizarCadastroUsuario" });
    } else {
      this.buscarSolicitacoesIniciadas();
    }
  },
  methods: {
    async buscarSolicitacoesIniciadas() {
      const loadingComponent = this.$buefy.loading.open();

      var filtro = "10";

      try {
        const response = await solicitaProjetoRepoV2.listarSolicitacoesFiltro(
          filtro
        );
        var listaProjetosIniciados = response.data["hydra:member"];
        if (listaProjetosIniciados.length > 0) {
          this.abrirModalProjetosIniciados(listaProjetosIniciados);
        }
        loadingComponent.close();
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    abrirModalProjetosIniciados(listaProjetosIniciados) {
      this.$buefy.modal.open({
        props: {
          listaSolicitacoes: listaProjetosIniciados,
        },
        parent: this,
        hasModalCard: true,
        fullScreen: false,
        component: ModalSolicitacoesIniciadas,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        canCancel: false,
        events: {
          solicitacaoSelecionada: (solicitacaoSelecionada) => {
            this.contextualizarSolicitacao(solicitacaoSelecionada);
          },
        },
      });
    },
    processarPassoAtual() {
      switch (this.activeStep) {
        case 0:
          this.$refs.refDadosPaciente.validarDados();
          break;
        case 1:
          this.$refs.refDadosTratamento.validarDados();
          break;
        case 2:
          this.$refs.refDadosInfoAdicionais.validarDados();
          break;
        case 3:
          this.$refs.refDadosArquivos.validarDados();
          break;
        case 4:
          this.$refs.refDadosCobranca.gerarCobranca();
          break;
        default:
          break;
      }
    },
    habilitarFinalizar() {
      this.finalizarDisable = false;
    },
    desabilitarFinalizar() {
      this.finalizarDisable = true;
    },
    desabilitarProximoPasso() {
      this.proximoDisable = true;
    },
    habilitarEnvioArquivo() {
      this.uploadArquivoDisable = false;
    },
    desabilitarEnvioArquivo() {
      this.uploadArquivoDisable = true;
    },
    habilitarProximoPasso() {
      this.proximoDisable = false;
    },
    proximoPasso(codigoSolicitacao) {
      this.codigoSolicitacao = codigoSolicitacao;
      if (this.activeStep < 4) {
        this.activeStep += 1;
        if (this.activeStep === 3) {
          this.desabilitarEnvioArquivo();
        }
      }
      this.reiniciarEnvioArquivo();
    },
    isentarCobranca(isentoCobranca) {
      this.isentoCobranca = isentoCobranca;
    },
    exibirUploadImagens() {
      this.uploadImagens = true;
    },
    exibirUploadModelos() {
      this.uploadModelos = true;
    },
    exibirUploadArquivoUnico(exibir) {
      this.uploadArquivoUnico = exibir;
    },
    enviarArquivoUnico() {
      this.$refs.refDadosArquivos.enviarArquivoUnico();
    },
    enviarImagens() {
      this.$refs.refDadosArquivos.enviarImagens();
    },
    envioArquivoUnicoFinalizado() {
      setTimeout(() => this.$emit((this.activeStep = 4)), 3000);
    },
    envioImagensFinalizado() {
      this.uploadImagens = false;
    },
    enviarModelos() {
      this.$refs.refDadosArquivos.enviarModelos();
    },
    envioModelosFinalizado() {
      this.uploadModelos = false;
      this.activeStep = 4;
    },
    reiniciarEnvioArquivo() {
      this.$refs.refDadosArquivos.reiniciarEnvioArquivo();
    },
    contextualizarSolicitacao(solicitacaoSelecionada) {
      if (solicitacaoSelecionada !== null) {
        this.solicitacao = solicitacaoSelecionada;
        this.codigoSolicitacao = solicitacaoSelecionada.codigoSolicitacao;
        this.isentoCobranca = solicitacaoSelecionada.isentoCobranca;
        this.verificarArquivos(solicitacaoSelecionada);
        this.verificarImagens(solicitacaoSelecionada);
        this.$refs.refDadosPaciente.contextualizarDadosPaciente(
          this.solicitacao.paciente,
          this.solicitacao.queixaPaciente,
          this.solicitacao.objetivoAlinhadores,
          this.solicitacao.implantesDescricao
        );
        this.$refs.refDadosTratamento.contextualizarDadosTratamento(
          this.solicitacao.arcadaMovimentacao,
          this.solicitacao.desgasteInterProximal,
          this.solicitacao.relacaoMolar,
          this.solicitacao.linhaSuperiorMedia,
          this.solicitacao.linhaSuperiorDirecao,
          this.solicitacao.linhaSuperiorMediaMovimento,
          this.solicitacao.linhaInferiorMedia,
          this.solicitacao.linhaInferiorDirecao,
          this.solicitacao.linhaInferiorMovimento
        );
        this.$refs.refDadosInfoAdicionais.contextualizarDadosDescritivos(
          this.solicitacao.movimentoDentes,
          this.solicitacao.attachmentDentes,
          this.solicitacao.tempoTratamento,
          this.solicitacao.observacao
        );
      }
    },
    verificarArquivos(solicitacao) {
      if (solicitacao.codigoArquivoUnicoImagemModelos !== null) {
        this.possuiArquivos = true;
      }

      if (solicitacao.codigoArquivoUnicoModelos !== null) {
        this.possuiArquivos = true;
      }

      if (
        solicitacao.codigoStlSuperior !== null &&
        solicitacao.codigoStlInferior !== null
      ) {
        this.possuiArquivos = true;
      }
    },
    verificarImagens(solicitacao) {
      if (solicitacao.codigoArquivoUnicoImagemModelos !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoFrontal !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoSorrindo !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoIntrabucalFrontal !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoIntrabucalDireita !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoIntrabucalEsquerda !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoOclusaoSuperior !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoRxPan !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoTeleRadiografia !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoLateral !== null) {
        this.possuiImagens = true;
      } else if (solicitacao.codigoFotoOclusaoInferior !== null) {
        this.possuiImagens = true;
      }
    },
  },
};
</script>

<style scoped>
.painel-solicitar-planejamento {
  padding-top: 2rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
}

.div-botao-avancar {
  padding: 2rem;
  display: flex;
  flex-direction: row;
}

.div-botao-voltar {
  text-align: left;
}

.body-step {
  padding-left: 2rem;
  min-width: 1200px;
  height: 400px;
}
</style>
