<template>
  <div>
    <div class="columns mb-1">
      <div class="column is-12">
        <p class="title is-5">Dados do tratamento</p>
      </div>
    </div>
    <hr class="mt-0" />
    <div v-if="envioDadosIniciado === false">
      <div class="columns">
        <div class="column is-2">
          <p class="title is-7">Algum dente não deve ser movimentado?</p>
          <b-field>
            <b-radio-button
              v-model="denteMovimentoCheck"
              size="is-small"
              native-value="Não"
              required
              type="is-primary"
              @input="dentesNaoMovimentaveis = ''"
            >
              <b-icon icon="times-circle"> </b-icon>
              <span>Não</span>
            </b-radio-button>

            <b-radio-button
              v-model="denteMovimentoCheck"
              size="is-small"
              native-value="Sim"
              required
              type="is-primary"
              @input="dentesNaoMovimentaveis = ''"
            >
              <b-icon icon="check-circle"></b-icon>
              <span>Sim</span>
            </b-radio-button>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label-position="inside">
            <b-input
              type="textarea"
              placeholder="Descreva"
              required
              rounded
              minle
              minlength="1"
              validation-message="Campo deve possuir no mínimo 1 caracteres"
              v-model="dentesNaoMovimentaveis"
              :disabled="denteMovimentoCheck !== 'Sim'"
            />
          </b-field>
        </div>
        <div class="column is-2 ml-6">
          <p class="title is-7">Deseja attachment de algum dente?</p>
          <b-field>
            <b-radio-button
              v-model="attachementDentesCheck"
              size="is-small"
              native-value="Não"
              required
              type="is-primary"
              @input="attachementDentes = ''"
            >
              <b-icon icon="times-circle"> </b-icon>
              <span>Não</span>
            </b-radio-button>

            <b-radio-button
              v-model="attachementDentesCheck"
              size="is-small"
              native-value="Sim"
              required
              type="is-primary"
              @input="attachementDentes = ''"
            >
              <b-icon icon="check-circle"></b-icon>
              <span>Sim</span>
            </b-radio-button>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label-position="inside">
            <b-input
              type="textarea"
              placeholder="Descreva"
              required
              rounded
              minlength="1"
              validation-message="Campo deve possuir no mínimo 1 caracteres"
              v-model="attachementDentes"
              :disabled="attachementDentesCheck !== 'Sim'"
            />
          </b-field>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column is-2">
          <p class="title is-7">
            Existe um tempo máximo, data limite para termino do tratamento?
          </p>
          <b-field>
            <b-radio-button
              v-model="tempoTratamentoCheck"
              size="is-small"
              native-value="Não"
              required
              type="is-primary"
              @input="tempoTratamento = ''"
            >
              <b-icon icon="times-circle"> </b-icon>
              <span>Não</span>
            </b-radio-button>

            <b-radio-button
              v-model="tempoTratamentoCheck"
              size="is-small"
              native-value="Sim"
              required
              type="is-primary"
              @input="tempoTratamento = ''"
            >
              <b-icon icon="check-circle"></b-icon>
              <span>Sim</span>
            </b-radio-button>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label-position="inside">
            <b-input
              type="textarea"
              placeholder="Descreva"
              rounded
              required
              minlength="1"
              maxlength="255"
              validation-message="Campo deve possuir no mínimo 1 caracteres"
              v-model="tempoTratamento"
              :disabled="tempoTratamentoCheck !== 'Sim'"
            />
          </b-field>
        </div>
        <div class="column is-2 ml-6">
          <p class="title is-7">Informações adicionais?</p>
          <b-field>
            <b-radio-button
              v-model="infoAdicionaisCheck"
              size="is-small"
              native-value="Não"
              required
              type="is-primary"
              @input="infoAdicionais = ''"
            >
              <b-icon icon="times-circle"> </b-icon>
              <span>Não</span>
            </b-radio-button>

            <b-radio-button
              v-model="infoAdicionaisCheck"
              size="is-small"
              native-value="Sim"
              required
              type="is-primary"
              @input="infoAdicionais = ''"
            >
              <b-icon icon="check-circle"></b-icon>
              <span>Sim</span>
            </b-radio-button>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label-position="inside">
            <b-input
              type="textarea"
              placeholder="Descreva"
              rounded
              v-model="infoAdicionais"
              minlength="1"
              maxlength="255"
              validation-message="Campo deve possuir no mínimo 1 caracteres"
              :disabled="infoAdicionaisCheck !== 'Sim'"
            />
          </b-field>
        </div>
      </div>
    </div>
    <div v-if="envioDadosIniciado === true">
      <div class="columns is-centered mt-6 pl-6">
        <div class="column is-6">
          <h6 class="title is-6">Atualizando Solicitação de Planejamento</h6>
          <b-progress size="is-large"> </b-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import solicitaProjetoRepoV2 from "../../../repository/solicitacaoProjetoV2Repository";

export default {
  name: "DadosInfoAdicionaisV2",
  props: {
    codigoSolicitacao: String,
  },
  data() {
    return {
      envioDadosIniciado: false,
      dentesNaoMovimentaveis: "",
      attachementDentes: "",
      tempoTratamento: "",
      infoAdicionais: "",
      denteMovimentoCheck: "",
      attachementDentesCheck: "",
      tempoTratamentoCheck: "",
      infoAdicionaisCheck: "",
    };
  },
  methods: {
    async adicionarDadosInformativos() {
      let body = {
        movimentoDentes: this.dentesNaoMovimentaveis,
        attachmentDentes: this.attachementDentes,
        observacao: this.infoAdicionais,
        tempoTratamento: this.tempoTratamento,
      };
      try {
        this.envioDadosIniciado = true;
        this.$emit("desabilitarProximoPasso");
        const response = await solicitaProjetoRepoV2.alterarSolicitacao(
          this.codigoSolicitacao,
          body
        );
        this.envioDadosIniciado = false;
        this.$emit("proximoPasso", response.data.codigoSolicitacao);
        this.$emit("habilitarProximoPasso");
      } catch (e) {
        this.envioDadosIniciado = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    contextualizarDadosDescritivos(
      movimentoDentes,
      attachmentDentes,
      tempoTratamento,
      observacao
    ) {
      if (movimentoDentes !== "" && movimentoDentes !== null) {
        this.dentesNaoMovimentaveis = movimentoDentes;
        this.denteMovimentoCheck = "Sim";
      } else {
        this.denteMovimentoCheck = "Não";
      }

      if (attachmentDentes !== "" && attachmentDentes !== null) {
        this.attachementDentes = attachmentDentes;
        this.attachementDentesCheck = "Sim";
      } else {
        this.attachementDentesCheck = "Não";
      }

      if (tempoTratamento !== "" && tempoTratamento !== null) {
        this.tempoTratamento = tempoTratamento;
        this.tempoTratamentoCheck = "Sim";
      } else {
        this.tempoTratamentoCheck = "Não";
      }

      if (observacao !== "" && observacao !== null) {
        this.infoAdicionais = observacao;
        this.infoAdicionaisCheck = "Sim";
      } else {
        this.infoAdicionaisCheck = "Não";
      }
    },
    validarDados() {
      if (this.denteMovimentoCheck === "") {
        this.$buefy.toast.open({
          message: `Informe se algum dente não deve ser movimentado`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (
        this.denteMovimentoCheck === "Sim" &&
        this.dentesNaoMovimentaveis.length < 1
      ) {
        this.$buefy.toast.open({
          message: `Descreva o(s) dente(s) que não deve(m) ser movimentado(s)`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.attachementDentesCheck === "") {
        this.$buefy.toast.open({
          message: `Informe se deseja attachement de algum dente`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (
        this.attachementDentesCheck === "Sim" &&
        this.attachementDentes.length < 1
      ) {
        this.$buefy.toast.open({
          message: `Descreva o campo referente ao attachement`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.tempoTratamentoCheck === "") {
        this.$buefy.toast.open({
          message: `Informe se deseja descrever o tempo de tratamento`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (
        this.tempoTratamentoCheck === "Sim" &&
        this.tempoTratamento.length < 1
      ) {
        this.$buefy.toast.open({
          message: `Descreva o tempo de tratamento`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (this.infoAdicionaisCheck === "") {
        this.$buefy.toast.open({
          message: `Informe se deseja complementar com informações adicionais`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else if (
        this.infoAdicionaisCheck === "Sim" &&
        this.infoAdicionais.length < 1
      ) {
        this.$buefy.toast.open({
          message: `Preencha o campo referente as informações adicionais`,
          position: "is-bottom",
          type: "is-danger",
        });
      } else {
        this.adicionarDadosInformativos();
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped></style>
