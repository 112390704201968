<template>
  <div class="corpo-dados-basicos">
    <div class="columns is-centered is-12">
      <div class="column is-10">
        <TabelaProjetos
          @atualizarListaProjetos="reiniciar"
          :lista-projetos="listaProjetos"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TabelaProjetos from "../../projeto/TabelaProjetos";
import projetoRepositoryV2 from "@/repository/projetoRepositoryV2";

export default {
  name: "PlanejamentosSolicitacao",
  props: {
    nrSlctProjeto: String,
  },
  components: {
    TabelaProjetos,
  },
  data() {
    return {
      listaProjetos: null,
    };
  },
  beforeMount: function () {
    this.listarProjetos();
  },
  methods: {
    reiniciar() {
      this.$emit("atualizacaoProjeto");
    },
    async listarProjetos() {
      const loadingComponent = this.$buefy.loading.open();

      try {
        const params = {
          solicitacaoProjeto: this.nrSlctProjeto,
        };
        const response = await projetoRepositoryV2.buscaProjetoPorSolicitacao(
          params
        );
        this.listaProjetos = response.data["hydra:member"];
        loadingComponent.close();
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao buscar planejamentos",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.corpo-dados-basicos {
  padding-left: 2rem;
}
</style>
