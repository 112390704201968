<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Pedido Impressão Modelo 3D</p>
    </header>
    <section class="modal-card-body" v-if="dadosPedidoImpressaoModelo">
      <div class="columns">
        <div class="column">
          <h6 class="title is-6">Pedido</h6>
        </div>
      </div>
      <div class="columns">
        <div class="column is-1">
          <b-field label="Código" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.numeroPedido"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2" v-if="dadosPedidoImpressaoModelo.valorPedido">
          <b-field label="Valor Pedido" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="
                dadosPedidoImpressaoModelo.valorPedido.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              "
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Estado Pedido" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="
                dadosPedidoImpressaoModelo.estadoPedidoImpressaoModelo.descricao
              "
              disabled
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-3"
          v-if="dadosPedidoImpressaoModelo.usuario !== null"
        >
          <b-field label="Usuário" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.usuario.nome"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Data Pedido" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="
                new Date(
                  dadosPedidoImpressaoModelo.dataPedido
                ).toLocaleDateString('pt-BR', {
                  timeZone: 'UTC',
                })
              "
              disabled
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div
          class="column is-3"
          v-if="dadosPedidoImpressaoModelo.paciente !== null"
        >
          <b-field label="Paciente" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.paciente.nome"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-1">
          <b-field label="Com Base" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.base ? 'Sim' : 'Não'"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-1">
          <b-field label="Maxilar" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.maxilar ? 'Sim' : 'Não'"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-1">
          <b-field label="Mandíbula" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.mandibula ? 'Sim' : 'Não'"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-button
            type="is-primary"
            @click="downloadArquivo('mx')"
            :disabled="dadosPedidoImpressaoModelo.maxilar === false"
            rounded
            ><strong>Arquivo Maxilar</strong></b-button
          >
        </div>
        <div class="column is-2">
          <b-button
            type="is-primary"
            @click="downloadArquivo('md')"
            :disabled="dadosPedidoImpressaoModelo.mandibula === false"
            rounded
            ><strong>Arquivo Mandíbula</strong></b-button
          >
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <h6 class="title is-6">Endereço Entrega</h6>
        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <b-field label="Logradouro" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.frete.endereco.logradouro"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-1">
          <b-field label="Nr." label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.frete.endereco.numero"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-1">
          <b-field label="Complemento" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.frete.endereco.complemento"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Bairro" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.frete.endereco.bairro"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Município" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.frete.endereco.municipio"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-1">
          <b-field label="UF" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.frete.endereco.uf"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="CEP" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.frete.endereco.cep"
              disabled
            ></b-input>
          </b-field>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <h6 class="title is-6">Frete</h6>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <b-field label="Tipo Frete" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.frete.tipoFrete.descricao"
              disabled
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-2"
          v-if="dadosPedidoImpressaoModelo.frete.valorFrete"
        >
          <b-field label="Valor Frete" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="
                dadosPedidoImpressaoModelo.frete.valorFrete.toLocaleString(
                  'pt-BR',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  }
                )
              "
              disabled
            ></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label="Data Prevista Entrega" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="
                new Date(
                  dadosPedidoImpressaoModelo.frete.dataEstimadaEntrega
                ).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
              "
              disabled
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-2"
          v-if="dadosPedidoImpressaoModelo.frete.codigoRastreio !== null"
        >
          <b-field label="Cód. Rastreio" label-position="on-border">
            <b-input
              custom-class="input-show-only"
              :value="dadosPedidoImpressaoModelo.frete.codigoRastreio"
              disabled
            ></b-input>
          </b-field>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-danger"
        @click="$parent.close()"
        icon-left="arrow-left"
        rounded
        ><strong>Fechar</strong></b-button
      >
      <b-button
        v-if="
          dadosPedidoImpressaoModelo &&
          dadosPedidoImpressaoModelo.cobranca !== null
        "
        type="is-primary"
        @click="recuperarBoleto(dadosPedidoImpressaoModelo.cobranca.linkBoleto)"
        :disabled="
          !dadosPedidoImpressaoModelo.cobranca.linkBoleto === 'PENDING' ||
          dadosPedidoImpressaoModelo.cobranca.linkBoleto === ''
        "
        icon-left="barcode"
        rounded
        ><strong>Visualizar boleto</strong></b-button
      >
      <b-button
        v-if="
          (dadosPedidoImpressaoModelo &&
            dadosPedidoImpressaoModelo.estadoPedidoImpressaoModelo &&
            dadosPedidoImpressaoModelo.estadoPedidoImpressaoModelo.codigo ===
              10) ||
          dadosPedidoImpressaoModelo.estadoPedidoImpressaoModelo.codigo === 20
        "
        type="is-twitter"
        @click="finalizarPedido"
        icon-left="truck"
        rounded
        ><strong>{{
          dadosPedidoImpressaoModelo.estadoPedidoImpressaoModelo.codigo === 20
            ? "Enviar"
            : "Finalizar e/ou Enviar"
        }}</strong></b-button
      >
      <b-button
        v-if="
          dadosPedidoImpressaoModelo &&
          dadosPedidoImpressaoModelo.estadoPedidoImpressaoModelo &&
          dadosPedidoImpressaoModelo.estadoPedidoImpressaoModelo.codigo === 30
        "
        type="is-twitter"
        @click="alterarCodigoRastreio"
        icon-left="truck"
        rounded
        ><strong>Alterar Rastreio</strong></b-button
      >
      <b-button
        v-if="
          dadosPedidoImpressaoModelo &&
          dadosPedidoImpressaoModelo.frete.codigoRastreio !== null
        "
        type="is-twitter"
        @click="rastrear(dadosPedidoImpressaoModelo.frete.codigoRastreio)"
        icon-left="barcode"
        rounded
        ><strong>Rastrear</strong></b-button
      >
    </footer>
  </div>
</template>

<script>
import { buscarPedidoModeloImpressao } from "@/repository/PedidoImpressaoModelo/PedidoImpressaoModeloRepository";
import pedidoImpressaoModeloArquivosRepository from "@/repository/pedidoImpressaoModeloArquivosV2Repository";
import pedidoImpressaoModeloRepository from "@/repository/pedidoImpressaoModeloV2Repository";
import freteV2Repository from "@/repository/freteV2Repository";

export default {
  name: "ModalConsultaPedidoImpressaoModelo",
  props: {
    pedidoImpressaoModeloCodigo: { type: Number, require: true },
  },
  data() {
    return {
      dadosPedidoImpressaoModelo: null,
    };
  },
  beforeMount() {
    this.buscarDadosPedido(this.pedidoImpressaoModeloCodigo);
  },
  methods: {
    async buscarDadosPedido(codigo) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const response = await buscarPedidoModeloImpressao(codigo);
        this.dadosPedidoImpressaoModelo = response.data;
        loadingComponent.close();
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response
            ? e.response.data["hydra:description"]
            : e.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async atualizarPedido(codRastreio) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        if (codRastreio !== null && codRastreio !== "") {
          const bodyFrete = {
            estadoFrete: "/frete_estado/110",
            codigoRastreio: codRastreio,
          };
          await freteV2Repository.atualizarFrete(
            bodyFrete,
            this.dadosPedidoImpressaoModelo.frete.codigo
          );

          const bodyPedidoImpressaoModelo = {
            estadoPedidoImpressaoModelo: "/pedido_impressao_modelo_estado/30",
          };
          await pedidoImpressaoModeloRepository.atualizarPedido(
            bodyPedidoImpressaoModelo,
            this.dadosPedidoImpressaoModelo.codigo
          );
        } else {
          const bodyPedidoImpressaoModelo = {
            estadoPedidoImpressaoModelo: "/pedido_impressao_modelo_estado/20",
          };
          await pedidoImpressaoModeloRepository.atualizarPedido(
            bodyPedidoImpressaoModelo,
            this.dadosPedidoImpressaoModelo.codigo
          );
        }
        this.$emit("atualizacaoPedido");
        loadingComponent.close();
        this.$parent.close();
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response
            ? e.response.data["hydra:description"]
            : e.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async atualizarCodRastreioFrete(codRastreio) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        if (codRastreio !== null && codRastreio !== "") {
          const bodyFrete = {
            estadoFrete: "/frete_estado/110",
            codigoRastreio: codRastreio,
          };
          await freteV2Repository.atualizarFrete(
            bodyFrete,
            this.dadosPedidoImpressaoModelo.frete.codigo
          );
        } else {
          this.$buefy.toast.open({
            message: `Código de rastreio não informado`,
            position: "is-top",
            type: "is-danger",
          });
        }
        this.$emit("atualizacaoPedido");
        loadingComponent.close();
        this.$parent.close();
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response
            ? e.response.data["hydra:description"]
            : e.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    recuperarBoleto(linkBoleto) {
      window.open(linkBoleto, "_blank");
    },
    rastrear(codigoRastreio) {
      window.open(
        "https://www.mdlivre.com.br/tracking/view/" + codigoRastreio,
        "_blank"
      );
    },
    finalizarPedido() {
      let message = "";
      let title = "";
      let codRastreioRequired = false;
      if (
        this.dadosPedidoImpressaoModelo.estadoPedidoImpressaoModelo.codigo !==
        20
      ) {
        message =
          "Informe abaixo o nr. de rastreio, caso não seja informado o pedido somente terá produção finalizada.";
        title = "Finalizar/Enviar Pedido";
        codRastreioRequired = false;
      } else {
        message = "Informe abaixo o nr. de rastreio.";
        title = "Registrar Envio do Pedido";
        codRastreioRequired = true;
      }
      this.$buefy.dialog.prompt({
        title: title,
        message: message,
        inputAttrs: {
          placeholder: "",
          value: "",
          required: codRastreioRequired,
        },
        trapFocus: true,
        confirmText: "Confirmar",
        cancelText: "Fechar",
        onConfirm: (value) => this.atualizarPedido(value),
      });
    },
    alterarCodigoRastreio() {
      this.$buefy.dialog.prompt({
        title: "Alterar Código de Rastreio",
        message: "Informe abaixo o código de rastreio.",
        inputAttrs: {
          placeholder: "",
          value: "",
          required: true,
        },
        trapFocus: true,
        confirmText: "Confirmar",
        cancelText: "Fechar",
        onConfirm: (value) => this.atualizarCodRastreioFrete(value),
      });
    },
    async downloadArquivo(codigoArquivo) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const body = {
          pedidoImpressao: this.dadosPedidoImpressaoModelo["@id"],
          operacao: "D",
        };
        const response =
          await pedidoImpressaoModeloArquivosRepository.gerarLinkArquivos(body);
        this.obterUrlUploadLoading = false;
        const linkDownload =
          codigoArquivo === "mx"
            ? response.data.linkDownloadMaxilar
            : response.data.linkDownloadMandibula;
        window.open(linkDownload, "_blank");
        loadingComponent.close();
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao obter link arquivos",
          message: e.response
            ? e.response.data["hydra:description"]
            : e.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tabela-projetos {
  font-size: 0.9rem;
  max-width: 100%;
}

.coluna-tabela-projetos {
  white-space: nowrap;
}

.item-list {
  float: left;
  margin-left: 1rem;
  margin-top: 0.5rem;
  min-width: 3.7rem;
}
</style>
