<template>
  <div class="columns" id="dados-paciente">
    <div class="column is-7">
      <b-field label="Nome" :label-position="labelPosition">
        <b-input disabled rounded :value="nome" />
      </b-field>
    </div>
    <div class="column is-3">
      <b-field label="Data Nascimento" :label-position="labelPosition">
        <b-input disabled rounded :value="dataNascimento" />
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "DadosPaciente",
  props: {
    nome: String,
    dataNascimento: String,
  },
  data() {
    return {
      labelPosition: "on-border",
    };
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
