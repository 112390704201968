import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/pedido_alinhadores_lista";

export default {
  listarPedidoAlinhadores(page, estado) {
    return Repository().get(`${resource}`, {
      params: {
        page: page,
        codEstadoPedido: estado,
      },
    });
  },
  listarPedidoAlinhadoresFiltro(estado, page) {
    return Repository().get(`${resource}`, {
      params: {
        codigoEstadoSolicitacao: estado,
        page: page,
      },
    });
  },
};
