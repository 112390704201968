<template>
  <div class="corpo-dados-basicos">
    <div class="columns is-variable is-12">
      <div class="column is-3">
        <b-field label="Movimento Arcadas" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="arcadaMovimentacao"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Desgaste Interproximal" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="desgasteInterProximal"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Relação Molar" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="relacaoMolar"
          ></b-input>
        </b-field>
      </div>
    </div>
    <hr />
    <div class="columns is-variable is-12">
      <div class="column is-3">
        <b-field label="Linha Média Superior" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="linhaSuperiorMedia"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field
          label="Linha Média Superior Direção"
          label-position="on-border"
        >
          <b-input
            custom-class="input-show-only"
            disabled
            :value="linhaSuperiorDirecao"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-4">
        <b-field
          label=" Linha Média Superior Distância"
          label-position="on-border"
        >
          <b-input
            custom-class="input-show-only"
            disabled
            :value="linhaSuperiorMediaMovimento"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-variable is-12">
      <div class="column is-3">
        <b-field label="Linha Média Inferior" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            disabled
            :value="linhaInferiorMedia"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field
          label="Linha Média Superior Inferior"
          label-position="on-border"
        >
          <b-input
            custom-class="input-show-only"
            disabled
            :value="linhaInferiorDirecao"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-4">
        <b-field
          label=" Linha Média Superior Distância"
          label-position="on-border"
        >
          <b-input
            custom-class="input-show-only"
            disabled
            :value="linhaInferiorMovimento"
          ></b-input>
        </b-field>
      </div>
    </div>
    <hr />
    <div class="columns is-variable is-12">
      <div class="column is-6">
        <b-field
          label="Dentes que não devem ser movimentados"
          label-position="on-border"
        >
          <b-input
            custom-class="input-show-only"
            type="textarea"
            disabled
            :value="movimentoDentes"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Attachment" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            type="textarea"
            disabled
            :value="attachmentDentes"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-variable is-12">
      <div class="column is-6">
        <b-field label="Tempo Tratamento" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            type="textarea"
            disabled
            :value="tempoTratamento"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Informações Adicionais" label-position="on-border">
          <b-input
            custom-class="input-show-only"
            type="textarea"
            disabled
            :value="observacao"
          ></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DadosTratamentoSolicitacao",
  props: {
    dadosSolicitacaoProjeto: Object,
  },
  data() {
    return {
      arcadaMovimentacao: null,
      desgasteInterProximal: null,
      queixaPaciente: null,
      linhaSuperiorMedia: null,
      linhaSuperiorDirecao: null,
      linhaSuperiorMediaMovimento: null,
      linhaInferiorMedia: null,
      linhaInferiorDirecao: null,
      linhaInferiorMovimento: null,
      relacaoMolar: null,
      movimentoDentes: null,
      observacao: null,
      tempoTratamento: null,
      attachmentDentes: null,
    };
  },
  beforeMount: function () {
    this.inicializarData(this.dadosSolicitacaoProjeto);
  },
  methods: {
    inicializarData(dadosSolicitacaoProjeto) {
      this.arcadaMovimentacao =
        dadosSolicitacaoProjeto.arcadaMovimentacao != null
          ? dadosSolicitacaoProjeto.arcadaMovimentacao
          : "";
      this.desgasteInterProximal =
        dadosSolicitacaoProjeto.desgasteInterProximal !== null
          ? dadosSolicitacaoProjeto.desgasteInterProximal
          : "";
      this.linhaSuperiorMedia =
        dadosSolicitacaoProjeto.linhaSuperiorMedia !== null
          ? dadosSolicitacaoProjeto.linhaSuperiorMedia
          : "";
      this.linhaSuperiorDirecao =
        dadosSolicitacaoProjeto.linhaSuperiorDirecao !== null
          ? dadosSolicitacaoProjeto.linhaSuperiorDirecao
          : "";
      this.linhaSuperiorMediaMovimento =
        dadosSolicitacaoProjeto.linhaSuperiorMediaMovimento !== null
          ? dadosSolicitacaoProjeto.linhaSuperiorMediaMovimento
          : "";
      this.linhaInferiorMedia =
        dadosSolicitacaoProjeto.linhaInferiorMedia !== null
          ? dadosSolicitacaoProjeto.linhaInferiorMedia
          : "";
      this.linhaInferiorDirecao =
        dadosSolicitacaoProjeto.linhaInferiorDirecao !== null
          ? dadosSolicitacaoProjeto.linhaInferiorDirecao
          : "";
      this.linhaInferiorMovimento =
        dadosSolicitacaoProjeto.linhaInferiorMovimento !== null
          ? dadosSolicitacaoProjeto.linhaInferiorMovimento
          : "";
      this.relacaoMolar =
        dadosSolicitacaoProjeto.relacaoMolar !== null
          ? dadosSolicitacaoProjeto.relacaoMolar
          : "";
      this.movimentoDentes =
        dadosSolicitacaoProjeto.movimentoDentes !== null
          ? dadosSolicitacaoProjeto.movimentoDentes
          : "";
      this.observacao =
        dadosSolicitacaoProjeto.observacao !== null
          ? dadosSolicitacaoProjeto.observacao
          : "";
      this.tempoTratamento =
        dadosSolicitacaoProjeto.tempoTratamento !== null
          ? dadosSolicitacaoProjeto.tempoTratamento
          : "";
      this.attachmentDentes =
        dadosSolicitacaoProjeto.attachmentDentes !== null
          ? dadosSolicitacaoProjeto.attachmentDentes
          : "";
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.corpo-dados-basicos {
  padding-left: 2rem;
}
</style>
