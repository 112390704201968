import Repository from "@/service/bluealApi/BluealApiCliente";
import qs from "qs";

const resource = "/enderecos";

export default {
  buscaEnderecoCodigo(codigo) {
    return Repository().get(`${resource}/${codigo}`);
  },
  buscaEnderecosAtivosUsuario(params) {
    return Repository().get(`${resource}`, {
      params: {
        ...params,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });
  },
  incluirEndereco(body) {
    return Repository().post(`${resource}`, body);
  },
};
