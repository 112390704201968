
import Vue from "vue";

const SnackBack = Vue.extend({
  name: "SnackBar",
  props: {
    title: String,
    message: String,
    timeout: { type: Number, default: 5000 },
    color: { type: String, default: "danger", required: false },
  },
  data() {
    return {
      open: false as boolean,
      classColor: "is-danger",
    };
  },
  beforeMount() {
    setInterval(() => this.setOpen(false), this.timeout);
    this.classColor = this.getClassColor(this.color);
  },
  mounted() {
    this.setOpen(true);
  },
  methods: {
    async setOpen(state: boolean) {
      if (!state) {
        setInterval(() => this.$emit("snackBarClosed"), 250);
      }
      this.open = state;
    },
    getClassColor(color: string): string {
      switch (color) {
        case "danger":
          return "message is-danger";
        case "warning":
          return "message is-warning";
        case "success":
          return "message is-success";
        default:
          return "message is-info";
      }
    },
  },
});

export default SnackBack;
