import Repository from "@/service/bluealApi/BluealApiCliente";
import qs from "qs";

const resource = "/telefones";

export const buscaTelefones = (params) => {
  return Repository().get(`${resource}`, {
    params: {
      ...params,
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  });
};

export const incluirTelefone = (body) => {
  return Repository().post(`${resource}`, body);
};

export const atualizarTelefone = (body) => {
  return Repository().put(`${resource}`, body);
};
