<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        <b-icon icon="file-invoice-dollar"></b-icon>
        Pedido Alinhadores
      </p>
    </header>
    <section class="modal-card-body">
      <form action>
        <div class="columns">
          <div class="column is-2">
            <b-field label="Pedido" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="nrPedido"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Estado Pedido" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="descEstadoPedido"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Data" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="dataPedido"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Planejamento" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="nrProjeto"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="Cobrança" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="codigoCobranca"
                disabled
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <b-field label="Usuario" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="codUsuario + ' ' + nomeUsuario"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Paciente" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="codPaciente + ' ' + nomePaciente"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field
              label="Qtd. Alinhadores Inferiores"
              label-position="on-border"
            >
              <b-input
                custom-class="input-show-only"
                :value="qtdAlinhadoresInferiores"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field
              label="Qtd. Alinhadores Superiores"
              label-position="on-border"
            >
              <b-input
                custom-class="input-show-only"
                :value="qtdAlinhadoresSuperiores"
                disabled
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div v-if="vlrTotalPedido" class="column is-2">
            <b-field label="Valor Total Pedido" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="
                  vlrTotalPedido.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                "
                disabled
              ></b-input>
            </b-field>
          </div>
          <div v-if="valorFrete" class="column is-2">
            <b-field label="Valor Frete" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="
                  valorFrete.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                "
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="CPF na Nota" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="notaFiscalAnonima === 'S' ? 'Sim' : 'Não'"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field
              label="Tipo Frete"
              label-position="on-border"
              v-if="tipoFrete === 4510"
            >
              <b-input
                custom-class="input-show-only"
                value="PAC-Normal"
                disabled
              ></b-input>
            </b-field>
            <b-field
              label="Tipo Frete"
              label-position="on-border"
              v-if="tipoFrete === 4014"
            >
              <b-input
                custom-class="input-show-only"
                value="Sedex"
                disabled
              ></b-input>
            </b-field>
            <b-field
              label="Tipo Frete"
              label-position="on-border"
              v-if="tipoFrete === 1"
            >
              <b-input
                custom-class="input-show-only"
                value="Retirada na Empresa"
                disabled
              ></b-input>
            </b-field>
            <b-field
              label="Tipo Frete"
              label-position="on-border"
              v-if="tipoFrete === 2"
            >
              <b-input
                custom-class="input-show-only"
                value="Receber via MotoBoy"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Data Estimada Entrega" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="dataEstimadaEntrega"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Código Rastreio" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                v-model="codigoRastreio"
                :value="codigoRastreio"
                :disabled="codEstadoPedido === 30"
              ></b-input>
            </b-field>
          </div>
        </div>
        <hr class="mt-0 mb-2" />
        <div class="columns" v-if="listaAlinhadoresSuperiores.length > 0">
          <div class="column is-10">
            <h3 class="title is-7">Endereço Entrega</h3>
          </div>
        </div>
        <div class="columns" v-if="isLoadingEndereco === false">
          <div class="column is-2">
            <b-field label="Logradouro" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="endereco.logradouro"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="Número" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="endereco.numero"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Complemento" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="endereco.complemento"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Bairro" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="endereco.bairro"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Município" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="endereco.municipio"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="UF" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="endereco.uf"
                disabled
              >
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="CEP" label-position="on-border">
              <b-input
                custom-class="input-show-only"
                :value="endereco.cep"
                disabled
              >
              </b-input>
            </b-field>
          </div>
        </div>
        <div class="columns" v-if="isLoadingEndereco === true">
          <div class="column is-11">
            <b-skeleton height="37" />
          </div>
        </div>
        <hr class="mt-0 mb-2" />
        <div class="columns" v-if="listaAlinhadoresSuperiores.length > 0">
          <div class="column is-10">
            <h3 class="title is-7">Alinhadores Superiores</h3>
          </div>
        </div>
        <div class="columns" v-if="listaAlinhadoresSuperiores.length > 0">
          <div class="column is-12">
            <b-taglist>
              <b-tag type="is-info" size="is-medium" v-if="alAttachmentSup"
                >Guia Attach Sup.
              </b-tag>
              <b-tag
                type="is-info"
                size="is-medium"
                v-for="al in listaAlinhadoresSuperiores"
                :key="al.alinhador"
                >{{ al }}
              </b-tag>
            </b-taglist>
          </div>
        </div>
        <hr class="mt-0 mb-2" />
        <div class="columns">
          <div
            class="column is-10"
            v-if="listaAlinhadoresInferiores.length > 0"
          >
            <h3 class="title is-7">Alinhadores Inferiores</h3>
          </div>
        </div>
        <div class="columns">
          <div
            class="column is-12"
            v-if="listaAlinhadoresInferiores.length > 0"
          >
            <b-taglist>
              <b-tag type="is-info" size="is-medium" v-if="alAttachmentInf"
                >Guia Attach Inf.
              </b-tag>
              <b-tag
                type="is-info"
                size="is-medium"
                v-for="al in listaAlinhadoresInferiores"
                :key="al.alinhador"
                >{{ al }}
              </b-tag>
            </b-taglist>
          </div>
        </div>
      </form>
    </section>
    <footer class="modal-card-foot">
      <b-button
        class="is-danger"
        icon-left="times-circle"
        rounded
        @click="$parent.close()"
        ><strong>Fechar</strong></b-button
      >
      <b-button
        v-if="vlrTotalPedido"
        class="button is-primary"
        @click="recuperarBoleto"
        :disabled="
          !estadoCobrancaAsaas === 'PENDING' || estadoCobrancaAsaas === ''
        "
        icon-left="barcode"
        rounded
        ><strong>Visualizar boleto</strong></b-button
      >
      <b-button
        class="is-info"
        @click="verificarFinalizacaoProducao"
        v-if="codEstadoPedido === 10"
        icon-left="cubes"
        rounded
        ><strong>Finalizar Produção</strong></b-button
      >
      <b-button
        class="is-info"
        @click="verificarRastreioInformadoParaEnvio"
        v-if="codEstadoPedido !== 30 && codEstadoPedido !== 40"
        icon-left="truck-loading"
        rounded
      >
        <strong>Enviar</strong>
      </b-button>
      <b-button
        class="is-link"
        @click="verificarRastreioInformado"
        v-if="codEstadoPedido !== 30"
        icon-left="clipboard-check"
        rounded
        ><strong>Finalizar Pedido</strong></b-button
      >
      <b-button
        v-if="
          codEstadoPedido === 30 &&
          codigoRastreio !== null &&
          codigoRastreio !== ''
        "
        class="is-link"
        @click="rastrear"
        icon-left="barcode"
        rounded
        ><strong>Rastrear</strong></b-button
      >
    </footer>
  </div>
</template>

<script>
import enderecoV2Repository from "@/repository/enderecoV2Repository";
import cobrancaRepository from "@/repository/cobrancaRepository";
import pedidoAlinhadoresRepositoryV2 from "@/repository/pedidoAlinhadoresRepositoryV2";

export default {
  name: "ModalConsultaPedidoAlinhadores",
  props: {
    dadosPedidoSelecionado: Object,
  },
  data() {
    return {
      nrPedido: null,
      dataPedido: null,
      codEstadoPedido: null,
      descEstadoPedido: null,
      listaAlinhadoresInferiores: [],
      listaAlinhadoresSuperiores: [],
      qtdAlinhadoresInferiores: null,
      qtdAlinhadoresSuperiores: null,
      codigoCobranca: null,
      estadoCobrancaAsaas: null,
      nrProjeto: null,
      codSolicitacao: null,
      codUsuario: null,
      nomeUsuario: null,
      codPaciente: null,
      nomePaciente: null,
      vlrTotalPedido: null,
      notaFiscalAnonima: null,
      endereco: null,
      tipoFrete: null,
      valorFrete: null,
      dataEstimadaEntrega: null,
      codigoRastreio: "",
      isLoadingEndereco: true,
      alAttachmentInf: false,
      alAttachmentSup: false,
    };
  },
  beforeMount() {
    this.buscarEndereco();
  },
  mounted() {
    this.nrPedido = this.dadosPedidoSelecionado.nrPedido;
    this.nrProjeto = this.dadosPedidoSelecionado.nrProjeto;
    this.dataPedido = this.dadosPedidoSelecionado.dataPedido;
    this.codEstadoPedido = this.dadosPedidoSelecionado.codEstadoPedido;
    this.descEstadoPedido = this.dadosPedidoSelecionado.descEstadoPedido;
    this.listaAlinhadoresInferiores =
      this.dadosPedidoSelecionado.listaAlinhadoresInferiores;
    this.listaAlinhadoresSuperiores =
      this.dadosPedidoSelecionado.listaAlinhadoresSuperiores;
    this.qtdAlinhadoresInferiores =
      this.dadosPedidoSelecionado.qtdAlinhadoresInferiores;
    this.qtdAlinhadoresSuperiores =
      this.dadosPedidoSelecionado.qtdAlinhadoresSuperiores;
    this.codigoCobranca = this.dadosPedidoSelecionado.codigoCobranca;
    this.estadoCobrancaAsaas = this.dadosPedidoSelecionado.estadoCobrancaAsaas;
    this.codSolicitacao = this.dadosPedidoSelecionado.codSolicitacao;
    this.codUsuario = this.dadosPedidoSelecionado.codUsuario;
    this.nomeUsuario = this.dadosPedidoSelecionado.nomeUsuario;
    this.codPaciente = this.dadosPedidoSelecionado.codPaciente;
    this.nomePaciente = this.dadosPedidoSelecionado.nomePaciente;
    this.vlrTotalPedido = this.dadosPedidoSelecionado.vlrTotalPedido;
    this.notaFiscalAnonima = this.dadosPedidoSelecionado.notaFiscalAnonima;
    this.tipoFrete = this.dadosPedidoSelecionado.codigoTipoFrete;
    this.valorFrete = this.dadosPedidoSelecionado.valorFrete;
    this.dataEstimadaEntrega = this.dadosPedidoSelecionado.dataEstimadaEntrega;
    this.codigoRastreio = this.dadosPedidoSelecionado.codigoRastreio;
    if (this.dadosPedidoSelecionado.guiaAttachPedido) {
      this.alAttachmentInf =
        this.dadosPedidoSelecionado.guiaAttachPedido.attachmentInferior;
      this.alAttachmentSup =
        this.dadosPedidoSelecionado.guiaAttachPedido.attachmentSuperior;
    }
  },
  methods: {
    async recuperarBoleto() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const response = await cobrancaRepository.buscarCobrancaCodigo(
          this.codigoCobranca
        );
        loadingComponent.close();
        if (response.data.linkBoleto !== null) {
          window.open(response.data.linkBoleto, "_blank");
        } else {
          this.$buefy.dialog.alert({
            title: "Erro ao recuperar boleto",
            message: "Este boleto não está mais disponível.",
            type: "is-warning",
            hasIcon: true,
            icon: "exclamation-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao recuperar boleto",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async buscarEndereco() {
      this.isLoadingEndereco = true;
      try {
        const response = await enderecoV2Repository.buscaEnderecoCodigo(
          this.dadosPedidoSelecionado.codigoEndereco
        );
        this.endereco = response.data;
        this.isLoadingEndereco = false;
      } catch (error) {
        this.isLoadingEndereco = false;
        this.$buefy.dialog.alert({
          title: error.response.status + " " + error.response.statusText,
          message: error.response.data.mensagem,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    verificarFinalizacaoProducao() {
      if (this.codigoRastreio === null || this.codigoRastreio === "") {
        this.$buefy.dialog.alert({
          title: "Atenção",
          message:
            "Deseja finalizar a produção do pedido? O estado será alterado para IMPRESSO.",
          type: "is-warning",
          hasIcon: true,
          icon: "exclamation-triangle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          confirmText: "Sim",
          cancelText: "Não",
          canCancel: ["button"],
          onConfirm: () => {
            this.finalizaProducaoAlinhadores();
          },
        });
      } else {
        this.finalizaProducaoAlinhadores();
      }
    },
    verificarRastreioInformadoParaEnvio() {
      if (this.codigoRastreio === null || this.codigoRastreio === "") {
        this.$buefy.dialog.alert({
          title: "Atenção",
          message:
            "Deseja prosseguir sem informar o código de rastreio? O pedido será ENVIADO",
          type: "is-warning",
          hasIcon: true,
          icon: "exclamation-triangle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          confirmText: "Sim",
          cancelText: "Não",
          canCancel: ["button"],
          onConfirm: () => {
            this.enviarPedidoAlinhadores();
          },
        });
      } else {
        this.enviarPedidoAlinhadores();
      }
    },
    verificarRastreioInformado() {
      if (this.codigoRastreio === null || this.codigoRastreio === "") {
        this.$buefy.dialog.alert({
          title: "Atenção",
          message: "Deseja prosseguir sem informar o código de rastreio?",
          type: "is-warning",
          hasIcon: true,
          icon: "exclamation-triangle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          confirmText: "Sim",
          cancelText: "Não",
          canCancel: ["button"],
          onConfirm: () => {
            this.finalizaPedidoAlinhadores();
          },
        });
      } else {
        this.finalizaPedidoAlinhadores();
      }
    },
    async finalizaProducaoAlinhadores() {
      const loadingComponent = this.$buefy.loading.open();

      let body = {
        operacao: "I",
      };

      try {
        await pedidoAlinhadoresRepositoryV2.alterarPedidoAlinhadores(
          this.nrPedido,
          body
        );
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Sucesso",
          message: "Produção Finalizado, pedido passará para estado 'Impresso'",
          type: "is-success",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          onConfirm: () => {
            this.$emit("atualizarPedidos");
            this.$parent.close();
          },
        });
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao finalizar pedido",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async finalizaPedidoAlinhadores() {
      const loadingComponent = this.$buefy.loading.open();

      let body = {
        operacao: "F",
      };

      if (this.codigoRastreio !== null && this.codigoRastreio !== "") {
        body = {
          ...body,
          codigoRastreio: this.codigoRastreio,
        };
      }

      try {
        await pedidoAlinhadoresRepositoryV2.alterarPedidoAlinhadores(
          this.nrPedido,
          body
        );
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Sucesso",
          message: "Pedido Finalizado",
          type: "is-success",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          onConfirm: () => {
            this.$emit("atualizarPedidos");
            this.$parent.close();
          },
        });
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao finalizar pedido",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async enviarPedidoAlinhadores() {
      const loadingComponent = this.$buefy.loading.open();

      let body = {
        operacao: "E",
      };

      if (this.codigoRastreio !== null && this.codigoRastreio !== "") {
        body = {
          ...body,
          codigoRastreio: this.codigoRastreio,
        };
      }

      try {
        await pedidoAlinhadoresRepositoryV2.alterarPedidoAlinhadores(
          this.nrPedido,
          body
        );
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Sucesso",
          message: "Pedido Enviado",
          type: "is-success",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          onConfirm: () => {
            this.$emit("atualizarPedidos");
            this.$parent.close();
          },
        });
      } catch (error) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro ao enviar pedido",
          message: error.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    rastrear() {
      window.open(
        "https://www.mdlivre.com.br/tracking/view/" + this.codigoRastreio,
        "_blank"
      );
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.tabela-projetos {
  font-size: 0.9rem;
  max-width: 100%;
}

.coluna-tabela-projetos {
  white-space: nowrap;
}

.item-list {
  float: left;
  margin-left: 1rem;
  margin-top: 0.5rem;
  min-width: 3.7rem;
}
</style>
