import Repository from "@/service/bluealApi/BluealApiCliente";
import qs from "qs";

const resource = "/servicos";

export default {
  buscaServicos(params) {
    return Repository().get(`${resource}`, {
      params: {
        ...params,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });
  },
};
