
import Vue from "vue";
import router from "@/router/index";
import { SubMenu } from "@/views/menu/MenuSide.vue";

export default Vue.extend({
  name: "SubMenuItem",
  props: {
    subMenuList: {
      type: Array as () => Array<SubMenu>,
      required: true,
    },
    profile: {
      type: String,
      required: true,
    },
    expanded: { type: Boolean, required: true, default: false },
    collapsed: { type: Boolean, required: true, default: true },
  },
  data() {
    return {
      subMenus: this.subMenuList as SubMenu[],
    };
  },
  beforeMount() {
    this.subMenus = this.subMenus.filter((subMenu: SubMenu) => {
      return subMenu.profiles.includes(this.profile);
    });
  },
  methods: {
    navigate(route: string) {
      router.push({ name: route });
    },
  },
});
