<template>
  <section>
    <article class="panel is-light">
      <p class="panel-heading">Meus Dados</p>
      <div class="panel-block dados-usuario-painel columns">
        <div class="column is-12">
          <form @submit.prevent="atualizarCadastro()">
            <div class="columns">
              <div class="column is-12">
                <b-message
                  v-if="dadosUsuario.usuarioEstado.codigoEstadoUsuario === 100"
                  type="is-warning"
                  has-icon
                  size="is-small"
                >
                  Para utilizar o sistema é necessário concluir seu cadastro
                </b-message>
              </div>
            </div>
            <div class="columns">
              <div class="column is-12">
                <div class="columns">
                  <div class="column is-4">
                    <b-field label="Nome" label-position="on-border">
                      <b-input
                        placeholder="Nome completo"
                        minlength="10"
                        maxlength="255"
                        required
                        validation-message="Campo deve possuir no mínimo 10 caracteres"
                        v-model="nome"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field label="E-mail" label-position="on-border">
                      <b-input
                        placeholder="Email"
                        minlength="5"
                        maxlength="150"
                        required
                        validation-message="Email inválido"
                        v-model="email"
                        type="email"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-1 mr-6 ml-2">
                    <b-field>
                      <b-radio-button
                        v-model="isCpf"
                        size="is-small"
                        :native-value="true"
                        required
                        type="is-primary"
                      >
                        <b-icon size="is-small" icon="check"></b-icon>
                        <span>CPF</span>
                      </b-radio-button>

                      <b-radio-button
                        v-model="isCpf"
                        size="is-small"
                        :native-value="false"
                        required
                        @input="
                          cpf = '';
                          cnpj = '';
                        "
                        type="is-twitter"
                      >
                        <b-icon size="is-small" icon="times"></b-icon>
                        <span>CNPJ</span>
                      </b-radio-button>
                    </b-field>
                  </div>
                  <div class="column is-2" v-if="isCpf === true">
                    <b-field label="CPF" label-position="on-border">
                      <b-input
                        type="text"
                        placeholder="Somente Números"
                        minlength="14"
                        maxlength="14"
                        required
                        validation-message="CPF inválido"
                        :value="cpf | maskFilter('###.###.###-##')"
                        @input="(value) => (cpf = value)"
                      />
                    </b-field>
                  </div>
                  <div class="column is-2" v-if="isCpf === false">
                    <b-field label="CNPJ" label-position="on-border">
                      <b-input
                        type="text"
                        placeholder="Somente Números"
                        minlength="18"
                        maxlength="18"
                        required
                        validation-message="CNPJ inválido"
                        :value="cnpj | maskFilter('##.###.###/####-##')"
                        @input="(value) => (cnpj = value)"
                      />
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-1 coluna-possui-cro mr-6">
                    <p class="title is-7 titulo-possui-cro">Possui CRO</p>
                    <b-field>
                      <b-radio-button
                        v-model="possuiCRO"
                        size="is-small"
                        :native-value="true"
                        required
                        type="is-primary"
                      >
                        <b-icon size="is-small" icon="check"></b-icon>
                        <span>Sim</span>
                      </b-radio-button>

                      <b-radio-button
                        v-model="possuiCRO"
                        size="is-small"
                        :native-value="false"
                        required
                        @input="
                          nrCRO = '';
                          ufCRO = '';
                        "
                        type="is-danger"
                      >
                        <b-icon size="is-small" icon="times"></b-icon>
                        <span>Não</span>
                      </b-radio-button>
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field label="Nr. CRO" label-position="on-border">
                      <b-input
                        placeholder="Número CRO"
                        minlength="3"
                        maxlength="10"
                        :disabled="possuiCRO === false"
                        required
                        validation-message="Número inválido"
                        v-model="nrCRO"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-1">
                    <b-field label="UF CRO" label-position="on-border">
                      <b-select
                        placeholder="UF"
                        :disabled="possuiCRO === false"
                        required
                        v-model="ufCRO"
                        validation-message="UF inválido"
                      >
                        <option
                          v-for="option in listaUf"
                          :value="option.uf"
                          :key="option.uf"
                        >
                          {{ option.uf }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column is-2" v-if="isTelefonesLoading === true">
                    <b-skeleton height="5.6vh" />
                  </div>
                  <div class="column is-2" v-if="isTelefonesLoading === false">
                    <b-field
                      label="Telefone Celular"
                      label-position="on-border"
                    >
                      <b-input
                        type="text"
                        placeholder="Somente Números"
                        minlength="14"
                        maxlength="14"
                        required
                        validation-message="Número inválido"
                        :value="foneMovel | maskFilter('(##)#####-####')"
                        @input="(value) => (foneMovel = value)"
                      />
                    </b-field>
                  </div>
                  <div class="column is-2" v-if="isTelefonesLoading === true">
                    <b-skeleton height="5.6vh" />
                  </div>
                  <div class="column is-2" v-if="isTelefonesLoading === false">
                    <b-field
                      label="Telefone Fixo"
                      label-position="on-border"
                      type="text"
                      validation-message="Número inválido"
                    >
                      <b-input
                        type="text"
                        placeholder="Somente Números"
                        minlength="13"
                        maxlength="13"
                        :value="foneFixo | maskFilter('(##)####-####')"
                        @input="(value) => (foneFixo = value)"
                      />
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-button
                      type="is-twitter"
                      icon-left="user-plus"
                      rounded
                      @click="abrirModalImageProfile"
                      ><strong>Imagem Perfil</strong></b-button
                    >
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-12">
                    <p class="title is-5 mb-0 pt-0">
                      Endereços
                      <span
                        class="endereco-aviso"
                        v-if="
                          enderecoPrincipal === null &&
                          isLoadingEndereco === false
                        "
                        >- *Atenção, você deve incluir um endereço
                        principal!</span
                      >
                    </p>
                    <hr class="mt-2 mb-0" />
                  </div>
                </div>
                <div class="columns" v-if="isLoadingEndereco">
                  <div class="column is-4">
                    <b-skeleton height="5.6vh" />
                  </div>
                  <div class="column is-1">
                    <b-skeleton height="5.6vh" />
                  </div>
                  <div class="column is-2">
                    <b-skeleton height="5.6vh" />
                  </div>
                  <div class="column is-3">
                    <b-skeleton height="5.6vh" />
                  </div>
                  <div class="column is-2">
                    <b-skeleton height="5.6vh" />
                  </div>
                </div>
                <div class="columns" v-if="isLoadingEndereco">
                  <div class="column is-4">
                    <b-skeleton height="5.6vh" />
                  </div>
                  <div class="column is-1">
                    <b-skeleton height="5.6vh" />
                  </div>
                  <div class="column is-2">
                    <b-skeleton height="5.6vh" />
                  </div>
                  <div class="column is-3">
                    <b-skeleton height="5.6vh" />
                  </div>
                </div>
                <div class="columns" v-if="isLoadingEndereco === false">
                  <div class="column is-4">
                    <b-field label="Logradouro" label-position="on-border">
                      <b-input disabled v-model="logradouro"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-1">
                    <b-field label="Nr." label-position="on-border">
                      <b-input disabled v-model="numero"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <b-field label="Complemento" label-position="on-border">
                      <b-input disabled v-model="complemento"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-4">
                    <b-field label="Bairro" label-position="on-border">
                      <b-input disabled v-model="bairro"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns" v-if="isLoadingEndereco === false">
                  <div class="column is-4">
                    <b-field label="Município" label-position="on-border">
                      <b-input disabled v-model="municipio"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-1">
                    <b-field label="UF" label-position="on-border">
                      <b-input disabled v-model="uf"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field label="CEP" label-position="on-border">
                      <b-input disabled v-model="cep"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-3">
                    <button
                      @click.prevent="isModalEnderecosActive = true"
                      class="button is-primary is-rounded"
                    >
                      <strong>
                        {{
                          enderecoPrincipal === null
                            ? "Incluir Endereço"
                            : "Alterar Endereço"
                        }}</strong
                      >
                    </button>
                    <b-modal
                      v-model="isModalEnderecosActive"
                      has-modal-card
                      trap-focus
                      :destroy-on-hide="true"
                    >
                      <template #default="props">
                        <EnderecosModal
                          @close="props.close"
                          :tab-index="1"
                          :is-endereco-principal="true"
                          :lista-oculta="true"
                          @enderecoIncluido="enderecoIncluido($event)"
                        />
                      </template>
                    </b-modal>
                  </div>
                </div>

                <div
                  v-if="dadosUsuario.usuarioEstado.codigoEstadoUsuario === 100"
                  class="columns"
                >
                  <div class="column is-12">
                    <p class="title is-5 mb-0 pt-0">
                      Senhas
                      <span class="endereco-aviso"
                        >- *Atenção, você deve alterar a senha
                      </span>
                    </p>
                    <hr class="mt-1 mb-0" />
                  </div>
                </div>
                <div
                  v-if="dadosUsuario.usuarioEstado.codigoEstadoUsuario === 100"
                  class="columns"
                >
                  <div class="column is-2">
                    <b-field label="Senha Atual" label-position="on-border">
                      <b-input
                        type="password"
                        placeholder
                        minlength="6"
                        maxlength="12"
                        required
                        v-model="senhaAtual"
                        @input="
                          senhaAtual.length >= 6 ? senhaNovaValida() : null
                        "
                      />
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field
                      label="Nova Senha"
                      label-position="on-border"
                      :message="senhaNovaValidationMessage"
                      :type="
                        senhaNovaValidationMessage.length > 0 ? 'is-danger' : ''
                      "
                    >
                      <b-input
                        type="password"
                        placeholder
                        minlength="6"
                        maxlength="12"
                        required
                        v-model="senhaNova"
                        @input="
                          senhaNova.length >= 6 ? senhaNovaValida() : null
                        "
                      />
                    </b-field>
                  </div>
                  <div class="column is-2">
                    <b-field
                      label="Confirmação"
                      label-position="on-border"
                      :message="senhaConfirmacaoValidationMessage"
                      :type="
                        senhaConfirmacaoValidationMessage.length > 0
                          ? 'is-danger'
                          : ''
                      "
                    >
                      <b-input
                        type="password"
                        placeholder
                        minlength="6"
                        maxlength="12"
                        required
                        v-model="senhaConfirmacao"
                        @input="
                          senhaConfirmacao.length >= 6
                            ? senhaNovaValida()
                            : null
                        "
                      />
                    </b-field>
                  </div>
                </div>
                <hr />
                <div class="columns">
                  <div class="column is-9">
                    <b-button
                      native-type="submit"
                      type="is-primary"
                      icon-left="user-plus"
                      rounded
                    >
                      <strong> Atualizar </strong>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import store from "@/store/store";
import ImageProfile from "./ImageProfile";
import { VueMaskFilter } from "v-mask";
import EnderecosModal from "@/views/endereco/EnderecosModal";
import enderecoRespository from "@/repository/enderecoV2Repository";
import {
  buscaTelefones,
  incluirTelefone,
} from "@/repository/telefoneV2Repository";
import { atualizarUsuario } from "@/service/bluealApi/repository/user/UserRepository";
import ufs from "@/data/uf/ufs";

export default {
  name: "AtualizarCadastroUsuario",
  filters: {
    maskFilter: VueMaskFilter,
  },
  components: {
    EnderecosModal,
  },
  data() {
    return {
      possuiCRO: true,
      isCpf: true,
      isModalEnderecosActive: false,
      isLoadingEndereco: true,
      isTelefonesLoading: true,
      enderecoPrincipal: null,
      dadosUsuario: null,
      listaUf: ufs,
      //formData
      nome: "",
      nrCRO: "",
      email: "",
      cpf: "",
      cnpj: "",
      ufCRO: "",
      foneMovel: "",
      foneMovelIncial: "",
      incluirFoneMovel: true,
      foneFixo: "",
      foneFixoIncial: "",
      incluirFoneFixo: true,
      logradouro: "",
      complemento: "",
      numero: "",
      cep: "",
      uf: "",
      municipio: "",
      bairro: "",
      senhaAtual: "",
      senhaNova: "",
      senhaNovaValidationMessage: "",
      senhaConfirmacao: "",
      senhaConfirmacaoValidationMessage: "",
    };
  },
  beforeMount() {
    this.dadosUsuario = store.getters["doneDadosUsuario"];

    if (this.dadosUsuario !== null) {
      this.nome = this.dadosUsuario.nome;
      this.email = this.dadosUsuario.email;
      this.ufCRO = this.dadosUsuario.ufCRO;
      this.nrCRO = this.dadosUsuario.numeroCRO;
      if (
        this.dadosUsuario.cpfCnpj !== null &&
        this.dadosUsuario.cpfCnpj.length > 14
      ) {
        this.isCpf = false;
        this.cnpj = this.dadosUsuario.cpfCnpj;
      } else {
        this.isCpf = true;
        this.cpf = this.dadosUsuario.cpfCnpj;
      }
    }

    this.buscarEnderecoPrincipal();
    this.buscarTelefones();
  },
  methods: {
    async atualizarCadastro() {
      const dadosValidos = this.validarDados();

      if (dadosValidos === true) {
        const loadingComponent = this.$buefy.loading.open();
        try {
          await this.incluirTelefones();

          let body = {
            nome: this.nome,
            email: this.email,
          };

          if (this.isCpf === true) {
            body = {
              ...body,
              cpfCnpj: this.cpf,
            };
          } else {
            body = {
              ...body,
              cpfCnpj: this.cnpj,
            };
          }

          if (this.possuiCRO === true) {
            body = {
              ...body,
              numeroCRO: Number(this.nrCRO),
              ufCRO: this.ufCRO,
            };
          } else {
            body = {
              ...body,
              numeroCRO: null,
              ufCRO: null,
            };
          }

          if (this.dadosUsuario.usuarioEstado.codigoEstadoUsuario === 100) {
            body = {
              ...body,
              newPassword: this.senhaNova,
              oldPassword: this.senhaAtual,
            };
          }

          const response = await atualizarUsuario(
            body,
            this.dadosUsuario.codigoUsuario
          );

          this.$store.commit("SET_DADOS_USUARIO", response.data);
          await store.dispatch("changedDadosUsuario", response.data);
          loadingComponent.close();

          this.$buefy.dialog.alert({
            title: "Sucesso",
            message: "Cadastro atualizado com sucesso.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            onConfirm: () => this.$router.push({ name: "Home" }),
          });
        } catch (error) {
          loadingComponent.close();
          this.$buefy.dialog.alert({
            title: "Erro ao atualizar cadastro",
            message: error.response
              ? error.response.data["hydra:description"]
              : error.message,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      }
    },
    validarDados() {
      let dadosValidos = true;
      if (this.enderecoPrincipal === null) {
        this.$buefy.toast.open({
          message: `Você não possui endereço principal, inclua um.`,
          position: "is-top",
          type: "is-danger",
        });
        dadosValidos = false;
      } else if (this.foneMovel === "") {
        this.$buefy.toast.open({
          message: `Você não informou número de telefone móvel.`,
          position: "is-top",
          type: "is-danger",
        });
        dadosValidos = false;
      } else if (this.dadosUsuario.usuarioEstado.codigoEstadoUsuario === 100) {
        if (this.senhaNovaValida() === false) {
          this.$buefy.toast.open({
            message: `Nova senha possui erros, verifique.`,
            position: "is-top",
            type: "is-danger",
          });
          dadosValidos = false;
        }
      } else if (this.isCpf === true) {
        let cpfValido = this.validaCpf(this.cpf);
        if (cpfValido === false) {
          this.$buefy.toast.open({
            message: `Você informou um CPF inválido.`,
            position: "is-top",
            type: "is-danger",
          });
          dadosValidos = false;
        }
      } else if (this.isCpf === false) {
        let cnpjValido = this.validaCnpj(this.cnpj);
        if (cnpjValido === false) {
          this.$buefy.toast.open({
            message: `Você informou um CNPJ inválido.`,
            position: "is-top",
            type: "is-danger",
          });
          dadosValidos = false;
        }
      }

      return dadosValidos;
    },
    async incluirTelefones() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        let foneMovelFormatado = Number(
          this.foneMovel.toString().replace(/\D/g, "")
        );

        if (this.foneMovelIncial !== foneMovelFormatado) {
          const bodyFoneMovel = {
            numero: foneMovelFormatado,
            tipo: 1,
          };
          const response = await incluirTelefone(bodyFoneMovel);

          this.foneMovel = response.data.numero;
          this.foneMovelIncial = response.data.numero;
        }

        if (this.foneFixo !== "") {
          let foneFixoFormatado = Number(
            this.foneFixo.toString().replace(/\D/g, "")
          );

          if (this.foneFixoIncial !== foneFixoFormatado) {
            const bodyFoneFixo = {
              numero: foneFixoFormatado,
              tipo: 2,
            };
            const response = await incluirTelefone(bodyFoneFixo);

            this.foneFixo = response.data.numero;
            this.foneFixoIncial = response.data.numero;
          }
        }

        loadingComponent.close();
      } catch (e) {
        loadingComponent.close();
        this.$buefy.dialog.alert({
          title: "Erro atualizar telefones",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async buscarEnderecoPrincipal() {
      try {
        this.isLoadingEndereco = true;
        const params = {
          estadoEndereco: 10,
          tipoEndereco: 10,
          usuario: this.dadosUsuario.codigoUsuario,
        };
        const response = await enderecoRespository.buscaEnderecosAtivosUsuario(
          params
        );
        response.data["hydra:member"].length > 0
          ? (this.enderecoPrincipal = response.data["hydra:member"][0])
          : (this.enderecoPrincipal = null);
        this.enderecoPrincipal !== null
          ? this.atribuirDadosEndereco(this.enderecoPrincipal)
          : null;
        this.isLoadingEndereco = false;
      } catch (e) {
        this.isLoadingEndereco = false;
        this.$buefy.dialog.alert({
          title: "Erro ao obter endereços do usuário",
          message: e.response
            ? e.response.data["hydra:description"]
            : e.message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async buscarTelefones() {
      try {
        this.isTelefonesLoading = true;
        const params = {
          estado: 10,
          usuario: this.dadosUsuario.codigoUsuario,
        };
        const response = await buscaTelefones(params);

        response.data["hydra:member"].length > 0
          ? this.atribuirDadosTelefones(response.data["hydra:member"])
          : null;
        this.isTelefonesLoading = false;
      } catch (e) {
        this.isLoadingEndereco = false;
        this.$buefy.dialog.alert({
          title: "Erro ao obter endereços do usuário",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    abrirModalImageProfile() {
      this.$buefy.modal.open({
        parent: this,
        component: ImageProfile,
        fullScreen: true,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        width: 960,
      });
    },
    atribuirDadosEndereco(endereco) {
      this.isLoadingEndereco = false;
      this.logradouro = endereco.logradouro;
      this.numero = endereco.numero;
      this.complemento = endereco.complemento;
      this.bairro = endereco.bairro;
      this.municipio = endereco.municipio;
      this.uf = endereco.uf;
      this.cep = endereco.cep;
    },
    atribuirDadosTelefones(fones) {
      fones.forEach((element) => {
        if (element.tipo === 1) {
          this.foneMovel = element.numero;
          this.foneMovelIncial = element.numero;
          this.incluirFoneMovel = false;
        } else if (element.tipo === 2) {
          this.foneFixo = element.numero;
          this.foneFixoIncial = element.numero;
          this.incluirFoneFixo = false;
        }
      });
    },
    enderecoIncluido(endereco) {
      this.enderecoPrincipal = endereco;
      this.atribuirDadosEndereco(this.enderecoPrincipal);
    },
    validaCpf(strCPF) {
      strCPF = strCPF.replace(/\D/g, "");
      var Soma;
      var Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (var i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    validaCnpj(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj == "") return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      let i = tamanho;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },
    senhaNovaValida() {
      let senhasValidas = true;

      if (this.senhaAtual === this.senhaNova) {
        this.senhaNovaValidationMessage = "Senha nova igual a atual";
        senhasValidas = false;
      } else {
        this.senhaNovaValidationMessage = "";
      }

      if (this.senhaNova !== this.senhaConfirmacao) {
        this.senhaConfirmacaoValidationMessage = "Senha não confere";
        senhasValidas = false;
      } else {
        this.senhaConfirmacaoValidationMessage = "";
      }

      return senhasValidas;
    },
    tratarPrimeiroNomeUsuario(nome) {
      const primeiroNome = nome.substr(0, nome.indexOf(" "));
      return primeiroNome;
    },
  },
};
</script>

<style scoped>
.dados-usuario-painel {
  padding: 1.5rem;
}

.endereco-aviso {
  font-size: 0.9rem;
  color: brown;
}

.coluna-possui-cro {
  padding-top: 0px !important;
}

.titulo-possui-cro {
  left: 1em;
  font-size: calc(0.9rem * 3 / 4);
  background-color: transparent;
  margin-bottom: 0.35rem !important;
}
</style>
