























































































































































































































import Vue, { PropType } from "vue";
import { CobrancasPedidoAlinhadoresItem } from "@/repository/Cobranca/CobrancaListaData";

const financeiroPedidoAlinhadoresTabela = Vue.extend({
  name: "FinanceiroPedidoAlinhadoresTabela",
  props: {
    isLoadingLista: { type: Boolean, required: true },
    pedidoAlinhadoresLista: {
      type: Array as PropType<CobrancasPedidoAlinhadoresItem[]>,
      required: true,
    },
    totalItens: { type: Number, required: true },
  },
  data() {
    return {
      pedidoAlinhadoresSelecionado:
        null as CobrancasPedidoAlinhadoresItem | null,
      currentPage: 1 as number,
      perPage: 10 as number,
    };
  },
  methods: {
    selecionarCobranca(cobranca: CobrancasPedidoAlinhadoresItem) {
      if (cobranca.dadosCobrancaAsaas) {
        window.open(cobranca.dadosCobrancaAsaas?.invoiceUrl, "_blank");
      }
    },
    verificarRechamada(page: number) {
      const itensPageLoaded = Math.ceil(
        this.pedidoAlinhadoresLista.length / this.perPage
      );
      const itensPageTotal = Math.ceil(this.totalItens / this.perPage);
      if (page > itensPageLoaded && page <= itensPageTotal) {
        this.carregarNovosItens(Math.floor(page / this.perPage) + 1);
      }
    },
    carregarNovosItens(page: number) {
      this.$emit("carregarNovaPagina", page);
    },
  },
});

export default financeiroPedidoAlinhadoresTabela;
