import Repository from "@/service/bluealApi/BluealApiCliente";

const resource = "/pedido_alinhadores";

export default {
  listaProjetosAptosPedidos() {
    return Repository().get(`${resource}/lista_projetos_apto_pedido`);
  },
  buscaProjetoAptoPedido(payload) {
    return Repository().post(`${resource}/busca_projeto_apto_pedido`, payload);
  },
  listaPedidosUsuario() {
    return Repository().get(`${resource}/lista_pedidos_usuario`);
  },
  listaPedidosTodos() {
    return Repository().get(`${resource}/lista_pedidos_todos`);
  },
  pedirAlinhadores(payload) {
    return Repository().post(`${resource}/incluir_pedido`, payload);
  },
  finalizarPedido(payload) {
    return Repository().post(`${resource}/finalizar`, payload);
  },
  calcFrete(payload) {
    return Repository().post(`${resource}/calc_frete`, payload);
  },
};
